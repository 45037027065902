import { gql } from '@apollo/client'

export const orderFields = gql`
  fragment OrderFields on Order {
    _id
    customer
    customerId
    payment {
      status
      amountRefunded
      currency
    }
    paidAt
    createdAt
    items {
      product
      quantity
    }
    occurrence
    currency
    instalments {
      count
      invoices {
        id
        status
        total
        currency
      }
    }
    sequence
    values {
      discount
      discountPercentage
      total
      totalWithoutDiscounts
      shipping
    }
    locale
    preset
    pricing {
      amount
      products {
        product
        quantity
      }
    }
    discounts {
      ref
      refId
      amount
      currency
    }
    invoice {
      id
      status
    }
    subscription {
      id
    }
    vouchers {
      used
      errors {
        message
      }
      systemDefault
      voucher {
        _id
        code
        active
        applicableRules {
          name
          targetGroup
          locales
          validUntil
          pricing
          firstPurchase
        }
        discountGroupId
      }
    }
  }
`

export const getAllOrders = gql`
  ${orderFields}
  query GetAllOrders($offset: Int, $limit: Int, $filter: OrdersFilter) {
    getAllOrders(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...OrderFields
      }
      totalRows
    }
  }
`

export const getCustomerByV1Id = gql`
  query GetCustomerByV1Id($customerId: String!) {
    getCustomerByV1Id(customerId: $customerId) {
      _id
      phoneNumber
      email
      billingAddress {
        city
        firstName
        lastName
        street
        CPF
        phoneNumber
        postalCode
        gender
        neighborhood
        state
        streetNumber
        #  additionalAddress
      }
      shippingAddress {
        city
        firstName
        lastName
        street
        CPF
        phoneNumber
        postalCode
        gender
        neighborhood
        state
        streetNumber
        #  additionalAddress
      }
      metadata {
        v1UserId
      }
    }
  }
`
