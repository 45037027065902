import { gql } from '@apollo/client'

export const getPricing = gql`
  query GetPricing($filter: PricingFilter, $offset: Int, $limit: Int) {
    pricing(filter: $filter, offset: $offset, limit: $limit) {
      rows {
        _id
        occurrences
        products {
          product
          quantity
        }
        locale
        amount
        currency
      }
      totalRows
    }
  }
`
