import EuroIcon from '@mui/icons-material/Euro'
import { Box } from '@mui/material'

export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
  BRL = 'BRL',
}

export const CurrencyAmount = ({ amount, currency }: any) => {
  return (
    <Box
      sx={{
        px: 1,
        py: 2,
        mx: 1,
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
      }}
    >
      {(amount / 100).toFixed(2)}{' '}
      {currency === Currency.EUR ? (
        <EuroIcon
          sx={{
            fontSize: '14px',
          }}
        />
      ) : (
        currency
      )}
    </Box>
  )
}
