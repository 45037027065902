import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { PatientsFilters } from './patients.filters'
import { PatientsTable } from './patients.table'

const CUSTOMER_FILTER_INIT_VALUES = {
  id: StringParam,
  // v1UserId: StringParam,
  email: StringParam,
  // paypalEmail: StringParam,
  // name: StringParam,
  accountId: StringParam,
  patientId: StringParam,
}

export const PatientsPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={CUSTOMER_FILTER_INIT_VALUES}>
        <PatientsFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <PatientsTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
