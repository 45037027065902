import { Button, ButtonProps, styled } from '@mui/material'
import React from 'react'

export const DismissButton = styled((props: ButtonProps) => (
  <Button
    sx={{
      textTransform: 'unset',
      color: '#666678',
      '&:hover': {
        color: '#1b3e88',
        backgroundColor: 'unset',
      },
    }}
    variant="text"
    disableElevation
    disableRipple
    disableFocusRipple
    disableTouchRipple
    {...props}
  >
    Dismiss
  </Button>
))()
