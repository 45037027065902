import { Box, Typography } from '@mui/material'
import { HorizontalList } from '@trueskin-backoffice/components'

export const CaseGoals = ({ currentCase, hideLabel }: any) => {
  if (!currentCase?.questionnaireFields?.treatmentGoals) {
    return null
  }

  return (
    <HorizontalList sx={{ maxWidth: 200 }}>
      {!hideLabel && (
        <Typography variant="caption" sx={{ fontWeight: 600, pr: 0, mr: 1 }}>
          Goals
        </Typography>
      )}

      {currentCase?.questionnaireFields?.treatmentGoals.map((x: any) => (
        <Box
          sx={{
            maxWidth: 300,
            wordBreak: 'break-all',
            textAlign: 'left',
            fontSize: 12,
            display: 'inline-flex',
            flexGrow: 1,
            alignItems: 'center',
            // pr: 5,
            px: 1,
            borderRadius: 1,
            position: 'relative',
            verticalAlign: 'middle',
            backgroundColor: caseGoalBg(x),
            color: caseGoalColor(x),
            mr: 1,
          }}
        >
          <Box>{x}</Box>
        </Box>
      ))}
    </HorizontalList>
  )
}

const caseGoalBg = (goal: string) => {
  switch (goal) {
    case 'unknown': {
      return '#FCFCBB'
    }
    case 'acne': {
      return '#6C2084'
    }
    case 'rosacea': {
      return '#AFEEDF'
    }
    case 'moles': {
      return '#4AC9CB'
    }
    case 'hair_or_nails': {
      return '#D3F394'
    }
    case 'rash': {
      return '#C1AAB1'
    }
    case 'psoriasis': {
      return '#578CEF'
    }
    case 'other': {
      return '#000'
    }
    default: {
      return '#FFF'
    }
  }
}

const caseGoalColor = (goal: string) => {
  switch (goal) {
    case 'unknown': {
      return '#000'
    }
    case 'acne': {
      return '#FFF'
    }
    case 'rosacea': {
      return '#000'
    }
    case 'moles': {
      return '#FFF'
    }
    case 'hair_or_nails': {
      return '#000'
    }
    case 'rash': {
      return '#FFF'
    }
    case 'psoriasis': {
      return '#FFF'
    }
    case 'other': {
      return '#FFF'
    }
    default: {
      return '#000'
    }
  }
}
