import { DataObject } from '@mui/icons-material'
import { Box, Dialog, IconButton } from '@mui/material'
import { useState } from 'react'
import JsonView from 'react-json-view'

export const JsonButton = ({ id, jsonData }: any) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setOpen(true)
        }}
      >
        <DataObject />
      </IconButton>

      <Dialog
        open={open}
        fullWidth={true}
        sx={{ '& .MuiPaper-root': { maxWidth: 'unset', width: 'fit-content' } }}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            p: 4,
          }}
        >
          <JsonView name={id} src={jsonData} />
        </Box>
      </Dialog>
    </>
  )
}
