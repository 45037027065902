import { gql } from '@apollo/client'

export const getCurrentEmployeeQuery = gql`
  query GetCurrentEmployee {
    currentEmployee {
      id
      email
      firstName
      lastName
      roles
      profile
    }
  }
`

export const employeeGoogleLoginMutation = gql`
  mutation EmployeeGoogleLogin($accessToken: String!, $identifier: String!) {
    employeeGoogleLogin(accessToken: $accessToken, identifier: $identifier) {
      jwt
      #      employee {
      #        id
      #        firstName
      #        lastName
      #        email
      #        roles
      #        #        profile
      #      }
    }
  }
`
