import { Edit } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import { DockAction } from '@trueskin-backoffice/components'
import React from 'react'
import 'react-day-picker/dist/style.css'
import { UpdateBirthNameModal } from '../../components/update-birth-date.modal'

export const EditBirthDateAction = () => {
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  return (
    <>
      <DockAction startIcon={<Edit />} onClick={() => setShowEditDialog(true)}>
        Birth date
      </DockAction>

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <UpdateBirthNameModal onClose={() => setShowEditDialog(false)} />
        </Dialog>
      ) : null}
    </>
  )
}
