import { Box, Button, TextField } from '@mui/material'
import { H1 } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { useCustomerMutations } from '../contexts'

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
})

export const UpdateFirstNameModal = ({ onClose }: { onClose: any }) => {
  const { customer } = useCustomer()
  const { updateCustomerFirstName } = useCustomerMutations()

  return (
    <Formik
      initialValues={{
        firstName: customer.firstName,
      }}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await updateCustomerFirstName(values.firstName)
        onClose()
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              minWidth: 500,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <H1>Update customer first name</H1>

            <Box>
              <Box sx={{ mb: 4 }}>
                Current first name value: {customer.firstName}
              </Box>

              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="First name"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && Boolean(errors.firstName)}
              />

              <Box sx={{ color: 'red' }}>
                <ErrorMessage name="firstName" />
              </Box>
            </Box>

            <Box>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
