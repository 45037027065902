import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination as MuiPagination,
  Select,
  SelectChangeEvent,
  styled,
  TablePagination,
} from '@mui/material'
import { ChangeEvent, FC, Fragment, useState } from 'react'

const StyledSimplePagination = styled(MuiPagination)`
  display: flex;
  justify-content: right;
`

interface IRowPerPageChangeEvent {
  (event: ChangeEvent<HTMLInputElement>): number
  (event: SelectChangeEvent<string>): number
}

enum PaginationStyle {
  Simple = 'simple',
  Table = 'table',
}

interface PaginationProps {
  page?: number
  totalPages: number
  pageSize?: number
  paginationStyle?: PaginationStyle
  onPageChange: any
  onRowsPerPageChange: any
}

const RowOptions = [10, 25, 50]

const Pagination: FC<PaginationProps> = ({
  page,
  totalPages,
  pageSize = RowOptions[0],
  paginationStyle = PaginationStyle.Simple,
  onPageChange,
  onRowsPerPageChange,
}: PaginationProps) => {
  const [paginationPage, setPaginationPage] = useState(page || 1)

  const handlePageChange = (event: unknown, currentPage: number) => {
    setPaginationPage(currentPage === 0 ? 1 : currentPage)
    onPageChange?.(currentPage === 0 ? 1 : currentPage)
  }
  const handleRowsPerPageChange: IRowPerPageChangeEvent = (event) => {
    const rows = Number(event?.target.value)
    onRowsPerPageChange?.(rows)
    return rows
  }

  if (!totalPages && !page) {
    return null
  }

  return (
    <Fragment>
      {paginationStyle === 'simple' && (
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <FormControl size="small">
              <InputLabel>Rows</InputLabel>
              <Select
                value={pageSize.toString()}
                label="Rows"
                onChange={handleRowsPerPageChange}
              >
                {RowOptions?.map((rowOption) => (
                  <MenuItem key={rowOption} value={rowOption}>
                    {rowOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <StyledSimplePagination
              count={totalPages}
              page={paginationPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      )}
      {paginationStyle === 'table' && (
        <TablePagination
          rowsPerPageOptions={RowOptions}
          component="div"
          count={totalPages}
          page={paginationPage}
          rowsPerPage={pageSize}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Fragment>
  )
}

export { Pagination, PaginationStyle }
