import { Button } from '@mui/material'
import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { useNavigate } from 'react-router-dom'

export const PricingFilters = () => {
  const navigate = useNavigate()

  return (
    <DataFilterV2
      inputRows={[
        [
          {
            label: 'Search for product id',
            key: 'productIds',
            type: DataFilterV2FilterComponent.TEXT,
            op: DataFilterV2InputOperation.CONTAINS,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
        ],
      ]}
      additionalFilters={[
        {
          label: 'Currency',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'currency',
          op: DataFilterV2InputOperation.EQUALS,
        },
        {
          label: 'Locale',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'locale',
          op: DataFilterV2InputOperation.EQUALS,
        },
        {
          label: 'Occurrence',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'occurrence',
          op: DataFilterV2InputOperation.EQUALS,
        },
        {
          label: 'Tags',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'tags',
          op: DataFilterV2InputOperation.EQUALS,
        },
      ]}
    >
      <Button onClick={() => navigate('/pricing/create')}>
        Create new pricing
      </Button>
    </DataFilterV2>
  )
}
