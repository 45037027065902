import { gql } from '@apollo/client'

/**
 * Do not resolve attachments, messages or accountEmail in this query as those fields trigger extra resolvers
 */
export const getWaitingRoomCases = gql`
  query waitingRoomCases(
    $offset: Int!
    $limit: Int!
    $filter: WaitingRoomsFilter
  ) {
    waitingRoomCases(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        accountId
        customerId
        documentNumber
        status
        extendedStatus
        uploadedPhotosCount
        #        questionnaireSummary
        #        attachments
        #        messages
        #        paymentObject
        #        orderObject
        customer
        metadata
        postDiagnosisMetadata
        chatEnabled

        actions {
          canAssignCase {
            enabled
          }
          canAssignCaseDiagnosisContentRequest {
            enabled
          }
          canWriteMessage {
            enabled
          }
          canCreateDiagnosisDraft {
            enabled
          }
          canPublishDiagnosis {
            enabled
          }
          canRejectDiagnosis {
            enabled
          }
        }

        questionnaireFields
      }
      totalRows
    }
  }
`

export const getWaitingRoomCase = gql`
  query waitingRoomCase($caseId: String!) {
    waitingRoomCase(caseId: $caseId) {
      id
      createdAt
      accountId
      customerId
      #      accountEmail
      documentNumber
      status
      assignedTo
      extendedStatus
      questionnaireSummary
      diagnosis
      # TODO @fox: we should remove this from here because we have an extra query to load this information
      diagnosisRecord
      attachments
      messages
      paymentObject
      orderObject
      rejectionReason
      rejectionMessage
      rejectedBy
      customer
      patient {
        id
        profile
        medicalData
        metadata
        cases
      }
      metadata
      postDiagnosisMetadata
      chatEnabled

      questionnaireFields

      actions {
        canAssignCase {
          enabled
        }
        canAssignCaseDiagnosisContentRequest {
          enabled
        }
        canWriteMessage {
          enabled
        }
        canCreateDiagnosisDraft {
          enabled
        }
        canPublishDiagnosis {
          enabled
        }
        canRejectDiagnosis {
          enabled
        }
      }
    }
  }
`

export const getWaitingRoomCaseAttachments = gql`
  query waitingRoomCaseAttachments($caseId: String!) {
    waitingRoomCase(caseId: $caseId) {
      id
      accountId
      customerId
      extendedStatus
      attachments
    }
  }
`

export const getWaitingRoomCaseDiagnosisQuery = gql`
  query waitingRoomCaseDiagnosis($caseId: String!) {
    waitingRoomCase(caseId: $caseId) {
      id
      diagnosis
      diagnosisRecord
    }
  }
`

export const getWaitingRoomCaseDiagnosisPreviewQuery = gql`
  query waitingRoomCaseDiagnosisPreview($caseId: String!) {
    waitingRoomCase(caseId: $caseId) {
      id
      diagnosis
      diagnosisPreview
    }
  }
`

export const addDoctorMessageToCaseMutation = gql`
  mutation AddDoctorMessageToCaseMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $richText: String!
    $markdown: String!
    $prefillHeaderAndFooter: Boolean
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      addDoctorMessageToCase(
        doctor: $doctor
        caseId: $caseId
        richText: $richText
        markdown: $markdown
        prefillHeaderAndFooter: $prefillHeaderAndFooter
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const rejectDiagnosisForCaseMutation = gql`
  mutation RejectDiagnosisForCaseMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $rejectionReason: CaseRejectionReason!
    $rejectionMessageRichText: String!
    $rejectionMessageMarkdown: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      rejectDiagnosisForCase(
        doctor: $doctor
        caseId: $caseId
        rejectionReason: $rejectionReason
        rejectionMessageRichText: $rejectionMessageRichText
        rejectionMessageMarkdown: $rejectionMessageMarkdown
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const createDraftDiagnosisForCaseMutation = gql`
  mutation CreateDraftDiagnosisForCaseMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      createDraftDiagnosisForCase(doctor: $doctor, caseId: $caseId) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const setHeadingNotesForDiagnosisMutation = gql`
  mutation SetHeadingNotesForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput
    $diagnosisRecordId: String!
    $editorState: String!
    $richText: String!
    $markdown: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      setHeadingNotesForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        editorState: $editorState
        richText: $richText
        markdown: $markdown
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const setDiagnosisDetailsNotesForDiagnosisMutation = gql`
  mutation SetDiagnosisDetailsNotesForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput
    $diagnosisRecordId: String!
    $editorState: String!
    $richText: String!
    $markdown: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      setDiagnosisDetailsNotesForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        editorState: $editorState
        richText: $richText
        markdown: $markdown
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

// export const setDiagnosisNotesForDiagnosisMutation = gql`
//   mutation SetDiagnosisNotesForDiagnosisMutation(
//     $customerId: String!
//     $caseId: String!
//     $doctor: DoctorProfileInput!
//     $diagnosisRecordId: String!
//     $editorState: String!
//     $richText: String!
//     $markdown: String!
//   ) {
//     patientCase(customerId: $customerId, caseId: $caseId) {
//       setDiagnosisNotesForDiagnosis(
//         doctor: $doctor
//         diagnosisRecordId: $diagnosisRecordId
//         editorState: $editorState
//         richText: $richText
//         markdown: $markdown
//       ) {
//         case {
//           id
//           accountId
//           customerId
//           documentNumber
//           status
//           extendedStatus
//           assignedTo
//           customer
//           metadata
//           chatEnabled
//         }
//       }
//     }
//   }
// `

// export const setPrescriptionNotesForDiagnosisMutation = gql`
//   mutation SetPrescriptionNotesForDiagnosisMutation(
//     $customerId: String!
//     $caseId: String!
//     $doctor: DoctorProfileInput!
//     $diagnosisRecordId: String!
//     $editorState: String!
//     $richText: String!
//     $markdown: String!
//   ) {
//     patientCase(customerId: $customerId, caseId: $caseId) {
//       setPrescriptionNotesForDiagnosis(
//         doctor: $doctor
//         diagnosisRecordId: $diagnosisRecordId
//         editorState: $editorState
//         richText: $richText
//         markdown: $markdown
//       ) {
//         case {
//           id
//           accountId
//           customerId
//           documentNumber
//           status
//           extendedStatus
//           assignedTo
//           customer
//           metadata
//           chatEnabled
//         }
//       }
//     }
//   }
// `

export const setSkinFindingsNodesForDiagnosisMutation = gql`
  mutation SetSkinFindingsNotesForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput
    $diagnosisRecordId: String!
    $editorState: String!
    $richText: String!
    $markdown: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      setSkinFindingsNotesForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        editorState: $editorState
        richText: $richText
        markdown: $markdown
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const setTreatmentPlanNotesForDiagnosisMutation = gql`
  mutation SetTreatmentPlanNotesForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput
    $diagnosisRecordId: String!
    $editorState: String!
    $richText: String!
    $markdown: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      setTreatmentPlanNotesForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        editorState: $editorState
        richText: $richText
        markdown: $markdown
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const setAdditionalAdviceNotesForDiagnosisMutation = gql`
  mutation SetAdditionalAdvicesNoteForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput
    $diagnosisRecordId: String!
    $editorState: String!
    $richText: String!
    $markdown: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      setAdditionalAdviceNotesForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        editorState: $editorState
        richText: $richText
        markdown: $markdown
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const AddConditionDiagnosisMutation = gql`
  mutation AddConditionDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
    $diagnosisType: String!
    $medicalDiagnosis: String!
    $icd10Diagnosis: String!
    $recommendOfflineConsultation: Boolean
    $additionalDirections: String
    $prefillId: String
    $candidateForCore: Boolean
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      addConditionDiagnosisForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        diagnosisType: $diagnosisType
        medicalDiagnosis: $medicalDiagnosis
        icd10Diagnosis: $icd10Diagnosis
        recommendOfflineConsultation: $recommendOfflineConsultation
        additionalDirections: $additionalDirections
        prefillId: $prefillId
        candidateForCore: $candidateForCore
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const updateConditionDiagnosisMutation = gql`
  mutation updateConditionDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $conditionDiagnosisId: String!
    $diagnosisRecordId: String!
    $diagnosisType: String!
    $medicalDiagnosis: String!
    $icd10Diagnosis: String!
    $recommendOfflineConsultation: Boolean
    $additionalDirections: String
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      updateConditionDiagnosisForDiagnosis(
        doctor: $doctor
        conditionDiagnosisId: $conditionDiagnosisId
        diagnosisRecordId: $diagnosisRecordId
        diagnosisType: $diagnosisType
        medicalDiagnosis: $medicalDiagnosis
        icd10Diagnosis: $icd10Diagnosis
        recommendOfflineConsultation: $recommendOfflineConsultation
        additionalDirections: $additionalDirections
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const RemoveConditionDiagnosisMutation = gql`
  mutation RemoveConditionDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
    $conditionDiagnosisId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      removeConditionDiagnosisForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        conditionDiagnosisId: $conditionDiagnosisId
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const AddMedicationForDiagnosisMutation = gql`
  mutation AddMedicationForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
    $medication: String!
    $requiresPrescription: Boolean
    $usageDirections: String!
    $additionalDirections: String
    $prefillId: String
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      addMedicationForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        medication: $medication
        requiresPrescription: $requiresPrescription
        usageDirections: $usageDirections
        additionalDirections: $additionalDirections
        prefillId: $prefillId
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const UpdateMedicationForDiagnosisMutation = gql`
  mutation UpdateMedicationForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
    $medicationId: String!
    $medication: String!
    $requiresPrescription: Boolean
    $usageDirections: String!
    #    $usageDirectionsShort: String!
    $additionalDirections: String
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      updateMedicationForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        medicationId: $medicationId
        medication: $medication
        requiresPrescription: $requiresPrescription
        usageDirections: $usageDirections
        #        usageDirectionsShort: $usageDirectionsShort
        additionalDirections: $additionalDirections
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const RemoveMedicationForDiagnosisMutation = gql`
  mutation RemoveMedicationForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
    $medicationId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      removeMedicationForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        medicationId: $medicationId
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const setPersonalizedTreatmentForDiagnosisMutation = gql`
  mutation SetPersonalizedTreatmentForDiagnosisMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
    $enabled: Boolean!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      setPersonalizedTreatmentForDiagnosis(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
        enabled: $enabled
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const publishDiagnosisForCaseMutation = gql`
  mutation PublishDiagnosisForCaseMutation(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
    $diagnosisRecordId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      publishDiagnosisForCase(
        doctor: $doctor
        diagnosisRecordId: $diagnosisRecordId
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const watchCaseMutation = gql`
  mutation WatchCaseMutation(
    $customerId: String!
    $caseId: String!
    $employeeId: String!
    $email: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      watchCase(caseId: $caseId, employeeId: $employeeId, email: $email) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const unwatchCaseMutation = gql`
  mutation UnwatchCaseMutation(
    $customerId: String!
    $caseId: String!
    $employeeId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      unwatchCase(caseId: $caseId, employeeId: $employeeId) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const caseDoesNotRequireMedicalReplyMutation = gql`
  mutation CaseDoesNotRequireMedicalReply(
    $customerId: String!
    $caseId: String!
    $employeeId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      caseDoesNotRequireMedicalReply(caseId: $caseId, employeeId: $employeeId) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const requestDiagnosisContentMutation = gql`
  mutation RequestDiagnosisContentMutation(
    $customerId: String!
    $caseId: String!
    $employeeId: String!
    $diagnosisRecordId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      requestContentForDiagnosis(
        employeeId: $employeeId
        diagnosisRecordId: $diagnosisRecordId
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`

export const requestDiagnosisContentReviewMutation = gql`
  mutation RequestDiagnosisContentReviewMutation(
    $customerId: String!
    $caseId: String!
    $employeeId: String!
    $diagnosisRecordId: String!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      requestContentReviewForDiagnosis(
        employeeId: $employeeId
        diagnosisRecordId: $diagnosisRecordId
      ) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
          postDiagnosisMetadata
          chatEnabled
        }
      }
    }
  }
`
