import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { Currency, H2 } from '@trueskin-backoffice/components'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import omitDeep from 'omit-deep-lodash'
import { SetStateAction, useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import {
  createDiscountGroupMutation,
  updateDiscountGroupMutation,
} from '../discount-groups/discount-groups.gql.bff'
import { FormSection } from '../employees/employee.components'
import { getDiscountGroupByIdQuery } from './discount-group.gql.bff'

enum RedemptionRule {
  MAX_REDEMPTIONS = 'MAX_REDEMPTIONS',
}

enum SelectionRule {
  OCCURRENCES = 'OCCURRENCES',
}

const INITIAL_VALUES = {
  name: '',
  definition: {
    confirmationMessage: '',
    referralClaMessage: '',
  },
  variants: [
    {
      selectionRules: [
        {
          name: SelectionRule.OCCURRENCES,
          occurrences: ['occ_daily', 'occ_monthly'],
        },
      ],
      discount: {
        currency:
          process.env['NX_I18N_LOCALE'] === 'pt-BR'
            ? Currency.BRL
            : Currency.EUR,
        type: 'AMOUNT',
        amount: '',
      },
      redemptionRules: [],
      definition: {
        confirmationMessage: '',
      },
    },
    {
      selectionRules: [
        {
          name: SelectionRule.OCCURRENCES,
          occurrences: ['occ_2days', 'occ_bimonthly'],
        },
      ],
      discount: {
        currency:
          process.env['NX_I18N_LOCALE'] === 'pt-BR'
            ? Currency.BRL
            : Currency.EUR,
        type: 'AMOUNT',
        amount: '',
      },
      redemptionRules: [],
      definition: {
        confirmationMessage: '',
      },
    },
  ],
  available: false,
}

const DiscountGroupVariantRedemptionRuleComponentConfig = () => {
  const ADD_RULES_BUTTONS = [
    {
      type: RedemptionRule.MAX_REDEMPTIONS,
      initialValue: {
        name: RedemptionRule.MAX_REDEMPTIONS,
        maxRedemptions: '',
      },
    },
  ]

  const RULES_FIELDS_MAP = {
    [RedemptionRule.MAX_REDEMPTIONS]: (
      variantIndex: number,
      redemptionIndex: number,
      editMode: boolean,
    ) => (
      <Field
        size="small"
        style={{ width: '100%', maxWidth: '170px' }}
        name={`variants[${variantIndex}].redemptionRules[${redemptionIndex}].maxRedemptions`}
        label="Max redemptions"
        type="number"
        disabled={editMode}
        InputProps={{
          inputProps: {
            min: 1,
          },
        }}
        as={TextField}
      />
    ),
  }

  return { ADD_RULES_BUTTONS, RULES_FIELDS_MAP }
}

const DiscountGroupVariantRedemptionRuleComponent = ({
  formik,
  variantIndex,
  variant,
  editMode,
}: any) => {
  const { ADD_RULES_BUTTONS, RULES_FIELDS_MAP } =
    DiscountGroupVariantRedemptionRuleComponentConfig()

  return (
    <FormSection
      label="Redemption rules"
      value={
        <FormikProvider value={formik}>
          <FieldArray
            name={`variants[${variantIndex}].redemptionRules`}
            render={(redemptionRulesArrayHelper: any) => {
              return (
                <div>
                  {variant.redemptionRules.map(
                    (redemptionRule: any, redemptionIndex: number) => {
                      return (
                        <div
                          key={redemptionIndex}
                          style={{
                            display: 'flex',
                            paddingTop: '10px',
                          }}
                        >
                          {RULES_FIELDS_MAP[
                            redemptionRule.name as RedemptionRule
                          ](variantIndex, redemptionIndex, editMode)}

                          {false && (
                            <Button
                              type="button"
                              onClick={() =>
                                redemptionRulesArrayHelper.remove(
                                  redemptionIndex,
                                )
                              }
                            >
                              Remove redemption rule
                            </Button>
                          )}
                        </div>
                      )
                    },
                  )}

                  {ADD_RULES_BUTTONS.map(({ initialValue, type }) => {
                    if (
                      variant.redemptionRules.find(
                        (item: any) => item.name === type,
                      )
                    ) {
                      return
                    }

                    return (
                      <Button
                        type="button"
                        onClick={() => {
                          return redemptionRulesArrayHelper.push(initialValue)
                        }}
                      >
                        Add {type}
                      </Button>
                    )
                  })}
                </div>
              )
            }}
          />
        </FormikProvider>
      }
    />
  )
}

const DiscountGroupVariantComponent = ({ formik, editMode }: any) => {
  return (
    <FormSection
      label="Variants"
      value={
        <FormikProvider value={formik}>
          <FieldArray
            name="variants"
            render={() => {
              return (
                <div>
                  {formik.values.variants.map(
                    (variant: any, variantIndex: number) => {
                      return (
                        <div key={variantIndex}>
                          <div
                            style={{
                              display: 'flex',
                              paddingTop: '10px',
                            }}
                          >
                            <Field
                              size="small"
                              style={{
                                width: '100%',
                                maxWidth: '170px',
                                marginRight: 4,
                              }}
                              value={variant.selectionRules
                                .find(
                                  (item: any) =>
                                    item.name === SelectionRule.OCCURRENCES,
                                )
                                .occurrences.join('/')}
                              label="Occurrence"
                              disabled={true}
                              as={TextField}
                            />
                            <Field
                              size="small"
                              style={{
                                width: '100%',
                                maxWidth: '170px',
                                marginRight: 4,
                              }}
                              name={`variants[${variantIndex}].discount.amount`}
                              label="Discount amount"
                              type="number"
                              disabled={editMode}
                              InputProps={{ inputProps: { min: 1 } }}
                              as={TextField}
                            />
                            <Field
                              size="small"
                              style={{ width: '100%' }}
                              name={`variants[${variantIndex}].definition.confirmationMessage`}
                              as={TextField}
                              label="Confirmation message"
                            />
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              paddingTop: '10px',
                            }}
                          >
                            {DiscountGroupVariantRedemptionRuleComponent({
                              formik,
                              variantIndex,
                              variant,
                              editMode,
                            })}
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              )
            }}
          />
        </FormikProvider>
      }
    />
  )
}

export const DiscountGroupPage = () => {
  const [
    createDiscountGroup,
    {
      data: createdDiscountGroup,
      loading: discountGroupCreationLoading,
      error: discountGroupCreationError,
    },
  ] = useMutation(createDiscountGroupMutation)

  const [
    updateDiscountGroup,
    {
      data: updatedDiscountGroup,
      loading: discountGroupUpdatingLoading,
      error: discountGroupUpdatingError,
    },
  ] = useMutation(updateDiscountGroupMutation)

  const [
    getDiscountGroupById,
    {
      data: foundDiscountGroup,
      loading: discountGroupFindingLoading,
      error: discountGroupFindingError,
    },
  ] = useLazyQuery(getDiscountGroupByIdQuery)

  const { discountGroupId } = useParams()

  const [editMode, setEditMode] = useState(false)
  const [numberOfRequiredVariants, setNumberOfRequiredVariants] = useState(2)

  useEffect(() => {
    ;(async () => {
      if (discountGroupId && discountGroupId !== 'create') {
        setEditMode(true)
        setNumberOfRequiredVariants(1)

        await getDiscountGroupById({
          variables: { id: discountGroupId },
          fetchPolicy: 'no-cache',
        })
      }
    })()
  }, [discountGroupId])

  useEffect(() => {
    if (foundDiscountGroup?.discountGroup) {
      formik.setValues(
        omitDeep(
          foundDiscountGroup.discountGroup,
          '__typename',
        ) as SetStateAction<any>,
      )
    }
  }, [foundDiscountGroup])

  const formik = useFormik<{
    name: string
    definition: {
      confirmationMessage: string
      referralClaMessage: string
    }
    variants: any[]
    available: boolean
  }>({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      return editMode
        ? updateDiscountGroup({ variables: { discountGroup: values } })
        : createDiscountGroup({ variables: { discountGroup: values } })
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      definition: Yup.object({
        confirmationMessage: Yup.string().required('Required'),
        referralClaMessage: Yup.string().nullable(),
      }),
      available: Yup.boolean(),
      variants: Yup.array(
        Yup.object({
          selectionRules: Yup.array(
            Yup.object({
              name: Yup.string().required('Required'),
              occurrences: Yup.array(Yup.string()),
            }),
          ),
          discount: Yup.object({
            amount: Yup.number().min(1).required(),
          }),
          redemptionRules: Yup.array(
            Yup.object({
              name: Yup.string().required('Required'),
              maxRedemptions: Yup.number().required(),
            }),
          )
            .min(
              1,
              'Discount group should contain at least one redemption rule',
            )
            .required(),
          definition: Yup.object({
            confirmationMessage: Yup.string().required(),
          }),
        }),
      )
        .min(
          numberOfRequiredVariants,
          `Discount group should contain at least ${numberOfRequiredVariants} variant${
            numberOfRequiredVariants > 1 ? 's' : ''
          }`,
        )
        .required(),
    }),
  })

  if (
    createdDiscountGroup?.createDiscountGroup?.id ||
    updatedDiscountGroup?.updateDiscountGroup
  ) {
    return <Navigate to="/discount-groups" />
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 20, py: 10 }}>
      <Box sx={{ m: 2 }}>
        <H2>{editMode ? 'Edit' : 'Create'} discount group</H2>
      </Box>

      <Card sx={{ p: 3 }}>
        {(discountGroupFindingLoading ||
          discountGroupCreationLoading ||
          discountGroupUpdatingLoading) && <LinearProgress />}

        {(discountGroupFindingError ||
          discountGroupCreationError ||
          discountGroupUpdatingError) && (
          <Typography color={'red'}>
            {
              (
                discountGroupFindingError ||
                discountGroupCreationError ||
                discountGroupUpdatingError
              )?.message
            }
          </Typography>
        )}

        <Grid container spacing={2} alignItems="center">
          <FormSection
            label="Name"
            value={
              <TextField
                size="small"
                sx={{ width: '100%' }}
                value={formik.values.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                onBlur={() => formik.setFieldTouched('name', true)}
                error={Boolean(formik.errors.name && formik.touched.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            }
          />

          <FormSection
            label="Confirmation message"
            value={
              <TextField
                size="small"
                sx={{ width: '100%' }}
                value={formik.values.definition.confirmationMessage}
                onChange={(e) => {
                  formik.setFieldValue(
                    'definition.confirmationMessage',
                    e.target.value,
                  )
                }}
                onBlur={() =>
                  formik.setFieldTouched('definition.confirmationMessage', true)
                }
                error={Boolean(
                  formik.errors.definition?.confirmationMessage &&
                    formik.touched.definition?.confirmationMessage,
                )}
                helperText={
                  formik.touched.definition?.confirmationMessage &&
                  formik.errors.definition?.confirmationMessage
                }
              />
            }
          />

          <FormSection
            label="Referral CLA Message"
            value={
              <TextField
                size="small"
                sx={{ width: '100%' }}
                value={formik.values.definition.referralClaMessage}
                onChange={(e) => {
                  formik.setFieldValue(
                    'definition.referralClaMessage',
                    e.target.value,
                  )
                }}
                onBlur={() =>
                  formik.setFieldTouched('definition.referralClaMessage', true)
                }
                error={Boolean(
                  formik.errors.definition?.referralClaMessage &&
                    formik.touched.definition?.referralClaMessage,
                )}
                helperText={
                  formik.touched.definition?.referralClaMessage &&
                  formik.errors.definition?.referralClaMessage
                }
              />
            }
          />

          <FormSection
            label="Available"
            value={
              <Checkbox
                checked={formik.values.available}
                onChange={(e) => {
                  formik.setFieldValue('available', e.target.checked)
                }}
                onBlur={() => formik.setFieldTouched('available', true)}
              />
            }
          />

          {DiscountGroupVariantComponent({ formik, editMode })}
        </Grid>
      </Card>

      <Button
        variant="contained"
        sx={{ alignSelf: 'center', m: 2 }}
        onClick={formik.submitForm}
        disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
      >
        {editMode ? 'Update' : 'Create'}
      </Button>
    </Box>
  )
}
