import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import * as yup from 'yup'
import { H1 } from '../../headings'
import { AccountMutationsProvider, useAccountMutations } from '../context'

export const EditAccountPasswordModal = ({
  accountId,
  open,
  onClose,
  onComplete,
}: any) => {
  if (!open || !accountId) {
    return null
  }

  return (
    <AccountMutationsProvider accountId={accountId}>
      <Modal onClose={onClose} onComplete={onComplete} />
    </AccountMutationsProvider>
  )
}

const Modal = ({ onClose, onComplete }: { onClose: any; onComplete?: any }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    updateAccountPassword,
    updateAccountPasswordLoading,
    updateAccountPasswordError,
  } = useAccountMutations()

  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: '',
      }}
      validateOnChange={true}
      validationSchema={yup.object({
        newPassword: yup
          .string()
          .min(8, 'Password must be at least 8 characters long')
          .required('New password required'),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('newPassword')], 'Passwords must match'),
      })}
      onSubmit={async (values) => {
        try {
          await updateAccountPassword(values)
          enqueueSnackbar('Password updated successfully.', {
            variant: 'success',
          })
          onComplete?.()
          onClose()
        } catch (err) {
          enqueueSnackbar(
            `Password update failed. ${JSON.stringify(
              updateAccountPasswordError || err,
            )}`,
            {
              variant: 'error',
            },
          )
        }
      }}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form autoComplete="off">
          <Box
            sx={{
              minWidth: 500,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <H1>Update account password</H1>

            <Box sx={{ mt: 4 }}>
              <Box>
                <PasswordInput
                  name="newPassword"
                  label="New password"
                  value={values.newPassword}
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  onChange={handleChange}
                />

                <Box sx={{ color: 'red' }}>
                  <ErrorMessage name="newPassword" />
                </Box>
              </Box>

              <Box sx={{ mt: 2 }}>
                <PasswordInput
                  name="confirmPassword"
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  onChange={handleChange}
                />

                <Box sx={{ color: 'red' }}>
                  <ErrorMessage name="confirmPassword" />
                </Box>
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={
                  !values.newPassword ||
                  !values.confirmPassword ||
                  updateAccountPasswordLoading
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const PasswordInput = ({ label, name, value, error, onChange }: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      type={showPassword ? 'text' : 'password'}
      autoComplete={`${Date.now()}_${name}`}
      value={value}
      onChange={onChange}
      error={error}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
