import { Button } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import {
  Row,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useTable,
} from 'react-table'
import { useSticky } from 'react-table-sticky'

const Styles = styled('div')({
  // width: '100%',
  '.infinite-scroll-component': {
    overflow: 'unset !important',
  },
  '.table': {
    // height: '100%',
    maxWidth: 'calc(100vw - 16px)',
    '.tr': {
      '&:last-child': {
        '.td': {
          borderBottom: 0,
        },
      },
    },
    '.th,.td': {
      padding: 4,
      borderBottom: '1px solid #ddd',
      borderRight: '1px solid #ddd',
      backgroundColor: '#fff',
      overflow: 'hidden',
      '&:last-child': {
        borderRight: 0,
      },
      '&:first-child': {
        paddingLeft: 16,
      },

      '.resizer': {
        display: 'inline-block',
        width: 2,
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        transform: 'translated(50%)',
        zIndex: 1,

        '&.isResizing': {
          background: 'red',
        },
      },
    },
  },
  '.sticky': {
    overflow: 'scroll',
    '.header,.footer': {
      position: 'sticky',
      zIndex: 1,
      width: 'fit-content',
    },
    '.header': {
      top: 0,
      boxShadow: '0px 3px 3px #ccc',
    },
    '.footer': {
      bottom: 0,
      boxShadow: '0px -3px 3px #ccc',
    },
    '.body': {
      position: 'relative',
      width: 'max-content',
      zIndex: 0,
    },
    '[data-sticky-td]': {
      position: 'sticky',
    },
    '[data-sticky-last-left-td]': {
      boxShadow: '2px 0px 3px #ccc',
    },
    '[data-sticky-first-right-td]': {
      boxShadow: '-2px 0px 3px #ccc',
    },
  },
})

export function Table({
  columns,
  data,
  update,
  expandProperty,
  hasMore = true,
}: any) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 150,
      maxWidth: 400,
    }),
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        manualPagination: true,
      },
      useFilters,
      useGlobalFilter,
      useBlockLayout,
      useSticky,
    )

  const [expandRows, setExpandRows] = React.useState<boolean[]>([])

  // Workaround as react-table footerGroups doesn't provide the same internal data than headerGroups
  const footerGroups = headerGroups.slice().reverse()

  const handleRowClick = (index: number) => () => {
    if (!expandProperty) {
      return
    }

    const copy = [...expandRows]
    copy[index] = !copy[index]
    setExpandRows(copy)
  }

  return (
    <Styles>
      <div
        {...getTableProps()}
        className="table sticky"
        // style={{ width: 800, height: 400 }}
      >
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column: any) => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  <div>{column.Filter && column.render('Filter')}</div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/*<InfiniteScroll*/}
        {/*  dataLength={rows.length}*/}
        {/*  next={update}*/}
        {/*  hasMore={true}*/}
        {/*  loader={<h4>Loading more data...</h4>}*/}
        {/*>*/}
        <div {...getTableBodyProps()} className="body">
          {rows.map((row: Row<any>, index: number) => {
            prepareRow(row)
            return (
              <div
                {...row.getRowProps()}
                className="tr"
                style={{ cursor: expandProperty ? 'pointer' : 'default' }}
                onClick={handleRowClick(index)}
              >
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  )
                })}

                {expandProperty &&
                  expandRows[index] &&
                  row.original[expandProperty]?.map((item: any, i: number) => {
                    return (
                      <div key={i} className="tr" style={{ fontSize: '0.8em' }}>
                        <pre>{JSON.stringify(item, null, 2)}</pre>
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
        {/*</InfiniteScroll>*/}

        <div className="footer">
          {hasMore && <Button onClick={update}>Load more</Button>}
          {/*  {footerGroups.map((footerGroup) => (*/}
          {/*    <div {...footerGroup.getHeaderGroupProps()} className="tr">*/}
          {/*      {footerGroup.headers.map((column) => (*/}
          {/*        <div {...column.getHeaderProps()} className="td">*/}
          {/*          {column.render('Footer')}*/}
          {/*        </div>*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*  ))}*/}
        </div>
      </div>
    </Styles>
  )
}
