import PhotoIcon from '@mui/icons-material/Photo'
import {
  Box,
  Grid,
  ImageListItem,
  ImageListItemBar,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEventHandler, ReactElement, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

interface Props {
  label: string
  value: string | ReactElement
  labelXs?: number
  totalXs?: number
}

export const FormSection = ({
  label,
  value,
  labelXs = 1,
  totalXs = 12,
}: Props) => (
  <>
    <Grid item xs={labelXs}>
      <Typography component="h2" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
    </Grid>
    <Grid item xs={totalXs - labelXs}>
      {value}
    </Grid>
  </>
)

export const ReadOnlyTextField = ({ value }: { value: string }) => {
  return (
    <TextField
      InputProps={{
        readOnly: true,
      }}
      value={value}
      sx={{ width: '100%' }}
    />
  )
}

const FileInput = styled('input')({
  display: 'none',
})

export const ImageLoad = ({
  value,
  label,
  readonly,
  onChange,
}: {
  value: string | File
  onChange: (file: File) => void
  label?: string
  readonly?: boolean
}) => {
  const [fileUrl, setFileUrl] = useState<string | undefined>(
    typeof value === 'string' ? value : undefined,
  )

  const handleFileLoad: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0]
    if (FileReader && file) {
      const fr = new FileReader()
      fr.onload = function () {
        setFileUrl(fr.result as string)
      }
      fr.readAsDataURL(file)
      onChange(file)
    } else {
      throw Error('File not supported')
    }
  }

  const inputFileId = useMemo(() => uuid(), [])

  return (
    <label htmlFor={inputFileId}>
      <Box sx={{ cursor: !readonly ? 'pointer' : undefined }}>
        <ImageListItem>
          {value ? (
            <img src={fileUrl} alt={label} loading="lazy" />
          ) : (
            <PhotoIcon
              sx={{ mt: 1, width: '100%', height: 'auto' }}
              color="disabled"
            />
          )}
          {label && <ImageListItemBar position="top" title={label} />}

          <FileInput
            accept="image/*"
            id={inputFileId}
            type="file"
            onChange={handleFileLoad}
            disabled={readonly}
          />
        </ImageListItem>
      </Box>
    </label>
  )
}
