import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DockItem = styled((props: BoxProps) => {
  if (!props?.children) {
    return null
  }

  return (
    <Box
      sx={{
        mx: 2,
        my: 2,
        display: 'flex',
      }}
      {...props}
    ></Box>
  )
})()
