import { useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { useV2Mutation } from '@trueskin-backoffice/api-client'
import React, { useState } from 'react'
import { getVouchers } from '../../vouchers'
import { addVoucherToUpcomingOrder, getOrderById } from '../gql'
import { OrderPaymentStatusGQL } from '../mappers/order.mapper'

type AddVoucherDialogProps = {
  customerId: string
  orderId: string
  open: boolean
  onDismiss: any
  paymentStatus: string
}

const OrderVoucherAddDialog = ({
  customerId,
  orderId,
  open,
  onDismiss,
  paymentStatus,
}: AddVoucherDialogProps) => {
  const [voucherCode, setVoucherCode] = useState<string>('')
  const [voucherAdditionType, setVoucherAdditionType] = React.useState('code')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherAdditionType((event.target as HTMLInputElement).value)
    resetErrorState()
    resetInputState()
  }

  const [hasAddVoucherError, setHasAddVoucherError] = useState<boolean>(false)
  const [addVoucherError, setAddVoucherError] = useState<string>('')

  const [addVoucher, { loading: addVoucherLoading }] = useV2Mutation(
    addVoucherToUpcomingOrder,
    {
      variables: {
        voucherCode: voucherCode,
        v1UserId: customerId,
        upcomingOrderPaymentStatus: OrderPaymentStatusGQL[paymentStatus],
      },
      refetchQueries: [
        {
          query: getOrderById,
          variables: {
            orderId,
          },
        },
      ],
      onError: (error) => {
        setHasAddVoucherError(true)
        if (error.message === 'VOUCHER_NOT_FOUND') {
          setAddVoucherError(`Voucher ${voucherCode} not found`)
        } else {
          setAddVoucherError(error.message)
        }
      },
      onCompleted: () => {
        resetErrorState()
        resetInputState()
        onDismiss()
      },
    },
  )

  const { data: vouchersData } = useQuery(getVouchers, {
    variables: {
      filter: { stackable: true },
    },
  })

  const vouchers = vouchersData?.vouchers?.rows ?? []

  const resetErrorState = () => {
    setAddVoucherError('')
    setHasAddVoucherError(false)
  }

  const resetInputState = () => {
    setVoucherCode('')
    setAddVoucherError('')
  }

  const handleVoucherInputChange = (event: any) => {
    resetErrorState()
    setVoucherCode(event.target?.value)
  }

  const handleAddVoucherCode = async () => {
    await addVoucher()
  }

  const handleCancel = () => {
    resetErrorState()
    resetInputState()
    onDismiss()
  }

  return (
    <Dialog onClose={onDismiss} open={open}>
      <DialogTitle sx={{ p: 0 }}>Add voucher to upcoming order</DialogTitle>

      <Container sx={{ p: 4 }}>
        <Box sx={{ mb: 4 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={voucherAdditionType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="code"
                control={<Radio />}
                label="Use voucher code"
              />
              <FormControlLabel
                value="stackable"
                control={<Radio />}
                label="Select stackable voucher"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box>
          {voucherAdditionType === 'code' && (
            <TextField
              fullWidth
              id="voucherCode"
              label="Enter voucher code"
              value={voucherCode}
              onChange={handleVoucherInputChange}
              error={hasAddVoucherError}
              helperText={addVoucherError}
            />
          )}

          {voucherAdditionType === 'stackable' && (
            <SelectStackableVoucher
              vouchers={vouchers}
              setVoucherCode={setVoucherCode}
            />
          )}
        </Box>

        <Grid
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 4 }}
        >
          <LoadingButton
            loading={addVoucherLoading}
            disabled={!voucherCode}
            onClick={handleAddVoucherCode}
          >
            Add voucher
          </LoadingButton>

          <Button disabled={addVoucherLoading} onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
      </Container>
    </Dialog>
  )
}

export { OrderVoucherAddDialog }

const SelectStackableVoucher = ({ vouchers, setVoucherCode }: any) => {
  const [preLoadedVoucherCode, setPreLoadedVoucherCode] = useState<{
    code: string
  } | null>(null)

  const handlePreLoadedVoucherInputChange = (event: any, newValue: any) => {
    setPreLoadedVoucherCode(newValue)
    setVoucherCode(newValue?.code || '')
  }

  return (
    <Autocomplete
      fullWidth
      id="cs-vouchers"
      options={vouchers || []}
      getOptionLabel={(option: any) => option.code}
      value={preLoadedVoucherCode}
      onChange={handlePreLoadedVoucherInputChange}
      autoComplete
      includeInputInList
      renderInput={(params) => (
        <TextField autoFocus {...params} label="Select voucher" />
      )}
    />
  )
}
