import { ApolloError, useQuery } from '@apollo/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import {
  DateTime,
  HeroCard,
  KeyTag,
  TagGroup,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { Link } from 'react-router-dom'
import { PageContentLayout } from '../layouts/page-content.layout'
import { getCustomerReferralRewards } from './referral-rewards.gql.bff'

interface CustomerReferralReward {
  _id: string
  referrer: string
  referee: string
  amount: number
  currency: string
  usedOn: string
  addedOn: string
  reason: string
}

export const ReferralRewardsPage = () => {
  const {
    customer: currentCustomer,
    loading: customerLoading,
    error: customerError,
  } = useCustomer()

  const {
    data,
    loading: rewardsLoading,
    error: rewardsError,
  } = useQuery(getCustomerReferralRewards, {
    skip: !currentCustomer?.id,
    variables: {
      customerId: currentCustomer?.id,
    },
  })

  const error = rewardsError || customerError
  const loading = rewardsLoading || customerLoading

  if (error) {
    return (
      <Typography color={'red'}>{(error as ApolloError).message}</Typography>
    )
  }

  if (loading || customerLoading) {
    return <LinearProgress />
  }

  if (!data?.customer?.referralRewards?.length) {
    return <Box>No Data</Box>
  }

  return (
    <PageContentLayout>
      {data?.customer?.referralRewards.map((reward: CustomerReferralReward) => (
        <HeroCard key={reward._id} sx={{ mb: 2 }}>
          <TagGroup
            items={[<KeyTag id={reward._id} />]}
            sx={{ mb: 2 }}
          ></TagGroup>

          {reward.referee && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Referee
              </Typography>

              <Link to={`/customers/${reward.referee}`}>
                <TagGroup
                  items={[
                    <KeyTag id={reward.referee} sx={{ mt: 0, px: 1, py: 0 }} />,
                  ]}
                ></TagGroup>
              </Link>
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="caption" sx={{ mr: 1 }}>
              Amount
            </Typography>

            <Typography fontWeight={500}>
              {formatCurrency(reward.amount / 100, null, reward.currency)}
            </Typography>
          </Box>

          {reward.reason && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Reason
              </Typography>

              <Typography>{reward.reason}</Typography>
            </Box>
          )}

          {reward.usedOn && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="caption">Used on</Typography>

              <Box sx={{ ml: 1 }}>
                <DateTime dateTime={reward.usedOn} showTime />
              </Box>
            </Box>
          )}

          {reward.addedOn && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Added on
              </Typography>

              <DateTime dateTime={reward.addedOn} showTime />
            </Box>
          )}
        </HeroCard>
      ))}
    </PageContentLayout>
  )
}
