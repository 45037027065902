import { Box, Button, TextField } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { H1 } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import 'react-day-picker/dist/style.css'
import { useCustomerMutations } from '../contexts'

export const UpdateBirthNameModal = ({ onClose }: { onClose: any }) => {
  const { customer } = useCustomer()
  const { updateCustomerBirthDate } = useCustomerMutations()

  return (
    <Formik
      initialValues={{
        birthDate: customer.birthDate,
      }}
      validateOnChange={true}
      // validationSchema={validationSchema}
      onSubmit={async (values) => {
        const dt = values.birthDate as unknown as Date
        const dtDateOnly = new Date(
          dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
        )

        await updateCustomerBirthDate(format(dtDateOnly, 'yyyy-MM-dd'))
        onClose()
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              minWidth: 500,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <H1>Update customer birthdate</H1>

            <Box sx={{ my: 4 }}>
              Current birth date:{' '}
              {new Date(customer.birthDate).toLocaleDateString(
                process.env['NX_I18N_LOCALE'],
                {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                },
              )}
            </Box>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                inputFormat="yyyy-MM-dd"
                value={values.birthDate}
                onChange={(value) => {
                  setFieldValue('birthDate', value)
                }}
                renderInput={(params) => (
                  <TextField name="birthDate" {...params} />
                )}
                openTo={'day'}
                displayStaticWrapperAs="desktop"
                disableFuture
                reduceAnimations
              />
            </LocalizationProvider>

            <Box>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>

              <Button type="submit">Save</Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
