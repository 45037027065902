import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Box, Button, Collapse, FormControlLabel, Switch } from '@mui/material'
import { HeroCard, HeroCardSection } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import React from 'react'
import { ExpandMore } from '../../order/sub-sections/order-invoice-information'
import { useCustomerMutations } from '../contexts'

export const CustomerSettings = () => {
  const { customer } = useCustomer()
  const { setCustomerIsTest } = useCustomerMutations()

  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const onChangeIsTestCustomer = async (event: any) => {
    await setCustomerIsTest(event.target.checked)
  }

  return (
    <HeroCard>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="text"
          size="small"
          onClick={handleExpandClick}
          endIcon={
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon sx={{ fontSize: 16 }} />
            </ExpandMore>
          }
          sx={{ fontSize: 12, textTransform: ' unset' }}
        >
          {expanded ? 'Hide settings' : 'Show settings'}
        </Button>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <HeroCardSection sx={{ m: 0 }}>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={customer.settings.isTest}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onClick={onChangeIsTestCustomer}
                />
              }
              label="Is test"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={customer.settings.emailConfirmed}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={true}
                />
              }
              label="Email confirmed"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={customer.settings.newsletterSubscription}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={true}
                />
              }
              label="Newsletter subscription"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={customer.settings.smsCheckInReminder}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={true}
                />
              }
              label="Sms notifications"
            />
          </Box>
        </HeroCardSection>
      </Collapse>
    </HeroCard>
  )
}
