import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'

export const CustomersFilters = () => (
  <DataFilterV2
    inputRows={[
      [
        {
          label: 'Search for customer id / v1 user id',
          key: 'id',
          type: DataFilterV2FilterComponent.ID,
        },
        {
          type: DataFilterV2FilterComponent.DIVIDER,
          borderVisible: false,
        },
      ],
    ]}
    additionalFilters={[
      {
        label: 'Email',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'email',
        op: DataFilterV2InputOperation.EQUALS,
      },
      {
        label: 'First / Last name',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'name',
        op: DataFilterV2InputOperation.CONTAINS,
      },
      {
        label: 'Paypal email',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'paypalEmail',
        op: DataFilterV2InputOperation.EQUALS,
      },
    ]}
  />
)
