import axios, { AxiosRequestConfig } from 'axios'
import { useCallback, useState } from 'react'
import { getJwt } from './storage.service'

const AXIOS_DEFAULT_CONFIG = {
  baseURL: process.env['NX_V2_REST_API_URL'],
}

export const axiosClient = axios.create(AXIOS_DEFAULT_CONFIG)

axiosClient.interceptors.request.use(
  async (config) => {
    const token = getJwt()

    config.headers!['Authorization'] = `Bearer ${token}`

    return config
  },
  (error) => Promise.reject(error),
)

type AxiosFunction<T, D> = (
  config: Pick<AxiosRequestConfig<D>, 'params' | 'data' | 'headers'>,
) => Promise<{ data: T | null; error: Error | null }>

interface AxiosHookResult<T> {
  loading: boolean
  data: T | null
  error: Error | null
}

export const useAxios = <T = any, D = any>(
  config: AxiosRequestConfig<D>,
  deps = [],
): [AxiosFunction<T, D>, AxiosHookResult<T>] => {
  const [loading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState<T | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const request = useCallback<AxiosFunction<T, D>>((variablesConfig = {}) => {
    setLoading(true)
    setValue(null)
    setError(null)

    return axiosClient
      .request({ ...config, ...variablesConfig })
      .then((response) => {
        const { data, error } = response.data
        if (data) {
          setValue(data)
        } else if (error) {
          setError(error)
        }
        return { data, error }
      })
      .catch((error: Error) => {
        setError(error)
        return { error, data: null }
      })
      .finally(() => {
        setLoading(false)
      })
  }, deps)

  return [request, { data: value, loading, error }]
}
