import { gql } from '@apollo/client'

export const patientQuery = gql`
  query Patient($customerId: String!) {
    patient(customerId: $customerId) {
      id
      accountId
      registrationDate
      profile
      medicalData
      metadata
    }
  }
`
