import { Box, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  DataTable,
  IdTag,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { formatCurrency, formatDateTime } from '@trueskin-backoffice/functions'
import { useEffect, useMemo, useState } from 'react'
import {
  InvoiceStatus,
  InvoiceStatuses,
} from '../order/components/invoice-status'
import { getAllInvoices } from './invoices.gql'

const DEFAULT_PAGE_SIZE = 25

const recordNeedsAttention = (row: any): boolean => {
  return (
    // @ts-ignore
    InvoiceStatuses[row?.original.status] === InvoiceStatus.PAYMENT_DUE &&
    row?.original?.updatedAt &&
    new Date().getTime() - new Date(row?.original?.updatedAt).getTime() >
      60 * 60 * 1000
  )
}

export const InvoicesTable = () => {
  const columns = useMemo(() => invoicesTableColumns(), [])
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      getAllInvoices: {
        rows: invoices = [],
        totalRows: totalInvoices = 0,
      } = {},
    } = {},
    loading,
    error: allInvoicesError,
    refetch: refetchInvoices,
  } = useV2Query(getAllInvoices, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalInvoices / pageSize)

  useEffect(() => {
    if (!loading) {
      refetchInvoices({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        filter,
      })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (allInvoicesError) {
    return <Typography color={'red'}>{allInvoicesError.message}</Typography>
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={invoices}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const invoicesTableColumns = (): ColumnDef<any, any>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    cell: ({ row }) => {
      return (
        <IdTag
          id={row?.original?.id}
          linkTo={`/customers/${row.original.customerId}/orders/${row.original.orderId}`}
          allowCopyToClipboard
          showAlert={recordNeedsAttention(row)}
        />
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      return <InvoiceStatus invoice={row.original} showTime hideCollectNow />
    },
  },
  {
    accessorKey: 'total',
    header: 'Total',
    cell: ({ row }) => {
      return (
        <Box>
          {formatCurrency(
            row.original.total / 100,
            row.original.locale,
            row.original.currency,
          )}
        </Box>
      )
    },
  },
  {
    accessorKey: 'amountPaid',
    header: 'Paid',
    cell: ({ row }) => {
      return (
        <Box>
          {formatCurrency(
            row.original.amountPaid / 100,
            row.original.locale,
            row.original.currency,
          )}
        </Box>
      )
    },
  },
  {
    accessorKey: 'amountDue',
    header: 'Due',
    cell: ({ row }) => {
      return (
        <Box>
          {formatCurrency(
            row.original.amountDue / 100,
            row.original.locale,
            row.original.currency,
          )}
        </Box>
      )
    },
  },
  {
    accessorKey: 'amountRefunded',
    header: 'Refunded',
    cell: ({ row }) => {
      return (
        <Box>
          {formatCurrency(
            row.original.amountRefunded / 100,
            row.original.locale,
            row.original.currency,
          )}
        </Box>
      )
    },
  },
  {
    accessorKey: 'generatedAt',
    header: 'Generated at',
    cell: ({ getValue }) => {
      return <Box>{formatDateTime(getValue())}</Box>
    },
  },
  {
    accessorKey: 'updatedAt',
    header: 'Updated at',
    cell: ({ getValue }) => {
      return <Box>{formatDateTime(getValue())}</Box>
    },
  },
]
