import { DataObject } from '@mui/icons-material'
import { Box, Dialog } from '@mui/material'
import { DockAction } from '@trueskin-backoffice/components'
import { useState } from 'react'
import JsonView from 'react-json-view'

export const ViewJsonDockAction = ({ id, jsonData }: any) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <DockAction
        startIcon={<DataObject />}
        onClick={() => {
          setOpen(true)
        }}
      >
        View json
      </DockAction>

      <Dialog
        open={open}
        fullWidth
        sx={{
          height: '100%',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            width: 'fit-content',
          },
        }}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            p: 4,
          }}
        >
          <JsonView name={id} src={jsonData} />
        </Box>
      </Dialog>
    </>
  )
}
