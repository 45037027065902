import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { CaseOrdersFilters } from './case-orders.filters'
import { CaseOrdersTable } from './case-orders.table'

const PRESCRIPTIONS_FILTER_INIT_VALUES = {
  // id: StringParam,
  // v1UserId: StringParam,
  // email: StringParam,
  // paypalEmail: StringParam,
  // name: StringParam,
  accountId: StringParam,
  patientId: StringParam,
}

export const CaseOrdersPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={PRESCRIPTIONS_FILTER_INIT_VALUES}>
        <CaseOrdersFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <CaseOrdersTable />
          {/*<TofuContext>*/}
          {/*  <DemoSubscriptionComponent />*/}
          {/*</TofuContext>*/}
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
