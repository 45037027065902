import { Alert, Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  H2,
  InputOperation,
} from '@trueskin-backoffice/components'
import React from 'react'
import { useQueryParams } from 'use-query-params'
import { PrescriptionsTable } from './prescriptions.table'

const PRESCRIPTIONS_FILTER_INIT_VALUES = {} // TODO: remove
export type PrescriptionsFilterProps = typeof PRESCRIPTIONS_FILTER_INIT_VALUES // TODO: remove

export const PrescriptionsPage = () => {
  const [filter, setFilter] = useQueryParams<PrescriptionsFilterProps>(
    PRESCRIPTIONS_FILTER_INIT_VALUES,
  )

  return (
    <Box>
      <DataFilter
        filter={filter} // TODO: remove
        onChange={setFilter}
        names={Object.keys(PRESCRIPTIONS_FILTER_INIT_VALUES)}
        inputs2={[
          {
            label: 'Date resolved',
            component: FilterComponent.TEXT,
            key: 'dateResolved',
            op: InputOperation.EQUALS,
          },
          // {
          //   label: 'First / Last name',
          //   component: FilterComponent.TEXT,
          //   key: 'name',
          //   op: InputOperation.CONTAINS,
          // },
          // {
          //   label: 'Paypal email',
          //   component: FilterComponent.TEXT,
          //   key: 'paypalEmail',
          //   op: InputOperation.EQUALS,
          // },
        ]}
        // id={{
        //   label: 'Search for customer id / v1 user id',
        //   key: 'id',
        // }}
      />

      <Alert severity="error">
        <H2>Still WIP, do not use</H2>
      </Alert>

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        <PrescriptionsTable filter={filter} />
      </Card>
    </Box>
  )
}
