import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { WaitingRoomCase } from '../waiting-room/waiting-room-case'
import { WaitingRoomCaseProvider } from '../waiting-room/waiting-room-case.context'
import { WaitingRoomLayoutControllerProvider } from '../waiting-room/waiting-room-layout-controller.context'

export const CaseMedicalViewPage = () => {
  const { caseId } = useParams<{
    caseId: string
  }>()

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY: 'auto',
        p: 10,
        py: 2,
      }}
    >
      <WaitingRoomLayoutControllerProvider hideControls={true}>
        <WaitingRoomCaseProvider caseId={caseId}>
          <WaitingRoomCase />
        </WaitingRoomCaseProvider>
      </WaitingRoomLayoutControllerProvider>
    </Box>
  )
}
