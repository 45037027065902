import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline } from '@mui/material'
import {
  ThemeContext,
  UiControllerProvider,
} from '@trueskin-backoffice/contexts'
import { SnackbarProvider } from 'notistack'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { App, AppContext } from './app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <UiControllerProvider>
      <ThemeContext>
        <CssBaseline />

        <AppContext>
          <SnackbarProvider maxSnack={5} autoHideDuration={3500}>
            <App />
          </SnackbarProvider>
        </AppContext>
      </ThemeContext>
    </UiControllerProvider>
  </StrictMode>,
)
