import { ApolloError, useQuery } from '@apollo/client'
import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { patientQuery } from './gql/patient.gql.bff'

//todo: this is duplicated in app.context (find a single place for it)
interface StandardProps {
  children?: ReactNode
  maxSnackStack?: number
}

export interface PatientContextProps {
  loading: boolean
  error: string | undefined | ApolloError | Error
  customer: any
}

const PatientContext = React.createContext<PatientContextProps | undefined>(
  undefined,
)

function PatientProvider(props: StandardProps) {
  const { customerId } = useParams<{ customerId: string }>()

  const {
    data: { patient: customer } = {},
    loading,
    error,
  } = useQuery(patientQuery, {
    variables: {
      customerId,
    },
  })

  const value = React.useMemo(
    () => ({
      loading,
      customer,
      error,
    }),
    [loading, customer, error],
  )

  return <PatientContext.Provider value={value} {...props} />
}

function usePatient(): PatientContextProps {
  const context = React.useContext(PatientContext)
  if (context === undefined) {
    throw new Error(`useCustomer must be used within a PatientProvider`)
  }
  return context
}

export { PatientProvider, usePatient }
