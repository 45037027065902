import { useMutation, useQuery } from '@apollo/client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  Button,
  Card,
  Checkbox,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  DataFilterIdInput,
  DataFilterV2FilterComponent,
  DataTable,
  DateTime,
  HighlightedTypography,
  IdTag,
  PageHeader,
} from '@trueskin-backoffice/components'
import { groupBy, isNumber } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  getAllEmployees,
  getLatestAvailabilitiesQuery,
} from '../employees/employees.gql'
import { Role } from '../employees/types'
import {
  bulkAssignConsultationsMutation,
  getOpenedConsultationsByAssignees,
} from './consultations.gql.bff'

enum AssignSteps {
  FROM = 'from',
  TO = 'to',
  CONFIRM = 'confirm',
}

const EMPLOYEE_FIELDS_TO_SEARCH = ['email', 'firstName', 'lastName', '_id']

export const AssignConsultationsPage = () => {
  const navigate = useNavigate()

  const [step, setStep] = useState(AssignSteps.FROM)
  const [reassignsFrom, setReassignsFrom] = useState<Record<string, number>>({})
  const [reassignsTo, setReassignsTo] = useState<Record<string, number>>({})
  const [search, setSearch] = useQueryParam('search', StringParam)
  const [validationError, setValidationError] = useState<string | null>(null)

  const handleStepChange = (step: AssignSteps) => {
    setSearch(null)
    setStep(step)
  }

  const {
    data: getOpenedConsultationsByAssigneesData,
    loading: getOpenedConsultationsByAssigneesLoading,
    error: getOpenedConsultationsByAssigneesError,
    refetch: refetchOpenedConsultations,
  } = useQuery(getOpenedConsultationsByAssignees, {
    notifyOnNetworkStatusChange: true,
  })

  const assigns =
    getOpenedConsultationsByAssigneesData?.openedConsultationsByAssignees

  const {
    data: employeesQueryData,
    loading: getAllEmployeesLoading,
    error: getAllEmployeesError,
  } = useV2Query(getAllEmployees, {
    variables: {
      offset: 0,
      limit: 9999,
      filter: { role: Role.DOCTOR, active: true },
    },
  })

  const employees = useMemo(
    () => employeesQueryData?.getAllEmployees?.data ?? [],
    [employeesQueryData?.getAllEmployees?.data],
  )

  const {
    data: latestAvailabilitiesQueryData,
    loading: getLatestAvailabilitiesLoading,
    error: getLatestAvailabilitiesError,
  } = useV2Query(getLatestAvailabilitiesQuery)

  const [
    bulkAssignConsultations,
    {
      data: assignedConsultations,
      loading: bulkAssignConsultationsMutationLoading,
      error: bulkAssignConsultationsMutationError,
    },
  ] = useMutation(bulkAssignConsultationsMutation)

  const todaysAvailabilities = useMemo(
    () =>
      latestAvailabilitiesQueryData?.getLatestAvailabilities?.filter(
        ({ date }: { date: string }) =>
          new Date().toDateString() === new Date(date).toDateString(),
      ) ?? [],
    [latestAvailabilitiesQueryData?.getLatestAvailabilities],
  )

  const loading =
    getOpenedConsultationsByAssigneesLoading ||
    getAllEmployeesLoading ||
    getLatestAvailabilitiesLoading ||
    bulkAssignConsultationsMutationLoading

  const error =
    getOpenedConsultationsByAssigneesError ||
    getAllEmployeesError ||
    getLatestAvailabilitiesError ||
    bulkAssignConsultationsMutationError

  const unassigned = useMemo(
    () =>
      step === AssignSteps.FROM
        ? assigns?.filter(({ employeeId }: any) => employeeId === null) ?? []
        : [],
    [assigns, step],
  )

  const employeesAssigns: any[] = useMemo(
    () =>
      assigns && employees
        ? unassigned.concat(
            employees
              .filter((e: any) => {
                if (!search) {
                  return true
                }

                if (
                  (step === AssignSteps.FROM && reassignsFrom[e._id]) ||
                  (step === AssignSteps.TO && reassignsTo[e._id])
                ) {
                  return true
                }

                const searchPattern = new RegExp(
                  search.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'),
                  'i',
                )

                return EMPLOYEE_FIELDS_TO_SEARCH.some((field) =>
                  e[field]?.toString().match(searchPattern),
                )
              })
              .filter((e: any) =>
                step === AssignSteps.TO ? !reassignsFrom[e._id] : true,
              )
              .map((e: any) => {
                const employeeAssigns = assigns.find(
                  (av: any) => e._id === av.employeeId,
                )

                if (!employeeAssigns && step !== AssignSteps.TO) {
                  return null
                }

                const employeeAvailability = todaysAvailabilities.find(
                  (av: any) => e._id === av.employeeId,
                )

                const withAssigns = employeeAssigns ?? { employeeId: e._id }

                return {
                  ...e,
                  ...withAssigns,
                  availability: employeeAvailability?.value,
                }
              })
              .filter((v: any) => !!v),
          )
        : [],
    [
      assigns,
      employees,
      reassignsFrom,
      reassignsTo,
      search,
      step,
      todaysAvailabilities,
      unassigned,
    ],
  )

  const withFromColumns: ColumnDef<any, any>[] = useMemo(
    () =>
      step === AssignSteps.FROM
        ? [
            {
              header: '# of consultations to reassign',
              cell: ({ row, table }) => {
                const metaReassignsFrom = (
                  table.options.meta as {
                    reassignsFrom: Record<string, number>
                  }
                ).reassignsFrom

                const currentValue = metaReassignsFrom[row.original.employeeId]

                return (
                  <Box>
                    <TextField
                      onChange={(e) => {
                        const maxValue = row.original.v1consultations.length
                        const value = parseInt(e.target.value, 10)

                        setReassignsFrom((prevReassigns) => {
                          if (!value || value < 0) {
                            const reassigns = { ...prevReassigns }
                            delete reassigns[row.original.employeeId]
                            return reassigns
                          }

                          return {
                            ...prevReassigns,
                            [row.original.employeeId]:
                              currentValue && value > maxValue
                                ? maxValue
                                : value,
                          }
                        })
                      }}
                      value={currentValue}
                      size="small"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {metaReassignsFrom[row.original.employeeId] !==
                      row.original.v1consultations.length && (
                      <Button
                        onClick={() =>
                          setReassignsFrom((prevReassigns) => ({
                            ...prevReassigns,
                            [row.original.employeeId]:
                              row.original.v1consultations.length,
                          }))
                        }
                      >
                        all
                      </Button>
                    )}
                  </Box>
                )
              },
            },
          ]
        : [],
    [step],
  )

  const totalConsultationsToReassign = Object.values(reassignsFrom).reduce(
    (cur, next) => cur + next,
    0,
  )

  const totalReassignedConsultations = Object.values(reassignsTo).reduce(
    (cur, next) => cur + next,
    0,
  )

  const withToColumns: ColumnDef<any, any>[] = useMemo(
    () =>
      step === AssignSteps.TO
        ? [
            {
              header: 'To',
              cell: ({ row, table }) => {
                const meta = table.options.meta as {
                  reassignsTo: Record<string, number>
                  totalConsultationsToReassign: number
                }

                const checked = isNumber(
                  meta.reassignsTo[row.original.employeeId],
                )
                const currentValue = meta.reassignsTo[row.original.employeeId]

                const recomputeCounts = (employeesIds: string[]) => {
                  const amountOfConsultationsToAssign = Math.floor(
                    meta.totalConsultationsToReassign / employeesIds.length,
                  )

                  const restCount =
                    meta.totalConsultationsToReassign % employeesIds.length

                  const newReassignsTo = employeesIds.reduce(
                    (cur, next, index) => {
                      cur[next] =
                        amountOfConsultationsToAssign +
                        (index + 1 > restCount ? 0 : 1)
                      return cur
                    },
                    {} as Record<string, number>,
                  )

                  return newReassignsTo
                }

                return (
                  <Box>
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        checked
                          ? setReassignsTo((prevReassignsTo) =>
                              recomputeCounts(
                                Object.keys(prevReassignsTo).filter(
                                  (id) => id !== row.original.employeeId,
                                ),
                              ),
                            )
                          : setReassignsTo((prevReassignsTo) =>
                              recomputeCounts(
                                Object.keys(prevReassignsTo).concat([
                                  row.original.employeeId,
                                ]),
                              ),
                            )
                      }}
                    />
                    <TextField
                      sx={{ visibility: !checked ? 'hidden' : 'visible' }}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10)

                        setReassignsTo((prevReassignsTo) => ({
                          ...prevReassignsTo,
                          [row.original.employeeId]: newValue,
                        }))
                      }}
                      value={currentValue}
                      size="small"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                )
              },
            },
          ]
        : [],
    [step],
  )

  const columns: ColumnDef<any, any>[] = useMemo(
    () => [
      {
        accessorKey: 'employeeId',
        header: 'Id',
        cell: ({ getValue }) => {
          return getValue() ? (
            <IdTag
              id={getValue()}
              linkTo={`/employees/${getValue()}`}
              allowCopyToClipboard
            />
          ) : null
        },
      },
      {
        header: 'Name',
        accessorKey: 'firstName',
        cell: ({ row, table }) => {
          return row.original.employeeId ? (
            <HighlightedTypography
              highlight={(table.options.meta as { search: string }).search}
            >
              {row.original.firstName} {row.original.lastName}
            </HighlightedTypography>
          ) : (
            <Typography fontWeight="bold">Unassigned</Typography>
          )
        },
      },
      {
        header: '# of consultations',
        accessorKey: 'v1consultations',
        cell: ({ getValue }) => {
          return <Typography>{getValue()?.length}</Typography>
        },
      },
      {
        header: 'Availability',
        accessorKey: 'availability',
        cell: ({ getValue }) => {
          return <Typography>{getValue() ?? '-'}</Typography>
        },
      },
      ...withFromColumns,
      ...withToColumns,
    ],
    [withFromColumns, withToColumns],
  )

  const assignStepsMeta = {
    [AssignSteps.FROM]: {
      title: 'Number of consultations per doctor',
      backTooltip: 'Back to consultations',
      handleBack: () => {
        navigate('/consultations')
      },
    },
    [AssignSteps.TO]: {
      title: `To which doctors do you want to assign the (${totalConsultationsToReassign}) consultations`,
      backTooltip: 'Change assign amount',
      handleBack: () => {
        setReassignsTo({})
        handleStepChange(AssignSteps.FROM)
      },
    },
    [AssignSteps.CONFIRM]: {
      title: `Overview`,
      backTooltip: 'Change assigned doctors',
      handleBack: () => {
        handleStepChange(AssignSteps.TO)
      },
    },
  }
  const currentStep = assignStepsMeta[step]

  const handleBulkAssign = useCallback(
    async (save: boolean) =>
      bulkAssignConsultations({
        variables: { data: { save, from: reassignsFrom, to: reassignsTo } },
      }),
    [bulkAssignConsultations, reassignsFrom, reassignsTo],
  )

  const groupOverview = useCallback(
    (prop: 'employeeId' | 'prevEmployeeId') => {
      return Object.entries(
        groupBy(assignedConsultations?.bulkAssignConsultations, prop),
      ).map(([id, records]) => {
        const employeeId = id === 'null' ? null : id
        const employee =
          employeeId === null
            ? { firstName: '', lastName: 'Unassigned' }
            : employees?.find(({ _id }: any) => _id === employeeId)

        const lastCount =
          assigns?.find(({ employeeId: id }: any) => id === employeeId)
            ?.v1consultations?.length ?? 0

        return {
          employeeId,
          count: lastCount + records.length * (prop === 'employeeId' ? 1 : -1),
          employeeName: `${employee.firstName} ${employee.lastName}`,
        }
      })
    },
    [assignedConsultations?.bulkAssignConsultations, assigns, employees],
  )

  const overview = useMemo(() => {
    if (!assignedConsultations?.bulkAssignConsultations) {
      return null
    }

    const newEmployeesConsultations = groupOverview('employeeId')
    const prevEmployeesConsultations = groupOverview('prevEmployeeId')

    return newEmployeesConsultations.concat(prevEmployeesConsultations)
  }, [assignedConsultations?.bulkAssignConsultations, groupOverview])

  return (
    <Box>
      <PageHeader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={5}>
            <Tooltip title={currentStep.backTooltip} placement="bottom">
              <Button onClick={currentStep.handleBack}>
                <ArrowBackIcon />
              </Button>
            </Tooltip>
          </Box>
          <DataFilterIdInput
            input={{
              key: 'search',
              label: 'Search for employee id / name / email',
              type: DataFilterV2FilterComponent.ID,
            }}
          />
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
            <Typography color="primary" fontWeight="bold">
              <DateTime dateTime={new Date().toISOString()} />
            </Typography>
            {step === AssignSteps.FROM && totalConsultationsToReassign > 0 ? (
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => handleStepChange(AssignSteps.TO)}
              >
                Reassign ({totalConsultationsToReassign}) consultations
              </Button>
            ) : null}

            {step === AssignSteps.TO && Object.keys(reassignsTo).length > 0 ? (
              <Box>
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  disabled={bulkAssignConsultationsMutationLoading}
                  onClick={async () => {
                    if (
                      totalReassignedConsultations >
                        totalConsultationsToReassign ||
                      totalReassignedConsultations <= 0
                    ) {
                      setValidationError(
                        'Invalid consultations count to assign',
                      )
                      return
                    }

                    await handleBulkAssign(false)
                    handleStepChange(AssignSteps.CONFIRM)
                  }}
                >
                  Reassign to ({Object.keys(reassignsTo).length}) doctors
                </Button>
                {validationError && (
                  <Typography color={'red'}>{validationError}</Typography>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </PageHeader>

      <Card
        sx={{
          m: 2,
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
        }}
      >
        <Box>
          {loading && <LinearProgress />}
          {error && <Typography color={'red'}>{error.message}</Typography>}

          {!error && !loading ? (
            <Box>
              <Typography align="center" variant="h2" color="primary" m={3}>
                {currentStep.title}
              </Typography>
              {[AssignSteps.FROM, AssignSteps.TO].includes(step) && (
                <DataTable
                  columns={columns}
                  data={employeesAssigns}
                  isLoading={loading}
                  meta={{
                    reassignsFrom,
                    reassignsTo,
                    search,
                    totalConsultationsToReassign,
                  }}
                />
              )}
              {step === AssignSteps.CONFIRM && (
                <Box>
                  <Box sx={{ m: 3, height: '70vh', overflowY: 'auto' }}>
                    {overview?.map(({ employeeName, employeeId, count }) => (
                      <Typography sx={{ m: 2 }} variant="h2">
                        {employeeId === null ? (
                          `Unassigned consultations - ${count}`
                        ) : (
                          <>
                            {' '}
                            <Link to={`/employees/${employeeId}`}>
                              {employeeName}{' '}
                            </Link>
                            {`will have ${count} consultations`}
                          </>
                        )}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Card>
      {step === AssignSteps.CONFIRM && !loading && (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 4,
            m: 2,
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          }}
        >
          <Box>
            <Typography variant="h1">Do you confirm your selection?</Typography>
            <Box
              sx={{ mt: 2, ml: 2, display: 'flex', justifyContent: 'center' }}
            >
              <Button
                color="secondary"
                disabled={bulkAssignConsultationsMutationLoading}
                onClick={async () => {
                  setReassignsTo({})
                  handleStepChange(AssignSteps.FROM)
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                disabled={bulkAssignConsultationsMutationLoading}
                onClick={async () => {
                  await handleBulkAssign(true)
                  setReassignsFrom({})
                  setReassignsTo({})
                  handleStepChange(AssignSteps.FROM)
                  await refetchOpenedConsultations()
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Card>
      )}
    </Box>
  )
}
