import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import { DateTime, JsonButton, Table } from '@trueskin-backoffice/components'
import { formatDateTime } from '@trueskin-backoffice/functions'
import React, { useEffect, useState } from 'react'
import { DecodedValueMap } from 'use-query-params'
import { getAllExternalWebhooks } from './external-webhooks.gql'
import { ExternalWebhooksFilterProps } from './external-webhooks.page'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
}

interface Props {
  filter: DecodedValueMap<ExternalWebhooksFilterProps>
}

const LIMIT = 20

export const ExternalWebhooksTable = ({ filter }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [json, setJson] = useState('')

  const handleOpenModal = (jsonData: any) => (event: any) => {
    event.stopPropagation()
    setJson(jsonData)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setJson('')
    setOpenModal(false)
  }

  const {
    data: allWebhooksData,
    loading: allWebhooksLoading,
    error: allWebhooksError,
    refetch: refetchAllWebhooks,
    fetchMore: fetchMoreExternalWebhooks,
  } = useV2Query(getAllExternalWebhooks, {
    variables: { limit: LIMIT, offset: 0, filter },
  })

  useEffect(() => {
    if (!allWebhooksLoading) {
      refetchAllWebhooks({ offset: 0, limit: LIMIT, filter })
    }
  }, [allWebhooksLoading, refetchAllWebhooks, filter])

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 325,
      },
      {
        Header: 'Status',
        accessor: 'status',
        // width: 200,
      },
      {
        Header: '# of retries',
        Cell: ({ row }: any) => {
          return <Box>{row?.original.executions?.length}</Box>
        },
      },
      {
        Header: 'Max # of retries',
        accessor: 'maxNumberOfRetries',
      },
      {
        Header: 'Customer ID',
        accessor: 'customerId',
        width: 325,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          return (
            <DateTime showTime dateTime={row?.original?.createdAt} />
            // <div>
            //   {row?.original?.createdAt ? (
            //     <Box>{formatDateTime(row?.original.createdAt)}</Box>
            //   ) : (
            //     <Box>-</Box>
            //   )}
            // </div>
          )
        },
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: ({ row }: any) => {
          return (
            <div>
              {row?.original?.updatedAt ? (
                <Box>{formatDateTime(row?.original.updatedAt)}</Box>
              ) : (
                <Box>-</Box>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }: any) => {
          return <JsonButton jsonData={row.original} />
        },
      },
    ],
    [],
  )

  if (allWebhooksLoading) {
    return <LinearProgress />
  }
  if (allWebhooksError) {
    return <Typography color={'red'}>{allWebhooksError.message}</Typography>
  }

  const fetchMoreData = () => {
    fetchMoreExternalWebhooks({
      variables: {
        offset: allWebhooksData.getAllExternalWebhooks.length,
      },
      updateQuery(
        { getAllExternalWebhooks: prevWebhooks },
        { fetchMoreResult: { getAllExternalWebhooks: nextWebhooks } },
      ) {
        return { getAllExternalWebhooks: [...prevWebhooks, ...nextWebhooks] }
      },
    })
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </Box>
      </Modal>

      <Table
        columns={columns}
        data={allWebhooksData.getAllExternalWebhooks}
        update={fetchMoreData}
        expandProperty={'executions'}
      />
    </>
  )
}
