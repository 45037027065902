import { CheckCircle, ContentCopy, ReportProblem } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import { KeyTag } from './tag'

// TODO: ideally move this to components and
//  make it more configurable, like (showKeyIcon + copyToClipboard + copyToClipboardPosition)
export const IdTag = ({
  id,
  linkTo,
  showBorder,
  allowCopyToClipboard,
  copyToClipboardPosition = 'start', // start | end
  showAlert,
  hideKeyIcon,
}: any) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {showAlert && <ReportProblem color="error" fontSize="small" />}

      {allowCopyToClipboard && copyToClipboardPosition === 'start' && (
        <CopyToClipboard value={id} />
      )}

      <KeyTag
        id={id}
        linkTo={linkTo}
        hideKeyIcon={hideKeyIcon}
        sx={{ mt: 0, px: 1, py: 0, border: showBorder ? 1 : 0 }}
      />

      {allowCopyToClipboard && copyToClipboardPosition === 'end' && (
        <CopyToClipboard value={id} />
      )}
    </Box>
  )
}

const CopyToClipboard = ({ value }: any) => {
  const [copied, setCopied] = useState(false)

  const handleClipboardCopy = (e: any) => {
    e.preventDefault()
    navigator.clipboard.writeText(value)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return !copied ? (
    <IconButton size="small" onClick={handleClipboardCopy}>
      <ContentCopy fontSize="inherit" />
    </IconButton>
  ) : (
    <Tooltip title="Copied" placement="top">
      <IconButton size="small" color="primary">
        <CheckCircle fontSize="inherit" />
      </IconButton>
    </Tooltip>
  )
}
