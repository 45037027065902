import { Box, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  DataTable,
  DateTime,
  HtmlTooltip,
  IdTag,
  OrderItem,
  Pagination,
  StatusBadge,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { formatCurrency } from '@trueskin-backoffice/functions'
import React, { useEffect, useMemo, useState } from 'react'
import { getAllOrders } from './orders.gql'

const DEFAULT_PAGE_SIZE = 25

export const OrdersTable = () => {
  const columns = useMemo(() => ordersTableColumns(), [])
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      getAllOrders: { rows: orders = [], totalRows: totalOrders = 0 } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useV2Query(getAllOrders, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalOrders / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={orders}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />
      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const ordersTableColumns = (): ColumnDef<any, any>[] => [
  {
    accessorKey: '_id',
    header: 'Id',
    cell: ({ row }) => {
      const order = row.original

      return (
        <IdTag
          id={order._id}
          linkTo={`/customers/${order.customerId}/orders/${order._id}`}
          allowCopyToClipboard
        />
      )
    },
  },
  {
    accessorKey: 'totals.value',
    header: 'Price',
    cell: ({ row }) => {
      return (
        <Box>
          {formatCurrency(
            row?.original?.values.total / 100,
            row?.original?.locale,
            row?.original?.currency,
          )}
        </Box>
      )
    },
  },
  {
    accessorKey: 'payment.status',
    header: 'Status',
    cell: ({ getValue }) => {
      return <StatusBadge status={getValue()} isRounded />
    },
  },
  {
    accessorKey: 'discounts',
    header: 'Discounts',
    cell: ({ row }) => {
      const order = row.original
      const discounts = order.discounts
      const totalDiscountAmount = order.discounts
        .filter(({ amount }: any) => !!amount)
        .reduce((crt: number, next: any) => crt + next.amount, 0)

      return (
        <HtmlTooltip
          placement="right"
          title={
            totalDiscountAmount ? (
              <React.Fragment>
                {discounts.map((discount: any) => {
                  return (
                    <Box sx={{ display: 'flex' }}>
                      <Typography color="inherit">{discount.ref}</Typography>
                      &nbsp;
                      {discount.amount && discount.currency && (
                        <Typography color="inherit">
                          {formatCurrency(
                            discount.amount / 100,
                            order.locale,
                            discount.currency,
                          )}
                        </Typography>
                      )}
                    </Box>
                  )
                })}
              </React.Fragment>
            ) : (
              ''
            )
          }
        >
          <Box>
            {formatCurrency(
              totalDiscountAmount / 100,
              order.locale,
              order.currency,
            )}
          </Box>
        </HtmlTooltip>
      )
    },
  },
  {
    accessorKey: 'items',
    header: 'Products',
    cell: ({ row }) => {
      const order = row.original
      const products = order.items

      return (
        <HtmlTooltip
          placement="right"
          title={
            <Box sx={{ p: 1 }}>
              {products.map(({ product, quantity }: any) => (
                <OrderItem product={product} quantity={quantity} />
              ))}
            </Box>
          }
        >
          <Box>{products.length}</Box>
        </HtmlTooltip>
      )
    },
  },
  {
    accessorKey: 'customerId',
    header: 'Customer Id',
    cell: ({ getValue }) => {
      return (
        <IdTag
          id={getValue()}
          linkTo={`/customers/${getValue()}`}
          allowCopyToClipboard
        />
      )
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Created at',
    cell: ({ getValue }) => {
      return <DateTime dateTime={getValue()} showTime />
    },
  },
]
