import { gql } from '@apollo/client'

export const getCasesByCustomerId = gql`
  query GetCasesByCustomerId($customerId: String!) {
    patient(customerId: $customerId) {
      id
      cases
    }
  }
`

export const updatePatientBirthDateMutation = gql`
  mutation UpdatePatientBirthDate($customerId: String!, $birthDate: String!) {
    patient(customerId: $customerId) {
      updateBirthDate(birthDate: $birthDate) {
        id
        medicalData
      }
    }
  }
`
