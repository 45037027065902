import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import { uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { getAllEmployees } from './employees.gql'
import { Employee, EmployeeFilter } from './types'

interface Props {
  employee?: Employee
  onChange: (e: Employee) => void
  filterBy?: (e: Employee) => boolean
  filter?: EmployeeFilter
  noOptionsText?: string
}

export const EmployeesAutocomplete = ({
  employee,
  filter = {},
  filterBy = () => true,
  onChange,
  noOptionsText,
}: Props) => {
  const [search, setSearch] = useState(
    employee ? `${employee.firstName} ${employee.lastName}` : '',
  )

  const {
    data: { getAllEmployees: { data: employeesData = [] } = {} } = {},
    loading,
    error,
    refetch,
  } = useV2Query(getAllEmployees, {
    variables: {
      limit: 10,
      offset: 0,
      filter,
      search: '',
    },
  })

  useEffect(() => {
    if (!loading) {
      refetch({ offset: 0, limit: 10, search, filter })
    }
  }, [loading, refetch, search, filter])

  const handleSearch = (value: string) => {
    refetch({
      limit: 10,
      offset: 0,
      filter,
      search: value,
    })
    setSearch(value)
  }

  const options = useMemo(
    () =>
      uniqBy<any>(
        employee ? [employee, ...employeesData] : employeesData,
        '_id',
      )
        .filter(filterBy)
        .filter(({ _id }) => _id !== employee?._id),
    [employee, employeesData, filterBy],
  )

  return (
    <Box>
      {error && (
        <Typography variant="caption" color="red">
          {error?.message}
        </Typography>
      )}
      <Autocomplete
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        value={employee}
        onChange={(_, value) => onChange(value)}
        inputValue={search}
        onInputChange={(_, value) => handleSearch(value)}
        loading={loading}
        options={options}
        noOptionsText={noOptionsText}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  )
}
