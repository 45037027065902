import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  styled,
  TextField,
} from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import { forwardRef, useEffect, useState } from 'react'
import PinInput from 'react-pin-input'
import { useWaitingRoomCaseDiagnosis } from '../../../waiting-room-case-diagnosis.context'
import {
  AddMedicationForDiagnosisMutation,
  getWaitingRoomCaseDiagnosisQuery,
  UpdateMedicationForDiagnosisMutation,
} from '../../../waiting-room.gql.bff'

export const MedicationInput = forwardRef(
  ({ medication, onClose }: any, ref) => {
    const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
    const { getDoctorProfile } = useAuth()

    const [medicationName, setMedicationName] = useState(medication?.name || '')
    const [requiresPrescription, setRequiresPrescription] = useState(
      medication?.requiresPrescription || false,
    )
    const [usageDirections, setUsageDirections] = useState(
      medication?.usageDirections || '',
    )
    const [additionalDirections, setAdditionalDirections] = useState(
      medication?.additionalDirections || '',
    )

    console.log('MedicationInput.medication = ', medication)

    const [
      addMedicationForDiagnosis,
      {
        loading: addMedicationForDiagnosisLoading,
        error: addMedicationForDiagnosisError,
      },
    ] = useMutation(AddMedicationForDiagnosisMutation, {
      refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
      onCompleted: () => {
        onClose?.()
      },
    })

    const [
      updateMedicationForDiagnosis,
      {
        loading: updateMedicationForDiagnosisLoading,
        error: updateMedicationForDiagnosisError,
      },
    ] = useMutation(UpdateMedicationForDiagnosisMutation, {
      refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
      onCompleted: () => {
        onClose?.()
      },
    })

    return (
      <Box ref={ref}>
        <Box sx={{ display: 'flex' }}>
          <CustomTextField
            fullWidth
            autoFocus
            size="small"
            id="medication"
            name="medication"
            value={medicationName}
            label="Type medication here"
            disabled={addMedicationForDiagnosisLoading}
            onChange={(e) => setMedicationName(e.target.value)}
          />
        </Box>

        <Box>
          <FormControlLabel
            id="prescriptionRequired"
            control={
              <Checkbox
                checked={requiresPrescription}
                onChange={(e) => setRequiresPrescription(e.target.checked)}
              />
            }
            label="Prescription required"
          />
        </Box>

        <CustomTextField
          size="small"
          id="usage"
          name="usage"
          fullWidth
          label="Usage directions"
          sx={{ flex: 1, mb: 2 }}
          value={usageDirections}
          disabled={addMedicationForDiagnosisLoading}
          onChange={(e) => setUsageDirections(e.target.value)}
        />

        <CustomTextField
          fullWidth
          size="small"
          id="additionalDirections"
          name="additionalDirections"
          label="Additional directions for treatment"
          value={additionalDirections}
          disabled={addMedicationForDiagnosisLoading}
          onChange={(e) => setAdditionalDirections(e.target.value)}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {onClose && (
            <Button onClick={onClose} color="error" size="small">
              Cancel
            </Button>
          )}

          <Button
            variant="contained"
            size="small"
            onClick={async () => {
              if (medication?.id) {
                await updateMedicationForDiagnosis({
                  variables: {
                    customerId: diagnosisRecord.customerId,
                    caseId: diagnosisRecord.caseId,
                    doctor: getDoctorProfile(),
                    diagnosisRecordId: diagnosisRecord.id,
                    medicationId: medication.id,
                    medication: medicationName,
                    requiresPrescription,
                    usageDirections,
                    additionalDirections,
                    // prefillId: medication?.prefillId,
                  },
                })
              } else {
                await addMedicationForDiagnosis({
                  variables: {
                    customerId: diagnosisRecord.customerId,
                    caseId: diagnosisRecord.caseId,
                    doctor: getDoctorProfile(),
                    diagnosisRecordId: diagnosisRecord.id,
                    medication: medicationName,
                    requiresPrescription,
                    usageDirections,
                    additionalDirections,
                    prefillId: medication?.prefillId,
                  },
                })
              }
            }}
            disabled={!medicationName || !usageDirections}
          >
            Save
          </Button>
        </Box>
      </Box>
    )
  },
)

const MedicationDosageInput = ({ initialValue, onChange, disabled }: any) => {
  const [usageDirections, setUsageDirections] = useState(
    initialValue.usageDirections || '',
  )
  const [usageDirectionsShort, setUsageDirectionsShort] = useState(
    initialValue.usageDirectionsShort || '0000',
  )

  useEffect(() => {
    onChange({ usageDirections, usageDirectionsShort })
  }, [usageDirections, usageDirectionsShort])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <CustomTextField
        size="small"
        id="usage"
        name="usage"
        label="Usage directions"
        sx={{ flex: 1 }}
        value={usageDirections}
        disabled={disabled}
        onChange={(e) => setUsageDirections(e.target.value)}
      />

      <Box>
        <PinInput
          length={4}
          initialValue={usageDirectionsShort}
          onChange={(value, index) => {
            setUsageDirectionsShort(value)
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{
            // margin: 2,
            width: 34,
            height: 34,
            borderRadius: 4,
            borderColor: 'lightgray',
          }}
          disabled={disabled}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Box>
    </Box>
  )
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: theme.palette.divider,

    height: '100%',
    maxHeight: 'unset',
    textTransform: 'unset',
    // padding: '28px 14px',
    padding: '0px 4px',
    marginRight: 4,
    borderTopLeftRadius: theme.shape.borderRadius + 'px',
    borderBottomLeftRadius: theme.shape.borderRadius + 'px',

    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
  '& .MuiInputBase-root': {
    fontSize: 12,
  },
}))
