import { EventRepeat } from '@mui/icons-material'
import { DockAction } from '@trueskin-backoffice/components'
import React, { useMemo } from 'react'
import { useSubscriptionMutations } from '../../contexts'
import { ConfirmSubscriptionActionDialog } from './confirm-subscription-action-dialog'

export const EditRenewalDateAction = ({ subscription }: any) => {
  const { actions } = subscription
  const [showEditDialog, setShowEditDialog] = React.useState(false)
  const { scheduleToRenew, renewImmediately } = useSubscriptionMutations()

  const options = useMemo(
    () => [
      {
        rule: actions.canEditRenewalDate.immediately.enabled,
        action: 'Renew immediately',
        onSubmit: renewImmediately,
      },
      {
        rule: actions.canEditRenewalDate.atEndOfTerm.enabled,
        action: 'Renew at end of term',
        onSubmit: scheduleToRenew,
      },
    ],
    [subscription],
  )

  return (
    <>
      <DockAction
        startIcon={<EventRepeat />}
        disabled={!actions.canEditRenewalDate.enabled}
        onClick={() => setShowEditDialog(true)}
      >
        Edit renewal date
      </DockAction>

      {showEditDialog ? (
        <ConfirmSubscriptionActionDialog
          opened={showEditDialog}
          onClose={() => setShowEditDialog(false)}
          header={`Edit renewal date`}
          description={'When do you want to renew the subscription?'}
          confirmText={[
            'Submitting this will affect the customers renewal date. Select to confirm you want to make the changes?',
          ]}
          options={options}
          submitLabel={'Schedule renewal'}
        />
      ) : null}
    </>
  )
}
