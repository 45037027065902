import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
  DataFilterV2Provider,
  DataTable,
  HighlightedTypography,
  IdTag,
  LightTag,
  PageHeader,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BooleanParam, StringParam } from 'use-query-params'
import { getAllEmployees, getAllRoles, getAllTeams } from './employees.gql'
import { ROLE_ID_MAP } from './types'

const DEFAULT_PAGE_SIZE = 25

const EMPLOYEES_FILTER_INIT_VALUES = {
  search: StringParam,
  teamId: StringParam,
  role: StringParam,
  active: BooleanParam,
  teamless: BooleanParam,
}

export const EmployeesPage = () => {
  const navigate = useNavigate()

  return (
    <DataFilterV2Provider config={EMPLOYEES_FILTER_INIT_VALUES}>
      <Box>
        <PageHeader
          sx={{
            mb: 2,
            mr: -2,
            ml: -2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <EmployeesFilters />
            <Button
              size="small"
              sx={{ ml: 'auto' }}
              onClick={() => navigate('/employees/create', { replace: true })}
            >
              <AddIcon /> Employee
            </Button>
          </Box>
        </PageHeader>

        <EmployeesTable />
      </Box>
    </DataFilterV2Provider>
  )
}

export const EmployeesTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const { query } = useDataFilterV2()
  const { search = '', ...filter } = query

  const offset = (currentPage - 1) * pageSize

  const { data, loading, error, refetch } = useV2Query(getAllEmployees, {
    variables: {
      offset,
      limit: pageSize,
      search,
      filter,
    },
  })

  const employees = data?.getAllEmployees?.data
  const total = data?.getAllEmployees?.total

  const totalPages = Math.ceil(total / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({
        offset,
        limit: pageSize,
        filter,
        search,
      })
    }
  }, [
    pageSize,
    currentPage,
    loading,
    refetch,
    filter,
    search,
    employees,
    offset,
  ])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter, loading])

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'Id',
        cell: ({ getValue }) => {
          return (
            <IdTag
              id={getValue()}
              linkTo={`/employees/${getValue()}`}
              allowCopyToClipboard
            />
          )
        },
      },
      {
        accessorKey: 'firstName',
        header: 'Full Name',
        cell: ({ row, table }) => {
          return (
            <HighlightedTypography
              highlight={(table.options.meta as { search: string }).search}
            >
              {row.original?.firstName} {row.original?.lastName}
            </HighlightedTypography>
          )
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: ({ getValue, table }) => (
          <HighlightedTypography
            highlight={(table.options.meta as { search: string }).search}
          >
            {getValue() as string}
          </HighlightedTypography>
        ),
      },
      {
        accessorKey: 'memberOf',
        header: 'Teams',
        cell: ({ getValue, row }) => {
          const memberOf = (getValue() as any[]) ?? []
          return memberOf.length > 0 ? (
            <Box>
              {memberOf.map((m: any) => (
                <LightTag label={m.name} key={`${row.original._id}_${m._id}`} />
              ))}
            </Box>
          ) : null
        },
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
        cell: ({ getValue, row }) => {
          const roles = (getValue() as string[]) ?? []
          return roles.length > 0 ? (
            <Box>
              {roles.map((r: string) => (
                <LightTag label={r} key={`${row.original._id}_${r}`} />
              ))}
            </Box>
          ) : null
        },
      },
      {
        accessorKey: 'active',
        header: 'Active',
        cell: ({ getValue }) => (
          <Checkbox checked={getValue() as boolean} disabled={true} />
        ),
      },
    ],
    [],
  )

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Typography color={'red'}>{error.message}</Typography>}
      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          mt: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        {employees && (
          <Box>
            <DataTable
              columns={columns}
              data={employees}
              isLoading={loading}
              page={currentPage}
              totalPages={totalPages}
              rowsPerPage={pageSize}
              onPageChange={setCurrentPage}
              onRowsPerPageChange={setPageSize}
              meta={{ search }}
            />

            {totalPages > 1 && (
              <Box>
                <Pagination
                  page={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  onPageChange={setCurrentPage}
                  onRowsPerPageChange={setPageSize}
                />
              </Box>
            )}
          </Box>
        )}
      </Card>
    </>
  )
}

const EmployeesFilterWrapper = ({ children }: React.PropsWithChildren) => (
  <Box>{children}</Box>
)

const EmployeesFilters = () => {
  const {
    data: { getAllRoles: roles } = {},
    loading: rolesLoading,
    error: rolesError,
  } = useV2Query(getAllRoles)

  const {
    data: { getAllTeams: teams } = {},
    loading: teamsLoading,
    error: teamsError,
  } = useV2Query(getAllTeams)

  const error = teamsError || rolesError
  const loading = rolesLoading || teamsLoading

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <DataFilterV2
      wrapper={EmployeesFilterWrapper}
      inputRows={[
        [
          {
            label: 'Search for employee id / name / email',
            key: 'search',
            type: DataFilterV2FilterComponent.ID,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
        ],
      ]}
      additionalFilters={[
        {
          label: 'Team',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'teamId',
          options: teams.map(({ _id, name }: any) => ({
            label: name,
            value: _id,
          })),
          op: DataFilterV2InputOperation.IS,
        },
        {
          label: 'Role',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'role',
          options: roles.map(({ _id, name }: any) => ({
            label: name,
            value: Object.entries(ROLE_ID_MAP).find(
              ([key, value]) => value === _id,
            )?.[0],
          })),
          op: DataFilterV2InputOperation.IS,
        },
        {
          label: 'Without team',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'teamless',
          options: [{ label: 'yes', value: true }],
          op: DataFilterV2InputOperation.IS,
        },
        {
          label: 'Active',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'active',
          options: [
            { label: 'yes', value: true },
            { label: 'no', value: false },
          ],
          op: DataFilterV2InputOperation.IS,
        },
      ]}
    />
  )
}
