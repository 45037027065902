import { gql } from '@apollo/client'

const discountGroupDefinitionFields = gql`
  fragment DiscountGroupDefinitionFields on DiscountGroupDefinition {
    confirmationMessage
    referralClaMessage
  }
`

const discountGroupVariantSelectionRulesFields = gql`
  fragment DiscountGroupVariantSelectionRulesFields on DiscountGroupVariantSelectionRule {
    name
    occurrences
  }
`

const discountGroupVariantDiscountFields = gql`
  fragment DiscountGroupVariantDiscountFields on DiscountGroupVariantDiscount {
    amount
    currency
    type
  }
`

const discountGroupVariantRedemptionRulesFields = gql`
  fragment DiscountGroupVariantRedemptionRulesFields on DiscountGroupVariantRedemptionRule {
    name
    maxRedemptions
  }
`

const discountGroupVariantDefinitionLineItemFields = gql`
  fragment DiscountGroupVariantDefinitionLineItemFields on DiscountGroupVariantDefinitionLineItem {
    title
    amount
    currency
    integrateShipping
  }
`

const discountGroupVariantDefinitionRulesFields = gql`
  ${discountGroupVariantDefinitionLineItemFields}
  fragment DiscountGroupVariantDefinitionRules on DiscountGroupVariantDefinition {
    mainLabel
    secondaryLabel
    lineItems {
      ...DiscountGroupVariantDefinitionLineItemFields
    }
    confirmationMessage
  }
`

const discountGroupVariantFields = gql`
  ${discountGroupVariantSelectionRulesFields}
  ${discountGroupVariantDiscountFields}
  ${discountGroupVariantRedemptionRulesFields}
  ${discountGroupVariantDefinitionRulesFields}
  fragment DiscountGroupVariantFields on DiscountGroupVariant {
    selectionRules {
      ...DiscountGroupVariantSelectionRulesFields
    }
    discount {
      ...DiscountGroupVariantDiscountFields
    }
    redemptionRules {
      ...DiscountGroupVariantRedemptionRulesFields
    }
    definition {
      ...DiscountGroupVariantDefinitionRules
    }
  }
`

export const discountGroupFields = gql`
  ${discountGroupDefinitionFields}
  ${discountGroupVariantFields}
  fragment DiscountGroupFields on DiscountGroup {
    id
    name
    definition {
      ...DiscountGroupDefinitionFields
    }
    variants {
      ...DiscountGroupVariantFields
    }
    available
  }
`

export const getDiscountGroupByIdQuery = gql`
  ${discountGroupFields}
  query DiscountGroup($id: String!) {
    discountGroup(id: $id) {
      ...DiscountGroupFields
    }
  }
`

export const getDiscountGroupVariant = gql`
  ${discountGroupVariantFields}
  query DiscountGroupVariant($id: String!, $occurrence: String!) {
    discountGroupVariant(id: $id, occurrence: $occurrence) {
      ...DiscountGroupVariantFields
    }
  }
`
