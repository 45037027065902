import { gql } from '@apollo/client'

export const getOrdersByCustomerId = gql`
  query GetOrdersByCustomerId($customerId: String) {
    customer(customerId: $customerId) {
      id
      type
      orders {
        _id
        payment {
          status
          amountRefunded
        }
        paidAt
        createdAt
        items {
          product
          quantity
        }
        occurrence
        currency
        values {
          discount
          discountPercentage
          total
          totalWithoutDiscounts
          shipping
        }
        preset
        pricing {
          amount
          products {
            product
            quantity
          }
        }
        payment {
          amountRefunded
          currency
        }
        sequence
      }
    }
  }
`

export const getCustomerCurrentSubscription = gql`
  query GetCustomerCurrentSubscription($customerId: String!) {
    customer(customerId: $customerId) {
      id
      activeSubscriptions {
        status
        occurrence
        endBehaviour
        endDate
        startDate
        id
      }
      currentSubscription {
        status
        occurrence
        endBehaviour
        endDate
        startDate
        id

        metadata {
          v1Subscription {
            _id
          }
        }
      }
    }
  }
`

export const updateCustomerEmailMutation = gql`
  mutation UpdateCustomerEmail(
    $customerId: String!
    $v1UserId: String!
    $email: String!
  ) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      updateEmail(email: $email) {
        email
      }
    }
  }
`

export const setCustomerIsTesMutation = gql`
  mutation setCustomerIsTest(
    $customerId: String!
    $v1UserId: String!
    $isTest: Boolean!
  ) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      setCustomerIsTest(isTest: $isTest) {
        settings {
          isTest
        }
      }
    }
  }
`

export const updateCustomerFirstNameMutation = gql`
  mutation UpdateCustomerFirstName(
    $customerId: String!
    $v1UserId: String!
    $firstName: String!
  ) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      updateFirstName(firstName: $firstName) {
        firstName
      }
    }
  }
`

export const updateCustomerLastNameMutation = gql`
  mutation UpdateCustomerLastName(
    $customerId: String!
    $v1UserId: String!
    $lastName: String!
  ) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      updateLastName(lastName: $lastName) {
        lastName
      }
    }
  }
`

export const updateCustomerBirthDateMutation = gql`
  mutation UpdateCustomerBirthDate(
    $customerId: String!
    $v1UserId: String!
    $birthDate: String!
  ) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      updateBirthDate(birthDate: $birthDate) {
        birthDate
      }
    }
  }
`

export const updateAccountPasswordMutation = gql`
  mutation updateAccountPassword(
    $accountId: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    account(accountId: $accountId) {
      updatePassword(
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      ) {
        updated
      }
    }
  }
`

export const updateAccountEmailMutation = gql`
  mutation updateAccountEmail($accountId: String!, $email: String!) {
    account(accountId: $accountId) {
      updateEmail(email: $email) {
        updated
      }
    }
  }
`

export const deleteCustomerMutation = gql`
  mutation DeleteCustomer($customerId: String!, $v1UserId: String!) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      deleteCustomer(customerId: $customerId) {
        id
      }
    }
  }
`

export const customerSubscriptionsQuery = gql`
  query CustomerSubscriptionsQuery($customerId: String!) {
    customer(customerId: $customerId) {
      subscriptions
    }
  }
`

export const updateCustomerAddressMutation = gql`
  mutation UpdateCustomerAddress(
    $customerId: String
    $v1UserId: String
    $data: UpdateCustomerAddress!
    $addressId: String!
  ) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      updateAddress(data: $data, addressId: $addressId) {
        id
      }
    }
  }
`
