import { useQuery } from '@apollo/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import {
  DateTime,
  HeroCard,
  HeroCardDivider,
  HeroCardEmpty,
  HeroCardSection,
  IdTag,
  TagGroupItem,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { Order } from '@trueskin-backoffice/gql'
import { getOrdersByCustomerId } from '../customer.gql.bff'

export const CustomersOrdersSummary = () => {
  const {
    customer: { customerId },
  } = useCustomer()

  const {
    data: { customer } = {},
    loading,
    error,
  } = useQuery(getOrdersByCustomerId, {
    skip: customerId === undefined,
    variables: {
      customerId,
    },
  })

  if (loading) {
    return <LinearProgress />
  }

  if (!customer.orders?.length) {
    return (
      <HeroCardEmpty>
        <Typography fontSize={12}>No orders found</Typography>
      </HeroCardEmpty>
    )
  }

  if (error) {
    return (
      <HeroCardEmpty>
        <Typography color={'red'}>{error.message}</Typography>
      </HeroCardEmpty>
    )
  }

  // TODO: Add type
  const orders: [Order] = customer.orders || []

  return (
    <HeroCard>
      {orders.map((order: any, index: number) => (
        <>
          <HeroCardSection sx={{ m: 0 }}>
            <OrderSummary order={order} />
          </HeroCardSection>

          {index < orders.length - 1 ? <HeroCardDivider /> : null}
        </>
      ))}
    </HeroCard>
  )
}

const OrderSummary = ({ order }: any) => {
  return (
    <>
      <VerticalList>
        <VerticalListRow sx={{ verticalAlign: 'baseline' }}>
          <VerticalListRowItem>
            {order.sequence && (
              <TagGroupItem sx={{ py: 0, px: 1, mb: 1 }}>
                <Box sx={{ fontWeight: 500, fontSize: 13 }}>
                  # {order.sequence}
                </Box>
              </TagGroupItem>
            )}

            <TagGroupItem
              sx={{
                textTransform: 'uppercase',
                borderRadius: 1,
                py: 0,
                px: 1,
              }}
            >
              {order.payment?.status}
            </TagGroupItem>
          </VerticalListRowItem>

          <VerticalListRowItem fullWidth={true}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ mr: 1, fontWeight: 500 }}>
                  {!!order.values.total &&
                    formatCurrency(
                      order.values.total / 100,
                      null,
                      order.values.currency || order.currency,
                    )}
                </Box>

                {order.paidAt && (
                  <>
                    <Box sx={{ mr: 1 }}>paid at</Box>
                    <DateTime
                      dateTime={order.paidAt}
                      tooltipPlacement="right"
                    />
                  </>
                )}
              </Box>
            </Box>

            <Box>
              <IdTag
                id={order._id}
                linkTo={`orders/${order._id}`}
                allowCopyToClipboard
                copyToClipboardPosition="end"
              />
            </Box>

            <Box sx={{ display: 'flex' }}>
              {order.items?.map(({ product, quantity }: any) => (
                <TagGroupItem
                  key={product}
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    borderRadius: 1,
                    px: 1,
                    py: 0,
                  }}
                >{`${product}  ${
                  quantity > 1 ? `- ${quantity} pcs` : ''
                }`}</TagGroupItem>
              ))}
            </Box>
          </VerticalListRowItem>

          <VerticalListRowItem>
            {!!order.payment.amountRefunded && (
              <Typography variant="caption">
                Refunded&nbsp;
                {formatCurrency(
                  order.payment.amountRefunded / 100,
                  null,
                  order.payment.currency,
                )}
              </Typography>
            )}
          </VerticalListRowItem>
        </VerticalListRow>
      </VerticalList>
    </>
  )
}
