import { Clear } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { DataFilterV2InputConfigId } from '../data-filter-input-config'

export const DataFilterIdInput = ({
  input,
}: {
  input: DataFilterV2InputConfigId
}) => {
  const [inputValue, setInputValue] = useQueryParam(input.key, StringParam)

  const [filterValue, setFilterValue] = useState<string | null | undefined>(
    inputValue,
  )

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!filterValue && inputValue) {
        setInputValue(undefined)
      } else {
        setInputValue(filterValue)
      }
    }, 250)

    return () => clearTimeout(getData)
  }, [filterValue])

  useEffect(() => {
    setFilterValue(inputValue)
  }, [inputValue])

  return (
    <TextField
      value={filterValue || ''}
      placeholder={input.label}
      autoFocus
      onChange={(e) => setFilterValue(e.target.value)}
      onKeyDown={(event) =>
        event.key === 'Enter' && filterValue && setInputValue(filterValue)
      }
      InputProps={{
        startAdornment: <InputAdornment position="start">🔑</InputAdornment>,
        endAdornment: (
          <InputAdornment
            position="start"
            onClick={() => {
              setInputValue(undefined)
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Clear />
          </InputAdornment>
        ),
      }}
      size="small"
      sx={{
        '& .MuiInputBase-root': {
          pr: 0,
        },
        '& input': {
          minWidth: 250,
          height: 30,
          lineHeight: '32px',
          fontSize: 12,
          py: 0,
        },
      }}
    />
  )
}
