import { gql } from '@apollo/client'

export const orderFieldsBff = gql`
  fragment OrderFields on Order {
    id
    customer
    customerId
    payment {
      status
      amountRefunded
      currency
    }
    paidAt
    createdAt
    items {
      product
      quantity
    }
    occurrence
    currency
    sequence
    instalments {
      count
      invoices {
        id
        status
        total
        currency
      }
    }
    values {
      discount
      discountPercentage
      total
      totalWithoutDiscounts
      shipping
    }
    locale
    preset
    pricing {
      amount
      products {
        product
        quantity
      }
    }
    discounts {
      ref
      refId
      amount
      currency
    }
    invoice {
      id
      status
    }
    vouchers {
      used
      errors {
        message
      }
      systemDefault
      voucher {
        _id
        code
        active
        applicableRules {
          name
          targetGroup
          locales
          validUntil
          pricing
          firstPurchase
        }
      }
    }
  }
`
