import { Edit } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import { DockAction } from '@trueskin-backoffice/components'
import React from 'react'
import 'react-day-picker/dist/style.css'
import { UpdateFirstNameModal } from '../../components/update-first-name.modal'

export const EditFirstNameAction = () => {
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  return (
    <>
      <DockAction startIcon={<Edit />} onClick={() => setShowEditDialog(true)}>
        First name
      </DockAction>

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <UpdateFirstNameModal onClose={() => setShowEditDialog(false)} />
        </Dialog>
      ) : null}
    </>
  )
}
