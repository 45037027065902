import { gql } from '@apollo/client'

// TODO: improve query to not load all fields by default
export const getAllQuestionnairesDefinitions = gql`
  query AllQuestionnairesDefinitionsQuery(
    $offset: Int!
    $limit: Int!
    $filter: QuestionnairesDefinitionsFilter
  ) {
    questionnairesDefinitions(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
      }
      totalRows
    }
  }
`
