import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {
  Box,
  Button,
  Grid,
  IconButton,
  ImageList,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAxios, useV2Mutation } from '@trueskin-backoffice/api-client'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { noop } from 'lodash'
import { useCallback, useState } from 'react'
import { FormSection, ImageLoad } from '../employee.components'
import {
  getEmployeeById,
  removeDoctorProfileMutation,
  updateDoctorProfile,
} from '../employees.gql'
import { Employee, Gender, Role } from '../types'

interface Props {
  employee: Employee
  onSuccess?: () => void
  onCancel?: () => void
  editOnly?: boolean
}

export const DoctorProfile = ({
  employee,
  onSuccess = noop,
  onCancel = noop,
  editOnly = false,
}: Props) => {
  const [edit, setEdit] = useState(editOnly)

  const profile = employee?.profile?.doctor

  const getProfileValues = useCallback(
    () => ({
      gender: profile?.gender || '',
      profileImage: profile?.profileImage?.locationUrl,
      bannerImage: profile?.bannerImage?.locationUrl,
      signatureImage: profile?.signatureImage?.locationUrl,
      title: profile?.title || '',
    }),
    [profile],
  )

  const [updateImages, { loading: imagesLoading, error: imagesError }] =
    useAxios<Employee>({
      method: 'post',
      url: `/employees/${employee._id}/profile/doctor/images`,
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    })

  const [
    updateProfileFields,
    { loading: profileLoading, error: profileError },
  ] = useV2Mutation(updateDoctorProfile, {
    refetchQueries: [
      {
        query: getEmployeeById,
        variables: { employeeId: employee._id },
      },
    ],
  })

  const [removeDoctorProfile, { loading: removeLoading, error: removeError }] =
    useV2Mutation(removeDoctorProfileMutation, {
      variables: { employeeId: employee._id },
      refetchQueries: [
        {
          query: getEmployeeById,
          variables: { employeeId: employee._id },
        },
      ],
    })

  const formik = useFormik<{
    profileImage: string | File
    bannerImage: string | File
    signatureImage: string | File
    gender: string
    title: string
  }>({
    initialValues: getProfileValues(),
    onSubmit: async (values) => {
      const body = new FormData()

      if (values.profileImage instanceof File) {
        body.append('profileImage', values.profileImage)
      }
      if (values.bannerImage instanceof File) {
        body.append('bannerImage', values.bannerImage)
      }
      if (values.signatureImage instanceof File) {
        body.append('signatureImage', values.signatureImage)
      }

      const { data: updatedImages } = await updateImages({ data: body })

      const { data: updatedFields } = await updateProfileFields({
        variables: {
          employeeId: employee._id,
          doctorProfile: { gender: values.gender, title: values.title },
        },
      })

      if (updatedImages && updatedFields) {
        setEdit(false)
        onSuccess()
      }
    },
  })

  const handleCancelEdit = () => {
    formik.setValues(getProfileValues())
    setEdit(false)
    onCancel()
  }

  const loading = imagesLoading || profileLoading || removeLoading
  const error = imagesError || profileError || removeError

  const hasRole = employee?.roles.includes(Role.DOCTOR)

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        border: !editOnly ? 'dashed' : undefined,
        width: '100%',
        borderColor: edit ? 'primary.main' : hasRole ? 'grey' : 'orange',
        borderWidth: edit ? '3px' : '1px',
      }}
    >
      {loading && <LinearProgress />}
      {error && (
        <Typography variant="caption" color="red">
          {error?.message}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Doctor</Typography>{' '}
        {!edit ? (
          <Button onClick={() => setEdit(true)}>
            <EditIcon />
          </Button>
        ) : (
          !editOnly && (
            <Button onClick={handleCancelEdit}>
              <CloseIcon color="secondary" />
            </Button>
          )
        )}
        <Box sx={{ ml: 'auto' }}>
          {!hasRole && !editOnly && (
            <Tooltip title="Remove doctor's profile">
              <IconButton onClick={() => removeDoctorProfile()}>
                <RemoveCircleIcon color="error"></RemoveCircleIcon>
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Grid container spacing={2} alignItems="center">
        <FormSection
          label="Title"
          value={
            <TextField
              InputProps={{
                readOnly: !edit,
              }}
              sx={{ width: '100%' }}
              value={formik.values.title}
              onChange={(e) => formik.setFieldValue('title', e.target.value)}
            />
          }
        />
        <FormSection
          label="Gender"
          labelXs={2}
          value={
            <Box m={2}>
              <Select
                sx={{ width: '100%' }}
                value={formik.values.gender}
                name="gender"
                displayEmpty
                onChange={formik.handleChange}
                inputProps={{
                  readOnly: !edit,
                }}
              >
                <MenuItem value={Gender.MALE}>{Gender.MALE}</MenuItem>
                <MenuItem value={Gender.FEMALE}>{Gender.FEMALE}</MenuItem>
              </Select>
            </Box>
          }
        />
      </Grid>

      <ImageList cols={3}>
        <ImageLoad
          readonly={!edit}
          value={formik.values.profileImage}
          onChange={(file) => formik.setFieldValue('profileImage', file)}
          label={t('ProfilePage.Doctor.profileImage')}
        />
        <ImageLoad
          readonly={!edit}
          onChange={(file) => formik.setFieldValue('bannerImage', file)}
          value={formik.values.bannerImage}
          label={t('ProfilePage.Doctor.bannerImage')}
        />
        <ImageLoad
          readonly={!edit}
          onChange={(file) => formik.setFieldValue('signatureImage', file)}
          value={formik.values.signatureImage}
          label={t('ProfilePage.Doctor.signatureImage')}
        />
      </ImageList>
      {edit && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            onClick={formik.submitForm}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleCancelEdit}
            sx={{ ml: 3 }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  )
}
