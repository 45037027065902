import { useMutation } from '@apollo/client'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  MenuItem,
  styled,
  Typography,
} from '@mui/material'
import {
  H2,
  HeroCard,
  Id,
  JsonButton,
  MoreActionsButton,
  Section,
} from '@trueskin-backoffice/components'
import { formatCurrency } from '@trueskin-backoffice/functions'
import React, { useState } from 'react'
import { InvoiceStatus } from '../../../order/components/invoice-status'
import { InvoiceTransactions } from '../../../order/components/invoice-transaction'
import { InvoiceRefundDialog } from '../../../order/sub-sections/invoice-refund-dialog'
import { getCaseById, initiateCaseRefundMutation } from '../gql/case.gql.bff'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} disableRipple sx={{ p: 0 }} color="primary" />
  // return <ExpandMoreIcon />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const InvoiceSummary = ({ invoice }: any) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const [voidConfirmationDialogOpened, setVoidConfirmationDialogOpened] =
    useState(false)

  const [initiateCaseRefund, { error, loading }] = useMutation(
    initiateCaseRefundMutation,
    {
      refetchQueries: [getCaseById],
    },
  )

  return (
    <HeroCard>
      <Box
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <H2>
            Invoice for{' '}
            <b>
              {formatCurrency(
                invoice.total / 100,
                invoice.locale,
                invoice.currency,
              )}
            </b>
            <Id fontSize="11px">{invoice.id}</Id>
          </H2>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InvoiceStatus invoice={invoice} />
            <JsonButton id={invoice.id} jsonData={invoice} />
            {invoice?.status === 'PAYMENT_DUE' && (
              <MoreActionsButton
                horizontalIcon
                size={'small'}
                buttonProps={{ p: 0 }}
              >
                <MenuItem onClick={() => setVoidConfirmationDialogOpened(true)}>
                  Void
                </MenuItem>
              </MoreActionsButton>
            )}
          </Box>
        </Box>

        <Divider sx={{ mt: 1 }} />

        <Box
          sx={{
            px: 4,
            py: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography>
              Paid:{' '}
              {formatCurrency(
                invoice.amountPaid / 100,
                invoice.locale,
                invoice.currency,
              )}
            </Typography>
          </Box>

          <Box>
            <div>
              Due:{' '}
              {formatCurrency(
                invoice.amountDue / 100,
                invoice.locale,
                invoice.currency,
              )}
            </div>
          </Box>

          <Box>
            <div>
              Refunded:{' '}
              {formatCurrency(
                invoice.amountRefunded / 100,
                invoice.locale,
                invoice.currency,
              )}
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InvoiceRefundDialog
              invoice={invoice}
              refundFn={({ variables }: any) =>
                initiateCaseRefund({
                  variables: {
                    ...variables,
                    customerId: invoice.customerId,
                    caseId: invoice.linkedObject.caseId,
                  },
                })
              }
            />
          </Box>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="text"
            size="small"
            onClick={handleExpandClick}
            endIcon={
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          >
            {expanded ? 'Hide' : 'Show'} transactions
          </Button>
        </Box>

        <Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <InvoiceTransactions invoice={invoice} />
          </Collapse>
        </Box>
      </Box>
    </HeroCard>
  )
}

export const CaseInvoiceInformation = ({ invoice }: any) => {
  if (!invoice) {
    return null
  }

  return (
    <Section headerLabel={`Invoice`}>
      <Box sx={{ mb: 2 }}>
        <InvoiceSummary invoice={invoice} />
      </Box>
    </Section>
  )
}
