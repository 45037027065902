import { Box } from '@mui/material'
import React, { ReactNode } from 'react'

// TODO: move to better folder
export const MedicalLayout = (props: LayoutProps) => {
  const { children } = props

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/*{authenticated && <MedicalMenu />}*/}

      <Box sx={{ flex: '1 1 auto', display: 'flex', overflow: 'hidden' }}>
        <Box
          component="main"
          sx={{
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export interface LayoutProps {
  children: ReactNode
}
