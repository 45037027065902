import { Edit } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import { bffGqlClient } from '@trueskin-backoffice/api-client'
import {
  DockAction,
  EditAccountEmailModal,
} from '@trueskin-backoffice/components'
import { patientQuery, usePatient } from '@trueskin-backoffice/contexts'
import React from 'react'
import 'react-day-picker/dist/style.css'

export const EditEmailAction = () => {
  const [showEditDialog, setShowEditDialog] = React.useState(false)
  const { customer } = usePatient()

  return (
    <>
      <DockAction startIcon={<Edit />} onClick={() => setShowEditDialog(true)}>
        E-mail
      </DockAction>

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <EditAccountEmailModal
            accountId={customer.accountId}
            email={customer.profile.email}
            open={showEditDialog}
            onClose={() => setShowEditDialog(false)}
            onComplete={() =>
              bffGqlClient.refetchQueries({ include: [patientQuery] })
            }
          />
        </Dialog>
      ) : null}
    </>
  )
}
