import { useMutation } from '@apollo/client'
import { Search, Visibility } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  Popper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import {
  HeroCard,
  HeroCardSection,
  HtmlTooltip,
} from '@trueskin-backoffice/components'
import { useAuth, useNotionCmsQuestions } from '@trueskin-backoffice/contexts'
import { EditorPlayground } from '@trueskin/bo/editor'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useWaitingRoomCase } from './waiting-room-case.context'
import {
  addDoctorMessageToCaseMutation,
  getWaitingRoomCase,
} from './waiting-room.gql.bff'

export const CaseMessageBox = () => {
  const { employee, getDoctorProfile } = useAuth()
  const { currentCase } = useWaitingRoomCase()
  const [abc, setAbc] = useState<{
    mdContent?: string
    revision?: number
    append?: boolean
  }>({})
  const { notionCmsQuestions } = useNotionCmsQuestions()
  const prefillHeaderAndFooterRef = useRef<HTMLInputElement>(null)

  const [prefillHeaderAndFooter, setPrefillHeaderAndFooter] = useState(false)

  const [diagnosisPrefillsAnchorEl, setDiagnosisPrefillsAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const [
    addDoctorMessageToCase,
    {
      loading: addDoctorMessageToCaseLoading,
      error: addDoctorMessageToCaseError,
    },
  ] = useMutation(addDoctorMessageToCaseMutation, {
    refetchQueries: [getWaitingRoomCase],
    // onCompleted: () => {
    //   setEditorHtmlValue('<p><br></p>')
    // },
  })

  useEffect(() => {
    console.log('prefillHeaderAndFooter2 = ', prefillHeaderAndFooter)
  }, [prefillHeaderAndFooter])

  const handleSendMessage = useCallback(
    async ({
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      console.log(
        'prefillHeaderAndFooter = ',
        prefillHeaderAndFooter,
        prefillHeaderAndFooterRef,
        prefillHeaderAndFooterRef.current?.checked,
        prefillHeaderAndFooterRef.current?.value,
      )
      await addDoctorMessageToCase({
        variables: {
          customerId: currentCase.customerId,
          caseId: currentCase.id,
          doctor: getDoctorProfile(),
          richText,
          markdown,
          prefillHeaderAndFooter: prefillHeaderAndFooterRef.current?.checked,
        },
      })
    },
    [prefillHeaderAndFooter, prefillHeaderAndFooterRef],
  )

  const handleClose = () => {
    if (diagnosisPrefillsAnchorEl) {
      diagnosisPrefillsAnchorEl.focus()
    }
    setDiagnosisPrefillsAnchorEl(null)
  }

  const handleSearchDiagnosisPrefillsClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    if (diagnosisPrefillsAnchorEl) {
      handleClose()
    } else {
      setDiagnosisPrefillsAnchorEl(event.currentTarget)
    }
  }

  const handlePrefillSelected = (event: any, newValue: any) => {
    setAbc({ mdContent: newValue.mdContent, append: true })
    handleClose()
  }

  return (
    <HeroCard sx={{ overflow: 'visible', borderRadius: 1, mb: 4, p: 0 }}>
      <HeroCardSection>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              backgroundColor: '#eaeaf4',
              px: 2,
              fontWeight: 500,
              display: 'flex',
            }}
          >
            Questions
          </Typography>

          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
              backgroundColor: '#eaeaf4',
              px: 2,
              fontWeight: 500,
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={handleSearchDiagnosisPrefillsClick}
            >
              Prefill
              <Search sx={{ fontSize: 14, ml: 2 }} />
            </Box>

            <StyledPopper
              disablePortal={false}
              open={
                Boolean(diagnosisPrefillsAnchorEl) &&
                Boolean(diagnosisPrefillsAnchorEl)
              }
              anchorEl={diagnosisPrefillsAnchorEl}
            >
              {Boolean(diagnosisPrefillsAnchorEl) && (
                <Autocomplete
                  fullWidth
                  id="diagnosis-prefills"
                  size="small"
                  options={notionCmsQuestions || []}
                  getOptionLabel={(option: any) => option._id}
                  onChange={handlePrefillSelected}
                  autoComplete
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      {...params}
                      label="Search questions prefills"
                    />
                  )}
                />
              )}
            </StyledPopper>
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <EditorPlayground
            showActionsSendMessageButton
            handleSendMessage={handleSendMessage}
            injectContent={abc}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: '#eaeaf4',
            mt: 1,
            pl: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              inputRef={prefillHeaderAndFooterRef}
              size="small"
              checked={prefillHeaderAndFooter}
              onChange={(e) => setPrefillHeaderAndFooter(e.target.checked)}
              sx={{ m: 0, p: 0 }}
            />

            <Typography sx={{ fontSize: 12, ml: 2 }}>
              Prefill header and a footer when sending the message.
            </Typography>
          </Box>

          <HtmlTooltip
            title={
              <Box>
                <Box>
                  Header: Hallo {currentCase.customer?.profile?.firstName},
                </Box>
                <Box>
                  Footer: Liebe Grüße, {employee?.profile?.doctor?.title}{' '}
                  {employee?.firstName} {employee?.lastName}
                </Box>
              </Box>
            }
            placement="top"
          >
            <IconButton size="small" color="info">
              <Visibility color="info" sx={{ fontSize: 20 }} />
            </IconButton>
          </HtmlTooltip>
        </Box>
      </HeroCardSection>
    </HeroCard>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,
  minWidth: 400,
  padding: 8,
  zIndex: theme.zIndex.modal,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))
