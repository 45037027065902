import { useQuery } from '@apollo/client'
import { Box, Button, Modal, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getAllDiscountGroups } from './discount-groups.gql.bff'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
}

const DEFAULT_PAGE_SIZE = 25

export const DiscountGroupsTable = () => {
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const [openModal, setOpenModal] = useState(false)
  const [json, setJson] = useState('')

  const handleOpenModal = (jsonData: any) => (event: any) => {
    event.stopPropagation()
    setJson(jsonData)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setJson('')
    setOpenModal(false)
  }

  const columns = useMemo(
    () => discountGroupsTableColumns(handleOpenModal),
    [handleOpenModal],
  )

  const {
    data: {
      discountGroups: {
        rows: discountGroups = [],
        totalRows: totalDiscountGroups = 0,
      } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getAllDiscountGroups, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
    fetchPolicy: 'no-cache',
  })

  const totalPages = Math.ceil(totalDiscountGroups / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </Box>
      </Modal>

      <DataTable
        columns={columns}
        data={discountGroups}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const discountGroupsTableColumns = (
  handleOpenModal: any,
): ColumnDef<any, any>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    cell: ({ getValue }) => {
      return (
        <div>
          <NavLink to={`/discount-groups/${getValue()}`}>
            <Typography fontSize={13}>{getValue()}</Typography>
          </NavLink>
        </div>
      )
    },
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ getValue }) => {
      return (
        <div>
          <Typography fontSize={13}>{getValue()}</Typography>
        </div>
      )
    },
  },
  {
    header: 'Available',
    accessorKey: 'available',
    cell: ({ getValue }) => {
      return (
        <div>
          <Typography fontSize={13}>{getValue().toString()}</Typography>
        </div>
      )
    },
  },
  {
    header: 'Actions',
    cell: ({ row }: any) => {
      return (
        <Box>
          <Button onClick={handleOpenModal(row.original)}>View JSON</Button>
        </Box>
      )
    },
  },
]
