import { Box } from '@mui/material'
import { Restricted } from '@trueskin-backoffice/components'
import { PatientProvider } from '@trueskin-backoffice/contexts'
import { Outlet, Route, Routes } from 'react-router-dom'
import { CaseMedicalViewPage } from '../case/case-medical-view.page'
import { CasePage } from '../case/case.page'
import { PatientHeader } from './patient.header'
import { PatientPage } from './patient.page'

export const PatientRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path={'/*'}
          element={
            <PatientProvider>
              {/*<Box>*/}
              {/*  <CustomerHeader />*/}

              {/*</Box>*/}

              {/*<HealthRecordProvider>*/}
              {/*  <CustomerHeader />*/}
              {/*  <CustomerHealthRecord />*/}
              {/*</HealthRecordProvider>*/}
              {/*<HeroCardEmpty sx={{ minHeight: 40 }}>dadasds</HeroCardEmpty>*/}
              <Outlet />
            </PatientProvider>
          }
        >
          {/*<Route path={''} element={<CustomerPage />} />*/}
          <Route path={''} element={<PatientPage />} />

          <Route
            path={'*'}
            element={
              <Box>
                <PatientHeader />

                <Outlet />
              </Box>
            }
          >
            <Route path={'cases/:caseId'} element={<CasePage />} />

            <Route
              path={'cases/:caseId/medical-view'}
              element={
                <Restricted to={['medicalassistant', 'doctor']}>
                  <CaseMedicalViewPage />
                </Restricted>
              }
            />
          </Route>

          {/*<Route*/}
          {/*  path={'*'}*/}
          {/*  element={*/}
          {/*    <Box>*/}
          {/*      <CustomerHeader />*/}

          {/*      <Outlet />*/}
          {/*    </Box>*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Route path={'subscriptions/*'}>*/}
          {/*    <Route path={''} element={<CustomerSubscriptionsPage />} />*/}
          {/*    <Route path={':subscriptionId'} element={<SubscriptionPage />} />*/}
          {/*  </Route>*/}

          {/*  <Route*/}
          {/*    path={'orders/:orderId'}*/}
          {/*    element={*/}
          {/*      <OrderProvider>*/}
          {/*        <Outlet />*/}
          {/*      </OrderProvider>*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <Route path={''} element={<OrderPage />} />*/}
          {/*    <Route />*/}
          {/*  </Route>*/}
          {/*</Route>*/}
        </Route>
      </Routes>
    </>
  )
}
