import { Alert, Box } from '@mui/material'
import { H2 } from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useNavigate } from 'react-router-dom'

export const ConsultationsPage = () => {
  const navigate = useNavigate()

  const { employee, loading } = useAuth()

  if (!employee || loading) {
    return
  }

  return (
    <Box>
      {/* <PageHeader>
      
      </PageHeader> */}
      <Box sx={{ m: 2 }}>
        <Alert severity="warning">
          <H2>Still WIP, only 'Assign' feature can be used by Medical Lead</H2>
        </Alert>
      </Box>
    </Box>
  )
}
