import i18next from "i18next"
import ICU from "i18next-icu"
import { initReactI18next } from "react-i18next"
import {
  deBackofficeTranslations,
} from "./de"
import {
  enBackofficeTranslations,
} from "./en"
import { i18nLanguage, systemLocale } from "./i18n"
import {
  ptBackofficeTranslations,
} from "./pt"

const en = {
  translation: {
    ...enBackofficeTranslations,
  },
}

const de = {
  translation: {
    ...deBackofficeTranslations,
  },
}

const pt = {
  translation: {
    ...ptBackofficeTranslations,
  },
}

i18next
  .use(initReactI18next)
  .use(ICU)
  .init({
    resources: { en, de, pt },
    lng: i18nLanguage(),
    fallbackLng: i18nLanguage(systemLocale),
  })

export default i18next
