import { Box, Typography } from '@mui/material'
import { orange, red } from '@mui/material/colors'
import {
  HeroCard,
  HeroCardDivider,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
} from '@trueskin-backoffice/components'
import { ConditionDiagnosis } from '../diagnosis-record-form/condition-diagnosis'
import { Medication } from '../diagnosis-record-form/medication'
import { useWaitingRoomCaseDiagnosis } from '../waiting-room-case-diagnosis.context'
import { DiagnosisAndTreatmentPlan } from './diagnosis-and-treatment-plan'

export const DiagnosisRecordView = () => {
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()

  if (!diagnosisRecord || diagnosisRecord.status !== 'published') {
    return null
  }

  const hasDifferentialDiagnoses = diagnosisRecord?.conditionDiagnosis?.filter(
    (cd: any) => cd.type === 'differential_diagnosis',
  ).length
  const hasSecondaryFindings = diagnosisRecord?.conditionDiagnosis?.filter(
    (cd: any) => cd.type === 'secondary_findings',
  ).length

  return (
    <>
      <HorizontalList sx={{ mb: 2 }}>
        <HorizontalListItem>
          <Typography variant="caption" sx={{ mr: 1 }}>
            Id
          </Typography>
          {diagnosisRecord.id}
        </HorizontalListItem>

        <HorizontalListItem>
          <Typography variant="caption" sx={{ mr: 1 }}>
            Generated by
          </Typography>
          <Box sx={{ fontStyle: 'italic' }}>
            {diagnosisRecord.generatedBy.title}{' '}
            {diagnosisRecord.generatedBy.firstName}{' '}
            {diagnosisRecord.generatedBy.lastName}
          </Box>
        </HorizontalListItem>
      </HorizontalList>

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          alignItems: 'center',
          m: 0,
          p: 0,
          mb: 2,
          // py: 1,
          backgroundColor: red[50],
        }}
      >
        {diagnosisRecord?.conditionDiagnosis
          ?.filter((cd: any) => cd.type === 'diagnosis')
          .map((cd: any, index: number) => (
            <>
              <HeroCardSection sx={{ m: 0, width: '100%' }}>
                <ConditionDiagnosis conditionDiagnosis={cd} />
              </HeroCardSection>

              {index !== diagnosisRecord?.medication.length - 1 && (
                <HeroCardDivider />
              )}
            </>
          ))}
      </HeroCard>

      {Boolean(hasDifferentialDiagnoses) && (
        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            p: 0,
            mb: 2,
            // py: 1,
            backgroundColor: orange[50],
          }}
        >
          {diagnosisRecord?.conditionDiagnosis
            ?.filter((cd: any) => cd.type === 'differential_diagnosis')
            .map((cd: any, index: number) => (
              <>
                <HeroCardSection sx={{ m: 0, width: '100%' }}>
                  <ConditionDiagnosis conditionDiagnosis={cd} />
                </HeroCardSection>

                {index !== diagnosisRecord?.medication.length - 1 && (
                  <HeroCardDivider />
                )}
              </>
            ))}
        </HeroCard>
      )}

      {Boolean(hasSecondaryFindings) && (
        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            p: 0,
            mb: 2,
            // py: 1,
            backgroundColor: orange[50],
          }}
        >
          {diagnosisRecord?.conditionDiagnosis
            ?.filter((cd: any) => cd.type === 'secondary_findings')
            .map((cd: any, index: number) => (
              <>
                <HeroCardSection sx={{ m: 0, width: '100%' }}>
                  <ConditionDiagnosis conditionDiagnosis={cd} />
                </HeroCardSection>

                {index !== diagnosisRecord?.medication.length - 1 && (
                  <HeroCardDivider />
                )}
              </>
            ))}
        </HeroCard>
      )}

      {/*<HorizontalList>*/}
      {/*  <HorizontalListItem>*/}
      {/*    <Typography variant="caption" sx={{ mr: 1 }}>*/}
      {/*      ICD-10 classification*/}
      {/*    </Typography>*/}
      {/*    {diagnosisRecord.medicalConditionsIcd10}*/}
      {/*  </HorizontalListItem>*/}
      {/*</HorizontalList>*/}

      {/*<HorizontalList>*/}
      {/*  <HorizontalListItem>*/}
      {/*    <Typography variant="caption" sx={{ mr: 1 }}>*/}
      {/*      Diagnosis summary*/}
      {/*    </Typography>*/}
      {/*    {diagnosisRecord.medicalCondition}*/}
      {/*  </HorizontalListItem>*/}
      {/*</HorizontalList>*/}

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          alignItems: 'center',
          m: 0,
          p: 0,
          // mb: 2,
          // py: 1,
        }}
      >
        {diagnosisRecord?.medication?.map((md: any, index: number) => (
          <>
            <HeroCardSection sx={{ m: 0, width: '100%' }}>
              <Medication medication={md} />
            </HeroCardSection>

            {index !== diagnosisRecord?.medication.length - 1 && (
              <HeroCardDivider />
            )}
          </>
        ))}
      </HeroCard>

      <HorizontalList sx={{ my: 1 }}>
        <HorizontalListItem>
          <Typography variant="caption" sx={{ mr: 2 }}>
            Candidate for core treatment
          </Typography>
          {diagnosisRecord.personalizedTreatment ? 'Yes' : 'No'}
        </HorizontalListItem>
      </HorizontalList>

      {/*<Box>*/}
      {/*  <TagGroupItem sx={{ mt: 1 }}>*/}
      {/*    <Box sx={{ display: 'flex' }}>*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          color: '#9c9cbc',*/}
      {/*          mr: 1,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        ICD-10 classification*/}
      {/*      </Box>*/}
      {/*      {diagnosisRecord.medicalConditionsIcd10}*/}
      {/*    </Box>*/}
      {/*  </TagGroupItem>*/}
      {/*</Box>*/}

      {/*<Box>*/}
      {/*  <TagGroupItem sx={{ mt: 1 }}>*/}
      {/*    <Box sx={{ display: 'flex' }}>*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          color: '#9c9cbc',*/}
      {/*          mr: 1,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Diagnosis summary*/}
      {/*      </Box>*/}
      {/*      {diagnosisRecord.medicalCondition}*/}
      {/*    </Box>*/}
      {/*  </TagGroupItem>*/}
      {/*</Box>*/}

      {/*<Box>*/}
      {/*  <TagGroupItem sx={{ mt: 1 }}>*/}
      {/*    <Box sx={{ display: 'flex' }}>*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          color: '#9c9cbc',*/}
      {/*          mr: 1,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Recommended for core treatment*/}
      {/*      </Box>*/}
      {/*      {diagnosisRecord.personalizedTreatment ? 'Yes' : 'No'}*/}
      {/*    </Box>*/}
      {/*  </TagGroupItem>*/}
      {/*</Box>*/}

      {/*<Box>*/}
      {/*  <TagGroupItem sx={{ mt: 1 }}>*/}
      {/*    <Box sx={{ display: 'flex', whiteSpace: 'break-spaces' }}>*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          color: '#9c9cbc',*/}
      {/*          mr: 1,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Medication*/}
      {/*      </Box>*/}
      {/*      {diagnosisRecord.prescribedMedication.medication.join(', ')}*/}
      {/*    </Box>*/}
      {/*  </TagGroupItem>*/}
      {/*</Box>*/}

      <DiagnosisAndTreatmentPlan
        diagnosisAndTreatment={diagnosisRecord.diagnosisAndTreatmentContent}
      />
    </>
  )
}
