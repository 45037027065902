import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import React from 'react'

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),

    backgroundColor: '#fff',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    p: 4,
    boxShadow:
      '0 0 2px 0 rgb(0 0 30 / 10%), 0 2px 4px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%)',
  },
}))
