import { gql } from '@apollo/client'

export const getCommentsQuery = gql`
  query Comments($namespace: String!) {
    comments(namespace: $namespace) {
      id
      author {
        type
        profile {
          id
          firstName
          lastName
          title
          email
          avatarUrl
        }
      }
      content {
        richText
        text
      }
      important
      namespace
      createdAt
      updatedAt
    }
  }
`

export const createCommentMutation = gql`
  mutation CreateComment(
    $namespace: String!
    $content: CommentContentInput!
    $author: ProfileInput!
  ) {
    comment {
      createComment(namespace: $namespace, content: $content, author: $author) {
        id
        author {
          type
          profile {
            id
            firstName
            lastName
            title
            email
            avatarUrl
          }
        }
        content {
          richText
          text
        }
        important
        namespace
        createdAt
        updatedAt
      }
    }
  }
`
