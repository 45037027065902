import { gql } from '@apollo/client'
import { discountGroupFields } from '../discount-group'

export const getAllDiscountGroups = gql`
  ${discountGroupFields}
  query DiscountGroups(
    $offset: Int
    $limit: Int
    $filter: DiscountGroupFilter
  ) {
    discountGroups(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...DiscountGroupFields
      }
      totalRows
    }
  }
`

export const createDiscountGroupMutation = gql`
  mutation CreateDiscountGroup($discountGroup: CreateDiscountGroupInput!) {
    createDiscountGroup(discountGroup: $discountGroup) {
      id
    }
  }
`

export const updateDiscountGroupMutation = gql`
  mutation UpdateDiscountGroup($discountGroup: UpdateDiscountGroupInput!) {
    updateDiscountGroup(discountGroup: $discountGroup)
  }
`
