import { useAuth } from '@trueskin-backoffice/contexts'
import { Route, Routes } from 'react-router-dom'
import { NotionCmsPage } from '../notion-cms/notion-cms.page'
import { CaseOrdersPage } from './case-orders'
import { CasesPage } from './cases'
import { PatientRoutes } from './patient/patient.routes'
import { PatientsPage } from './patients'
import { QuestionnairesDefinitionsPage } from './questionnaires-definitions'
import { QuestionnairesSessionsPage } from './questionnaires-sessions'
import { RegistrationsPage } from './registrations'
import { SettingsRoutes } from './settings/settings.routes'
import { WaitingRoomPage } from './waiting-room'

export const TofuRoutes = () => {
  const {
    employee,
    isCurrentEmployeeDoctor,
    isCurrentEmployeeMedicalAssistant,
  } = useAuth()
  const isMedical =
    isCurrentEmployeeDoctor() || isCurrentEmployeeMedicalAssistant()

  if (!employee) {
    return null
  }

  return (
    <>
      <Routes>
        {isMedical && (
          <Route path={'waiting-room/*'} element={<WaitingRoomPage />} />
        )}
        <Route path={'registrations'} element={<RegistrationsPage />} />
        <Route path={'prescriptions'} element={<CaseOrdersPage />} />
        <Route path={'patients'} element={<PatientsPage />} />
        {/*<Route path={'patients/*'} element={<PatientPage />} />*/}
        <Route path={'patients/:customerId/*'} element={<PatientRoutes />} />
        <Route path={'cases'} element={<CasesPage />} />
        <Route
          path={'questionnaires-definitions'}
          element={<QuestionnairesDefinitionsPage />}
        />
        <Route
          path={'questionnaires-sessions'}
          element={<QuestionnairesSessionsPage />}
        />
        <Route path={'settings/*'} element={<SettingsRoutes />} />
        <Route path={'notion-cms/*'} element={<NotionCmsPage />} />

        {/*<Route*/}
        {/*  path={'/m/*'}*/}
        {/*  element={*/}
        {/*    <MedicalLayout>*/}
        {/*      <Outlet />*/}
        {/*    </MedicalLayout>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Route path="" element={<MedicalDashboard />} />*/}
        {/*  <Route*/}
        {/*    path="patients/:customerId/*"*/}
        {/*    element={*/}
        {/*      <PatientProvider>*/}
        {/*        /!*<Box>*!/*/}
        {/*        /!*  <CustomerHeader />*!/*/}

        {/*        /!*  /!*<Outlet />*!/*!/*/}
        {/*        /!*</Box>*!/*/}

        {/*        /!*<HealthRecordProvider>*!/*/}
        {/*        /!*  <CustomerHeader />*!/*/}
        {/*        /!*  <CustomerHealthRecord />*!/*/}
        {/*        /!*</HealthRecordProvider>*!/*/}
        {/*        <HeroCardEmpty sx={{ minHeight: 40 }}>dadasds</HeroCardEmpty>*/}
        {/*      </PatientProvider>*/}
        {/*    }*/}
        {/*  >*/}
        {/*    <Route path="consultations/:consultationId" element={<></>} />*/}
        {/*  </Route>*/}
        {/*</Route>*/}
      </Routes>
    </>
  )
}
