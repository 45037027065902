import { Box, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  DataTable,
  DateTime,
  IdTag,
  Pagination,
  StatusBadge,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import { getAllSubscriptions } from './subscriptions.gql'

const DEFAULT_PAGE_SIZE = 25

export const SubscriptionsTable = () => {
  const columns = useMemo(() => subscriptionsTableColumn(), [])
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      getAllSubscriptions: {
        rows: subscriptions = [],
        totalRows: totalSubscriptions = 0,
      } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useV2Query(getAllSubscriptions, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalSubscriptions / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={subscriptions}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const subscriptionsTableColumn = (): ColumnDef<any, any>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    cell: ({ row }) => {
      const subscription = row.original

      return (
        <IdTag
          id={subscription.id}
          linkTo={`/customers/${subscription.customerId}/subscriptions/${subscription.id}`}
          allowCopyToClipboard
        />
      )
    },
  },
  {
    accessorKey: 'endBehaviour',
    header: 'Renewal status',
    cell: ({ row }) => (
      <div>
        {row?.original?.endBehaviour === 'RENEW' ? (
          <Box color="green">RENEWING</Box>
        ) : (
          <Box color="red">NON RENEWING</Box>
        )}
      </div>
    ),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => <StatusBadge status={getValue()} isRounded />,
  },
  {
    accessorKey: 'startDate',
    header: 'Start Date',
    cell: ({ getValue }) => {
      return <DateTime dateTime={getValue()} tooltipPlacement="right" />
    },
  },
  {
    accessorKey: 'endDate',
    header: 'End Date',
    cell: ({ getValue }) => {
      return <DateTime dateTime={getValue()} tooltipPlacement="right" />
    },
  },
]
