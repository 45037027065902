import { gql } from '@apollo/client'
import { subscriptionFields } from '../subscription/contexts/subscription.gql'

export const getAllSubscriptions = gql`
  ${subscriptionFields}
  query GetAllSubscriptions(
    $offset: Int!
    $limit: Int!
    $filter: SubscriptionFilter
  ) {
    getAllSubscriptions(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...SubscriptionFields
      }
      totalRows
    }
  }
`
