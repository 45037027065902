import { Edit } from '@mui/icons-material'
import { Box, IconButton, Popper, styled } from '@mui/material'
import { Restricted } from '@trueskin-backoffice/components'
import React from 'react'
import { MedicalDiagnosisInput } from '../add-diagnosis-button/medical-diagnosis.input'

export const EditDiagnosisButton = ({
  conditionDiagnosis,
}: {
  conditionDiagnosis: any
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Restricted to={['doctor']}>
      <Box>
        <IconButton disableRipple sx={{ p: 0 }} onClick={handleClick}>
          <Edit sx={{ fontSize: 16 }} />
        </IconButton>

        <StyledPopper
          disablePortal={false}
          open={open && Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom"
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
              },
            },
          ]}
        >
          {open && (
            <MedicalDiagnosisInput
              conditionDiagnosis={conditionDiagnosis}
              onClose={handleClose}
              type={conditionDiagnosis.type}
            />
          )}
        </StyledPopper>
      </Box>
    </Restricted>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,
  minWidth: 400,
  padding: 8,
  zIndex: 2,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))
