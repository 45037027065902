import { TagGroupItem, TagVariant } from './tags'

export const SubscriptionStatus = ({ subscription, size }: any) => {
  console.log('SubscriptionStatus.subscription', subscription)
  const variant: TagVariant =
    subscription.status === 'ACTIVE' && subscription.endBehaviour === 'CANCEL'
      ? 'light-error'
      : subscription.status === 'CANCELLED'
      ? 'error'
      : subscription.status === 'ACTIVE'
      ? 'success'
      : subscription.status === 'WAITING_PAYMENT'
      ? 'warning'
      : 'default'

  const tagSize =
    size === 'small'
      ? {
          fontSize: 10,
          px: 1,
          py: 0.5,
          lineHeight: '10px',
          borderRadius: 1,
        }
      : size === 'large'
      ? {
          fontWeight: 600,
        }
      : {}

  return (
    <TagGroupItem
      variant={variant}
      sx={{ textTransform: 'uppercase', ...tagSize }}
    >
      {subscription.status}
    </TagGroupItem>
  )
}

export const subscriptionStatusDescriptionText = (
  subscription: any,
): string => {
  if (subscription.status === 'ACTIVE') {
    if (subscription.endBehaviour === 'RENEW') {
      return 'Renews on'
    } else {
      return 'Cancels on'
    }
  }

  if (subscription.status === 'CANCELLED') {
    return 'Cancelled on'
  }

  return ''
}
