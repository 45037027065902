/* eslint-disable no-nested-ternary */
import { Typography, TypographyProps } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props extends TypographyProps {
  children?: ReactNode
  highlight?: string
  text?: string
  component?: string
}

export const HighlightedTypography = ({
  children = '',
  highlight = '',
  className,
  ...restProps
}: Props) => {
  const strChildren =
    typeof children === 'string'
      ? children
      : Array.isArray(children)
      ? children.join('')
      : ''

  const html =
    strChildren !== ''
      ? {
          dangerouslySetInnerHTML: {
            __html: strChildren.replace(
              new RegExp(highlight, 'gi'),
              (match: string) =>
                `<mark style="background: #009688; color: white;">${match}</mark>`,
            ),
          },
        }
      : {}
  return <Typography className={className} {...restProps} {...html} />
}
