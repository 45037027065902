import { gql } from '@apollo/client'

// TODO: improve query to not load all fields by default
export const getAllPatients = gql`
  query AllPatientsQuery($offset: Int!, $limit: Int!, $filter: PatientsFilter) {
    patients(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        accountId
        profile
        medicalData
        registrationDate
      }
      totalRows
    }
  }
`
