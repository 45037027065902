import { removeJwt } from '@trueskin-backoffice/api-client'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Logout = () => {
  // const { logOut } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    removeJwt()
    // logOut()
    navigate('/login')
  }, [])

  return null
}
