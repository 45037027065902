import {
  DataObject,
  East as EastIcon,
  Sync as SyncIcon,
  West as WestIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  IconButton,
} from '@mui/material'
import { useV2LazyQuery } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  CurrencyAmount,
  DataTable,
  H3,
  Id,
  StatusBadge,
} from '@trueskin-backoffice/components'
import { formatDateTime } from '@trueskin-backoffice/functions'
import React from 'react'
import JsonView from 'react-json-view'
import { getTransactionByIdQuery } from '../gql'

export const InvoiceTransactions = ({ invoice }: any) => {
  const [jsonTransactionOpen, setJsonTransactionOpen] = React.useState(false)

  const [
    getTransactionById,
    {
      data: transactionData,
      loading: transactionDataLoading,
      error: transactionDataError,
    },
  ] = useV2LazyQuery(getTransactionByIdQuery)

  const {
    paymentTransactions,
    refundTransactions,
    dunningTransactions,
    currency,
  } = invoice

  const subColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'transactionId',
      header: 'Id',
      cell: ({ getValue }) => {
        return <Id>{getValue()}</Id>
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Created at',
      cell: ({ getValue }) => {
        return <span>{formatDateTime(getValue())}</span>
      },
    },
    {
      accessorKey: 'appliedAt',
      header: 'Paid at',
      cell: ({ getValue }) => {
        return <span>{getValue() ? formatDateTime(getValue()) : '-'}</span>
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ getValue }) => {
        return <CurrencyAmount amount={getValue()} currency={currency} />
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue }) => {
        return <StatusBadge status={getValue()} isRounded fullWidth={true} />
      },
    },
    {
      id: 'actions',
      header: () => null,
      cell: ({ row: { original } }) => {
        return (
          <IconButton
            onClick={async () => {
              await getTransactionById({
                variables: { id: original.transactionId },
              })

              setJsonTransactionOpen(true)
            }}
          >
            <DataObject />
          </IconButton>
        )
      },
    },
  ]

  return (
    <Container>
      <Box sx={{ pl: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EastIcon
            sx={{
              mr: 1,
              fontSize: '1rem',
            }}
          />
          <H3>Payment Transactions</H3>
        </Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          {paymentTransactions.length > 0 && (
            <DataTable columns={subColumns} data={paymentTransactions} />
          )}
        </Box>
      </Box>

      {dunningTransactions.length > 0 && (
        <>
          <Divider />

          <Box sx={{ mt: 4, pl: 4 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SyncIcon
                sx={{
                  mr: 1,
                  fontSize: '1rem',
                }}
              />
              <H3>Dunning Transactions</H3>
            </Box>
            <Box
              sx={{
                p: 2,
              }}
            >
              <DataTable columns={subColumns} data={dunningTransactions} />
            </Box>
          </Box>
        </>
      )}

      {refundTransactions.length > 0 && (
        <>
          <Divider />

          <Box sx={{ mt: 4, pl: 4 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <WestIcon
                sx={{
                  mr: 1,
                  fontSize: '1rem',
                }}
              />
              <H3>Refund Transactions</H3>
            </Box>
            <Box
              sx={{
                p: 2,
              }}
            >
              <DataTable columns={subColumns} data={refundTransactions} />
            </Box>
          </Box>
        </>
      )}

      <Dialog
        onClose={() => setJsonTransactionOpen(false)}
        open={jsonTransactionOpen}
      >
        <Button></Button>
        <Box sx={{ p: 3, minWidth: '500px', minHeight: '70%' }}>
          <JsonView
            name={null}
            src={transactionData?.getTransactionById}
            displayDataTypes={false}
            displayObjectSize={false}
          />
        </Box>
      </Dialog>
    </Container>
  )
}
