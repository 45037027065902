import { gql } from '@apollo/client'
import { orderFieldsBff } from '../orders/orders.gql.bff'

export const getSubscriptionById = gql`
  ${orderFieldsBff}
  query GetSubscriptionById($customerId: String, $subscriptionId: String) {
    customer(customerId: $customerId) {
      subscription(subscriptionId: $subscriptionId) {
        id
        customerId
        orderId
        occurrence

        status
        endBehaviour

        startDate
        endDate

        metadata {
          v1Subscription {
            _id
            status
            startDate
            endDate
          }
        }

        order {
          ...OrderFields
        }

        upcomingOrder {
          ...OrderFields
        }

        actions {
          canCancel {
            enabled
            immediately {
              enabled
            }
            atEndOfTerm {
              enabled
            }
          }
          canEditRenewalDate {
            enabled
            immediately {
              enabled
            }
            atEndOfTerm {
              enabled
            }
          }
        }
      }
    }
  }
`
