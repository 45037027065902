export const namespaceTags = [
  { id: 'ns:akne' },
  { id: 'ns:allergie' },
  { id: 'ns:alopecia' },
  { id: 'ns:atherom' },
]
export const diagnosisTags = [
  { id: 'd:akne_papulopustulosa', label: 'Akne Papulopustulosa' },
]
export const medicationTags = [
  { id: 'm:tretinoin' },
  { id: 'm:clindamycinhydrochlorid' },
]
export const ageGroupTags = [{ id: 'a:20-30' }]
export const genderTags = [{ id: 'g:male' }, { id: 'g:female' }]
// const treatmentGoalsTags = [{ id: 'tg:akne'}]

export const blockTypes = [
  'diagnosis',
  'medication',
  'rich_text',
  'treatment',
  // 'diagnosisDetails',
  // 'treatmentPlan',
  // 'skinFindings',
  // 'additionalAdvice',
]

export const systemLabels = [
  'diagnosis_details',
  'treatment_plan',
  'skin_findings',
  'additional_advice',
  // 'diagnosisDetails',
  // 'treatmentPlan',
  // 'skinFindings',
  // 'additionalAdvice',
]

export const tofuDiagnosisBlocks = [
  {
    id: 'blk_1',
    blockType: 'treatment',
    tags: [],
    data: {
      title: '',
      blocks: [],
    },
  },
  {
    blockType: 'diagnosis',
    tags: [],
    data: {},
  },
  {
    blockType: 'medication',
    tags: ['ns:akne', 'd:akne papulopustulosa', 'd:something_else1'],
    data: {
      medicationName: 'Hydrophiles Tretinoingel 0,0125%',
      requiresPrescription: true,
      usageDirections: '',
      additionalDirectionsForTreatment: '',
    },
  },
  {
    blockType: 'rich_text',
    tags: ['ns:akne', 'm:tretinoin'],
    labels: ['diagnosis details'],
    content: 'variation1',
  },
  {
    blockType: 'rich_text',
    tags: ['ns:akne', 'd:akne papulopustulosa', 'm:tretinoin'],
    labels: ['diagnosis details'],
    content: 'variation2',
  },
]
