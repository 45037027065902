import { Box } from '@mui/material'

export const DiscountTag = ({ label }: any) => (
  <Box
    sx={{
      px: 2,
      color: '#3367d6',
      backgroundColor: '#e2e9f9',
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '18px',
      borderRadius: 1,
      verticalAlign: 'middle',
      textAlign: 'center',
      width: 'auto',
      fontWeight: 600,
      mr: 2,
    }}
  >
    {label}
  </Box>
)
