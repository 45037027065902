import { Box, darken, lighten } from '@mui/material'
import React from 'react'

interface Props {
  status: string
  colorMap: Record<string, any>
}

export const ColoredStatus = ({ status, colorMap }: Props) => {
  if (!status) {
    return null
  }
  const color = colorMap[status?.toLowerCase() as keyof typeof colorMap]
  const backgroundColor = lighten(color, 0.2)
  const textColor = darken(color, 0.2)

  return (
    <Box
      sx={{
        textTransform: 'uppercase',
        color: textColor,
        backgroundColor,
        borderRadius: 1,
        height: 'fit-content',
        width: 'fit-content',
        px: 1,
        mr: 2,
        ml: 2,
      }}
    >
      {status}
    </Box>
  )
}
