export enum WebhookSource {
  CHARGEBEE = 'chargebee',
  TYPEFORM = 'typeform',
  PAYPAL = 'paypal',
  STRIPE = 'stripe',
  PRIME_PHARMACY = 'prime_pharmacy',
  ADYEN = 'adyen',
}

export enum WebhookStatus {
  PROCESSING = 'processing',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}
