import { createTheme } from '@mui/material/styles'
import borders from '../base/borders'
import boxShadows from '../base/box-shadows'
import breakpoints from '../base/breakpoints'
import colors from '../base/colors'
import globals from '../base/globals'
import typography from '../base/typography'
import button from '../mui-components/button/button'
import card from '../mui-components/card'
import dialog from '../mui-components/dialog'
import { tableCell } from '../mui-components/table'

export const lightTheme = createTheme({
  breakpoints,
  palette: { mode: 'light', ...colors },
  typography,
  boxShadows,
  borders,

  spacing: 4,

  // MUI component overrides
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
      },
    },
    MuiButtonBase: { ...button },
    MuiCard: { ...card },

    MuiTableCell: {
      ...tableCell,
    },

    MuiDialog: {
      ...dialog,
    },
  },
})
