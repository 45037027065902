import { gql } from '@apollo/client'

export const getCaseById = gql`
  query GetCaseById($customerId: String!, $caseId: String!) {
    patient(customerId: $customerId) {
      id
      case(caseId: $caseId) {
        id
        accountId
        customerId
        documentNumber
        status
        extendedStatus
        #  questionnaireSummary
        attachments
        messages
        paymentObject
        orderObject
        diagnosis
        assignedTo
        customer
        metadata
      }
      casePayment(caseId: $caseId)
    }
  }
`

export const initiateCaseRefundMutation = gql`
  mutation InitiateCaseRefundMutation(
    $customerId: String!
    $caseId: String!
    $invoiceId: String!
    $amount: Float!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      initiateCaseRefund(invoiceId: $invoiceId, amount: $amount) {
        ok
      }
    }
  }
`
