import { Box, BoxProps, styled } from '@mui/material'

export const PageHeader = styled((props: BoxProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 auto',
      background: '#fff',
      boxShadow: '0 0 3px 0 rgb(0 0 40 / 8%)',
      borderBottom: '1px solid #d2d2d3',
      px: 10,
      py: 2,
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 3,
    }}
    {...props}
  />
))()
