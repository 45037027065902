import { useMutation } from '@apollo/client'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Checkbox, Typography } from '@mui/material'
import { TextTooltip } from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useWaitingRoomCaseDiagnosis } from '../../waiting-room-case-diagnosis.context'
import { useWaitingRoomCase } from '../../waiting-room-case.context'
import {
  getWaitingRoomCaseDiagnosisQuery,
  setPersonalizedTreatmentForDiagnosisMutation,
} from '../../waiting-room.gql.bff'

export const SelectForCoreTreatment = () => {
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()

  const initialValue = diagnosisRecord?.personalizedTreatment?.enabled

  const [
    setPersonalizedTreatmentForDiagnosis,
    {
      loading: setPersonalizedTreatmentForDiagnosisLoading,
      error: setPersonalizedTreatmentForDiagnosisError,
    },
  ] = useMutation(setPersonalizedTreatmentForDiagnosisMutation, {
    variables: {
      customerId: diagnosisRecord.customerId,
      caseId: diagnosisRecord.caseId,
      doctor: getDoctorProfile(),
      diagnosisRecordId: diagnosisRecord.id,
      enabled: initialValue,
    },
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      {/*<Box sx={{ display: 'flex', alignItems: 'center' }}>*/}
      {/*  <TextTooltip*/}
      {/*    title={*/}
      {/*      'Selecting this the patient will get a banner in the mobile app'*/}
      {/*    }*/}
      {/*    placement="left"*/}
      {/*  >*/}
      {/*    <InfoIcon fontSize={'small'} />*/}
      {/*  </TextTooltip>*/}

      {/*  <Typography variant="caption" sx={{ ml: 1 }}>*/}
      {/*    Core treatment*/}
      {/*  </Typography>*/}
      {/*</Box>*/}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          size="small"
          checked={initialValue}
          disabled={
            setPersonalizedTreatmentForDiagnosisLoading ||
            !diagnosisRecord.actions.canDiagnose.enabled
          }
          onChange={(e) => {
            setPersonalizedTreatmentForDiagnosis({
              variables: {
                customerId: diagnosisRecord.customerId,
                caseId: diagnosisRecord.caseId,
                doctor: getDoctorProfile(),
                diagnosisRecordId: diagnosisRecord.id,
                enabled: e.target.checked,
              },
            })
          }}
        />
        <Typography sx={{ fontSize: 13 }}>
          Patient is a candidate for core treatment
        </Typography>
      </Box>

      <Box sx={{ ml: 'auto' }}>
        <TextTooltip
          title={
            'Selecting this the patient will get a banner in the mobile app which will allow them to register on formelskin.de'
          }
          placement="left"
        >
          <InfoIcon fontSize={'small'} />
        </TextTooltip>
      </Box>
    </Box>
  )
}
