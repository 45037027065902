const storageKeys = {
  AUTH_JWT: '__formel_token__',
}

const getItem = ({ id }: { id: string }) => {
  const data = localStorage.getItem(id)

  try {
    return JSON.parse(data || '')
  } catch {
    return data
  }
}

const setItem = ({ id, data: payload }: { id: string; data: any }) => {
  const data = typeof payload === 'string' ? payload : JSON.stringify(payload)

  localStorage.setItem(id, data)
}

const removeItem = ({ id }: { id: string }) => {
  localStorage.removeItem(id)
}

const getJwt = () => getItem({ id: storageKeys.AUTH_JWT })
const saveJwt = (jwt: string) =>
  setItem({ id: storageKeys.AUTH_JWT, data: jwt })
const removeJwt = () => removeItem({ id: storageKeys.AUTH_JWT })

export { getItem, setItem, removeItem, getJwt, saveJwt, removeJwt }
