import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const HeroCardDivider = styled((props: BoxProps) => (
  <Box
    sx={{
      minHeight: '1px',
      mx: 2,
      minWidth: 'calc(100% - 48px)',
      backgroundColor: '#d4d4e8',
    }}
    {...props}
  >
    {props.children}
  </Box>
))()
