import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { DiscountGroupsFilters } from './discount-groups.filters'
import { DiscountGroupsTable } from './discount-groups.tabel'

const DISCOUNT_GROUP_FILTER_INIT_VALUES = {
  available: StringParam,
}

export const DiscountGroupsPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={DISCOUNT_GROUP_FILTER_INIT_VALUES}>
        <DiscountGroupsFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <DiscountGroupsTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
