import { TagGroupItem } from './tag'

export const AddressType = ({ label }: { label: string }) => (
  <TagGroupItem
    sx={{
      minWidth: 75,
      justifyContent: 'center',
      border: 'unset',
      py: 0,
      textTransform: 'uppercase',
      borderRadius: 1,
      backgroundColor: '#e2e9f9',
    }}
  >
    {label}
  </TagGroupItem>
)
