import { gql } from '@apollo/client'

const voucherV1ModelFields = gql`
  fragment VoucherV1ModelFields on VoucherV1Model {
    name
    released
    trackingCode
    enablesTrialMonth
  }
`
const voucherApplicableRulesFields = gql`
  fragment VoucherApplicableRulesFields on ApplicableRule {
    name
    targetGroup
    locales
    validUntil
    pricing
    firstPurchase
    priceTags
    active
    importSource
  }
`
const voucherFields = gql`
  ${voucherV1ModelFields}
  ${voucherApplicableRulesFields}
  fragment VoucherExtendedFields on Voucher {
    id
    name
    type
    code
    active
    stackable
    obsolete
    released
    trackingCode
    enablesTrialMonth
    discountGroupId
    confirmationMessage
    v1Model {
      ...VoucherV1ModelFields
    }
    applicableRules {
      ...VoucherApplicableRulesFields
    }
  }
`

export const getVoucherByIdQuery = gql`
  ${voucherFields}
  query Voucher($voucherId: String) {
    voucher(voucherId: $voucherId) {
      ...VoucherExtendedFields
    }
  }
`
export const createVoucherMutation = gql`
  ${voucherFields}
  mutation CreateVoucher($data: CreateVoucherInput) {
    createVoucher(data: $data) {
      ...VoucherExtendedFields
    }
  }
`
export const updateVoucherMutation = gql`
  ${voucherFields}
  mutation UpdateVoucher($voucherId: String!, $data: UpdateVoucherInput) {
    updateVoucher(voucherId: $voucherId, data: $data) {
      ...VoucherExtendedFields
    }
  }
`
