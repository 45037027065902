import { useQuery } from '@apollo/client'
import { Box, Checkbox, Modal, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  IdTag,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import { getVouchers } from './vouchers.gql.bff'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
}

const DEFAULT_PAGE_SIZE = 25

export const VouchersTable = () => {
  const columns = useMemo(() => vouchersTableColumns(), [])
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const [openModal, setOpenModal] = useState(false)
  const [json, setJson] = useState('')

  const handleOpenModal = (jsonData: any) => (event: any) => {
    event.stopPropagation()
    setJson(jsonData)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setJson('')
    setOpenModal(false)
  }

  const {
    data: vouchersData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(getVouchers, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
    fetchPolicy: 'no-cache',
  })

  const vouchers = vouchersData?.vouchers?.rows ?? []
  const total = vouchersData?.vouchers?.totalRows ?? 0

  const totalPages = Math.ceil(total / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </Box>
      </Modal>

      <DataTable
        columns={columns}
        data={vouchers}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const vouchersTableColumns = (): ColumnDef<any, any>[] => [
  {
    header: 'Code',
    accessorKey: 'code',
    cell: ({ getValue, row }) => {
      return (
        <IdTag
          id={getValue()}
          linkTo={`/vouchers/${row?.original?.id}`}
          allowCopyToClipboard
          hideKeyIcon
        />
      )
    },
  },
  {
    header: 'Active',
    accessorKey: 'active',
    cell: ({ getValue }) => {
      return (
        <div>
          <Checkbox disabled checked={getValue()} />
        </div>
      )
    },
  },
  {
    header: 'Stackable',
    accessorKey: 'stackable',
    cell: ({ getValue }) => {
      return (
        <div>
          <Checkbox disabled checked={getValue()} />
        </div>
      )
    },
  },
  {
    header: 'Obsolete',
    accessorKey: 'obsolete',
    cell: ({ getValue }) => {
      return (
        <div>
          <Checkbox disabled checked={getValue()} />
        </div>
      )
    },
  },
  {
    accessorKey: 'discountGroupId',
    header: 'Discount Group',
    cell: ({ getValue }) => {
      return (
        <IdTag
          id={getValue()}
          linkTo={`/discount-groups/${getValue()}`}
          allowCopyToClipboard
          hideKeyIcon
        />
      )
    },
  },
  {
    header: 'Type',
    accessorKey: 'type',
    cell: ({ getValue }) => {
      return (
        <div>
          <Typography fontSize={13}>{getValue()}</Typography>
        </div>
      )
    },
  },
]
