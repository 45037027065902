import { gql } from '@apollo/client'

export const initiateRefund = gql`
  mutation InitiateRefund($invoiceId: String!, $amount: Float!) {
    initiateRefund(invoiceId: $invoiceId, amount: $amount) {
      invoice {
        id
      }
    }
  }
`

export const collectInvoiceImmediatelyMutation = gql`
  mutation CollectInvoiceImmediately(
    $invoiceId: String!
    $customerId: String!
  ) {
    collectInvoiceImmediately(invoiceId: $invoiceId, customerId: $customerId) {
      invoice {
        id
      }
    }
  }
`

export const collectChargeNow = gql`
  mutation CollectCharge($invoiceId: String!, $customerId: String!) {
    collectCharge(invoiceId: $invoiceId, customerId: $customerId) {
      invoice {
        id
      }
    }
  }
`

const transactionMetadataFields = gql`
  fragment TransactionMetadataFields on TransactionMetadata {
    webhookId
  }
`

const transactionFields = gql`
  ${transactionMetadataFields}
  fragment TransactionFields on Transaction {
    id
    invoiceId
    customerId
    amount
    currency
    paymentMethodId
    gateway
    idAtGateway
    status
    paidAt
    gatewayRefusalReason
    gatewayRefusalReasonCode
    gatewayRefusalReasonDescription
    refusalReasonCode
    idempotencyId
    metadata {
      ...TransactionMetadataFields
    }
  }
`

export const getTransactionByIdQuery = gql`
  ${transactionFields}
  query GetTransactionById($id: String!) {
    getTransactionById(id: $id) {
      ...TransactionFields
    }
  }
`
