import { gql } from '@apollo/client'

export const getOpenedConsultationsByAssignees = gql`
  query OpenedConsultationsByAssignees {
    openedConsultationsByAssignees {
      employeeId
      v1consultations
    }
  }
`

export const bulkAssignConsultationsMutation = gql`
  mutation BulkAssignConsultations($data: ConsultationsAssignmentAction!) {
    bulkAssignConsultations(data: $data) {
      _id
      v1consultationId
      assignmentType
      assignedBy
      employeeId
      prevEmployeeId
    }
  }
`
export const getConsultationsSettingsQuery = gql`
  query GetConsultationsSettings {
    getConsultationsSettings {
      smartAssigning
    }
  }
`

export const updateConsultationsSettingsMutation = gql`
  mutation updateConsultationsSettings($data: ConsultationsSettings!) {
    updateConsultationsSettings(data: $data) {
      smartAssigning
    }
  }
`
