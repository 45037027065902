import { Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  InputOperation,
} from '@trueskin-backoffice/components'
import { useQueryParams } from 'use-query-params'
import { QuestionnairesDefinitionsTable } from './questionnaires-definitions.table'

const Q_DEFINITIONS_FILTER_INIT_VALUES = {
  // probably status? archived vs something?
}
export type QuestionnairesDefinitionsFilterProps =
  typeof Q_DEFINITIONS_FILTER_INIT_VALUES

export const QuestionnairesDefinitionsPage = () => {
  const [filter, setFilter] =
    useQueryParams<QuestionnairesDefinitionsFilterProps>(
      Q_DEFINITIONS_FILTER_INIT_VALUES,
    )

  return (
    <Box>
      <DataFilter
        filter={filter}
        onChange={setFilter}
        names={Object.keys(Q_DEFINITIONS_FILTER_INIT_VALUES)}
        inputs2={[
          {
            label: 'Email',
            component: FilterComponent.TEXT,
            key: 'email',
            op: InputOperation.EQUALS,
          },
          {
            label: 'First / Last name',
            component: FilterComponent.TEXT,
            key: 'name',
            op: InputOperation.CONTAINS,
          },
        ]}
        id={{
          label: 'Search for account id / patient id',
          key: 'id',
        }}
      />

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        <QuestionnairesDefinitionsTable filter={filter} />
      </Card>
    </Box>
  )
}
