import { useQuery } from '@apollo/client'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Button } from '@mui/material'
import {
  HeroCard,
  HeroCardSection,
  TextTooltip,
} from '@trueskin-backoffice/components'
import {
  getNotionCmsTreatmentBlocksQuery,
  useAuth,
  useNotionCms,
} from '@trueskin-backoffice/contexts'
import { useWaitingRoomCaseDiagnosis } from '../../../waiting-room-case-diagnosis.context'
import { useWaitingRoomCase } from '../../../waiting-room-case.context'

export const PrefillTreatmentContentButton = () => {
  const { employee } = useAuth()
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { setInjectTreatmentContent } = useNotionCms()

  const {
    refetch: getNotionCmsTreatmentBlocks,
    // loading: loadingNotionCmsPrefills,
    // error: errorLoadingNotionCmsPrefills,
  } = useQuery(getNotionCmsTreatmentBlocksQuery, {
    skip: true,
  })

  return (
    <>
      {diagnosisRecord.status === 'draft' &&
        currentCase.assignedTo?.id === employee?.id &&
        diagnosisRecord?.medication?.some((m: any) => m.prefillId) && (
          <HeroCard
            sx={{
              display: 'flex',
              borderRadius: 1,
              alignItems: 'center',
              m: 0,
              mb: 2,
              py: 1,
            }}
          >
            <HeroCardSection
              sx={{ display: 'flex', alignItems: 'center', flex: 1, mb: 0 }}
            >
              <Button
                sx={{ textTransform: 'unset' }}
                color="info"
                onClick={async () => {
                  const prefillsToSearch = new Set<string>(
                    diagnosisRecord?.medication
                      ?.filter((cd: any) => cd.prefillId)
                      .map((cd: any) => cd.prefillId),
                  )

                  console.log('prefillsToSearch = ', [...prefillsToSearch])

                  const { data } = await getNotionCmsTreatmentBlocks({
                    prefillIds: [...prefillsToSearch],
                  })

                  console.log('data = ', data)

                  const treatmentContent = [...prefillsToSearch].map(
                    (prefillId: string) =>
                      data?.notionCmsTreatments.find(
                        ({ _id }: any) => _id === prefillId,
                      ).mdContent,
                  )
                  setInjectTreatmentContent({
                    mdContent: treatmentContent?.join('\n\n'),
                    revision: new Date().getTime(),
                  })
                }}
              >
                Prefill treatment content
              </Button>

              <Box sx={{ ml: 'auto' }}>
                <TextTooltip
                  title={
                    'Clicking this button will overwrite content in the treatment plan details section, based on the medication prefills.'
                  }
                  placement="left"
                >
                  <InfoIcon fontSize={'small'} color="info" />
                </TextTooltip>
              </Box>
            </HeroCardSection>
          </HeroCard>
        )}
    </>
  )
}
