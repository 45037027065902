import { DockItem } from '@trueskin-backoffice/components'
import React from 'react'
import { PageContentDockDivider } from '../../layouts/page-content.layout'
import { SubscriptionMutationsProvider } from '../contexts'
import { CancelSubscriptionAction } from './actions/cancel-subscription.dock-action'
import { ComputeUpcomingOrderAction } from './actions/compute-upcoming-order.dock-action'
import { EditRenewalDateAction } from './actions/edit-renewal-date.dock-action'

export const SubscriptionDock = ({ subscription }: any) => {
  return (
    <SubscriptionMutationsProvider subscription={subscription}>
      <DockItem>
        <ComputeUpcomingOrderAction subscription={subscription} />
      </DockItem>

      <PageContentDockDivider />

      <DockItem>
        <EditRenewalDateAction subscription={subscription} />
      </DockItem>

      <PageContentDockDivider />

      <DockItem>
        <CancelSubscriptionAction subscription={subscription} />
      </DockItem>
    </SubscriptionMutationsProvider>
  )
}
