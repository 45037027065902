import { H2, HeroCard, HeroCardSection } from '@trueskin-backoffice/components'
import { useCase } from '../contexts'

export const CaseGeneralInformation = () => {
  const { currentCase } = useCase()

  if (!currentCase) {
    return null
  }

  return (
    <HeroCard>
      <H2>General information</H2>
      <HeroCardSection>Internal status: {currentCase.status}</HeroCardSection>
      <HeroCardSection>
        External status: {currentCase.extendedStatus}
      </HeroCardSection>
      <HeroCardSection>
        Assigned to:{' '}
        {!currentCase.assignedTo
          ? 'n/a'
          : `${currentCase.assignedTo.title} ${currentCase.assignedTo.firstName} ${currentCase.assignedTo.lastName}`}
      </HeroCardSection>
    </HeroCard>
  )
}
