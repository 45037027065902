import { gql } from '@apollo/client'

// TODO: improve query to not load all fields by default
export const getAllCustomers = gql`
  query CustomersQuery($offset: Int!, $limit: Int!, $filter: CustomerFilter) {
    customers(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        customerId
        phoneNumber
        email
        v1UserId
        registrationDate
      }
      totalRows
    }
  }
`
