import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
} from '@mui/material'
import numeral from 'numeral'
import React from 'react'

interface SummaryCardProps {
  label: string
  value: number
  info?: any
}

type Props = {
  children: JSX.Element
}

const SummaryCardTooltip = ({ children }: Props) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Box>
      <IconButton
        ref={anchorRef}
        // id="composition-button"
        // aria-controls={open ? 'composition-menu' : undefined}
        // aria-expanded={open ? 'true' : undefined}
        // aria-haspopup="true"
        onClick={handleToggle}
        sx={{ color: '#fff', p: 0 }}
      >
        <InfoIcon color="inherit" fontSize="small" />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

const SummaryCard = ({
  label,
  value,
  children,
}: SummaryCardProps & { children: any }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Paper
        sx={{
          padding: 4,
          backgroundColor: '#3d2b6a',
          color: '#fff',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span>{label}</span>

          {children ? (
            <SummaryCardTooltip>{children}</SummaryCardTooltip>
          ) : null}
        </Box>
        <Box>€{numeral(value / 100).format('0.00a')}</Box>
      </Paper>
    </Box>
  )
}

const NetPaymentsInfo = React.forwardRef(() => {
  return <div>dadsada</div>
})

export const DashboardPage = () => {
  return (
    <Box>
      <Paper sx={{ backgroundColor: '#28145a', p: 2 }}>
        <SummaryCard label={'Net Payments'} value={12312400}>
          <NetPaymentsInfo />
        </SummaryCard>
      </Paper>
    </Box>
  )
}
