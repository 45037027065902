import { Alert, Box, Button, Dialog } from '@mui/material'
import { useV2Mutation } from '@trueskin-backoffice/api-client'
import { formatDateTime } from '@trueskin-backoffice/functions'
import ConditionalWrap from 'conditional-wrap'
import React from 'react'
import { collectChargeNow, getInvoicesByOrderId, getOrderById } from '../gql'

export const DunningDetails = ({ invoice, hideCollectNow }: any) => {
  const [collectNowOpen, setCollectNowOpen] = React.useState(false)
  const [collectError, setCollectError]: [any, any] = React.useState()

  const [initiateCollectNow, { loading }] = useV2Mutation(collectChargeNow, {
    refetchQueries: [getOrderById, getInvoicesByOrderId],
    onError: (err) => setCollectError(err),
  })

  const collectNowSubmitted = () => {
    setCollectNowOpen(false)
    initiateCollectNow({
      variables: { invoiceId: invoice.id, customerId: invoice.customerId },
    }).catch((e) => setCollectError(e))
  }

  return (
    <>
      <ConditionalWrap
        condition={
          invoice.dunningStatus?.toLowerCase() === 'in_progress' ||
          //if invoice collect at is in the future
          (invoice.collectAt &&
            invoice.autoCollect &&
            new Date() < new Date(invoice.collectAt))
        }
        wrap={() => (
          <Box>
            <Alert
              severity="warning"
              sx={{
                width: 'fit-content',
              }}
              action={
                hideCollectNow ? null : (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setCollectNowOpen(true)}
                  >
                    Collect now
                  </Button>
                )
              }
            >
              {invoice.nextRetryAt ? (
                <>
                  Invoice is in dunning! Next retry will occur at{' '}
                  {formatDateTime(invoice.nextRetryAt)}
                </>
              ) : (
                ''
              )}
            </Alert>
          </Box>
        )}
      >
        <></>
      </ConditionalWrap>

      <Dialog onClose={() => setCollectNowOpen(false)} open={collectNowOpen}>
        <Box sx={{ p: 3, minWidth: '500px', minHeight: '70%' }}>
          <div>
            <p>Tech notes:</p>
            <p>
              - Please do not abuse this functionality for now. Certain errors
              from the payment gateways should not be retried very frequently.
            </p>
            <p>- This will use the customer's primary payment method.</p>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setCollectNowOpen(false)} disabled={loading}>
              Cancel
            </Button>

            <Button
              onClick={collectNowSubmitted}
              disabled={loading}
              color="warning"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>

      {collectError && (
        <Alert severity="error" onClose={() => setCollectError()}>
          {collectError.message}
        </Alert>
      )}
    </>
  )
}
