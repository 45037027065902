import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Lock } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { H2 } from '@trueskin-backoffice/components'
import { isAfter } from 'date-fns'
import { useFormik } from 'formik'
import { isEqual, sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { getDiscountGroupByIdQuery } from '../discount-group'
import { getAllDiscountGroups } from '../discount-groups'
import { FormSection } from '../employees/employee.components'
import {
  createVoucherMutation,
  getVoucherByIdQuery,
  updateVoucherMutation,
} from './voucher.gql.bff'

const VOUCHER_TYPES = ['marketing', 'cs', 'product', 'referral', 'system']
const VOUCHER_TARGET_GROUPS = ['new_customers', 'existing_customers']
const VOUCHER_IMPORT_SOURCES = ['tofu', 'true_skin']
const INITIAL_VALUES = {
  name: '',
  code: '',
  type: '',
  active: false,
  obsolete: false,
  stackable: false,
  released: false,
  trackingCode: '',
  enablesTrialMonth: false,
  discountGroupId: '',
  validUntil: null,
  targetGroup: '',
  importSource: '',
  locales:
    process.env['NX_I18N_LOCALE'] === 'pt-BR' ? ['pt-BR'] : ['de-DE', 'de-CH'],
}

const LockAdornment = ({ visible }: { visible: boolean }) => (
  <InputAdornment position="start" sx={{ color: 'red' }}>
    {visible && <Lock />}
  </InputAdornment>
)

const DiscountGroupVariantDetails = ({ variant }: { variant: any }) => {
  const { definition, discount, redemptionRules } = variant
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 4,
      }}
    >
      <TextField
        size="small"
        label="Discount amount"
        type="number"
        value={discount.amount}
        disabled={true}
        sx={{ flex: '1 1 15%' }}
      />

      {redemptionRules.map(
        ({ maxRedemptions }: { maxRedemptions: number }, index: number) => (
          <TextField
            size="small"
            key={index}
            label="Max redemptions"
            type="number"
            value={maxRedemptions}
            disabled={true}
            sx={{ flex: '1 1 15%' }}
          />
        ),
      )}

      <TextField
        size="small"
        label="Confirmation message"
        value={definition.confirmationMessage}
        disabled={true}
        sx={{ flex: '2 2 50%' }}
      />
    </Box>
  )
}

const DiscountGroupDetails = ({ discountGroup }: { discountGroup: any }) => {
  const { definition, variants } = discountGroup
  const oneMonthSet = variants?.find((v: any) => {
    const { occurrences } = v?.selectionRules.find(
      ({ name }: { name: string }) => name === 'OCCURRENCES',
    ) || { occurrences: [] }
    return isEqual(sortBy(occurrences), sortBy(['occ_daily', 'occ_monthly']))
  })
  const twoMonthSet = variants?.find((v: any) => {
    const { occurrences } = v?.selectionRules.find(
      ({ name }: { name: string }) => name === 'OCCURRENCES',
    ) || { occurrences: [] }
    return isEqual(sortBy(occurrences), sortBy(['occ_2days', 'occ_bimonthly']))
  })
  return (
    <>
      <FormSection
        label="Confirmation message"
        value={
          <TextField
            size="small"
            value={definition.confirmationMessage}
            disabled={true}
            sx={{ width: '100%' }}
          />
        }
      />

      {oneMonthSet && (
        <FormSection
          label="1-Month-Set"
          value={<DiscountGroupVariantDetails variant={oneMonthSet} />}
        />
      )}

      {twoMonthSet && (
        <FormSection
          label="2-Month-Set"
          value={<DiscountGroupVariantDetails variant={twoMonthSet} />}
        />
      )}
    </>
  )
}

export const VoucherPage = () => {
  const {
    data: { discountGroups: { rows: discountGroups = [] } = {} } = {},
    loading: discountGroupsLoading,
    error: discountGroupsError,
  } = useQuery(getAllDiscountGroups, {
    variables: { filter: { available: true.toString() } },
  })

  const [
    getDiscountGroupById,
    {
      data: { discountGroup = {} } = {},
      loading: discountGroupLoading,
      error: discountGroupError,
    },
  ] = useLazyQuery(getDiscountGroupByIdQuery)

  const [
    getVoucherById,
    {
      data: { voucher = {} } = {},
      loading: voucherLoading,
      error: voucherError,
    },
  ] = useLazyQuery(getVoucherByIdQuery)

  const [
    createVoucher,
    {
      data: createdVoucher,
      loading: voucherCreationLoading,
      error: voucherCreationError,
    },
  ] = useMutation(createVoucherMutation)

  const [
    updateVoucher,
    {
      data: updatedVoucher,
      loading: voucherUpdatingLoading,
      error: voucherUpdatingError,
    },
  ] = useMutation(updateVoucherMutation)

  const { voucherId } = useParams()
  const [editMode, setEditMode] = useState(false)
  const [released, setReleased] = useState(false)
  const [reviewed, setReviewed] = useState(false)

  const formik = useFormik<{
    name: string
    code: string
    type: string
    active: boolean
    obsolete: boolean
    stackable: boolean
    released: boolean
    trackingCode: string
    enablesTrialMonth: boolean
    discountGroupId: string
    locales: string[]
    validUntil: Date | null
    targetGroup: string | null
    importSource: string | null
  }>({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: yup.object({
      name: yup.string().required('Name is required.'),
      code: yup.string().required('Code is required.'),
      type: yup.string().required('Type is required.'),
      trackingCode: yup.string().required('Tracking code is required.'),
      discountGroupId: yup
        .string()
        .required('Discount group is required.')
        .test({
          name: 'isDiscountGroup',
          message: 'Discount group is invalid',
          test: (value) =>
            !!discountGroups.find(({ id }: { id: string }) => id === value),
        }),
      validUntil: yup
        .date()
        .typeError('Last active day format is invalid.')
        .required('Last active day is required.')
        .test({
          name: 'isDateValid',
          message: 'Last active day should be after today.',
          test: (value) => isAfter(value, new Date()),
        }),
    }),
    onSubmit: async (values) => {
      try {
        editMode
          ? await updateVoucher({
              variables: {
                voucherId,
                data: {
                  ...values,
                  targetGroup: values.targetGroup || null,
                  importSource: values.importSource || null,
                },
              },
            })
          : await createVoucher({
              variables: {
                data: {
                  ...values,
                  targetGroup: values.targetGroup || null,
                  importSource: values.importSource || null,
                },
              },
            })
      } catch (err) {
        console.error(err)
      }
    },
  })

  const availableDiscountGroups =
    editMode && released ? [discountGroup] : discountGroups

  const currentDiscountGroup = availableDiscountGroups.find(
    ({ id }: { id: string }) => id === formik.values.discountGroupId,
  )

  useEffect(() => {
    ;(async () => {
      if (voucherId && voucherId !== 'create') {
        setEditMode(true)
        await getVoucherById({
          variables: { voucherId },
          fetchPolicy: 'no-cache',
        })
      }
    })()
  }, [voucherId])

  useEffect(() => {
    ;(async () => {
      if (voucher.id) {
        setReleased(voucher.v1Model.released)
        await getDiscountGroupById({
          variables: { id: voucher.discountGroupId },
          fetchPolicy: 'no-cache',
        })

        formik.setValues({
          name: voucher.name || voucher.v1Model.name,
          code: voucher.code,
          type: voucher.type,
          active: voucher.active,
          obsolete: voucher.obsolete,
          stackable: voucher.stackable,
          released: voucher.v1Model.released,
          trackingCode: voucher.trackingCode || voucher.v1Model.trackingCode,
          enablesTrialMonth: voucher.v1Model.enablesTrialMonth,
          discountGroupId: voucher.discountGroupId,
          locales: voucher.applicableRules?.find(
            ({ name }: { name: string }) => name === 'LOCALES',
          )?.locales,
          validUntil: voucher.applicableRules?.find(
            ({ name }: { name: string }) => name === 'VALID_UNTIL',
          )?.validUntil,
          targetGroup:
            voucher.applicableRules?.find(
              ({ name }: { name: string }) => name === 'TARGET_GROUP',
            )?.targetGroup || '',
          importSource:
            voucher.applicableRules?.find(
              ({ name }: { name: string }) => name === 'IMPORT_SOURCE',
            )?.importSource || '',
        })
      }
    })()
  }, [voucher])

  const loading =
    voucherLoading ||
    discountGroupLoading ||
    discountGroupsLoading ||
    voucherCreationLoading ||
    voucherUpdatingLoading

  const error =
    voucherError ||
    discountGroupError ||
    discountGroupsError ||
    voucherCreationError ||
    voucherUpdatingError

  if (createdVoucher?.createVoucher?.id || updatedVoucher?.updateVoucher?.id) {
    return <Navigate to="/vouchers" />
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 20, py: 8 }}>
      <Box sx={{ m: 2 }}>
        <H2>{editMode ? 'Edit' : 'Create'} voucher</H2>
      </Box>

      <Card sx={{ p: 3 }}>
        {loading && <LinearProgress />}
        {error && <Typography color={'red'}>{error?.message}</Typography>}

        <Grid container spacing={2} alignItems="center">
          <FormSection
            label="Name"
            value={
              <TextField
                sx={{ width: '100%' }}
                size="small"
                name="name"
                value={formik.values.name}
                error={Boolean(formik.errors.name && formik.touched.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled={released}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <LockAdornment visible={formik.values.active || released} />
                  ),
                }}
              />
            }
          />

          <FormSection
            label="Code"
            value={
              <TextField
                sx={{ width: '100%' }}
                size="small"
                name="code"
                value={formik.values.code}
                error={Boolean(formik.errors.code && formik.touched.code)}
                helperText={
                  (formik.touched.code && formik.errors.code) ||
                  'Once created this field is locked forever.'
                }
                disabled={editMode}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(
                    'code',
                    e.target.value.trim().toUpperCase(),
                  )
                }}
                InputProps={{
                  startAdornment: (
                    <LockAdornment visible={formik.values.active || editMode} />
                  ),
                }}
              />
            }
          />

          <FormSection
            label="Type"
            value={
              <TextField
                sx={{ width: '100%' }}
                size="small"
                select
                name="type"
                value={formik.values.type}
                error={Boolean(formik.errors.type && formik.touched.type)}
                helperText={formik.touched.type && formik.errors.type}
                disabled={released}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <LockAdornment visible={formik.values.active || released} />
                  ),
                }}
              >
                {VOUCHER_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            }
          />

          <FormSection
            label="Tracking code"
            value={
              <TextField
                sx={{ width: '100%' }}
                size="small"
                name="trackingCode"
                value={formik.values.trackingCode}
                error={Boolean(
                  formik.errors.trackingCode && formik.touched.trackingCode,
                )}
                helperText={
                  formik.touched.trackingCode && formik.errors.trackingCode
                }
                disabled={released}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <LockAdornment visible={formik.values.active || released} />
                  ),
                }}
              />
            }
          />

          <FormSection
            label="Discount group"
            value={
              <TextField
                select
                size="small"
                sx={{ width: '100%' }}
                name="discountGroupId"
                value={formik.values.discountGroupId}
                error={Boolean(
                  formik.errors.discountGroupId &&
                    formik.touched.discountGroupId,
                )}
                helperText={
                  formik.touched.discountGroupId &&
                  formik.errors.discountGroupId
                }
                disabled={released}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <LockAdornment visible={formik.values.active || released} />
                  ),
                }}
              >
                {availableDiscountGroups.map(
                  ({ id, name }: { id: string; name: string }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ),
                )}
              </TextField>
            }
          />

          {currentDiscountGroup && (
            <DiscountGroupDetails discountGroup={currentDiscountGroup} />
          )}

          <FormSection
            label="Last Active Day"
            value={
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                sx={{ width: '100%' }}
              >
                <DatePicker
                  openTo="day"
                  value={formik.values.validUntil}
                  onChange={(value) => {
                    formik.setFieldValue('validUntil', value)
                  }}
                  inputFormat={
                    process.env['NX_I18N_LOCALE'] === 'pt-BR'
                      ? 'dd/MM/yyyy'
                      : 'dd.MM.yyyy'
                  }
                  shouldDisableDate={(date: Date) => !isAfter(date, new Date())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{ width: '100%' }}
                      name="validUntil"
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.errors.validUntil && formik.touched.validUntil,
                      )}
                      helperText={
                        formik.touched.validUntil && formik.errors.validUntil
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            }
          />

          <FormSection
            label="Applies To"
            value={
              <TextField
                sx={{ width: '100%' }}
                size="small"
                select
                name="targetGroup"
                value={formik.values.targetGroup}
                error={Boolean(
                  formik.errors.targetGroup && formik.touched.targetGroup,
                )}
                helperText={
                  formik.touched.targetGroup && formik.errors.targetGroup
                }
                disabled={formik.values.enablesTrialMonth}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">all_customers</MenuItem>
                {VOUCHER_TARGET_GROUPS.map((targetGroup) => (
                  <MenuItem key={targetGroup} value={targetGroup}>
                    {targetGroup}
                  </MenuItem>
                ))}
              </TextField>
            }
          />

          <FormSection
            label="Import source"
            value={
              <TextField
                size="small"
                sx={{ width: '100%' }}
                select
                name="importSource"
                value={formik.values.importSource}
                error={Boolean(
                  formik.errors.importSource && formik.touched.importSource,
                )}
                helperText={
                  formik.touched.importSource && formik.errors.importSource
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">all_sources</MenuItem>
                {VOUCHER_IMPORT_SOURCES.map((importSource) => (
                  <MenuItem key={importSource} value={importSource}>
                    {importSource}
                  </MenuItem>
                ))}
              </TextField>
            }
          />

          {/*  <FormSection
            label="Trial month"
            value={
              <Checkbox
                name="enablesTrialMonth"
                checked={formik.values.enablesTrialMonth}
                disabled={editMode}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue('targetGroup', 'new_customers')
                  }
                  formik.setFieldValue('enablesTrialMonth', e.target.checked)
                }}
              />
            }
          />*/}

          <FormSection
            label="Active"
            value={
              <Checkbox
                name="active"
                checked={formik.values.active}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setReviewed(false)
                  }
                  formik.setFieldValue('active', e.target.checked)
                }}
              />
            }
          />
        </Grid>

        {!formik.values.active && (
          <Typography color="orange">
            Inactive vouchers are not available to users.
          </Typography>
        )}

        {(formik.values.active || (released && formik.values.active)) && (
          <Typography color="red">
            Activating this voucher will release it, meaning any user is free to
            apply it immediately.
            <br /> Because of this the fields marked <Lock /> will be locked
            from future editing.
          </Typography>
        )}
      </Card>

      {formik.values.active && !reviewed && (
        <Button
          variant="contained"
          sx={{ alignSelf: 'center', m: 2 }}
          onClick={() => setReviewed(true)}
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
        >
          Mark as reviewed
        </Button>
      )}

      {(!formik.values.active || (formik.values.active && reviewed)) && (
        <Button
          variant="contained"
          sx={{ alignSelf: 'center', m: 2 }}
          onClick={formik.submitForm}
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
        >
          {editMode ? 'Update' : 'Create'}
        </Button>
      )}
    </Box>
  )
}
