import { Avatar, Grid, Typography } from '@mui/material'
import { DateTime, UserGeneratedHtml } from '@trueskin-backoffice/components'
import { Comment } from '../contexts/comments.context'
import { stringAvatar } from './string-avatar'

interface CommentItemProps {
  comment: Comment
}

export const CommentItem = ({ comment }: CommentItemProps) => {
  const profile = comment.author.profile

  return (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Avatar {...stringAvatar(profile)} />
      </Grid>

      <Grid item justifyContent="left" sx={{ '&.MuiGrid-item': { pt: 1 } }}>
        <Typography variant="caption" fontWeight="bold">
          {profile?.email}

          <Typography variant="caption" sx={{ ml: 1 }}>
            (on{' '}
            <DateTime dateTime={comment.createdAt} tooltipPlacement="right" />)
          </Typography>
        </Typography>

        <Typography variant="body2">
          {comment.content.text ? (
            <div>{comment.content.text}</div>
          ) : (
            <UserGeneratedHtml html={comment.content.richText} />
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}
