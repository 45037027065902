import { Box, Typography } from '@mui/material'
import { DockItem, Restricted } from '@trueskin-backoffice/components'
import { PageContentDockDivider } from '../../../layouts/page-content.layout'
import { DeleteAccountDockAction } from './actions/delete-account.dock-action'
import { EditBirthDateAction } from './actions/edit-birthdate.dock-action'
import { EditEmailAction } from './actions/edit-email.dock-action'
import { EditPasswordAction } from './actions/edit-password.dock-action'

export const PatientDock = () => {
  return (
    <Box>
      <Typography sx={{ color: 'info.main' }}>Patient actions</Typography>

      <DockItem>
        <EditEmailAction />
      </DockItem>

      <DockItem>
        <EditBirthDateAction />
      </DockItem>

      <Restricted to={['customercareagent', 'admin']}>
        <PageContentDockDivider />

        <DockItem>
          <EditPasswordAction />
        </DockItem>

        <PageContentDockDivider />

        <DockItem>
          <DeleteAccountDockAction />
        </DockItem>
      </Restricted>
    </Box>
  )
}
