import { Box, IconButton } from '@mui/material'
import { useAuth, useUIController } from '@trueskin-backoffice/contexts'
import { GoSignOut } from 'react-icons/go'
import { CurrentChannelButton } from './components/current-channel.button'
import { TofuChannelMedicalMenuButton } from './components/dropdown-menu.button'

export const RightHandSideMenu = () => {
  const { controller } = useUIController()
  const { logOut, employee } = useAuth()

  if (!employee || !controller.showTopRightMenu) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 5,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TofuChannelMedicalMenuButton />

      <CurrentChannelButton />

      <Box>
        <Box sx={{ px: 2, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 2 }}>{employee.firstName}</Box>

          <IconButton
            onClick={logOut}
            color="inherit"
            sx={{ ml: 'auto', p: 0 }}
          >
            <GoSignOut />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
