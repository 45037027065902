import { gql } from '@apollo/client'

const cronJobFields = gql`
  fragment CronJobFields on CronJob {
    id
    status
    name
    schedulerName
    isArchived
    startDate
    endDate
  }
`

export const getAllCronJobs = gql`
  ${cronJobFields}
  query GetAllCronJobs($limit: Int, $offset: Int, $filter: CronJobsFilter) {
    getAllCronJobs(limit: $limit, offset: $offset, filter: $filter) {
      ...CronJobFields
    }
  }
`
