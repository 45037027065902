import { ApolloError, useMutation } from '@apollo/client'
import CakeIcon from '@mui/icons-material/Cake'
import EditIcon from '@mui/icons-material/Edit'
import FemaleIcon from '@mui/icons-material/Female'
import MaleIcon from '@mui/icons-material/Male'
import PhoneIcon from '@mui/icons-material/Phone'
import TransgenderIcon from '@mui/icons-material/Transgender'
import {
  Alert,
  Box,
  Dialog,
  Grid,
  Icon,
  Paper,
  Skeleton,
  styled,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  DateTime,
  HeroCard,
  HeroCardDivider,
  HeroCardEmpty,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { differenceInYears } from 'date-fns'
import { useSnackbar } from 'notistack'
import React from 'react'
import {
  FiArrowLeft,
  FiArrowRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi'
import { UpdateBirthNameModal } from '../patient/dock/actions/edit-birthdate.dock-action'
import { updatePatientBirthDateMutation } from '../patient/patient.gql.bff'
import { PatientComments } from '../patient/sub-sections/patient-comments'
import { CaseDiagnosisRecord } from './case-diagnosis-record'
import { CaseTimeline } from './case-timeline'
import { CaseGoals } from './components/case-goals'
import { TreatmentHistory } from './components/treatment-history'
import { PatientAddress } from './patient-address'
import { getPatientFullName } from './utils'
import {
  WaitingRoomCaseDiagnosisProvider,
  useWaitingRoomCaseDiagnosis,
} from './waiting-room-case-diagnosis.context'
import { useWaitingRoomCase } from './waiting-room-case.context'
import { useWaitingRoomLayoutController } from './waiting-room-layout-controller.context'
import { getWaitingRoomCase } from './waiting-room.gql.bff'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export const WaitingRoomCase = () => {
  const { controller, hideWaitingRoomList, hideAll } =
    useWaitingRoomLayoutController()
  const { currentCase, caseId, loading, error } = useWaitingRoomCase()

  if (!caseId) {
    return null
  }

  if (error instanceof ApolloError && error.message === 'CASE_NOT_FOUND') {
    return (
      <Alert color="error" sx={{ mt: 5 }}>
        Case with id {caseId} not found
      </Alert>
    )
  }

  if (loading || !currentCase) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography
            component="p"
            variant="caption"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Patient profile
          </Typography>

          <Skeleton
            variant="rectangular"
            height={650}
            sx={{ borderRadius: 1 }}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography
            component="p"
            variant="caption"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Case # {currentCase?.documentNumber} Q and messages
          </Typography>

          <Skeleton
            variant="rectangular"
            height={650}
            sx={{ borderRadius: 1 }}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography
            component="p"
            variant="caption"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Case diagnosis
          </Typography>

          <Skeleton
            variant="rectangular"
            height={650}
            sx={{ borderRadius: 1 }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      sx={
        {
          // boxShadow:
          //   '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          // overflow: 'unset',
          // p: 4,
          // maxWidth: '1500px',
          // mx: 'auto',
          // height: '100%',
          // maxHeight: '85vh',
        }
      }
    >
      {!controller.hidePatientProfile && (
        <Grid item xs={3} sx={{ height: '100%', maxHeight: '85vh' }}>
          <Box sx={{ width: '100%', position: 'relative' }}>
            <HideShowWaitingListButton />
            <Typography
              component="p"
              variant="caption"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Patient profile
            </Typography>
          </Box>

          <HeroCard sx={{ height: '100%', maxHeight: '85vh', borderRadius: 1 }}>
            <HeroCardSection sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  display: 'flex',
                  fontSize: 16,
                  fontWeight: 500,
                  mb: 1,
                }}
                component="span"
                color="primary"
              >
                <span>{getPatientFullName(currentCase.customer)}</span>
              </Typography>

              <Typography
                sx={{
                  display: 'flex',
                  // fontSize: 16,
                  fontWeight: 500,
                  mb: 1,
                  fontStyle: 'italic',
                }}
                variant="caption"
              >
                <span>{currentCase.customer.profile.email}</span>
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Icon component={CakeIcon} sx={{ fontSize: 16, mr: 2 }} />
                {currentCase.customer?.medicalData?.birthdate} (
                {differenceInYears(
                  new Date(),
                  new Date(currentCase.customer?.medicalData?.birthdate),
                )}
                )
                <EditBirthDateIcon
                  customerId={currentCase.customerId}
                  currentBirthDate={
                    currentCase.customer?.medicalData?.birthdate
                  }
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Icon
                  component={
                    currentCase.customer?.medicalData.gender === 'male'
                      ? MaleIcon
                      : currentCase.customer?.medicalData.gender === 'female'
                      ? FemaleIcon
                      : TransgenderIcon
                  }
                  sx={{ fontSize: 16, mr: 2 }}
                />
                {currentCase.customer?.medicalData.gender || 'unknown'}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon component={PhoneIcon} sx={{ fontSize: 16, mr: 2 }} />
                {currentCase.customer?.profile?.phoneNumber}
              </Box>
            </HeroCardSection>

            <HeroCardDivider sx={{ m: 0 }} />

            <HeroCardSection sx={{ mt: 1 }}>
              <Typography
                sx={{
                  display: 'flex',
                  fontSize: 15,
                }}
                component="span"
                color="text.primary"
              >
                Addresses
              </Typography>

              <PatientAddress
                label="Billing"
                address={currentCase.customer?.profile?.billingAddress}
              />

              <PatientAddress
                label="Shipping"
                address={currentCase.customer?.profile?.shippingAddress}
              />
            </HeroCardSection>

            <HeroCardDivider sx={{ m: 0 }} />

            <HeroCardSection>
              <Typography
                sx={{
                  display: 'flex',
                  fontSize: 15,
                }}
                component="span"
                color="text.primary"
              >
                Treatment history
              </Typography>

              <TreatmentHistory />
            </HeroCardSection>
          </HeroCard>

          {currentCase?.questionnaireFields?.alternatePatientDetails && (
            <HeroCard sx={{ mt: 2 }}>
              <Alert severity="error" sx={{ fontWeight: 500 }}>
                Different patient
              </Alert>
              <Box sx={{ mt: 2 }}>
                <Box>
                  Type:{' '}
                  {
                    currentCase?.questionnaireFields?.alternatePatientDetails
                      ?.type
                  }
                </Box>
                <Box>
                  Name:{' '}
                  {
                    currentCase?.questionnaireFields?.alternatePatientDetails
                      ?.name
                  }
                </Box>
                <Box>
                  Birthdate:{' '}
                  {
                    currentCase?.questionnaireFields?.alternatePatientDetails
                      ?.birthdate
                  }
                </Box>
                <Box>
                  Address:{' '}
                  {
                    currentCase?.questionnaireFields?.alternatePatientDetails
                      ?.address
                  }
                </Box>
              </Box>
            </HeroCard>
          )}

          <HeroCard sx={{ mt: 2 }}>
            <HeroCardSection sx={{ mb: 0 }}>
              <Typography
                sx={{
                  display: 'flex',
                  fontSize: 15,
                  mb: 2,
                }}
                component="span"
                color="text.primary"
              >
                Comments
              </Typography>

              <PatientComments customerId={currentCase?.customerId} />
            </HeroCardSection>
          </HeroCard>
        </Grid>
      )}

      <Grid item xs={controller.hidePatientProfile ? 6 : 5}>
        <Box sx={{ position: 'relative' }}>
          <HideShowAllButton />

          <Typography
            component="p"
            variant="caption"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Case # {currentCase?.documentNumber} Q and messages
          </Typography>
        </Box>

        <HeroCardEmpty sx={{ borderRadius: 1, mb: 2 }}>
          <HorizontalList>
            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Created on
              </Typography>
              <DateTime
                dateTime={currentCase.createdAt}
                tooltipPlacement="right"
              />
            </HorizontalListItem>
          </HorizontalList>

          <HorizontalList>
            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Activated on
              </Typography>
              <DateTime
                dateTime={currentCase.metadata.activatedAt}
                tooltipPlacement="right"
              />
            </HorizontalListItem>
          </HorizontalList>

          <CaseGoals currentCase={currentCase} />

          {!!currentCase.assignedTo && (
            <HorizontalList sx={{ mb: 1 }}>
              <HorizontalListItem sx={{ fontWeight: 600 }}>
                Assigned to
              </HorizontalListItem>
              <HorizontalListItem>
                <Box>{`${currentCase.assignedTo.title} ${currentCase.assignedTo.firstName} ${currentCase.assignedTo.lastName}`}</Box>
              </HorizontalListItem>
            </HorizontalList>
          )}
        </HeroCardEmpty>

        <CaseTimeline case={currentCase} />
      </Grid>

      <Grid item xs={controller.hidePatientProfile ? 6 : 4}>
        <WaitingRoomCaseDiagnosisProvider caseId={currentCase.id}>
          <CaseDiagnosisSection />
        </WaitingRoomCaseDiagnosisProvider>
      </Grid>
    </Grid>
  )
}

const CaseDiagnosisSection = () => {
  const { employee } = useAuth()
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()

  if (!currentCase || !employee) {
    return null // TODO: skeleton?
  }

  return (
    <>
      <Typography
        component="p"
        variant="caption"
        sx={{ width: '100%', textAlign: 'center' }}
      >
        Case diagnosis
      </Typography>

      <CaseDiagnosisRecord
        currentCase={currentCase}
        diagnosisRecord={diagnosisRecord}
      />
    </>
  )
}

const HideShowWaitingListButton = () => {
  const { controller, hideWaitingRoomList } = useWaitingRoomLayoutController()

  if (controller.hideControls) {
    return null
  }

  return (
    <Box>
      {!controller.hideWaitingRoomList ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
          }}
          onClick={() => hideWaitingRoomList(true)}
        >
          <Icon component={FiArrowLeft} sx={{ fontSize: 18 }} />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
          }}
          onClick={() => hideWaitingRoomList(false)}
        >
          <Icon component={FiArrowRight} sx={{ fontSize: 18 }} />
        </Box>
      )}
    </Box>
  )
}

const HideShowAllButton = () => {
  const { controller, hideAll } = useWaitingRoomLayoutController()

  if (controller.hideControls) {
    return null
  }

  return (
    <Box>
      {!controller.hidePatientProfile ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
          }}
          onClick={() => hideAll(true)}
        >
          <Icon component={FiChevronsLeft} sx={{ fontSize: 18 }} />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
          }}
          onClick={() => hideAll(false)}
        >
          <Icon component={FiChevronsRight} sx={{ fontSize: 18 }} />
        </Box>
      )}
    </Box>
  )
}

const EditBirthDateIcon = ({ customerId, currentBirthDate }: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  const [
    updatePatientBirthDate,
    {
      loading: updatePatientBirthDateLoading,
      error: updatePatientBirthDateError,
    },
  ] = useMutation(updatePatientBirthDateMutation, {
    refetchQueries: [getWaitingRoomCase],
  })

  if (!customerId) {
    return null
  }

  return (
    <>
      <Icon
        component={EditIcon}
        sx={{ fontSize: 16, ml: 2 }}
        onClick={() => setShowEditDialog(true)}
      />

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <UpdateBirthNameModal
            currentBirthDate={currentBirthDate}
            onClose={() => setShowEditDialog(false)}
            onSubmit={async (birthDate: string) => {
              try {
                await updatePatientBirthDate({
                  variables: {
                    customerId,
                    birthDate,
                  },
                })
                enqueueSnackbar('Birth date updated successfully.', {
                  variant: 'success',
                })
                setShowEditDialog(false)
              } catch (err) {
                enqueueSnackbar('Birth date update failed.', {
                  variant: 'error',
                })
              }
            }}
          />
        </Dialog>
      ) : null}
    </>
  )
}
