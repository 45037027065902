import { Box, Icon, Typography } from '@mui/material'
import {
  HeroCard,
  HeroCardProps,
  HeroCardSection,
  KeyTag,
  Section,
  TagGroup,
  TagGroupItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { CustomerType } from '@trueskin-backoffice/contexts/types'
import { ReactNode } from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { RiCake2Fill } from 'react-icons/ri'
import { BirthDate } from '../components/details'

const CustomerProfile = () => {
  const { customer } = useCustomer()

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{ fontWeight: 600, fontSize: 20, lineHeight: 1.2, m: 0 }}
        >
          {customer.email}
        </Typography>

        {customer.type && (
          <TagGroupItem sx={{ ml: 1 }}>
            <Box sx={{ mr: 1 }}>⭐{customer.type}</Box>
          </TagGroupItem>
        )}
      </Box>

      <Box sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
        <Typography>
          {customer.firstName} {customer.lastName}
        </Typography>
      </Box>
    </>
  )
}

export const CustomerIds = ({ noV1UserId }: { noV1UserId?: boolean }) => {
  const { customer } = useCustomer()

  return (
    <TagGroup
      items={[
        <KeyTag sx={{ mt: 1 }} id={customer.customerId} />,
        noV1UserId ? <></> : <KeyTag sx={{ mt: 1 }} id={customer.v1UserId} />,
      ]}
      sx={{ mb: 1 }}
    ></TagGroup>
  )
}

export const CustomerTags = ({
  noIds,
  noV1UserId,
  noLocale,
}: {
  noIds?: boolean
  noV1UserId?: boolean
  noLocale?: boolean
}) => {
  const { customer } = useCustomer()

  return (
    <>
      {!noIds && <CustomerIds noV1UserId={noV1UserId} />}

      <TagGroup
        items={[
          <TagGroupItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1, mb: -1 }}>
                <Icon component={FaPhoneAlt} sx={{ fontSize: 12 }} />
              </Box>
              <Box>{customer.phoneNumber || 'N/A'}</Box>
            </Box>
          </TagGroupItem>,
          <TagGroupItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1, mb: -1 }}>
                <Icon component={RiCake2Fill} sx={{ fontSize: 12 }} />
              </Box>
              <Box>
                <BirthDate birthDate={customer.birthDate} />
              </Box>
            </Box>
          </TagGroupItem>,
          noLocale || !customer.preferences?.locale ? (
            <></>
          ) : (
            <TagGroupItem sx={{ mt: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    color: '#9c9cbc',
                    mr: 1,
                  }}
                >
                  Locale
                </Box>
                {customer.preferences.locale}
              </Box>
            </TagGroupItem>
          ),
          <TagGroupItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  color: '#9c9cbc',
                  mr: 1,
                }}
              >
                Pharmacy
              </Box>
              {customer.preferences?.shippingPharmacy || 'N/A'}
            </Box>
          </TagGroupItem>,
          <TagGroupItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  color: '#9c9cbc',
                  mr: 1,
                }}
              >
                Doctor
              </Box>
              {customer.preferences?.primaryDoctor?.name || 'N/A'}
            </Box>
          </TagGroupItem>,
          customer?.type === CustomerType.TOFU ? (
            <TagGroupItem sx={{ mt: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    color: '#9c9cbc',
                    mr: 1,
                  }}
                >
                  Type
                </Box>
                {customer.type}
              </Box>
            </TagGroupItem>
          ) : (
            <></>
          ),
        ]}
      ></TagGroup>
    </>
  )
}

export const CustomerSummaryCard = (props: CustomerSummaryCard) => {
  return (
    <HeroCard>
      <HeroCardSection sx={{ mb: 2 }}>
        <CustomerProfile />
      </HeroCardSection>

      <HeroCardSection sx={{ mb: 0 }}>
        <CustomerTags />
      </HeroCardSection>

      {props.children}
    </HeroCard>
  )
}

export interface CustomerSummaryCard extends HeroCardProps {
  children?: ReactNode
}

export const CustomerSummarySection = () => {
  return (
    <Section>
      <CustomerSummaryCard />
    </Section>
  )
}
