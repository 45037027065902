import { useMutation } from '@apollo/client'
import { Delete } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import {
  TagGroupItem,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useSnackbar } from 'notistack'
import { useWaitingRoomCaseDiagnosis } from '../waiting-room-case-diagnosis.context'
import {
  RemoveMedicationForDiagnosisMutation,
  getWaitingRoomCaseDiagnosisQuery,
} from '../waiting-room.gql.bff'
import { EditMedicationButton } from './inputs/edit-medication-button'

export const Medication = ({ medication, editable }: any) => {
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [
    removeMedicationForDiagnosis,
    {
      loading: removeMedicationForDiagnosisLoading,
      error: removeMedicationForDiagnosisError,
    },
  ] = useMutation(RemoveMedicationForDiagnosisMutation, {
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
    onCompleted: () => {
      enqueueSnackbar('Medication removed successfully', { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar('Something went wrong', { variant: 'success' })
    },
  })

  return (
    <VerticalList>
      <VerticalListRow sx={{ display: 'flex', alignItems: 'baseline' }}>
        <VerticalListRowItem sx={{ display: 'flex', alignItems: 'center' }}>
          {editable && diagnosisRecord.actions.canDiagnose.enabled && (
            <IconButton
              disableRipple
              sx={{ p: 0 }}
              onClick={async () =>
                await removeMedicationForDiagnosis({
                  variables: {
                    customerId: diagnosisRecord.customerId,
                    caseId: diagnosisRecord.caseId,
                    doctor: getDoctorProfile(),
                    diagnosisRecordId: diagnosisRecord.id,
                    medicationId: medication.id,
                  },
                })
              }
            >
              <Delete sx={{ fontSize: 16 }} />
            </IconButton>
          )}

          <TagGroupItem
            sx={{
              minWidth: 75,
              justifyContent: 'center',
              border: 'unset',
              py: 0,
              textTransform: 'uppercase',
              borderRadius: 1,
              backgroundColor: '#e2e9f9',
              mr: 0,
            }}
          >
            Medication
          </TagGroupItem>

          {editable && diagnosisRecord.actions.canDiagnose.enabled && (
            <EditMedicationButton medication={medication} />
          )}
        </VerticalListRowItem>

        <VerticalListRowItem fullWidth={true}>
          <Box component="span" sx={{ fontWeight: 500 }}>
            {medication.name}
          </Box>

          <Box>
            Prescription:&nbsp;
            {medication.requiresPrescription ? 'required' : 'not required'}
          </Box>

          <Box>Usage: {medication.usageDirections}</Box>

          <Box>
            Additional directions:&nbsp;
            {medication.additionalDirections || 'none'}
          </Box>

          {medication.prefillId && (
            <Typography variant="caption">
              Prefill:&nbsp;
              {medication.prefillId}
            </Typography>
          )}
        </VerticalListRowItem>
      </VerticalListRow>
    </VerticalList>
  )
}
