import { ExpandMore } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { DangerButton, DismissButton } from '@trueskin-backoffice/components'
import React, { useState } from 'react'

const noSelection: Action = { action: 'None' }

export const ConfirmSubscriptionActionDialog = ({
  opened,
  onClose,
  header,
  description,
  confirmText,
  options,
  submitLabel,
  error,
}: Props) => {
  const [value, setValue] = useState<Action>(noSelection)

  const [confirmation, setConfirmation] = useState<boolean[]>(
    new Array(confirmText.length).fill(false),
  )

  const handleConfirm = (value: boolean, index: number) => {
    const copy = [...confirmation]

    copy[index] = value

    setConfirmation(copy)
  }

  return (
    <Dialog
      open={opened}
      onClose={onClose}
      keepMounted
      aria-labelledby="confirm-action-dialog-title"
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          position: 'relative',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ mb: 8 }}>
            <Typography variant="h1" fontWeight={500}>
              {header}
            </Typography>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flex: 1, maxWidth: 200 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{description}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  pl: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                  '&.MuiSelect-select': { backgroundColor: 'unset' },
                }}
              >
                <Select
                  value={value}
                  onChange={(x) => setValue(x.target.value as Action)}
                  variant="standard"
                  disableUnderline
                  IconComponent={ExpandMore}
                  color="info"
                  sx={{
                    '& .MuiSelect-select': { backgroundColor: 'unset' },
                  }}
                  displayEmpty={true}
                  renderValue={(value: any) => value.action}
                >
                  <MenuItem
                    key={noSelection.action}
                    value={noSelection as unknown as undefined}
                  >
                    <em>None</em>
                  </MenuItem>

                  {options.map((o) => (
                    <MenuItem
                      sx={{ m: 1 }}
                      key={o.action}
                      value={o as unknown as undefined}
                    >
                      {o.action}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 4,
            pl: 10,
            display: 'flex',
            flex: '0 0 280px',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'inline-block', textAlign: 'center' }}>
            <DangerButton
              sx={{ ml: 4, mt: 4 }}
              onClick={async () => {
                await value?.onSubmit?.()
                onClose()
              }}
              autoFocus
              disabled={
                confirmation.some((c) => !c) ||
                !value ||
                value.action === noSelection.action
              }
            >
              {submitLabel}
            </DangerButton>

            <DismissButton sx={{ ml: 4, mt: 4 }} onClick={onClose} />
          </Box>
        </Box>
      </Box>

      <FormGroup sx={{ mt: 4 }}>
        {confirmText.map((text, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={confirmation}
                onChange={(event) => handleConfirm(event.target.checked, index)}
              />
            }
            label={text}
          />
        ))}
      </FormGroup>
      {!!error && <Typography color={'red'}>{error}</Typography>}
    </Dialog>
  )
}

interface Action {
  action: string
  onSubmit?: (...props: any) => any | Promise<any>
}

interface Props {
  opened: boolean
  onClose: () => void
  header: string
  description: string
  confirmText: string[]
  options: Action[]
  submitLabel: string
  error?: string
}
