import {
  Comments,
  CommentsList,
  CreateComment,
} from '@trueskin-backoffice/comments'

export const PatientComments = ({ customerId }: { customerId: string }) => {
  const namespace = `gid://formelskin/patiens/${customerId}`

  return (
    <Comments namespace={namespace}>
      <CreateComment />
      <CommentsList />
    </Comments>
  )
}
