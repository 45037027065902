import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'
import { UserGeneratedHtml } from '@trueskin-backoffice/components'

export const ExpandableDiagnosisSection = ({
  title,
  richContent,
  defaultExpanded,
  disabled,
}: any) => {
  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        // position: 'relative',
        border: 0,
        borderRadius: 3,
        width: '100%',
        background: '#f4f7fc',
        mt: 2,
        '&:before': { opacity: 0 },
      }}
      disableGutters
      defaultExpanded={defaultExpanded}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${title}`}
        id={`panel-${title}`}
      >
        <h2>{title}</h2>
      </AccordionSummary>

      <AccordionDetails>
        <UserGeneratedHtml html={richContent} />
      </AccordionDetails>
    </Accordion>
  )
}

export const DiagnosisAndTreatmentPlan = ({ diagnosisAndTreatment }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // overflowX: 'hidden',
        // overflowY: 'auto',
        // maxHeight: '65vh',
      }}
    >
      <ExpandableDiagnosisSection
        title="Heading notes"
        richContent={diagnosisAndTreatment.headingNote.richText}
      />

      <ExpandableDiagnosisSection
        title="Diagnosis details"
        richContent={diagnosisAndTreatment.diagnosisDetails.richText}
        defaultExpanded
      />

      <ExpandableDiagnosisSection
        title="Skin findings"
        richContent={diagnosisAndTreatment.skinFindings.richText}
        defaultExpanded
      />

      <ExpandableDiagnosisSection
        title="Treatment plan notes"
        richContent={diagnosisAndTreatment.treatmentPlan.richText}
        defaultExpanded
      />

      <ExpandableDiagnosisSection
        title="Additional advice notes"
        richContent={diagnosisAndTreatment.additionalAdvice.richText}
      />
    </Box>
  )
}
