import { Box, ImageList, ImageListItem } from '@mui/material'
import {
  HeroCard,
  Section,
  VerticalList,
  VerticalListRow,
} from '@trueskin-backoffice/components'
import {
  CarouselProvider,
  useCarousel,
} from '../../medical/contexts/carousel.context'

const CaseImagesSection = ({ attachments }: any) => {
  const IMAGE_WIDTH = 280
  const IMAGE_HEIGHT = 160

  const { images, openCarousel } = useCarousel()

  return (
    <Section sx={{ mb: 2 }}>
      <HeroCard sx={{ p: 0, background: 'transparent', boxShadow: 'unset' }}>
        <VerticalList>
          <VerticalListRow sx={{ p: 1 }}>
            <Box>
              <ImageList
                cols={3}
                // rowHeight={IMAGE_HEIGHT}
                sx={{ overflowY: 'unset', gridAutoRows: 160 }}
              >
                {attachments.map((image: any) => (
                  <ImageListItem
                    key={image.url}
                    sx={{
                      cursor: 'pointer',
                      '& .MuiImageListItem-img': {
                        maxHeight: 160,
                      },
                    }}
                    onClick={() => openCarousel(image.carouselIndex)}
                  >
                    <img
                      src={image.url}
                      alt={'case-image-' + image.id}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </VerticalListRow>
        </VerticalList>
      </HeroCard>
    </Section>
  )
}

export const CaseImages = ({ attachments }: any) => {
  const attachmentsWithCarouselIndex = attachments.map(
    (atch: any, index: number) => ({
      ...atch.file,
      bodyParts: atch.bodyParts,
      carouselIndex: index,
    }),
  )

  return (
    <CarouselProvider images={attachmentsWithCarouselIndex}>
      <CaseImagesSection attachments={attachmentsWithCarouselIndex} />
    </CarouselProvider>
  )
}
