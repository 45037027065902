import { ApolloError, useMutation } from '@apollo/client'
import { customerQuery, useCustomer } from '@trueskin-backoffice/contexts'
import { useSnackbar } from 'notistack'
import React from 'react'
import { getAllCustomers } from '../../customers/customers.gql.bff'
import {
  deleteCustomerMutation,
  setCustomerIsTesMutation,
  updateAccountEmailMutation,
  updateAccountPasswordMutation,
  updateCustomerAddressMutation,
  updateCustomerBirthDateMutation,
  updateCustomerEmailMutation,
  updateCustomerFirstNameMutation,
  updateCustomerLastNameMutation,
} from '../customer.gql.bff'

interface CustomerAddressProps {
  firstName: string
  lastName: string
  phoneNumber: string
  country: string
  state?: string
  city: string
  postalCode: string
  neighborhood?: string
  street: string
  streetNumber?: string
  additionalAddress?: string
}

export interface CustomerMutationsContextProps {
  updateCustomerEmail: (email: string) => void
  updateCustomerEmailLoading: boolean
  updateCustomerEmailError: ApolloError | undefined
  resetUpdateCustomerEmailMutation: () => void

  updateCustomerFirstName: (firstName: string) => void
  updateCustomerLastName: (lastName: string) => void
  updateCustomerBirthDate: (birthDate: string) => void
  updateCustomerAddress: (addressId: string, data: CustomerAddressProps) => void
  deleteCustomer: () => void
  setCustomerIsTest: (isTest: boolean) => void

  updateAccountEmail: (email: string) => void
  updateAccountEmailLoading: boolean
  updateAccountEmailError: ApolloError | undefined

  updateAccountPassword: (newPassword: string, confirmPassword: string) => void
  updateAccountPasswordLoading: boolean
  updateAccountPasswordError: ApolloError | undefined
}

const CustomerMutationsContext = React.createContext<
  CustomerMutationsContextProps | undefined
>(undefined)

const CustomerMutationsProvider = ({ children }: { children: any }) => {
  const { customer } = useCustomer()
  const { enqueueSnackbar } = useSnackbar()

  const [
    updateEmail,
    {
      loading: updateCustomerEmailLoading,
      error: updateCustomerEmailError,
      reset: resetUpdateCustomerEmailMutation,
    },
  ] = useMutation(updateCustomerEmailMutation, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchQueries: [customerQuery],
    onCompleted: (_) => {
      enqueueSnackbar('Customer was updated successfully', {
        variant: 'success',
      })
    },
  })

  const [
    updateFirstName,
    { loading: scheduleToCancelLoading2, error: scheduleToCancelError2 },
  ] = useMutation(updateCustomerFirstNameMutation, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [customerQuery],
    onCompleted: (_) => {
      enqueueSnackbar('Customer was updated successfully', {
        variant: 'success',
      })
    },
  })

  const [
    updateLastName,
    { loading: scheduleToCancelLoading3, error: scheduleToCancelError3 },
  ] = useMutation(updateCustomerLastNameMutation, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [customerQuery],
    onCompleted: (_) => {
      enqueueSnackbar('Customer was updated successfully', {
        variant: 'success',
      })
    },
  })

  const [
    updateBirthDate,
    { loading: scheduleToCancelLoading4, error: scheduleToCancelError4 },
  ] = useMutation(updateCustomerBirthDateMutation, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [customerQuery],
    onCompleted: (_) => {
      enqueueSnackbar('Customer was updated successfully', {
        variant: 'success',
      })
    },
  })

  const [
    updateAccountEmail,
    { loading: updateAccountEmailLoading, error: updateAccountEmailError },
  ] = useMutation(updateAccountEmailMutation, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [customerQuery],
  })

  const [
    updateAccountPassword,
    {
      loading: updateAccountPasswordLoading,
      error: updateAccountPasswordError,
    },
  ] = useMutation(updateAccountPasswordMutation, {
    notifyOnNetworkStatusChange: true,
  })

  const [
    deleteCustomer,
    { loading: deleteCustomerLoading, error: deleteCustomerError },
  ] = useMutation(deleteCustomerMutation, {
    refetchQueries: [getAllCustomers],
    onCompleted: (_) => {
      enqueueSnackbar('Customer was deleted successfully', {
        variant: 'success',
      })
    },
  })

  const [
    updateAddress,
    { loading: updateAddressLoading, error: updateAddressError },
  ] = useMutation(updateCustomerAddressMutation, {
    refetchQueries: [customerQuery],
  })

  const [
    setCustomerIsTest,
    { loading: setCustomerIsTestLoading, error: setCustomerIsTestError },
  ] = useMutation(setCustomerIsTesMutation, {
    refetchQueries: [customerQuery],
  })

  const value = React.useMemo(
    () => ({
      updateCustomerEmail: (email: string) =>
        updateEmail({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
            email,
          },
        }),
      updateCustomerEmailLoading,
      updateCustomerEmailError,
      resetUpdateCustomerEmailMutation,
      updateCustomerFirstName: (firstName: string) =>
        updateFirstName({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
            firstName,
          },
        }),
      updateCustomerLastName: (lastName: string) =>
        updateLastName({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
            lastName,
          },
        }),
      updateCustomerBirthDate: (birthDate: string) =>
        updateBirthDate({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
            birthDate,
          },
        }),
      updateAccountEmail: (email: string) =>
        updateAccountEmail({
          variables: {
            accountId: customer?.accountId,
            email,
          },
        }),
      updateAccountEmailLoading,
      updateAccountEmailError,
      updateAccountPassword: (newPassword: string, confirmPassword: string) =>
        updateAccountPassword({
          variables: {
            accountId: customer?.accountId,
            newPassword,
            confirmPassword,
          },
        }),
      updateAccountPasswordLoading,
      updateAccountPasswordError,
      updateCustomerAddress: (
        addressId: string,
        data: CustomerAddressProps,
      ) => {
        return updateAddress({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
            addressId,
            data,
          },
        })
      },
      deleteCustomer: () =>
        deleteCustomer({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
          },
        }),
      setCustomerIsTest: (isTest: boolean) =>
        setCustomerIsTest({
          variables: {
            customerId: customer?.customerId,
            v1UserId: customer?.v1UserId,
            isTest,
          },
        }),
    }),
    [
      customer,

      updateEmail,
      updateCustomerEmailLoading,
      updateCustomerEmailError,
      resetUpdateCustomerEmailMutation,

      updateFirstName,
      updateLastName,
      updateBirthDate,
      updateAddress,
      deleteCustomer,
      setCustomerIsTest,

      updateAccountEmail,
      updateAccountEmailLoading,
      updateAccountEmailError,

      updateAccountPassword,
      updateAccountPasswordLoading,
      updateAccountPasswordError,
    ],
  )

  return (
    <CustomerMutationsContext.Provider value={value}>
      {children}
    </CustomerMutationsContext.Provider>
  )
}

function useCustomerMutations(): CustomerMutationsContextProps {
  const context = React.useContext(CustomerMutationsContext)
  if (context === undefined) {
    throw new Error(
      `useCustomerMutations must be used within a CustomerMutationsProvider`,
    )
  }
  return context
}

export { CustomerMutationsProvider, useCustomerMutations }
