import { gql } from '@apollo/client'

export const verifyAccountDeletionStateQuery = gql`
  query VerifyAccountDeletionState($accountId: String!) {
    account(accountId: $accountId) {
      id
      canDeleteV1User
      canDeleteTofuPatient
    }
  }
`

export const deleteAccountMutation = gql`
  mutation DeleteAccount($accountId: String!) {
    account(accountId: $accountId) {
      deleteAccount(accountId: $accountId) {
        deleted
      }
    }
  }
`

export const updateAccountPasswordMutation = gql`
  mutation updateAccountPassword(
    $accountId: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    account(accountId: $accountId) {
      updatePassword(
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      ) {
        updated
      }
    }
  }
`

export const updateAccountEmailMutation = gql`
  mutation updateAccountEmail($accountId: String!, $email: String!) {
    account(accountId: $accountId) {
      updateEmail(email: $email) {
        updated
      }
    }
  }
`

// export const useDeleteAccountMutation = () => {
//   const [
//     deleteAccount,
//     { loading: deleteAccountLoading, error: deleteAccountError },
//   ] = useMutation(deleteAccountMutation)
//
//   return {
//     deleteAccount,
//     deleteAccountLoading,
//     deleteAccountError,
//   }
// }
