import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { ProductsFilters } from './products.filters'
import { ProductsTable } from './products.table'

const PRODUCT_FILTER_INIT_VALUES = {
  locales: StringParam,
  ruleName: StringParam,
  tags: StringParam,
}

export const ProductsPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={PRODUCT_FILTER_INIT_VALUES}>
        <ProductsFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <ProductsTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
