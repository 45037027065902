import { useCustomer } from '@trueskin-backoffice/contexts'
import { ErrorMessage, Form, Formik } from 'formik'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { Box, Button, TextField, MenuItem, Typography } from '@mui/material'
import { H1 } from '@trueskin-backoffice/components'
import React from 'react'
import * as yup from 'yup'
import { useCustomerMutations } from '../contexts'

const COUNTRIES = ['DE', 'CH', 'BR']
const COUNTRY_STATES: Record<string, string> = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'MatoGrosso',
  MS: 'MatoGrosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
}
const COUNTRY_POSTAL_CODE_REGEXP: Record<string, RegExp> = {
  DE: /^\d{5}$/,
  CH: /^\d{4}$/,
  BR: /^\s?(\d\s?){5}-?\s?(\d\s?){3}$/,
}
const COUNTRY_POSTAL_CODE_LENGTH: Record<string, number> = {
  DE: 5,
  CH: 4,
  BR: 8,
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required.')
    .test({
      name: 'isValidName',
      message: 'First name must not contain numbers.',
      test: (value) => value == null || !/\d/.test(value),
    }),

  lastName: yup
    .string()
    .required('Last name is required.')
    .test({
      name: 'isValidName',
      message: 'Last name must not contain numbers.',
      test: (value) => value == null || !/\d/.test(value),
    }),

  phoneNumber: yup
    .string()
    .required('Phone number is required.')
    .test({
      name: 'isValidPhone',
      message: 'Phone number is invalid.',
      test: (value) => value == null || isPossiblePhoneNumber(value),
    }),

  postalCode: yup
    .string()
    .required('Postal code is required.')
    .test({
      name: 'isValidPostalCode',
      message: 'Postal code is invalid.',
      test: (value, context) => {
        const { country } = context.parent
        const requiredLength = COUNTRY_POSTAL_CODE_LENGTH[country]
        const numbers = (value || '').replace(/\D/g, '')
        if (numbers.length !== requiredLength) {
          return context.createError({
            message: `Must be ${requiredLength} long.`,
          })
        }
        return !value || COUNTRY_POSTAL_CODE_REGEXP[country].test(value)
      },
    }),

  state: yup.string().test({
    name: 'isStateValid',
    message: 'State is invalid.',
    test: (value, context) => {
      const { country } = context.parent
      if (country !== 'BR') {
        return true
      }
      if (!value) {
        return context.createError({ message: 'State is required.' })
      }
      return !/\d/.test(value)
    },
  }),

  neighborhood: yup.string().test({
    name: 'isNeighborhoodValid',
    message: 'Neighborhood must not contain numbers.',
    test: (value, context) => {
      const { country } = context.parent
      if (country !== 'BR') {
        return true
      }
      if (!value) {
        return context.createError({ message: 'Neighborhood is required.' })
      }
      return !/\d/.test(value)
    },
  }),

  city: yup
    .string()
    .required('City is required.')
    .test({
      name: 'isCityValid',
      message: 'City must not contain numbers.',
      test: (value) => value == null || !/\d/.test(value),
    }),

  street: yup
    .string()
    .required('Street is required.')
    .test({
      name: 'isStreetValid',
      message: 'Street must contain number.',
      test: (value, context) => {
        const { country } = context.parent
        return !value || (country !== 'BR' ? /\d/.test(value) : true)
      },
    }),

  streetNumber: yup.string().test({
    name: 'isStreetValid',
    message: 'Street number must contain number.',
    test: (value, context) => {
      const { country } = context.parent

      if (country !== 'BR') {
        return true
      }
      if (!value) {
        return context.createError({ message: 'Street number is required.' })
      }
      return /\d/.test(value)
    },
  }),
})

export const UpdateAddressModal = ({
  onClose,
  isBilling,
}: {
  onClose: any
  isBilling: boolean
}) => {
  const {
    customer: { addressBook },
  } = useCustomer()
  const { billingAddress, shippingAddress, isShippingSameAsBilling } =
    addressBook || {}
  const address = isBilling ? billingAddress : shippingAddress
  const { updateCustomerAddress } = useCustomerMutations()

  return (
    <Box>
      <Formik
        initialValues={{
          additionalAddress: address?.additionalAddress || '',
          city: address?.city || '',
          country: address?.country || '',
          firstName: address?.firstName || '',
          lastName: address?.lastName || '',
          neighborhood: address?.neighborhood || '',
          phoneNumber: address?.phoneNumber || '',
          postalCode: address?.postalCode || '',
          state: address?.state || '',
          street: address?.street || '',
          streetNumber: address?.streetNumber || '',
        }}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await updateCustomerAddress(address.id, values)
          onClose()
        }}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Box
                sx={{
                  maxWidth: 500,
                  minHeight: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <H1>Update customer address</H1>
                <Box sx={{ my: 4 }}>
                  <Typography color="inherit">
                    Changing the address will not affect already triggered
                    orders
                  </Typography>
                  {!isBilling && isShippingSameAsBilling && (
                    <Typography color={'red'}>
                      By updating the shipping address you will update the
                      billing address
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: 4,
                    rowGap: 4,
                    mb: 4,
                  }}
                >
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    error={Boolean(errors.firstName)}
                    helperText={String(errors.firstName || '')}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    value={values.lastName}
                    error={Boolean(errors.lastName)}
                    helperText={String(errors.lastName || '')}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone number"
                    value={values.phoneNumber}
                    error={Boolean(errors.phoneNumber)}
                    helperText={String(errors.phoneNumber || '')}
                    onChange={handleChange}
                  />

                  <TextField
                    select
                    id="country"
                    name="country"
                    label="Country"
                    value={values.country}
                    error={Boolean(errors.country)}
                    helperText={String(errors.country || '')}
                    onChange={handleChange}
                    sx={{ flex: '1 1 45%' }}
                  >
                    {COUNTRIES.filter((country) =>
                      values.country === 'BR'
                        ? country === 'BR'
                        : country !== 'BR',
                    ).map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="postalCode"
                    name="postalCode"
                    label="Postal code"
                    value={values.postalCode}
                    error={Boolean(errors.postalCode)}
                    helperText={String(errors.postalCode || '')}
                    onChange={handleChange}
                    sx={{ flex: '1 1 45%' }}
                  />

                  {values.country === 'BR' ? (
                    <>
                      <TextField
                        select
                        id="state"
                        name="state"
                        label="State"
                        value={values.state}
                        error={Boolean(errors.state)}
                        helperText={String(errors.state || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      >
                        {Object.keys(COUNTRY_STATES).map((state) => (
                          <MenuItem key={state} value={state}>
                            {COUNTRY_STATES[state].toString()}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        value={values.city}
                        error={Boolean(errors.city)}
                        helperText={String(errors.city || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      />
                      <TextField
                        id="neighborhood"
                        name="neighborhood"
                        label="Neighborhood"
                        value={values.neighborhood}
                        error={Boolean(errors.neighborhood)}
                        helperText={String(errors.neighborhood || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      />
                      <TextField
                        id="street"
                        name="street"
                        label="Street"
                        value={values.street}
                        error={Boolean(errors.street)}
                        helperText={String(errors.street || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      />
                      <TextField
                        id="streetNumber"
                        name="streetNumber"
                        label="Street number"
                        value={values.streetNumber}
                        error={Boolean(errors.streetNumber)}
                        helperText={String(errors.streetNumber || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        value={values.city}
                        error={Boolean(errors.city)}
                        helperText={String(errors.city || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      />
                      <TextField
                        id="street"
                        name="street"
                        label="Street"
                        value={values.street}
                        error={Boolean(errors.street)}
                        helperText={String(errors.street || '')}
                        onChange={handleChange}
                        sx={{ flex: '1 1 45%' }}
                      />
                    </>
                  )}
                  <TextField
                    fullWidth
                    id="additionalAddress"
                    name="additionalAddress"
                    label="Additional address"
                    value={values.additionalAddress}
                    error={Boolean(errors.additionalAddress)}
                    helperText={String(errors.additionalAddress || '')}
                    onChange={handleChange}
                  />
                </Box>

                <Box>
                  <Button color="secondary" onClick={() => onClose()}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
