import { Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useV2Mutation } from '@trueskin-backoffice/api-client'
import { DangerButton } from '@trueskin-backoffice/components'
import { useState } from 'react'
import { useOrder } from '../contexts'
import { getOrderById, removeVoucherFromUpcomingOrder } from '../gql'
import { OrderPaymentStatusGQL } from '../mappers/order.mapper'

export const RemoveVoucher = ({ voucher }: any) => {
  const { order } = useOrder()
  const [openVoucherRemoveDialog, setOpenVoucherRemoveDialog] =
    useState<boolean>(false)

  return (
    <>
      <Delete
        sx={{ fontSize: 16, cursor: 'pointer' }}
        onClick={() => setOpenVoucherRemoveDialog(true)}
      />

      {openVoucherRemoveDialog && (
        <Dialog
          onClose={() => setOpenVoucherRemoveDialog(true)}
          open={openVoucherRemoveDialog}
        >
          <OrderVoucherRemoveDialog
            voucherCode={voucher.code}
            orderId={order._id}
            customerId={order.customer}
            paymentStatus={order.payment.status}
            onDismiss={() => setOpenVoucherRemoveDialog(false)}
          />
        </Dialog>
      )}
    </>
  )
}

const OrderVoucherRemoveDialog = ({
  voucherCode,
  orderId,
  customerId,
  onDismiss,
  paymentStatus,
}: any) => {
  const [removeVoucher, { loading }] = useV2Mutation(
    removeVoucherFromUpcomingOrder,
    {
      refetchQueries: [
        {
          query: getOrderById,
          variables: {
            orderId,
          },
        },
      ],
      onCompleted: () => {
        onDismiss()
      },
    },
  )

  const handleRemoveVoucher = async () => {
    await removeVoucher({
      variables: {
        voucherCode,
        v1UserId: customerId,
        upcomingOrderPaymentStatus: OrderPaymentStatusGQL[paymentStatus],
      },
    })
  }

  return (
    <Box>
      <DialogTitle>Remove voucher from upcoming order</DialogTitle>

      <Container>
        <Box sx={{ p: 4 }}>
          <Typography>
            Are you sure you want to remove <b>{voucherCode}</b> voucher?
          </Typography>
        </Box>

        <Grid
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 4 }}
        >
          <Button disabled={loading} onClick={onDismiss}>
            Cancel
          </Button>

          <DangerButton disabled={loading} onClick={handleRemoveVoucher}>
            Remove voucher
          </DangerButton>
        </Grid>
      </Container>
    </Box>
  )
}
