import { Box, Card, LinearProgress, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import { getAllRoles } from './employees.gql'

export const RolesPage = () => {
  const {
    data: { getAllRoles: roles } = {},
    loading,
    error,
  } = useV2Query(getAllRoles)

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <>
      {roles.map((role: any) => (
        <Card
          key={role._id}
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            display: 'flex',
            flexGrow: 1,
            overflow: 'unset',
            p: 1,
            m: 1,
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            // onClick={() =>
            //   setFilter({
            //     ...filter,
            //     role: Object.entries(ROLE_ID_MAP).find(
            //       ([key, value]) => value === role._id,
            //     )?.[0] as Role,
            //   })
            // }
          >
            <Typography>{role?.name}</Typography>
            <Typography>{role?.description}</Typography>
          </Box>
        </Card>
      ))}
    </>
  )
}
