import { useQuery } from '@apollo/client'
import { Logout, PersonAdd, Settings } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  DateTime,
  HeroCard,
  HeroCardEmpty,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
  Section,
  SubscriptionStatus,
  subscriptionStatusDescriptionText,
  TextTooltip,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { getCustomerCurrentSubscription } from '../customer.gql.bff'

const CustomersSubscriptionSummary = ({ subscription }: any) => {
  if (!subscription) {
    return (
      <HeroCardEmpty>
        <Typography fontSize={12}>
          No subscriptions found for this customer
        </Typography>
      </HeroCardEmpty>
    )
  }

  return (
    <HeroCard>
      <HeroCardSection sx={{ m: 0 }}>
        <VerticalList>
          <VerticalListRow>
            <VerticalListRowItem>
              <SubscriptionStatus subscription={subscription} size="small" />
            </VerticalListRowItem>

            <VerticalListRowItem fullWidth={true}>
              <Box component="span" sx={{ fontWeight: 500 }}>
                <NavLink to={`subscriptions/${subscription.id}`}>
                  {
                    // @ts-ignore
                    (SubscriptionOccurrenceMap[
                      subscription.occurrence
                    ] as string) || subscription.occurrence
                  }
                </NavLink>
              </Box>

              <HorizontalList>
                <HorizontalListItem>
                  <Box sx={{ mr: 1 }}>🔑</Box>
                  <Box sx={{ mr: 1 }}>{subscription.id}</Box>
                </HorizontalListItem>

                {!!subscription.metadata?.v1Subscription?._id && (
                  <HorizontalListItem>
                    <Box sx={{ mr: 1 }}>🔑</Box>
                    <Box sx={{ mr: 1 }}>
                      {subscription.metadata.v1Subscription._id}
                    </Box>
                  </HorizontalListItem>
                )}
              </HorizontalList>

              {subscription.status !== 'CREATED' && (
                <HorizontalList>
                  <HorizontalListItem>
                    <Typography variant="caption" sx={{ mr: 1 }}>
                      Started on
                    </Typography>
                    <DateTime
                      dateTime={subscription.startDate}
                      tooltipPlacement="top"
                    />
                  </HorizontalListItem>

                  {subscription.status !== 'PAUSED' && (
                    <HorizontalListItem>
                      <Typography variant="caption" sx={{ mr: 1 }}>
                        {subscriptionStatusDescriptionText(subscription)}
                      </Typography>
                      {subscription.status !== 'WAITING_PAYMENT' && (
                        <DateTime
                          dateTime={subscription.endDate}
                          tooltipPlacement="top"
                        />
                      )}
                    </HorizontalListItem>
                  )}
                </HorizontalList>
              )}
            </VerticalListRowItem>
          </VerticalListRow>
        </VerticalList>
      </HeroCardSection>
    </HeroCard>
  )
}

export const SubscriptionOccurrenceMap = {
  occ_once: 'Non-renewing',
  occ_daily: 'Renewing daily',
  occ_monthly: 'Renewing monthly',
}

const SubscriptionActions = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/*<Typography sx={{ minWidth: 100 }}>Contact</Typography>*/}
        {/*<Typography sx={{ minWidth: 100 }}>Profile</Typography>*/}
        {/*<Tooltip title="Account settings">*/}
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
        </IconButton>
        {/*</Tooltip>*/}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export const CustomerSubscriptionSection = () => {
  const {
    customer: { customerId, v1UserId },
  } = useCustomer()

  const {
    data: { customer } = {},
    loading,
    error,
  } = useQuery(getCustomerCurrentSubscription, {
    skip: customerId === undefined,
    variables: {
      customerId,
    },
  })

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Section
      headerLabel={
        <Box
          sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        >
          <Box sx={{ mr: 1 }}>Current Subscription</Box>
          <TextTooltip
            title={
              'This is the current subscription attached to the customer in the CLA.'
            }
            placement="right"
          >
            <InfoIcon fontSize={'small'} />
          </TextTooltip>
        </Box>
      }
      headerActions={
        <NavLink to={`subscriptions`}>
          <Typography fontSize={12}>View all</Typography>
        </NavLink>
      }
    >
      <CustomersSubscriptionSummary
        subscription={customer?.currentSubscription}
      />

      {(customer?.activeSubscriptions || [])
        .filter(
          (subscription: { status: string; id: any }) =>
            subscription.id !== customer?.currentSubscription?.id &&
            subscription.status !== 'CANCELLED',
        )
        .map((subscription: any) => (
          <CustomersSubscriptionSummary subscription={subscription} />
        ))}
    </Section>
  )
}
