import { useAuth } from '@trueskin-backoffice/contexts'
import { intersection } from 'lodash'
import { ReactNode } from 'react'

type Role = 'doctor' | 'medicalassistant' | 'customercareagent' | 'admin'
type Props = {
  to: Role[]
  children: ReactNode
}

// This component is meant to be used everywhere a restriction based on roles is needed
export const Restricted = ({ to, children }: Props) => {
  const { employee } = useAuth()

  if (!employee) {
    return null
  }

  if (intersection([...to, 'admin'], employee.roles)?.length) {
    return <>{children}</>
  }

  return null
}
