import { Box, Divider, Skeleton } from '@mui/material'
import { useComments } from '../contexts/comments.context'

export const CommentsContainer = ({ children }: any) => {
  const { loading } = useComments()

  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            width: '100%',
          }}
        >
          <Skeleton
            variant="rectangular"
            width={200}
            height={30}
            sx={{ mr: 2 }}
          />
          <Skeleton variant="rectangular" width={120} height={30} />
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="rectangular" width={250} height={30} />
        </Box>
      </Box>
    )
  }

  return <Box sx={{ width: '100%' }}>{children}</Box>
}
