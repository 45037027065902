import { gql } from '@apollo/client'

export const getPresets = gql`
  query Presets($limit: Int, $offset: Int, $filter: PresetsFilter) {
    presets(limit: $limit, offset: $offset, filter: $filter) {
      totalRows
      rows {
        _id
        occurrence
        items {
          product
          quantity
        }
        actions {
          type
          string
          strings
          voucherCodes
          instalments {
            count
            frequency {
              value
              period
            }
          }
        }
        actionsOnRemoval {
          type
          voucherCodes
        }
        locales
        tags
        metadata {
          mainLabel
          secondaryLabel
        }
        computedData {
          mainLabel
          secondaryLabel
        }
      }
    }
  }
`
