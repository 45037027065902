import { gql } from '@apollo/client'

const invoiceFields = gql`
  fragment InvoiceFields on Invoice {
    id
    generatedAt
    orderId
    customerId
    total
    currency
    amountPaid
    amountDue
    amountRefunded
    voidedAt
    voidReason
    status
    paidAt
    dunningStatus
    nextRetryAt
    updatedAt
  }
`

const invoicesPaginationFields = gql`
  ${invoiceFields}
  fragment InvoicesPaginationFields on PaginatedInvoices {
    rows {
      ...InvoiceFields
    }
    totalRows
  }
`

export const getInvoiceById = gql`
  ${invoiceFields}
  query GetInvoiceById($invoiceId: String!) {
    getInvoiceById(invoiceId: $invoiceId) {
      ...InvoiceFields
    }
  }
`

export const voidInvoice = gql`
  mutation voidInvoice($orderId: String!, $invoiceId: String!) {
    voidInvoice(orderId: $orderId, invoiceId: $invoiceId) {
      _id
    }
  }
`

export const getAllInvoices = gql`
  ${invoicesPaginationFields}
  query GetAllInvoices($limit: Int!, $offset: Int!, $filter: InvoiceFilter) {
    getAllInvoices(limit: $limit, offset: $offset, filter: $filter) {
      ...InvoicesPaginationFields
    }
  }
`
