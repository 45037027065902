import { gql } from '@apollo/client'

export const getPresetLists = gql`
  query PresetLists($filter: PresetListsFilter) {
    presetLists(filter: $filter) {
      rows {
        _id
        name
        type
        locale
        defaultItemIndex
        items {
          type
          preset
          product
          occurrence
        }
      }
      totalRows
    }
  }
`
