import { Delete } from '@mui/icons-material'
import {
  ConfirmActionDialog,
  DockAction,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCustomerMutations } from '../../contexts'
import { DeleteAccountDockAction } from './delete-account.dock-action'

// TODO: remove this in favour of <DeleteAccountDockAction /> once migration to unified accounts if finalized
export const DeleteCustomerDockAction = () => {
  const navigate = useNavigate()

  const { customer } = useCustomer()
  const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState(false)

  const { deleteCustomer } = useCustomerMutations()

  if (customer?.accountId) {
    return <DeleteAccountDockAction />
  }

  return (
    <>
      <DockAction
        startIcon={<Delete />}
        onClick={() => setConfirmationDialogOpened(true)}
        disabled={!customer.actions?.canDelete.enabled}
        tooltip={
          !customer.actions?.canDelete.enabled
            ? 'You can not delete the customer, until the subscription is not cancelled.'
            : ''
        }
      >
        Delete customer
      </DockAction>

      {confirmationDialogOpened && (
        <ConfirmActionDialog
          title="Submit customer deletion"
          onClose={() => setConfirmationDialogOpened(false)}
          opened={confirmationDialogOpened}
          confirmText={[
            'Deleting a customer is an irreversible operation. Are you sure you want to continue?',
          ]}
          options={[
            {
              action: 'Delete customer',
              onSubmit: () => {
                deleteCustomer()
                navigate('/customers')
              },
            },
          ]}
        />
      )}
    </>
  )
}
