import { Box, Divider } from '@mui/material'

export const DataFilterDivider = ({ borderVisible }: any) => {
  if (borderVisible === false) {
    return <Box sx={{ mx: 1 }} />
  }

  return (
    <Divider
      flexItem
      orientation="vertical"
      sx={{ mx: 1.5, my: 0.5, borderRightWidth: 'medium' }}
    />
  )
}
