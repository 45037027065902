import { Box } from '@mui/material'
import React from 'react'
import { Employee } from '../types'
import { DoctorProfile } from './doctor-profile'
import { MedicalAssistantProfile } from './medical-assistant-profile'

export const Profile = ({ employee }: { employee: Employee }) => {
  return (
    <Box
      sx={{
        p: 2,
        border: '1px dashed grey',
        width: '100%',
      }}
    >
      {employee.profile.doctor && <DoctorProfile employee={employee} />}
      {employee.profile.medicalassistant && (
        <MedicalAssistantProfile employee={employee} />
      )}
    </Box>
  )
}
