export enum OrderStatus {
  'open' = 'Open',
  'partially_paid' = 'Partially paid',
  'paid' = 'Paid',
  'payment_due' = 'Payment due',
  'upcoming' = 'Upcoming',
  'cancelled' = 'Cancelled',
  'not_paid' = 'Not paid',
  'partially_not_paid' = 'Partially not paid',
}
