import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'

export interface UIControllerState {
  hideLeftHandSideMenu: boolean
  showTopRightMenu: boolean
}

export interface UIControllerProps {
  controller: UIControllerState
  // dispatch: React.Dispatch<any>
  showTopRightMenu: (value: boolean) => void
}

enum UIControllerActionKind {
  HIDE_LEFT_HAND_SIDE_MENU = 'HIDE_LEFT_HAND_SIDE_MENU',
  SHOW_TOP_RIGHT_MENU = 'SHOW_TOP_RIGHT_MENU',
}

interface UIControllerAction {
  type: UIControllerActionKind
  value: boolean
}

const UIControllerContext = createContext<UIControllerProps | null>(null)

function reducer(state: UIControllerState, action: UIControllerAction) {
  switch (action.type) {
    case 'HIDE_LEFT_HAND_SIDE_MENU': {
      return { ...state, hideLeftHandSideMenu: action.value }
    }
    case 'SHOW_TOP_RIGHT_MENU': {
      return { ...state, showTopRightMenu: action.value }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const UiControllerProvider = ({ children }: any) => {
  const initialState = {
    hideLeftHandSideMenu: false,
    showTopRightMenu: false,
  }

  const [controller, dispatch] = useReducer(reducer, initialState)

  const showTopRightMenu = useCallback((show: boolean) => {
    if (show) {
      dispatch({
        type: UIControllerActionKind.HIDE_LEFT_HAND_SIDE_MENU,
        value: true,
      })
      dispatch({
        type: UIControllerActionKind.SHOW_TOP_RIGHT_MENU,
        value: true,
      })
    } else {
      dispatch({
        type: UIControllerActionKind.HIDE_LEFT_HAND_SIDE_MENU,
        value: false,
      })
      dispatch({
        type: UIControllerActionKind.SHOW_TOP_RIGHT_MENU,
        value: false,
      })
    }
  }, [])

  const value = useMemo(
    () => ({ controller, dispatch, showTopRightMenu }),
    [controller, dispatch, showTopRightMenu],
  )

  return (
    <UIControllerContext.Provider value={value}>
      {children}
    </UIControllerContext.Provider>
  )
}

export function useUIController() {
  const context = useContext(UIControllerContext)

  if (!context) {
    throw new Error(
      'useUIController should be used inside the UIControllerContext.',
    )
  }

  return context
}
