import { Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  InputFilterProps,
} from '@trueskin-backoffice/components'
import { CronJobStatus } from '@trueskin-backoffice/contexts/types'
import React from 'react'
import { DecodedValueMap, StringParam, useQueryParams } from 'use-query-params'
import { CronJobsTable } from './cron-jobs.table'

const CRON_FILTER_INIT_VALUES = {
  name: StringParam,
  status: StringParam,
}

export type CronJobFilterProps = typeof CRON_FILTER_INIT_VALUES

type CronJobInputFilterProps = Record<
  keyof Pick<CronJobFilterProps, 'status'>,
  InputFilterProps
>

export const CronJobsPage = () => {
  const [query, setQuery] = useQueryParams<CronJobFilterProps>(
    CRON_FILTER_INIT_VALUES,
  )

  return (
    <Box>
      <DataFilter<DecodedValueMap<CronJobFilterProps>, CronJobInputFilterProps>
        filter={query}
        onChange={setQuery}
        names={Object.keys(CRON_FILTER_INIT_VALUES)}
        inputs={{
          status: {
            component: FilterComponent.SELECT,
            options: Object.keys(CronJobStatus).map((status) => ({
              label: status,
              value: status,
            })),
          },
        }}
      />
      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        <CronJobsTable filter={query} />
      </Card>
    </Box>
  )
}
