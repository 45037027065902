import { Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  InputFilterProps,
} from '@trueskin-backoffice/components'
import { DecodedValueMap, StringParam, useQueryParams } from 'use-query-params'
import { AuditTable } from './audit.table'
import { CommandStatus } from './types'

const COMMANDS_FILTER_INIT_VALUES = {
  customerId: StringParam,
  correlationId: StringParam,
  status: StringParam,
}

type CommandsInputFilterProps = Record<
  keyof Pick<CommandsFilterProps, 'status'>,
  InputFilterProps
>

export type CommandsFilterProps = typeof COMMANDS_FILTER_INIT_VALUES

export const AuditPage = () => {
  const [query, setQuery] = useQueryParams<CommandsFilterProps>(
    COMMANDS_FILTER_INIT_VALUES,
  )

  return (
    <Box>
      <DataFilter<
        DecodedValueMap<CommandsFilterProps>,
        CommandsInputFilterProps
      >
        filter={query}
        onChange={setQuery}
        names={Object.keys(COMMANDS_FILTER_INIT_VALUES)}
        inputs={{
          status: {
            component: FilterComponent.SELECT,
            options: Object.keys(CommandStatus).map((status) => ({
              label: status,
              value: status,
            })),
          },
        }}
      />

      {query && Object.values(query).filter((param) => !!param)?.length ? (
        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <AuditTable filter={query} />
        </Card>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          Select a filter to see results
        </Box>
      )}
    </Box>
  )
}
