import { ApolloError, useQuery } from '@apollo/client'
import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { getCaseById } from '../gql/case.gql.bff'

interface StandardProps {
  children?: ReactNode
}

export interface CaseContextProps {
  loading: boolean
  error: string | undefined | ApolloError | Error

  currentCase?: any
  currentCaseInvoice?: any
}

const CaseContext = React.createContext<CaseContextProps | undefined>(undefined)

function CaseProvider(props: StandardProps) {
  const { customerId, caseId } = useParams<{
    customerId: string
    caseId: string
  }>()

  const {
    data: {
      patient: { case: currentCase, casePayment: currentCaseInvoice },
    } = { patient: { case: {}, casePayment: {} } },
    loading,
    error,
    refetch,
  } = useQuery(getCaseById, {
    skip: !caseId || !customerId,
    variables: { customerId, caseId },
  })

  React.useEffect(() => {
    refetch()
  }, [customerId, caseId])

  const value = React.useMemo(
    () => ({
      loading,
      error,
      currentCase,
      currentCaseInvoice,
    }),
    [loading, error, currentCase, currentCaseInvoice],
  )

  return (
    <CaseContext.Provider value={value}>{props.children}</CaseContext.Provider>
  )
}

function useCase(): CaseContextProps {
  const context = React.useContext(CaseContext)
  if (context === undefined) {
    throw new Error(`useCase must be used within a CaseProvider`)
  }
  return context
}

export { CaseProvider, useCase }
