import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Box, Button, Collapse, Typography } from '@mui/material'
import {
  AddressType,
  HeroCard,
  HeroCardDivider,
  HeroCardEmpty,
  HeroCardSection,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import React from 'react'
import { ExpandMore } from '../../order/sub-sections/order-invoice-information'

const CustomerAddress = ({ address, label, isShippingSameAsBilling }: any) => (
  <VerticalList>
    <VerticalListRow sx={{ display: 'flex', alignItems: 'baseline' }}>
      <VerticalListRowItem>
        <AddressType label={label} />
      </VerticalListRowItem>

      {isShippingSameAsBilling ? (
        <VerticalListRowItem fullWidth={true}>
          The shipping address is the same as the billing address
        </VerticalListRowItem>
      ) : (
        <>
          <VerticalListRowItem fullWidth={true}>
            <Box component="span" sx={{ fontWeight: 500 }}>
              {address.firstName} {address.lastName}{' '}
              <Typography variant="caption">{address.phoneNumber}</Typography>
            </Box>

            <Box>
              {address.street}, {address.streetNumber} {address.state},{' '}
              {address.postalCode}, {address.city}, {address.country}
            </Box>
            {address.additionalAddress && (
              <Box>{address.additionalAddress}</Box>
            )}
          </VerticalListRowItem>
        </>
      )}
    </VerticalListRow>
  </VerticalList>
)

export const CustomerAddressesSummary = ({
  collapsed,
  small,
  transparent,
  shippingOnly,
}: {
  collapsed?: boolean
  small?: boolean
  transparent?: boolean
  shippingOnly?: boolean
}) => {
  const { customer } = useCustomer()
  const [expanded, setExpanded] = React.useState(!collapsed)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  if (!customer?.addressBook?.billingAddress) {
    return (
      <HeroCardEmpty>
        <Typography fontSize={12}>
          No addresses found for this customer
        </Typography>
      </HeroCardEmpty>
    )
  }

  return (
    <HeroCard
      sx={{
        ...(small && { p: 0 }),

        ...(transparent && {
          backgroundColor: 'rgba(0,0,0,0)',
          border: 0,
          boxShadow: 'unset',
        }),
      }}
    >
      {collapsed && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            size="small"
            onClick={handleExpandClick}
            endIcon={
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon sx={{ fontSize: 16 }} />
              </ExpandMore>
            }
            sx={{ fontSize: 12, textTransform: ' unset' }}
          >
            {expanded ? 'Hide' : 'Show'}{' '}
            {shippingOnly ? 'shipping address' : 'addresses'}
          </Button>
        </Box>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {!shippingOnly && (
          <>
            <HeroCardSection sx={{ m: 0 }}>
              <CustomerAddress
                label="Billing"
                address={customer.addressBook?.billingAddress}
              />
            </HeroCardSection>

            <HeroCardDivider />
          </>
        )}

        <HeroCardSection sx={{ m: 0 }}>
          <CustomerAddress
            label="Shipping"
            address={customer.addressBook?.shippingAddress}
            isShippingSameAsBilling={
              !shippingOnly && customer.addressBook?.isShippingSameAsBilling
            }
          />
        </HeroCardSection>
      </Collapse>
    </HeroCard>
  )
}
