import { Box, LinearProgress, Typography } from '@mui/material'
import { Id, PaymentInfo, Section } from '@trueskin-backoffice/components'
import { useAuth, usePatient } from '@trueskin-backoffice/contexts'
import { PageContentLayout } from '../../layouts/page-content.layout'
import { CaseProvider, useCase } from './contexts'
import { CaseGeneralInformation } from './sub-sections/case-general-information'
import { CaseInvoiceInformation } from './sub-sections/case-invoice-information'

export const CasePage = () => {
  return (
    <CaseProvider>
      <CasePageContent />
    </CaseProvider>
  )
}

const CasePageContent = () => {
  const { isCurrentEmployeeDoctor, isCurrentEmployeeMedicalAssistant } =
    useAuth()
  const { loading: customerLoading } = usePatient()
  const { currentCase, currentCaseInvoice, loading: orderLoading } = useCase()

  if (orderLoading || customerLoading) {
    // TODO: add skeleton
    return <LinearProgress />
  }

  return (
    <PageContentLayout>
      <Typography variant="h1">Case # {currentCase.documentNumber}</Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Id fontSize="1rem">{currentCase?.id}</Id>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {currentCase?.paymentObject && (
            <PaymentInfo
              amount={currentCase?.paymentObject?.invoiceTotal}
              currency={currentCase?.paymentObject?.currency}
              status={currentCase?.paymentObject?.invoiceStatus}
            />
          )}
        </Box>
      </Box>

      <Section>
        <CaseGeneralInformation />
      </Section>

      <Section>
        <CaseInvoiceInformation invoice={currentCaseInvoice} />
      </Section>

      {/*{(isCurrentEmployeeDoctor() || isCurrentEmployeeMedicalAssistant()) && (*/}
      {/*  <Section>*/}
      {/*    <CaseTimeline case={currentCase} />*/}
      {/*  </Section>*/}
      {/*)}*/}

      {/*{(isCurrentEmployeeDoctor() || isCurrentEmployeeMedicalAssistant()) && (*/}
      {/*  <Section>*/}
      {/*    <CaseDiagnosisRecord*/}
      {/*      currentCase={currentCase}*/}
      {/*      diagnosisRecord={currentCase.diagnosisRecord}*/}
      {/*    />*/}
      {/*  </Section>*/}
      {/*)}*/}
    </PageContentLayout>
  )
}
