import pxToRem from '../functions/pxToRem'
import colors from './colors'

const { dark } = colors

const baseProperties = {
  // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily:
    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
}

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightMedium,
}

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontSize: baseProperties.fontSizeLG,
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: baseProperties.fontSizeMD,
    ...baseHeadingProperties,
  },

  h3: {
    fontSize: baseProperties.fontSizeSM,
    ...baseHeadingProperties,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
  },

  body1: {
    fontSize: baseProperties.fontSizeSM,
  },

  caption: {
    color: '#64648c',
  },
}

export default typography
