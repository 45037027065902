import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TagGroupItemProps } from './tag'

export const TagGroup = styled((props: TagGroupProps) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }} {...props}>
      {props.items.map((item) => (
        <>{item}</>
      ))}
    </Box>
  )
})()

export interface TagGroupProps extends BoxProps {
  items: TagGroupItemProps[]
}
