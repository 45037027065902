import { ApolloProvider } from '@apollo/client'
import { bffGqlClient } from '@trueskin-backoffice/api-client'
import { AuthProvider } from '@trueskin-backoffice/contexts'
import i18next from '@trueskin-backoffice/translations'
import { ReactNode } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

export const AppContext = (props: StandardProps) => {
  const { children } = props

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <ApolloProvider client={bffGqlClient}>
          <AuthProvider>{children}</AuthProvider>
        </ApolloProvider>
      </I18nextProvider>
    </BrowserRouter>
  )
}

export interface StandardProps {
  children?: ReactNode
}
