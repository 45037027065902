import { useQuery } from '@apollo/client'
import { createContext, useContext, useMemo, useState } from 'react'
import { getNotionCmsDiagnosisPrefillsQuery } from './gql/notion-cms.gql.bff'

export interface NotionCmsState {
  hideLeftHandSideMenu: boolean
  showTopRightMenu: boolean
}

export interface NotionCmsProps {
  // controller: NotionCmsState
  // // dispatch: React.Dispatch<any>
  // showTopRightMenu: (value: boolean) => void
  loadingNotionCmsPrefills: boolean
  notionCmsDiagnoses: any[]
  notionCmsTreatments: any[]

  injectDiagnosisDetailsContent?: {
    mdContent?: string
    revision?: number
    append?: boolean
  }
  setInjectDiagnosisDetailsContent: (injectDiagnosisDetailsContent: {
    mdContent?: string
    revision?: number
  }) => void

  injectSkinFindingsContent?: {
    mdContent?: string
    revision?: number
    append?: boolean
  }
  setInjectSkinFindingsContent: (injectDiagnosisDetailsContent: {
    mdContent?: string
    revision?: number
  }) => void

  injectAdditionalAdviceDetailsContent?: {
    mdContent?: string
    revision?: number
  }
  setInjectAdditionalAdviceDetailsContent: (injectDiagnosisDetailsContent: {
    mdContent?: string
    revision?: number
  }) => void

  injectTreatmentContent?: {
    mdContent?: string
    revision?: number
    append?: boolean
  }
  setInjectTreatmentContent: (injectDiagnosisDetailsContent: {
    mdContent?: string
    revision?: number
    append?: boolean
  }) => void
}

enum UIControllerActionKind {
  HIDE_LEFT_HAND_SIDE_MENU = 'HIDE_LEFT_HAND_SIDE_MENU',
  SHOW_TOP_RIGHT_MENU = 'SHOW_TOP_RIGHT_MENU',
}

interface UIControllerAction {
  type: UIControllerActionKind
  value: boolean
}

const NotionCmsContext = createContext<NotionCmsProps | null>(null)

function reducer(state: NotionCmsState, action: UIControllerAction) {
  switch (action.type) {
    case 'HIDE_LEFT_HAND_SIDE_MENU': {
      return { ...state, hideLeftHandSideMenu: action.value }
    }
    case 'SHOW_TOP_RIGHT_MENU': {
      return { ...state, showTopRightMenu: action.value }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const NotionCmsProvider = ({ children }: any) => {
  const [injectDiagnosisDetailsContent, setInjectDiagnosisDetailsContent] =
    useState({})
  const [injectSkinFindingsContent, setInjectSkinFindingsContent] = useState({})
  const [
    injectAdditionalAdviceDetailsContent,
    setInjectAdditionalAdviceDetailsContent,
  ] = useState({})
  const [injectTreatmentContent, setInjectTreatmentContent] = useState({})

  const {
    data: { notionCmsDiagnoses, notionCmsTreatments } = {
      notionCmsDiagnoses: [],
      notionCmsTreatments: [],
    },
    loading: loadingNotionCmsPrefills,
    error: errorLoadingNotionCmsPrefills,
  } = useQuery(getNotionCmsDiagnosisPrefillsQuery)
  // const initialState = {
  //   hideLeftHandSideMenu: false,
  //   showTopRightMenu: false,
  // }

  // const [controller, dispatch] = useReducer(reducer, initialState)

  // const showTopRightMenu = useCallback((show: boolean) => {
  //   if (show) {
  //     dispatch({
  //       type: UIControllerActionKind.HIDE_LEFT_HAND_SIDE_MENU,
  //       value: true,
  //     })
  //     dispatch({
  //       type: UIControllerActionKind.SHOW_TOP_RIGHT_MENU,
  //       value: true,
  //     })
  //   } else {
  //     dispatch({
  //       type: UIControllerActionKind.HIDE_LEFT_HAND_SIDE_MENU,
  //       value: false,
  //     })
  //     dispatch({
  //       type: UIControllerActionKind.SHOW_TOP_RIGHT_MENU,
  //       value: false,
  //     })
  //   }
  // }, [])

  // const value = useMemo(
  //   () => ({ controller, dispatch, showTopRightMenu }),
  //   [controller, dispatch, showTopRightMenu],
  // )

  const value = useMemo(
    () => ({
      loadingNotionCmsPrefills,
      notionCmsDiagnoses,
      notionCmsTreatments,
      injectDiagnosisDetailsContent,
      setInjectDiagnosisDetailsContent,
      injectSkinFindingsContent,
      setInjectSkinFindingsContent,
      injectAdditionalAdviceDetailsContent,
      setInjectAdditionalAdviceDetailsContent,
      injectTreatmentContent,
      setInjectTreatmentContent,
    }),
    [
      loadingNotionCmsPrefills,
      notionCmsDiagnoses,
      notionCmsTreatments,
      injectDiagnosisDetailsContent,
      setInjectDiagnosisDetailsContent,
      injectSkinFindingsContent,
      setInjectSkinFindingsContent,
      injectAdditionalAdviceDetailsContent,
      setInjectAdditionalAdviceDetailsContent,
      injectTreatmentContent,
      setInjectTreatmentContent,
    ],
  )

  return (
    <NotionCmsContext.Provider value={value}>
      {children}
    </NotionCmsContext.Provider>
  )
}

export function useNotionCms() {
  const context = useContext(NotionCmsContext)

  if (!context) {
    throw new Error('useNotionCms should be used inside the NotionCmsContext.')
  }

  return context
}
