import AddBoxIcon from '@mui/icons-material/AddBox'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Button,
  Card,
  IconButton,
  LinearProgress,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material'
import { useV2Mutation, useV2Query } from '@trueskin-backoffice/api-client'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { EmployeesAutocomplete } from './employees-autocomplete'
import {
  addManagerToTeamMutation,
  getAllEmployees,
  getAllTeams,
  removeManagerFromTeamMutation,
} from './employees.gql'
import { Employee, Team } from './types'

export const TeamsPage = () => {
  const {
    data: { getAllTeams: teams } = {},
    loading,
    error,
  } = useV2Query(getAllTeams)

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <>
      {teams.map((team: Team) => (
        <Card
          key={team._id}
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            display: 'flex',
            flexGrow: 1,
            overflow: 'unset',
            p: 1,
            m: 1,
          }}
        >
          <Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
              // onClick={() =>
              //   setFilter({
              //     ...filter,
              //     teamId: team._id,
              //   })
              // }
            >
              <Typography>{team?.name}:</Typography>
              <Typography sx={{ ml: 1 }}>
                {team.availableRoles.join(', ')}
              </Typography>
            </Box>
            <Managers ids={team?.managers} teamId={team?._id} />
          </Box>
        </Card>
      ))}
    </>
  )
}

const Managers = ({ ids, teamId }: { ids?: string[]; teamId: string }) => {
  const [manager, setManager] = useState<Employee | undefined>(undefined)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { data, loading, error } = useV2Query(getAllEmployees, {
    variables: {
      offset: 0,
      limit: 100,
      filter: {
        ids,
      },
    },
    skip: !ids,
  })

  const [addManager, { loading: addManagerLoading, error: addManagerError }] =
    useV2Mutation(addManagerToTeamMutation, { refetchQueries: [getAllTeams] })

  const [
    removeManager,
    { loading: removeManagerLoading, error: removeManagerError },
  ] = useV2Mutation(removeManagerFromTeamMutation, {
    refetchQueries: [getAllTeams],
  })

  const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleAddManagerToTeam = async () => {
    await addManager({ variables: { teamId, employeeId: manager?._id } })
    setManager(undefined)
    handleClosePopup()
  }

  const handleRemoveManagerFromTeam = async (employeeId: string) => {
    await removeManager({ variables: { teamId, employeeId } })
  }

  const employees = data?.getAllEmployees?.data

  const open = Boolean(anchorEl)

  const id = open ? 'add-manage-popup' : undefined

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Managers:</Typography>
          <Tooltip title="Add manger">
            <IconButton color="primary" onClick={handleOpenPopup}>
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopup}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box sx={{ width: '30vw', p: 2 }}>
              {addManagerLoading && <LinearProgress />}
              {addManagerError && (
                <Typography color={'red'}>{addManagerError.message}</Typography>
              )}
              <EmployeesAutocomplete
                employee={manager}
                onChange={(v) => setManager(v)}
                filter={{ teamId }}
                noOptionsText="No employees for this team found"
                filterBy={(option) =>
                  !employees.find((e: Employee) => option._id === e._id)
                }
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  onClick={handleAddManagerToTeam}
                  variant="contained"
                  color="primary"
                  disabled={!manager?._id}
                >
                  Add
                </Button>
                <Button
                  onClick={handleClosePopup}
                  sx={{ ml: 3 }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box sx={{ ml: 3 }}>
          {removeManagerLoading && <LinearProgress />}
          {removeManagerError && (
            <Typography color={'red'}>{removeManagerError.message}</Typography>
          )}
          {employees?.length > 0 &&
            employees.map((empl: Employee) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <NavLink to={`${empl._id}`}>
                  <Typography>
                    {empl?.firstName} {empl?.lastName}
                  </Typography>
                </NavLink>
                <Tooltip title="Remove manger">
                  <IconButton
                    onClick={() => handleRemoveManagerFromTeam(empl._id)}
                    disabled={removeManagerLoading || loading}
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  )
}
