import { Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  InputFilterProps,
} from '@trueskin-backoffice/components'
import {
  WebhookSource,
  WebhookStatus,
} from '@trueskin-backoffice/contexts/types'
import React, { useEffect } from 'react'
import { DecodedValueMap, StringParam, useQueryParams } from 'use-query-params'
import { WebhooksTable } from './webhooks.table'

const WEBHOOKS_FILTER_INIT_VALUES = {
  source: StringParam,
  customerId: StringParam,
  status: StringParam,
}

type WebhooksInputFilterProps = Record<
  keyof Pick<WebhooksFilterProps, 'status' | 'source'>,
  InputFilterProps
>

export type WebhooksFilterProps = typeof WEBHOOKS_FILTER_INIT_VALUES

export const WebhooksPage = () => {
  const [query, setQuery] = useQueryParams<WebhooksFilterProps>(
    WEBHOOKS_FILTER_INIT_VALUES,
  )

  useEffect(() => {
    setQuery({ ...query, source: WebhookSource.ADYEN.toUpperCase() })
  }, [])

  return (
    <Box>
      <DataFilter<
        DecodedValueMap<WebhooksFilterProps>,
        WebhooksInputFilterProps
      >
        filter={query}
        onChange={setQuery}
        names={Object.keys(WEBHOOKS_FILTER_INIT_VALUES)}
        inputs={{
          source: {
            component: FilterComponent.SELECT,
            options: Object.keys(WebhookSource).map((source) => ({
              label: source,
              value: source,
            })),
          },
          status: {
            component: FilterComponent.SELECT,
            options: Object.keys(WebhookStatus).map((status) => ({
              label: status,
              value: status,
            })),
          },
        }}
      />

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        <WebhooksTable filter={query} />
      </Card>
    </Box>
  )
}
