import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { getJwt } from './storage.service'

const v2httpLink = createHttpLink({
  uri: process.env['NX_V2_GQL_API_URL'],
})

const bffHttpLink = createHttpLink({
  uri: process.env['NX_BFF_GQL_API_URL'],
})

const tofuHttpLink = createHttpLink({
  uri: process.env['NX_TOFU_GQL_API_URL'],
})

const getWsUrl = () => {
  const url = process.env['NX_TOFU_GQL_API_URL'] || ''
  return url.startsWith('https:')
    ? url.replace('https', 'wss')
    : url.replace('http', 'ws')
}

const tofuWsLink = new GraphQLWsLink(
  createClient({
    url: getWsUrl(),
    connectionParams: {
      authToken: getJwt(),
    },
  }),
)

const authLink = setContext((_, { headers }) => {
  const token = getJwt()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const cache = new InMemoryCache()

export const v2GqlClient = new ApolloClient({
  link: authLink.concat(v2httpLink),
  cache,
  // cache: new InMemoryCache(),
  // connectToDevTools: true,
})

export const bffGqlClient = new ApolloClient({
  link: authLink.concat(bffHttpLink),
  // cache: new InMemoryCache(),
  cache,
  // connectToDevTools: true,
  connectToDevTools: !!process.env['NX_BFF_GQL_CONNECT_DEV_TOOLS'],
})

const tofuLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  tofuWsLink,
  tofuHttpLink,
)

export const tofuGqlClient = new ApolloClient({
  link: tofuLink,
  cache,
})
