import { Typography } from '@mui/material'
import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'

export const WaitingRoomFilter = () => {
  const {
    employee,
    isCurrentEmployeeDoctor,
    isCurrentEmployeeMedicalAssistant,
  } = useAuth()

  if (isCurrentEmployeeMedicalAssistant()) {
    return (
      <DataFilterV2
        inputRows={[
          [
            {
              type: DataFilterV2FilterComponent.TOGGLE,
              key: 'caseDiagnosisStatus',
              options: [
                {
                  value: 'REQUIRES_CONTENT',
                  component: (
                    <>
                      <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                      <Typography sx={{ ml: 1 }}>content</Typography>
                    </>
                  ),
                },
                {
                  value: 'REQUIRES_DOCTOR_REVIEW',
                  component: (
                    <>
                      <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                      <Typography sx={{ ml: 1 }}>review</Typography>
                    </>
                  ),
                },
              ],
            },
            {
              type: DataFilterV2FilterComponent.DIVIDER,
            },

            {
              type: DataFilterV2FilterComponent.TOGGLE,
              key: 'showWatchedCases',
              options: [
                {
                  value: employee?.id,
                  component: (
                    <>
                      <Typography sx={{ fontWeight: 500 }}>Watched</Typography>
                      <Typography sx={{ ml: 1 }}>cases</Typography>
                    </>
                  ),
                },
              ],
            },
            {
              type: DataFilterV2FilterComponent.DIVIDER,
            },
            {
              label: 'Search for patient / case id',
              key: 'id',
              type: DataFilterV2FilterComponent.ID,
            },
            {
              type: DataFilterV2FilterComponent.DIVIDER,
              borderVisible: false,
            },
            {
              label: 'Case document number',
              type: DataFilterV2FilterComponent.TEXT,
              key: 'documentNumber',
              op: DataFilterV2InputOperation.EQUALS,
            },
          ],
        ]}
      />
    )
  }

  return (
    <DataFilterV2
      inputRows={[
        [
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'extendedStatus',
            options: [
              {
                value: 'AWAITING_DIAGNOSIS',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                    <Typography sx={{ ml: 1 }}>diagnosis</Typography>
                  </>
                ),
              },
              {
                value: 'REQUIRES_PATIENT_RESPONSE',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Awaiting</Typography>
                    <Typography sx={{ ml: 1 }}>reply</Typography>
                  </>
                ),
              },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'caseDiagnosisStatus',
            options: [
              {
                value: 'REQUIRES_CONTENT',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                    <Typography sx={{ ml: 1 }}>content</Typography>
                  </>
                ),
              },
              {
                value: 'REQUIRES_DOCTOR_REVIEW',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                    <Typography sx={{ ml: 1 }}>review</Typography>
                  </>
                ),
              },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'followupMessageFromCustomerRequiresFeedback',
            options: [
              {
                value: 'true',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Returning</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'showWatchedCases',
            options: [
              {
                value: employee?.id,
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Watched</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
            ],
          },
          // {
          //   type: DataFilterV2FilterComponent.DIVIDER,
          // },
        ],
        [
          {
            label: 'Search for patient / case id',
            key: 'id',
            type: DataFilterV2FilterComponent.ID,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
          {
            label: 'Case document number',
            type: DataFilterV2FilterComponent.TEXT,
            key: 'documentNumber',
            op: DataFilterV2InputOperation.EQUALS,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
        ],
      ]}
      additionalFilters={[
        {
          label: 'Email',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'email',
          op: DataFilterV2InputOperation.EQUALS,
        },
      ]}
    />
  )
}
