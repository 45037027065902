import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { SupportedLocales } from '@trueskin-backoffice/contexts/types'

export const PresetsFilters = () => (
  <DataFilterV2
    inputRows={[
      [
        {
          label: 'Search for preset id',
          key: '_id',
          type: DataFilterV2FilterComponent.ID,
        },
        {
          type: DataFilterV2FilterComponent.DIVIDER,
          borderVisible: false,
        },
      ],
    ]}
    additionalFilters={[
      {
        label: 'V1 user id',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'v1UserId',
        op: DataFilterV2InputOperation.EQUALS,
      },
      {
        label: 'Tags',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'tags',
        op: DataFilterV2InputOperation.EQUALS,
      },
      {
        label: 'Occurrence',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'occurrence',
        op: DataFilterV2InputOperation.EQUALS,
      },
      {
        label: 'Locales',
        component: DataFilterV2FilterComponent.SELECT,
        key: 'locales',
        op: DataFilterV2InputOperation.EQUALS,
        options: Object.keys(SupportedLocales).map((locale) => ({
          label: SupportedLocales[locale as keyof typeof SupportedLocales],
          value: locale,
        })),
      },
    ]}
  />
)
