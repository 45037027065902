import { Box, Typography } from '@mui/material'
import { HeroCard, HeroCardSection } from '@trueskin-backoffice/components'
import { CreateDraftDiagnosisButton } from './components/create-draft-diagnosis.button'
import { DiagnosisRecordRejectionView } from './components/diagnosis-record-rejection-view'
import { DiagnosisRecordView } from './components/diagnosis-record-view'
import { RejectDiagnosisButton } from './components/reject-diagnosis.button'
import { DiagnosisRecordForm } from './diagnosis-record-form'

export const CaseDiagnosisRecord = ({
  currentCase,
  diagnosisRecord,
  showOnlyCompleted,
}: any) => {
  if (
    diagnosisRecord &&
    showOnlyCompleted &&
    !['completed', 'rejected'].includes(currentCase.status.toLowerCase())
  ) {
    return null
  }

  if (!diagnosisRecord && currentCase.status.toLowerCase() === 'active') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            mb: 2,
          }}
        >
          <HeroCardSection sx={{ mb: 0 }}>
            <CreateDraftDiagnosisButton />
            <Typography variant="caption">
              A draft diagnosis is not immediately available to the patient. To
              make it available to the patient it will have to be published.
            </Typography>
          </HeroCardSection>
        </HeroCard>

        <RejectDiagnosisButton />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DiagnosisRecordView />

        <DiagnosisRecordForm />

        <DiagnosisRecordRejectionView />

        <RejectDiagnosisButton />
      </Box>
    </>
  )
}
