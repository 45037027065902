export enum SubscriptionStatus {
  // CREATED = 'created',
  // ACTIVE = 'active',
  // DUNNING = 'dunning',
  // CANCELLED = 'cancelled',
  // COMPLETED = 'completed',
  // WAITING_PAYMENT = 'waiting_payment',
  // PAUSED = 'paused',

  'created' = 'Created',
  'active' = 'Active',
  'dunning' = 'Dunning',
  'cancelled' = 'Cancelled',
  'completed' = 'Completed',
  'waiting_payment' = 'Waiting payment',
  'paused' = 'Paused',
}

export enum SubscriptionEndBehaviour {
  // RENEW = 'renew',
  // CANCEL = 'cancel',

  'renew' = 'Renew',
  'cancel' = 'Cancel',
}
