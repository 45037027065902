import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

export const HeroCardSection = styled((props: HeroCardSectionProps) => (
  <Box
    sx={{
      mb: 4,
    }}
    {...props}
  >
    {props.children}
  </Box>
))()

export interface HeroCardSectionProps {
  children: ReactNode
}
