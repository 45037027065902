export enum Role {
  DOCTOR = 'DOCTOR',
  MEDICAL_ASSISTANT = 'MEDICAL_ASSISTANT',
  MEDICAL_LEAD = 'MEDICAL_LEAD',
  CUSTOMER_CARE_AGENT = 'CUSTOMER_CARE_AGENT',
  BIZOPS_MANAGER = 'BIZOPS_MANAGER',
  TECH = 'TECH',
  PRODUCT_MANAGER = 'PRODUCT_MANAGER',
  MARKETING_MANAGER = 'MARKETING_MANAGER',
  ADMIN = 'ADMIN',
}

export const ROLE_ID_MAP: Record<Role, string> = {
  [Role.DOCTOR]: 'doctor',
  [Role.MEDICAL_ASSISTANT]: 'medicalassistant',
  [Role.MEDICAL_LEAD]: 'medicalLead',
  [Role.CUSTOMER_CARE_AGENT]: 'customercareagent',
  [Role.BIZOPS_MANAGER]: 'bizopsmanager',
  [Role.TECH]: 'tech',
  [Role.PRODUCT_MANAGER]: 'productmanager',
  [Role.MARKETING_MANAGER]: 'marketingManager',
  [Role.ADMIN]: 'admin',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum Locales {
  BR = 'pt-BR',
  FEMALE = 'de-DE',
}

export interface Team {
  _id: string
  name: string
  availableRoles: Role[]
  managers: string[]
}

export interface RoleDefinition {
  _id: string
  name: string
}

export interface Employee {
  _id: string
  name: string
  firstName: string
  lastName: string
  email: string
  active: boolean
  locales: string[]
  memberOf: Team[]
  roles: Role[]
  profile: {
    doctor: DoctorProfile
    medicalassistant: MedicalAssistantProfile
  }
}

export interface MedicalAssistantProfile {
  leadDoctor: Employee
}

export interface DoctorProfile {
  profileImage: {
    locationUrl: string
  }
  signatureImage: {
    locationUrl: string
  }
  bannerImage: {
    locationUrl: string
  }
  gender: Gender
  title: string
}

export interface EmployeeFilter {
  role?: Role
  teamId?: string
  ids?: string[]
  active?: boolean
  teamless?: boolean
}
