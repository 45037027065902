import { Box, Skeleton } from '@mui/material'
export const OrderPageSkeleton = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Skeleton variant="text" width={350} sx={{ fontSize: '3rem' }} />
        <Skeleton
          variant="text"
          width={150}
          sx={{ fontSize: '3rem' }}
        ></Skeleton>
      </Box>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <Skeleton variant="rectangular" height={250} sx={{ borderRadius: 2 }} />
      </Box>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <Skeleton variant="rectangular" height={250} sx={{ borderRadius: 2 }} />
      </Box>
    </>
  )
}
