import { Typography } from '@mui/material'
import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'

export const CasesFilters = () => {
  const {
    employee,
    isCurrentEmployeeDoctor,
    isCurrentEmployeeMedicalAssistant,
  } = useAuth()

  if (isCurrentEmployeeDoctor()) {
    return <DoctorCasesFilter employee={employee} />
  } else if (isCurrentEmployeeMedicalAssistant()) {
    return <MedicalAssistantCasesFilter employee={employee} />
  }

  return <DefaultCasesFilter />
}

const DoctorCasesFilter = ({ employee }: any) => {
  return (
    <DataFilterV2
      inputRows={[
        [
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'extendedStatus',
            options: [
              {
                value: 'AWAITING_DIAGNOSIS',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                    <Typography sx={{ ml: 1 }}>diagnosis</Typography>
                  </>
                ),
              },
              {
                value: 'REQUIRES_PATIENT_RESPONSE',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Awaiting</Typography>
                    <Typography sx={{ ml: 1 }}>reply</Typography>
                  </>
                ),
              },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'status',
            options: [
              {
                value: 'COMPLETED',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Completed</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
              {
                value: 'REJECTED',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Rejected</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'assignee',
            options: [
              {
                value: 'UNASSIGNED',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Unassigned</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
              {
                value: employee.id,
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Only my</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
            ],
          },
        ],
        [
          {
            label: 'Search for patient / case id',
            key: 'id',
            type: DataFilterV2FilterComponent.ID,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
          {
            label: 'Case document number',
            type: DataFilterV2FilterComponent.TEXT,
            key: 'documentNumber',
            op: DataFilterV2InputOperation.EQUALS,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
        ],
      ]}
      additionalFilters={[
        {
          label: 'Email',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'email',
          op: DataFilterV2InputOperation.EQUALS,
        },
      ]}
    />
  )
}

const MedicalAssistantCasesFilter = ({ employee }: any) => {
  return (
    <DataFilterV2
      inputRows={[
        [
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'caseDiagnosisStatus',
            options: [
              {
                value: 'REQUIRES_CONTENT',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                    <Typography sx={{ ml: 1 }}>content</Typography>
                  </>
                ),
              },
              // {
              //   value: 'REQUIRES_PATIENT_RESPONSE',
              //   component: (
              //     <>
              //       <Typography sx={{ fontWeight: 500 }}>Awaiting</Typography>
              //       <Typography sx={{ ml: 1 }}>reply</Typography>
              //     </>
              //   ),
              // },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          // {
          //   type: DataFilterV2FilterComponent.TOGGLE,
          //   key: 'extendedStatus',
          //   options: [
          //     {
          //       value: 'AWAITING_DIAGNOSIS',
          //       component: (
          //         <>
          //           <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
          //           <Typography sx={{ ml: 1 }}>diagnosis</Typography>
          //         </>
          //       ),
          //     },
          //     {
          //       value: 'REQUIRES_PATIENT_RESPONSE',
          //       component: (
          //         <>
          //           <Typography sx={{ fontWeight: 500 }}>Awaiting</Typography>
          //           <Typography sx={{ ml: 1 }}>reply</Typography>
          //         </>
          //       ),
          //     },
          //   ],
          // },
          // {
          //   type: DataFilterV2FilterComponent.DIVIDER,
          // },
          // {
          //   type: DataFilterV2FilterComponent.TOGGLE,
          //   key: 'status',
          //   options: [
          //     {
          //       value: 'COMPLETED',
          //       component: (
          //         <>
          //           <Typography sx={{ fontWeight: 500 }}>Completed</Typography>
          //           <Typography sx={{ ml: 1 }}>cases</Typography>
          //         </>
          //       ),
          //     },
          //     {
          //       value: 'REJECTED',
          //       component: (
          //         <>
          //           <Typography sx={{ fontWeight: 500 }}>Rejected</Typography>
          //           <Typography sx={{ ml: 1 }}>cases</Typography>
          //         </>
          //       ),
          //     },
          //   ],
          // },
          // {
          //   type: DataFilterV2FilterComponent.DIVIDER,
          // },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'diagnosisContentAssignee',
            options: [
              {
                value: 'UNASSIGNED',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Unassigned</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
              {
                value: employee.id,
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Only my</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
            ],
          },
        ],
        // [
        //   {
        //     label: 'Search for patient / case id',
        //     key: 'id',
        //     type: DataFilterV2FilterComponent.ID,
        //   },
        //   {
        //     type: DataFilterV2FilterComponent.DIVIDER,
        //     borderVisible: false,
        //   },
        //   {
        //     label: 'Case document number',
        //     type: DataFilterV2FilterComponent.TEXT,
        //     key: 'documentNumber',
        //     op: DataFilterV2InputOperation.EQUALS,
        //   },
        //   {
        //     type: DataFilterV2FilterComponent.DIVIDER,
        //     borderVisible: false,
        //   },
        // ],
      ]}
      // additionalFilters={[
      //   {
      //     label: 'Email',
      //     component: DataFilterV2FilterComponent.TEXT,
      //     key: 'email',
      //     op: DataFilterV2InputOperation.EQUALS,
      //   },
      // ]}
    />
  )
}

const DefaultCasesFilter = () => {
  return (
    <DataFilterV2
      inputRows={[
        [
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'extendedStatus',
            options: [
              {
                value: 'AWAITING_DIAGNOSIS',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Requires</Typography>
                    <Typography sx={{ ml: 1 }}>diagnosis</Typography>
                  </>
                ),
              },
              {
                value: 'REQUIRES_PATIENT_RESPONSE',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Awaiting</Typography>
                    <Typography sx={{ ml: 1 }}>reply</Typography>
                  </>
                ),
              },
            ],
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
          },
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'status',
            options: [
              {
                value: 'COMPLETED',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Completed</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
              {
                value: 'REJECTED',
                component: (
                  <>
                    <Typography sx={{ fontWeight: 500 }}>Rejected</Typography>
                    <Typography sx={{ ml: 1 }}>cases</Typography>
                  </>
                ),
              },
            ],
          },
        ],
        [
          {
            label: 'Search for patient / case id',
            key: 'id',
            type: DataFilterV2FilterComponent.ID,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
          {
            label: 'Case document number',
            type: DataFilterV2FilterComponent.TEXT,
            key: 'documentNumber',
            op: DataFilterV2InputOperation.EQUALS,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
        ],
      ]}
      additionalFilters={[
        {
          label: 'Email',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'email',
          op: DataFilterV2InputOperation.EQUALS,
        },
      ]}
    />
  )
}
