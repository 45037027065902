import { useQuery } from '@apollo/client'
import { Box, LinearProgress, Link, Typography } from '@mui/material'
import { getJwt } from '@trueskin-backoffice/api-client'
import { ColumnDef, DataTable, DateTime } from '@trueskin-backoffice/components'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { DecodedValueMap } from 'use-query-params'
import { prescriptionsQuery } from './prescriptions.gql.bff'
import { PrescriptionsFilterProps } from './prescriptions.page'

interface CustomerTableProps {
  filter: DecodedValueMap<PrescriptionsFilterProps>
}

const PRESCRIPTION_STATUSES: Record<string, string> = {
  open: 'Open',
  sent: 'Created',
}

export const PrescriptionsTable = ({ filter }: CustomerTableProps) => {
  const {
    data: { prescriptions = [] } = {},
    loading,
    error,
    refetch,
  } = useQuery(prescriptionsQuery, {})

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'dateResolved',
      header: 'Date resolved',
      cell: ({ getValue }) => {
        return <DateTime dateTime={getValue()} showTime />
      },
    },
    {
      accessorKey: 'subscription.paymentOrder.sequence',
      header: 'Order #',
      cell: ({ getValue }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'healthRecord.user._id',
      header: 'User ID',
      cell: ({ getValue }) => {
        return <Typography variant="caption">{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'subscription.type',
      header: 'Subscription',
      cell: ({ getValue }) => {
        return (
          <Typography sx={{ textDecoration: 'capitalize' }}>
            {getValue()}
          </Typography>
        )
      },
    },
    {
      accessorKey: 'assignee.email',
      header: 'Doctor',
      cell: ({ getValue }) => {
        return <Typography variant="caption">{getValue()}</Typography>
      },
    },
    {
      header: 'Prescription',
      cell: ({ row }) => {
        const consultation = row.original
        const treatmentPlan = consultation.subscription.treatmentPlan
        const productSet = treatmentPlan.productSet
        const cleanser = productSet.products.find(
          (product: any) => product.subType === 'cleanser',
        )

        const moisturizer = productSet.products.find(
          (product: any) => product.subType === 'moisturizer',
        )

        const medical = productSet.products
          .filter((product: any) => product.subType === 'medical')
          .reduce((acc: any[], medicalProduct: any) => {
            const idx = acc.findIndex((it) => it.name === medicalProduct.name)
            if (idx !== -1) {
              acc[idx].quantity++
              return acc
            } else {
              return acc.concat({ name: medicalProduct.name, quantity: 1 })
            }
          }, [])

        // return medical.map((m) => (
        //   <div>{m.quantity > 1 ? `${m.name} x${m.quantity}` : m.name}</div>
        // ));

        return (
          <Box>
            {medical.map((m: any) => (
              <Typography>
                {m.quantity > 1 ? `${m.name} x${m.quantity}` : m.name}
              </Typography>
            ))}
            <Typography>
              {cleanser && cleanser.name ? cleanser.name : ''}
            </Typography>
            <Typography>
              {moisturizer && moisturizer.name ? moisturizer.name : ''}
            </Typography>
          </Box>
        )
      },
    },
    {
      // accessorKey: 'assignee.email',
      header: 'Prescription status',
      cell: ({ row }) => {
        const consultation = row.original
        const treatmentPlan = consultation.subscription.treatmentPlan

        if (treatmentPlan.waitingESignature) {
          return (
            <NavLink to={treatmentPlan.eSignatureProcessURL}>
              Waiting for eSignature
            </NavLink>
            // <Link href={treatmentPlan.eSignatureProcessURL}>
            //   {' '}
            //   Waiting for eSignature
            // </Link>
          )
        }

        if (treatmentPlan.isESigned) {
          return <Typography>Signed by doctor</Typography>
        }

        return (
          <Typography>
            {PRESCRIPTION_STATUSES[treatmentPlan.prescriptionStatus]}
          </Typography>
        )
      },
    },
    {
      accessorKey: 'pharmacyWarehouse',
      header: 'Pharmacy',
      cell: ({ getValue }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'pharmacyWarehouse',
      header: 'Attachment',
      cell: ({ row }) => {
        const consultation = row.original
        const treatmentPlan = consultation.subscription.treatmentPlan

        return (
          <Box>
            {(treatmentPlan.prescriptionStatus === 'sent' ||
              treatmentPlan.unsignedS3FileStatus === 'uploaded') && (
              <Link
                href={`${
                  process.env['REACT_APP_API_URL']
                }/cabinet/treatment-plans/${
                  treatmentPlan.id || treatmentPlan._id
                }/prescription?token=${getJwt()}`}
                color="primary"
                sx={{ textDecoration: 'underline' }}
                target="_blank"
              >
                Unsigned
              </Link>
            )}
            &nbsp;
            {treatmentPlan.isESigned && (
              <Link
                href={`${
                  process.env['REACT_APP_API_URL']
                }/cabinet/treatment-plans/${
                  treatmentPlan.id || treatmentPlan._id
                }/signed-prescription?token=${getJwt()}`}
                color="primary"
                target="_blank"
                sx={{ textDecoration: 'underline' }}
              >
                Signed
              </Link>
            )}
          </Box>
        )
      },
    },
  ]

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Box>
      <DataTable columns={columns} data={prescriptions} isLoading={loading} />
    </Box>
  )
}
