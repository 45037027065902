import { Box } from '@mui/material'
import { Outlet, Route, Routes } from 'react-router-dom'
import { SettingsPage } from './settings.page'

export const SettingsRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path={'/*'}
          element={
            <Box>
              {/*<Box>Settings groups</Box>*/}
              {/*<Box>*/}
              {/*  <CustomerHeader />*/}

              {/*</Box>*/}

              {/*<HealthRecordProvider>*/}
              {/*  <CustomerHeader />*/}
              {/*  <CustomerHealthRecord />*/}
              {/*</HealthRecordProvider>*/}
              {/*<HeroCardEmpty sx={{ minHeight: 40 }}>dadasds</HeroCardEmpty>*/}
              <Outlet />
            </Box>
          }
        >
          {/*<Route path={''} element={<CustomerPage />} />*/}
          <Route path={''} element={<SettingsPage />} />

          <Route path={'*'} element={<Outlet />}>
            <Route path={'filters'} element={<Box>filters</Box>} />
            <Route
              path={'filters/:category'}
              element={<Box>filters category</Box>}
            />
          </Route>
        </Route>
      </Routes>
    </>
  )
}
