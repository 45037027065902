import { useQuery } from '@apollo/client'
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  DateTime,
  H2,
  HeroCard,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
  KeyTag,
  LabeledTag,
  PaymentInfo,
  Section,
  SubscriptionStatus,
  subscriptionStatusDescriptionText,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { useParams } from 'react-router-dom'
import { SubscriptionOccurrenceMap } from '../customer/sub-sections/customer-subscription-summary'
import { getInvoiceById } from '../invoices/invoices.gql'
import { PageContentLayout } from '../layouts/page-content.layout'
import { OrderPriceSummary } from '../order/sub-sections/order-general-information'
import { SubscriptionDock } from './dock/subscription-dock'
import { getSubscriptionById } from './subscription.gql.bff'

export const SubscriptionPage = () => {
  const { customer: currentCustomer } = useCustomer()
  const { subscriptionId } = useParams<{ subscriptionId: string }>()

  const customerId = currentCustomer?.customerId

  const {
    data: { customer } = {},
    loading: loadingSubscription,
    error: errorSubscription,
  } = useQuery(getSubscriptionById, {
    skip: customerId === undefined,
    variables: {
      customerId,
      subscriptionId,
    },
  })

  const subscription = customer?.subscription

  console.log('customer = ', customer)

  // const {
  //   data: { getOrderById: order } = {},
  //   loading: loadingOrder,
  //   error: errorOrder,
  // } = useV2Query(getOrderById, {
  //   skip: loadingSubscription || !customer?.subscription?.orderId,
  //   variables: {
  //     orderId: customer?.subscription?.orderId,
  //   },
  // })

  const {
    data: { getInvoiceById: invoice } = {},
    loading: loadingInvoice,
    error: errorInvoice,
  } = useV2Query(getInvoiceById, {
    // skip: loadingOrder || !order?.invoice?.id,
    skip: !customer?.subscription?.order?._id,
    variables: { invoiceId: customer?.subscription?.order?._id },
  })

  // const loading = loadingSubscription || loadingOrder || loadingInvoice
  const loading = loadingSubscription || loadingInvoice
  // const error = errorSubscription || errorOrder || errorInvoice
  const error = errorSubscription || errorInvoice

  if (loading) {
    return <LinearProgress />
  }

  if (!subscription) {
    return <LinearProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <PageContentLayout
      sidebar={<SubscriptionDock subscription={subscription} />}
    >
      <Section>
        <HeroCard>
          <HeroCardSection sx={{ m: 0 }}>
            <VerticalList>
              <VerticalListRow>
                <VerticalListRowItem fullWidth={true}>
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 500,
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <H2 sx={{ mr: 2 }}>
                      {
                        // @ts-ignore
                        (SubscriptionOccurrenceMap[
                          subscription.occurrence
                        ] as string) || subscription.occurrence
                      }
                    </H2>

                    <SubscriptionStatus
                      subscription={subscription}
                      size="large"
                    />
                  </Box>

                  <HorizontalList>
                    <HorizontalListItem>
                      <Box sx={{ mr: 1 }}>🔑</Box>
                      <Box sx={{ mr: 1 }}>{subscription.id}</Box>
                    </HorizontalListItem>

                    {!!subscription.startDate && (
                      <HorizontalListItem>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          Started on
                        </Typography>
                        <DateTime
                          dateTime={subscription.startDate}
                          tooltipPlacement="top"
                        />
                      </HorizontalListItem>
                    )}

                    {subscription.status !== 'PAUSED' && (
                      <HorizontalListItem>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {subscriptionStatusDescriptionText(subscription)}
                        </Typography>
                        {subscription.status !== 'WAITING_PAYMENT' && (
                          <DateTime
                            dateTime={subscription.endDate}
                            tooltipPlacement="top"
                          />
                        )}
                      </HorizontalListItem>
                    )}
                  </HorizontalList>
                </VerticalListRowItem>
              </VerticalListRow>
            </VerticalList>
          </HeroCardSection>

          {subscription.order && (
            <>
              <Divider />

              <HeroCardSection sx={{ mt: 2, mb: 0 }}>
                <OrderHeader
                  order={subscription.order}
                  customerId={currentCustomer.id}
                />

                <OrderSummary order={subscription.order} />
              </HeroCardSection>
            </>
          )}
        </HeroCard>
      </Section>

      {subscription.upcomingOrder && (
        <Section headerLabel={'Upcoming order'}>
          <HeroCard>
            <OrderHeader
              order={subscription.upcomingOrder}
              customerId={currentCustomer.id}
            />
            <OrderSummary order={subscription.upcomingOrder} />
          </HeroCard>
        </Section>
      )}
    </PageContentLayout>
  )
}

const OrderHeader = ({ order, customerId }: any) => (
  <VerticalList>
    <VerticalListRow>
      <VerticalListRowItem fullWidth={true}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <H2 sx={{ mr: 1 }}>Order</H2>
          {order.sequence && <H2 sx={{ mr: 1 }}># {order.sequence}</H2>}
          <KeyTag
            id={order.id}
            linkTo={`/customers/${customerId}/orders/${order.id}`}
          />
        </Box>
      </VerticalListRowItem>

      <VerticalListRowItem>
        <PaymentInfo status={order.payment.status} />
      </VerticalListRowItem>
    </VerticalListRow>
  </VerticalList>
)

const OrderSummary = ({ order }: any) => {
  const { customer } = useCustomer()
  return (
    <VerticalList>
      <VerticalListRow>
        <VerticalListRowItem sx={{ py: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <LabeledTag label="Preset">{order.preset}</LabeledTag>
              <LabeledTag label="Occurrence">{order.occurrence}</LabeledTag>
            </Box>

            <Box>
              {order.items.map((item: any) => (
                <LabeledTag label={`${item.quantity}x`}>
                  {item.product}
                </LabeledTag>
              ))}
            </Box>
          </Box>
        </VerticalListRowItem>

        <VerticalListRowItem sx={{ py: 0, minWidth: 180 }}>
          <Paper sx={{ p: 2, backgroundColor: grey['50'] }}>
            <OrderPriceSummary order={order} />
          </Paper>
        </VerticalListRowItem>
      </VerticalListRow>
    </VerticalList>
  )
}

// const CancelSubscriptionButton = ({ subscription, invoice }: any) => {
//   const [opened, setOpened] = useState(false)
//   const [
//     scheduleToCancel,
//     { loading: scheduleToCancelLoading, error: scheduleToCancelError },
//   ] = useV2Mutation(scheduleSubscriptionToCancelMutation, {
//     variables: { subscriptionId: subscription?.id },
//     refetchQueries: [getSubscriptionById],
//   })
//
//   const [
//     cancelImmediately,
//     { loading: cancelImmediatelyLoading, error: cancelImmediatelyError },
//   ] = useV2Mutation(cancelSubscriptionImmediatelyMutation, {
//     variables: { subscriptionId: subscription?.id },
//     refetchQueries: [getSubscriptionById],
//   })
//
//   const loading = scheduleToCancelLoading || cancelImmediatelyLoading
//   const error = scheduleToCancelError || cancelImmediatelyError
//
//   const active = subscription?.status?.toLowerCase() === 'active'
//   const cancelOnEnd = subscription?.endBehaviour?.toLowerCase() === 'cancel'
//   const dunning =
//     invoice?.status?.toLowerCase() === 'payment_due' &&
//     invoice.dunningStatus?.toLowerCase() === 'in_progress'
//   const paused = subscription?.status?.toLowerCase() === 'paused'
//
//   const options = useMemo(
//     () =>
//       [
//         {
//           rule: active && !cancelOnEnd,
//           action: 'Cancel at end of term',
//           onSubmit: scheduleToCancel,
//         },
//         {
//           rule: active || dunning || paused,
//           action: 'Cancel immediately',
//           onSubmit: cancelImmediately,
//         },
//       ].filter((ao) => ao.rule),
//     [subscription],
//   )
//
//   return (
//     <>
//       {options.length > 0 && (
//         <Button sx={{ mx: 1 }} onClick={() => setOpened(true)}>
//           Change cancellation date
//         </Button>
//       )}
//       <ConfirmActionDialog
//         loading={loading}
//         error={error?.message}
//         title="Submit cancel action"
//         onClose={() => setOpened(false)}
//         opened={opened}
//         confirmText={
//           dunning
//             ? [
//                 'Submitting this will affect the customers cancellation date. Select to confirm you want to make the changes?',
//                 'The current invoice is in dunning. Cancelling the subscription will also void the invoice and stop dunning process',
//               ]
//             : [
//                 'Submitting this will affect the customers cancellation date. Select to confirm you want to make the changes?',
//               ]
//         }
//         options={options}
//       />
//     </>
//   )
// }
// const RenewSubscriptionButton = ({ subscription }: any) => {
//   const [opened, setOpened] = useState(false)
//
//   const [
//     scheduleToRenew,
//     { loading: scheduleToRenewLoading, error: scheduleToRenewError },
//   ] = useV2Mutation(scheduleSubscriptionToRenewMutation, {
//     variables: { subscriptionId: subscription?.id },
//     refetchQueries: [getSubscriptionById],
//   })
//
//   const [
//     renewImmediately,
//     { loading: renewImmediatelyLoading, error: renewImmediatelyError },
//   ] = useV2Mutation(renewSubscriptionImmediatelyMutation, {
//     variables: { subscriptionId: subscription?.id },
//     refetchQueries: [getSubscriptionById],
//   })
//
//   const loading = scheduleToRenewLoading || renewImmediatelyLoading
//
//   const error = scheduleToRenewError || renewImmediatelyError
//
//   const once = subscription?.occurrence === 'occ_once'
//   const cancelOnEnd = subscription?.endBehaviour?.toLowerCase() === 'cancel'
//   const paused = subscription?.status?.toLowerCase() === 'paused'
//
//   const options = useMemo(
//     () =>
//       [
//         {
//           rule: !once && cancelOnEnd && !paused,
//           action: 'Renew at end of term',
//           onSubmit: scheduleToRenew,
//         },
//         {
//           rule: !once && !paused,
//           action: 'Renew immediately',
//           onSubmit: renewImmediately,
//         },
//       ].filter((ao) => ao.rule),
//     [subscription],
//   )
//
//   return (
//     <>
//       {options.length > 0 && (
//         <Button sx={{ mx: 1 }} onClick={() => setOpened(true)}>
//           Change renewal date
//         </Button>
//       )}
//       <ConfirmActionDialog
//         loading={loading}
//         error={error?.message}
//         title="Submit renewal action"
//         onClose={() => setOpened(false)}
//         opened={opened}
//         confirmText={[
//           'Submitting this will affect the customers renewal date. Select to confirm you want to make the changes?',
//         ]}
//         options={options}
//       />
//     </>
//   )
// }
