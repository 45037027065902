import { Add, Search } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Popper,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { HeroCardDivider, Restricted } from '@trueskin-backoffice/components'
import { useNotionCms } from '@trueskin-backoffice/contexts'
import React, { useRef, useState } from 'react'
import { MedicalDiagnosisInput } from './medical-diagnosis.input'

export const AddDiagnosisButton2 = ({
  type,
  label,
  showTopDivider,
  required,
  disabled,
}: {
  type: string
  label: string
  showTopDivider: boolean
  required?: boolean
  disabled?: boolean
}) => {
  const { notionCmsDiagnoses } = useNotionCms()

  const addDiagnosisBtnRef = useRef(null)
  const selectDiagnosisPrefillBtnRef = useRef(null)
  const [prefill, setPrefill] = useState<object | null>(null)

  const [showAddDiagnosisPopup, setShowAddDiagnosisnPopup] =
    useState<boolean>(false)
  const [showSelectDiagnosisPrefillPopup, setShowSelectDiagnosisPrefillPopup] =
    useState<boolean>(false)

  const handleAddDiagnosisClick = (event: React.MouseEvent<HTMLElement>) => {
    if (showAddDiagnosisPopup) {
      handleClose()
    } else {
      setShowAddDiagnosisnPopup(true)
    }
  }

  const handleSearchDiagnosisPrefillsClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    if (showSelectDiagnosisPrefillPopup) {
      setShowSelectDiagnosisPrefillPopup(false)
      setPrefill(null)
    } else {
      setShowSelectDiagnosisPrefillPopup(true)
    }
  }

  const handleClose = () => {
    setShowAddDiagnosisnPopup(false)
    setShowSelectDiagnosisPrefillPopup(false)
    setPrefill(null)
  }

  const handlePrefillSelected = (event: any, newValue: any) => {
    console.log('prefill = ', newValue)
    setPrefill({
      prefillId: newValue._id,
      name: newValue.diagnosis,
      recommendOfflineConsultation: newValue.recommendOfflineVisit,
      icd10Diagnosis: newValue.icd10,
      additionalDirections: newValue.additionalDirections,
      candidateForCore: newValue.candidateForCore,
    })

    setShowSelectDiagnosisPrefillPopup(false)
    setShowAddDiagnosisnPopup(true)
  }

  return (
    <Restricted to={['doctor']}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {showTopDivider && <HeroCardDivider />}

        <Box
          sx={{
            color: '#3367d6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 12,
            cursor: 'pointer',
            borderRadius: 1,
            width: '100%',
          }}
        >
          <Box>
            <Button
              size="small"
              onClick={handleAddDiagnosisClick}
              disabled={disabled}
              ref={addDiagnosisBtnRef}
            >
              <Add /> <Typography variant="caption">{label}</Typography>
              {required && (
                <Typography variant="caption" color="error">
                  *
                </Typography>
              )}
            </Button>

            <Popper
              open={showAddDiagnosisPopup}
              anchorEl={addDiagnosisBtnRef.current}
              placement="bottom"
              role={undefined}
              sx={{ zIndex: 2 }}
            >
              <Paper sx={{ p: 2 }}>
                <MedicalDiagnosisInput
                  onClose={handleClose}
                  type={type}
                  conditionDiagnosis={prefill}
                />
              </Paper>
            </Popper>
          </Box>

          <Box>
            <Button
              startIcon={<Search />}
              sx={{ p: 0, m: 0, minWidth: 'unset' }}
              onClick={handleSearchDiagnosisPrefillsClick}
              ref={selectDiagnosisPrefillBtnRef}
            ></Button>

            <Popper
              open={showSelectDiagnosisPrefillPopup}
              anchorEl={selectDiagnosisPrefillBtnRef.current}
              role={undefined}
              placement="bottom"
            >
              <Paper sx={{ minWidth: 300 }}>
                <Autocomplete
                  fullWidth
                  id="diagnosis-prefills"
                  size="small"
                  options={notionCmsDiagnoses || []}
                  getOptionLabel={(option: any) => option._id}
                  onChange={handlePrefillSelected}
                  autoComplete
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      {...params}
                      label="Search diagnosis prefills"
                    />
                  )}
                />
              </Paper>
            </Popper>
          </Box>
        </Box>
      </Box>
    </Restricted>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,
  minWidth: 400,
  padding: 8,
  zIndex: 2,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))
