import { useQuery } from '@apollo/client'
import { CheckCircle, ContentCopy } from '@mui/icons-material'
import {
  Box,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  ColumnDef,
  DataTable,
  DateTime,
  IdTag,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getAllPatients } from './patients.gql.bff'

const DEFAULT_PAGE_SIZE = 25

const PatientIdLink = ({ customerId }: any) => {
  const { getCustomerLink } = useAuth()
  const [copied, setCopied] = useState(false)

  const handleClipboardCopy = (e: any) => {
    e.preventDefault()
    navigator.clipboard.writeText(customerId)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!copied ? (
        <IconButton size="small" onClick={handleClipboardCopy}>
          <ContentCopy fontSize="inherit" />
        </IconButton>
      ) : (
        <Tooltip title="Copied" placement="top">
          <IconButton size="small" color="primary">
            <CheckCircle fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      <NavLink to={getCustomerLink(customerId)}>
        <Typography fontSize={11}>{customerId}</Typography>
      </NavLink>
    </Box>
  )
}

// TODO: Aggregate customers data with subscriptions
export const PatientsTable = () => {
  const { getCustomerLink } = useAuth()
  const columns = useMemo(
    () => patientsTableColumns(getCustomerLink),
    [getCustomerLink],
  )
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      patients: { rows: patients = [], totalRows: totalPatients = 0 } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getAllPatients, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalPatients / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={patients}
        isLoading={loading}
        page={currentPage}
        totalPages={100}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const patientsTableColumns = (getCustomerLink: any): ColumnDef<any, any>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    cell: ({ getValue }) => {
      return (
        <IdTag
          id={getValue()}
          linkTo={getCustomerLink(getValue())}
          allowCopyToClipboard
        />
      )
    },
  },
  {
    header: 'Email',
    accessorKey: 'profile.email',
    cell: ({ getValue }) => {
      return (
        <div>
          <Typography fontSize={11}>{getValue()}</Typography>
        </div>
      )
    },
  },
  {
    accessorKey: 'profile.firstName',
    header: 'First Name',
  },
  {
    accessorKey: 'profile.lastName',
    header: 'Last Name',
  },
  {
    accessorKey: 'registrationDate',
    header: 'Registration Date',
    cell: ({ getValue }) => {
      return (
        <Box>
          <DateTime dateTime={getValue()} tooltipPlacement="right" />
        </Box>
      )
    },
  },
  // {
  //   accessorKey: 'medicalData.birthdate',
  //   header: 'Bi Date',
  //   cell: ({ getValue }) => {
  //     return (
  //       <Box>
  //         <DateTime dateTime={getValue()} tooltipPlacement="right" />
  //       </Box>
  //     )
  //   },
  // },
]
