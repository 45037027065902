import { Box, LinearProgress, Typography } from '@mui/material'
import { HeroCardEmpty, Section } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { PageContentLayout } from '../layouts/page-content.layout'
import { CustomerMutationsProvider } from './contexts'
import { CustomerDock } from './dock/customer-dock'
import { CustomerAddressesSummary } from './sub-sections/customer-addresses-summary'
import { CustomersOrdersSummary } from './sub-sections/customer-orders-summary'
import { CustomerPaymentMethodsSection } from './sub-sections/customer-payment-methods-summary'
import { CustomerReferral } from './sub-sections/customer-referral'
import { CustomerSettings } from './sub-sections/customer-settings'
import { CustomerSubscriptionSection } from './sub-sections/customer-subscription-summary'
import { CustomerSummarySection } from './sub-sections/customer-summary'

const CustomerInfo = () => {
  return (
    <Box>
      <CustomerSummarySection />

      <CustomerSubscriptionSection />

      <Section headerLabel={'Addresses'}>
        <CustomerAddressesSummary />
      </Section>

      <CustomerPaymentMethodsSection />

      <Section headerLabel={'Orders history'}>
        <CustomersOrdersSummary />
      </Section>

      <CustomerReferral />

      <CustomerMutationsProvider>
        <Section>
          <CustomerSettings />
        </Section>
      </CustomerMutationsProvider>

      {/*<Section headerLabel={'Comments'}>*/}
      {/*  <CustomerComments />*/}
      {/*</Section>*/}

      {/*<Section headerLabel={'Activity log'}>*/}
      {/*  <CustomerInfoEmptyCard />*/}
      {/*</Section>*/}
    </Box>
  )
}

const CustomerInfoEmptyCard = () => {
  return <HeroCardEmpty sx={{ minHeight: 40 }}> </HeroCardEmpty>
}

export const CustomerPage = () => {
  const { loading, error } = useCustomer()

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    // @ts-ignore
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <PageContentLayout sidebar={<CustomerDock />}>
      <CustomerInfo />
    </PageContentLayout>
  )
}
