import { styled, Typography, TypographyProps } from '@mui/material'

export const H1 = (props: any) => (
  <Typography variant="h1" {...props}>
    {props.children}
  </Typography>
)

export const H2 = styled((props: TypographyProps) => (
  <Typography variant="h2" {...props} />
))()

export const H3 = (props: any) => (
  <Typography variant="h3" {...props}>
    {props.children}
  </Typography>
)
