import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { useV2Mutation, useV2Query } from '@trueskin-backoffice/api-client'
import { H2, MultiPick } from '@trueskin-backoffice/components'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import * as Yup from 'yup'
import { CONCURRENT_ROLES } from './constants'
import { FormSection } from './employee.components'
import {
  createEmployeeMutation,
  getAllRoles,
  getAllTeams,
} from './employees.gql'
import { Locales, Role, RoleDefinition, Team } from './types'

const INITIAL_VALUES = {
  locales: [],
  memberOf: [],
  roles: [],
  firstName: '',
  lastName: '',
  email: '',
}

export const CreateEmployeePage = () => {
  const [
    createEmployee,
    { data, loading: createEmployeeLoading, error: createEmployeeError },
  ] = useV2Mutation(createEmployeeMutation)

  const {
    data: teamsData,
    loading: teamsLoading,
    error: teamsError,
  } = useV2Query(getAllTeams)

  const {
    data: rolesData,
    loading: rolesLoading,
    error: rolesError,
  } = useV2Query(getAllRoles)

  const teams: Team[] = teamsData?.getAllTeams
  const roles: RoleDefinition[] = rolesData?.getAllRoles

  const formik = useFormik<{
    locales: Locales[]
    roles: RoleDefinition[]
    memberOf: Team[]
    firstName: string
    lastName: string
    email: string
  }>({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) =>
      createEmployee({ variables: { employee: values } }),
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, 'Should contain alt least 2 symbols')
        .matches(/^[A-Za-z ]*$/, 'Please enter valid firstName')
        .required('Required'),
      lastName: Yup.string()
        .min(2, 'Should contain alt least 2 symbols')
        .matches(/^[A-Za-z ]*$/, 'Please enter valid lastName')
        .required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
  })

  const rolesValues = useMemo(
    () =>
      roles?.filter((r) =>
        formik.values?.roles?.find((vr) => vr._id === r._id),
      ) || [],
    [formik.values?.roles, roles],
  )

  const concurrentRoles = useMemo(
    () =>
      CONCURRENT_ROLES.reduce((acc, [first, second]) => {
        if (rolesValues.find((rv) => rv._id === first)) {
          acc.push(second)
        } else if (rolesValues.find((rv) => rv._id === second)) {
          acc.push(first)
        }
        return acc
      }, [] as string[]),
    [rolesValues],
  )

  const rolesOptions = useMemo(
    () =>
      roles?.filter(
        (r) =>
          formik.values.memberOf
            .flatMap((m) => m.availableRoles)
            .includes(r._id as Role) &&
          !concurrentRoles.find((cr) => r._id === cr),
      ) || [],
    [concurrentRoles, formik.values.memberOf, roles],
  )

  if (data?.createEmployee?._id) {
    return <Navigate to={`/employees/${data?.createEmployee?._id}`} />
  }

  const loading = createEmployeeLoading || teamsLoading || rolesLoading
  const error = createEmployeeError || teamsError || rolesError

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 10 }}>
      <Box sx={{ m: 2 }}>
        <H2>Create Employee</H2>
      </Box>
      <Card sx={{ p: 3 }}>
        {loading && <LinearProgress />}
        {error && <Typography color={'red'}>{error.message}</Typography>}
        <Grid container spacing={2} alignItems="center">
          <FormSection
            label="First Name"
            value={
              <TextField
                sx={{ width: '100%' }}
                value={formik.values.firstName}
                onChange={(e) =>
                  formik.setFieldValue('firstName', e.target.value)
                }
                onBlur={() => formik.setFieldTouched('firstName', true)}
                error={Boolean(
                  formik.errors.firstName && formik.touched.firstName,
                )}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            }
          />
          <FormSection
            label="Last Name"
            value={
              <TextField
                sx={{ width: '100%' }}
                value={formik.values.lastName}
                onChange={(e) => {
                  formik.setFieldValue('lastName', e.target.value)
                }}
                onBlur={() => formik.setFieldTouched('lastName', true)}
                error={Boolean(
                  formik.errors.lastName && formik.touched.lastName,
                )}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            }
          />
          <FormSection
            label="Email"
            value={
              <TextField
                sx={{ width: '100%' }}
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                onBlur={() => formik.setFieldTouched('email', true)}
                error={Boolean(formik.errors.email && formik.touched.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            }
          />

          <FormSection
            label="Locales"
            value={
              <MultiPick<Locales>
                values={formik.values.locales}
                options={Object.values(Locales)}
                onAdd={(item) =>
                  formik.setFieldValue('locales', [
                    item,
                    ...formik.values.locales,
                  ])
                }
                onDelete={(item) =>
                  formik.setFieldValue(
                    'locales',
                    formik.values.locales.filter((p) => p !== item),
                  )
                }
              />
            }
          />

          <FormSection
            label="Teams"
            value={
              <MultiPick<Team>
                values={formik.values.memberOf}
                accessor="name"
                options={teams}
                onAdd={(item) =>
                  formik.setFieldValue('memberOf', [
                    item,
                    ...formik.values.memberOf,
                  ])
                }
                onDelete={({ _id: prevId }) =>
                  formik.setFieldValue(
                    'memberOf',
                    formik.values.memberOf.filter(({ _id }) => _id !== prevId),
                  )
                }
              />
            }
          />

          <FormSection
            label="Roles"
            value={
              <MultiPick<RoleDefinition>
                values={formik.values.roles}
                accessor="name"
                options={rolesOptions}
                onAdd={(item) =>
                  formik.setFieldValue('roles', [item, ...formik.values.roles])
                }
                onDelete={({ _id: prevId }) =>
                  formik.setFieldValue(
                    'roles',
                    formik.values.roles.filter(({ _id }) => _id !== prevId),
                  )
                }
              />
            }
          />
        </Grid>
      </Card>
      <Button
        variant="contained"
        sx={{ alignSelf: 'center', m: 2 }}
        onClick={formik.submitForm}
        disabled={Object.keys(formik.errors).length > 0}
      >
        Create
      </Button>
    </Box>
  )
}
