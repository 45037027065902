import borders from '../../base/borders'
import boxShadows from '../../base/box-shadows'

const { borderRadius } = borders
const { md } = boxShadows

const card = {
  styleOverrides: {
    root: {
      borderRadius: borderRadius.sm,
      boxShadow: md,
    },
  },
}

export default card
