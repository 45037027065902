import { teal } from '@mui/material/colors'

const colors = {
  background: {
    // default: '#f8f9fa',
    default: '#eaeaf4',
  },

  text: {
    main: '#67748e',
    focus: '#67748e',
  },

  transparent: {
    main: 'transparent',
  },

  white: {
    main: '#ffffff',
    focus: '#ffffff',
  },

  black: {
    light: '#141414',
    main: '#000000',
    focus: '#000000',
  },

  primary: {
    main: teal[500],
    // main: '#cb0c9f',
    // focus: '#ad0a87',
  },

  secondary: {
    main: '#8392ab',
    focus: '#96a2b8',
  },

  info: {
    // main: '#17c1e8',
    // focus: '#3acaeb',
    main: '#0052cc',
  },

  success: {
    main: '#82d616',
    focus: '#95dc39',
  },

  warning: {
    main: '#fbcf33',
    focus: '#fcd652',
  },

  error: {
    main: '#ea0606',
    focus: '#c70505',
  },

  light: {
    main: '#e9ecef',
    focus: '#e9ecef',
  },

  dark: {
    main: '#344767',
    focus: '#344767',
  },

  grey: {
    100: '#f8f9fa',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529',
  },
  //
  // gradients: {
  //   primary: {
  //     main: '#7928ca',
  //     state: '#ff0080',
  //   },
  //
  //   secondary: {
  //     main: '#627594',
  //     state: '#a8b8d8',
  //   },
  //
  //   info: {
  //     main: '#2152ff',
  //     state: '#21d4fd',
  //   },
  //
  //   success: {
  //     main: '#17ad37',
  //     state: '#98ec2d',
  //   },
  //
  //   warning: {
  //     main: '#f53939',
  //     state: '#fbcf33',
  //   },
  //
  //   error: {
  //     main: '#ea0606',
  //     state: '#ff667c',
  //   },
  //
  //   light: {
  //     main: '#ced4da',
  //     state: '#ebeff4',
  //   },
  //
  //   dark: {
  //     main: '#141727',
  //     state: '#3a416f',
  //   },
  // },
  custom: {
    created: '#F99E00',
    in_progress: '#00A6F9',
    succeeded: '#54A813',
    failed: '#D74637',
    action_required: '#D74637',
    deleted: '#555',

    // invoice.status
    payment_due: '#00A6F9',
    paid: '#54A813',
    upcoming: '#dbba11',
    open: '#F99E00',
    cancelled: '#D74637',
    not_paid: '#D74637',
    voided: '#555',

    // subscription.status
    active: '#54A813',
    waiting_payment: '#00A6F9',
    dunning: '#E2E914',
    completed: '#37d79a',
    paused: '#E2E914',

    // subscription.endBehaviour
    cancel: '#F99E00',
    renew: '#54A813',

    // order.status
    partially_paid: '#27cfe5',
  },
}

export default colors
