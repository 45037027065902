import React, { ReactNode } from 'react'
import { Lightbox } from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

interface StandardProps {
  images?: any
  children?: ReactNode
}

export interface CarouselContextProps {
  images?: any
  openCarousel: (slideIndex: number) => void
}

const CarouselContext = React.createContext<CarouselContextProps | undefined>(
  undefined,
)

function CarouselProvider({ images, children }: StandardProps) {
  const [showCarouselAtIndex, setShowCarouselAtIndex] = React.useState(-1)
  const openCarousel = React.useCallback(
    (slideIndex: number) => setShowCarouselAtIndex(slideIndex),
    [],
  )
  const value = React.useMemo(
    () => ({
      images,
      openCarousel,
    }),
    [images],
  )

  return (
    <CarouselContext.Provider value={value}>
      {showCarouselAtIndex !== -1 && (
        <Lightbox
          open={showCarouselAtIndex !== -1}
          close={() => setShowCarouselAtIndex(-1)}
          slides={images.map((image: any) => ({
            src: image.url,
            title: image?.bodyParts?.length
              ? 'Body parts: ' + image.bodyParts.join(',')
              : null,
          }))}
          plugins={[Captions, Fullscreen, Zoom, Thumbnails]}
          animation={{ fade: 0, swipe: 0, zoom: 0 }}
          carousel={{
            finite: true,
          }}
          index={showCarouselAtIndex}
          controller={{ closeOnBackdropClick: true }}
          zoom={{
            scrollToZoom: true,
          }}
          thumbnails={{ vignette: false }}
        />
      )}
      {children}
    </CarouselContext.Provider>
  )
}

function useCarousel(): CarouselContextProps {
  const context = React.useContext(CarouselContext)
  if (context === undefined) {
    throw new Error(`useCarousel must be used within a CarouselProvider`)
  }
  return context
}

export { CarouselProvider, useCarousel }
