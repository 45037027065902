import { Box, Typography } from '@mui/material'
import {
  AddressType,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'

export const PatientAddress = ({
  address,
  label,
  isShippingSameAsBilling,
}: any) =>
  address ? (
    <VerticalList>
      <VerticalListRow sx={{ display: 'flex', alignItems: 'baseline' }}>
        <VerticalListRowItem>
          <AddressType label={label} />
        </VerticalListRowItem>

        {isShippingSameAsBilling ? (
          <VerticalListRowItem fullWidth={true}>
            The shipping address is the same as the billing address
          </VerticalListRowItem>
        ) : (
          <>
            <VerticalListRowItem fullWidth={true}>
              <Box component="span" sx={{ fontWeight: 500 }}>
                {address.firstName} {address.lastName}{' '}
              </Box>

              <Box>
                <Typography variant="caption">{address.phoneNumber}</Typography>
              </Box>

              <Box>
                {address.address}, {address.postalCode}, {address.city},{' '}
                {address.country}
              </Box>
            </VerticalListRowItem>
          </>
        )}
      </VerticalListRow>
    </VerticalList>
  ) : null
