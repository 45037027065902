import { useQuery } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getProducts } from './products.gql.bff'

const DEFAULT_PAGE_SIZE = 25

export const ProductsTable = () => {
  const columns = useMemo(() => productsTableColumn(), [])
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: productsData,
    loading,
    error,
    refetch,
  } = useQuery(getProducts, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
    fetchPolicy: 'no-cache',
  })

  const products = productsData?.products?.rows ?? []
  const total = productsData?.products?.totalRows ?? 0

  const totalPages = Math.ceil(total / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={products}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const productsTableColumn = (): ColumnDef<any, any>[] => [
  {
    header: 'Id',
    accessorKey: 'id',
    cell: ({ getValue, row }) => {
      return (
        <div>
          <NavLink to={`/products/${row?.original?.id}`}>
            <Typography fontSize={13}>{getValue()}</Typography>
          </NavLink>
        </div>
      )
    },
  },
  {
    header: 'Locales',
    accessorKey: 'locales',
    cell: ({ getValue, row }) => {
      return (
        <div>
          {getValue().map((locale: string) => (
            <NavLink to={`/product-locales/${row?.original?.id}/${locale}`}>
              <Typography fontSize={13}>{locale}</Typography>
            </NavLink>
          ))}
        </div>
      )
    },
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    cell: ({ getValue }) => {
      return <Typography fontSize={13}>{getValue().join(', ')}</Typography>
    },
  },
  {
    header: 'Rules',
    accessorKey: 'rules',
    cell: ({ getValue }) => {
      return (
        <div>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>Name:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>Prods: </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>Number:</Typography>
            </Grid>
          </Grid>
          {getValue().map(
            (rule: { name: string; number: number; prods: string[] }) => (
              <Grid container spacing={1} sx={{ mb: 1 }}>
                <Grid item xs={4}>
                  {rule.name}
                </Grid>
                <Grid item xs={4}>
                  {rule?.prods?.join(', ')}
                </Grid>
                <Grid item xs={4}>
                  {rule?.number || ''}
                </Grid>
              </Grid>
            ),
          )}
        </div>
      )
    },
  },
]
