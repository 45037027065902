import { useQuery } from '@apollo/client'
import { Box, Button, Modal, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  IdTag,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import JsonView from 'react-json-view'
import { getPresets } from './presets.gql.bff'

const DEFAULT_PAGE_SIZE = 25

export const PresetsTable = () => {
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const [openModal, setOpenModal] = useState(false)
  const [json, setJson] = useState({})

  const handleCloseModal = () => {
    setJson('')
    setOpenModal(false)
  }

  const columns = useMemo(() => {
    const handleOpenModal = (jsonData: any) => (event: any) => {
      event.stopPropagation()
      setJson(jsonData)
      setOpenModal(true)
    }

    return presetsTableColumns(handleOpenModal)
  }, [])

  const {
    data: {
      presets: { rows: presets = [], totalRows: totalPresets = 0 } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getPresets, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
    fetchPolicy: 'no-cache',
  })

  const totalPages = Math.ceil(totalPresets / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'scroll',
            height: '80%',
            display: 'block',
          }}
        >
          <JsonView src={json} />
        </Box>
      </Modal>

      <DataTable
        columns={columns}
        data={presets}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const presetsTableColumns = (handleOpenModal: any): ColumnDef<any, any>[] => [
  {
    header: 'Id',
    accessorKey: '_id',
    cell: ({ getValue, row }) => {
      return (
        <IdTag
          id={getValue()}
          linkTo={`/presets/${row?.original?._id}`}
          allowCopyToClipboard
          hideKeyIcon
        />
      )
    },
  },
  {
    header: 'Occurrence',
    accessorKey: 'occurrence',
    cell: ({ getValue, row }) => {
      return <Typography fontSize={13}>{getValue()}</Typography>
    },
  },
  {
    header: 'Locales',
    accessorKey: 'locales',
    cell: ({ getValue }) => {
      return <Typography fontSize={13}>{getValue().join(', ')}</Typography>
    },
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    cell: ({ getValue }) => {
      return <Typography fontSize={13}>{getValue().join(', ')}</Typography>
    },
  },
  {
    header: 'Actions',
    cell: ({ row }: any) => {
      return (
        <Box>
          <Button onClick={handleOpenModal(row.original)}>View JSON</Button>
        </Box>
      )
    },
  },
]
