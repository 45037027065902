import { useMutation } from '@apollo/client'
import { Error } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import {
  DateTime,
  HeroCardEmpty,
  HorizontalList,
  HorizontalListItem,
  UserGeneratedHtml,
  VerticalList,
  VerticalListRow,
} from '@trueskin-backoffice/components'
import {
  NotionCmsQuestionsProvider,
  useAuth,
} from '@trueskin-backoffice/contexts'
import { CaseImages } from './case-images'
import { CaseMessageBox } from './case-message.input'
import { ConsultationQuestionnaire } from './case-questionnaire.timeline-item'
import { caseDoesNotRequireMedicalReplyMutation } from './waiting-room.gql.bff'

const MessageAuthor = ({ messageAuthor }: any) => {
  if (messageAuthor.type === 'customer') {
    return (
      <HorizontalListItem>
        <Typography variant="caption" sx={{ mr: 1 }}>
          By
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: 700 }}>
          customer
        </Typography>
      </HorizontalListItem>
    )
  }

  return (
    <HorizontalListItem>
      <Typography variant="caption" sx={{ mr: 1 }}>
        By
      </Typography>
      <Typography variant="caption" sx={{ fontWeight: 700 }}>
        {messageAuthor.profile.title} {messageAuthor.profile.firstName}{' '}
        {messageAuthor.profile.lastName}
      </Typography>
    </HorizontalListItem>
  )
}

export const CaseTimeline = ({ case: currentCase }: any) => {
  const { employee } = useAuth()

  const [
    caseDoesNotRequireMedicalReply,
    {
      loading: caseDoesNotRequireMedicalReplyLoading,
      error: caseDoesNotRequireMedicalReplyError,
    },
  ] = useMutation(caseDoesNotRequireMedicalReplyMutation, {
    variables: {
      customerId: currentCase.customerId,
      caseId: currentCase.id,
      employeeId: employee?.id,
    },
  })

  return (
    <>
      <VerticalList>
        <VerticalListRow>
          <HeroCardEmpty sx={{ borderRadius: 1 }}>
            <HorizontalList>
              <HorizontalListItem>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  Completed on
                </Typography>
                <DateTime
                  dateTime={currentCase.metadata.activatedAt}
                  tooltipPlacement="top"
                />
              </HorizontalListItem>

              <HorizontalListItem>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  By
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 700, fontStyle: 'italic' }}
                >
                  customer
                </Typography>
              </HorizontalListItem>
            </HorizontalList>

            <CaseImages attachments={currentCase.attachments} />

            <ConsultationQuestionnaire
              questionnaire={currentCase.questionnaireSummary}
            />
          </HeroCardEmpty>
        </VerticalListRow>

        {currentCase.messages?.map((m: any) => (
          <VerticalListRow key={m.id}>
            <HeroCardEmpty sx={{ mt: 2 }}>
              <HorizontalList sx={{ mb: 2 }}>
                <HorizontalListItem>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    Sent on
                  </Typography>
                  <DateTime dateTime={m.createdAt} tooltipPlacement="top" />
                </HorizontalListItem>

                <MessageAuthor messageAuthor={m.author} />
              </HorizontalList>

              {m.content.type === 'rich_text' && (
                <UserGeneratedHtml html={m.content.richText} />
              )}

              {m.content.type === 'text' && (
                <Typography>{m.content.text}</Typography>
              )}

              {!!m.attachments?.length && (
                <CaseImages attachments={m.attachments} />
              )}

              {/*<CaseImages attachments={m.attachments} />*/}
            </HeroCardEmpty>
          </VerticalListRow>
        ))}

        {currentCase.assignedTo?.id === employee?.id &&
          (currentCase.chatEnabled ||
            currentCase.postDiagnosisMetadata
              ?.followupMessageFromCustomerRequiresFeedback) &&
          currentCase.actions.canWriteMessage.enabled && (
            <VerticalListRow>
              <Box sx={{ mt: 2 }}>
                {!currentCase.chatEnabled &&
                  !!currentCase.postDiagnosisMetadata
                    ?.followupMessageFromCustomerRequiresFeedback && (
                    <Box
                      sx={{
                        color: 'info.main',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                      }}
                    >
                      <Error />
                      <Typography variant="caption" color="inherit">
                        4 weeks have passed since the diagnosis has been
                        generated. You can provide one single extra
                        response/comment to your customer if you choose to.
                      </Typography>
                    </Box>
                  )}
                <NotionCmsQuestionsProvider>
                  <CaseMessageBox />
                </NotionCmsQuestionsProvider>
              </Box>
            </VerticalListRow>
          )}

        {currentCase.assignedTo?.id === employee?.id &&
          currentCase.postDiagnosisMetadata
            ?.followupMessageFromCustomerRequiresFeedback &&
          currentCase.actions.canWriteMessage.enabled && (
            <VerticalListRow>
              <Box>
                <Button
                  sx={{ textTransform: 'unset' }}
                  color="info"
                  onClick={async () => await caseDoesNotRequireMedicalReply()}
                >
                  Mark as reply not required
                </Button>
              </Box>
            </VerticalListRow>
          )}
      </VerticalList>
    </>
  )
}
