import { Add, Clear, Close, FilterAlt, Search } from '@mui/icons-material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  InputAdornment,
  MenuItem,
  Popper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { lighten } from '@mui/system'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { PageHeader } from './page-header'

export enum FilterComponent {
  SELECT = 'select',
  TEXT = 'text',
  ID = 'id',
}

export enum InputOperation {
  EQUALS = 'equals',
  CONTAINS = 'contains',
  IS = 'is',
}

interface FilterOption {
  label?: string
  value: any
}

export interface InputFilterProps {
  component: FilterComponent
  options?: FilterOption[] | undefined
}

export interface InputConfig {
  component: FilterComponent
  label: string
  key: string
  op: InputOperation
}

export interface IdInputConfig {
  // component: FilterComponent
  label: string
  key: string
}

interface Props<T, P> {
  names: string[]
  filter: T
  onChange: (filter: T) => void
  inputs?: P
  inputs2?: InputConfig[]
  id?: IdInputConfig
}

export const DataFilter = <T extends object, P extends object>({
  filter,
  onChange,
  names,
  inputs,
  inputs2,
  id,
}: Props<T, P>) => {
  const [filterKey, setFilterKey] = useState('')
  const [filterValue, setFilterValue] = useState('')
  // @ts-ignore
  const [filterIdValue, setFilterIdValue] = useState<string>(filter?.[id?.key])

  const options = names.filter(
    (n) => !Object.entries(filter).find(([key, value]) => key === n && value),
  )

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!id) {
        return
      }
      if (filterIdValue === '') {
        handleAdd2({ filterKey: id.key, [id.key]: undefined })
      } else {
        handleAdd2({ filterKey: id.key, filterValue: filterIdValue })
      }
    }, 250)

    return () => clearTimeout(getData)
  }, [filterIdValue])

  const handleAdd = () => {
    onChange({ ...filter, [filterKey]: filterValue })
    setFilterKey('')
    setFilterValue('')
  }
  const handleAdd2 = ({ filterKey, filterValue }: any) => {
    onChange({ ...filter, [filterKey]: filterValue })
  }

  const inputComponent = useMemo(() => {
    const DEFAULT_COMPONENT = (
      <TextField
        label="Filter value"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        onKeyDown={(event) =>
          event.key === 'Enter' && filterKey && filterValue && handleAdd()
        }
        size="small"
        sx={{ ml: 2, minWidth: '18vw' }}
      />
    )

    if (!inputs) {
      return DEFAULT_COMPONENT
    }

    // @ts-ignore
    const inputFilter: InputFilterProps = inputs[filterKey]
    const filterOptions: FilterOption[] | undefined = inputFilter?.options

    switch (inputFilter?.component) {
      case FilterComponent.SELECT:
        return (
          <TextField
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            select
            sx={{ ml: 2, minWidth: '18vw' }}
            label="Filter value"
            size="small"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {filterOptions?.map(({ label, value }: FilterOption) => (
              <MenuItem key={`filter_item_${value}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )
      default:
        return DEFAULT_COMPONENT
    }
  }, [filterKey, filterValue, inputs])

  return (
    <PageHeader
      sx={{ flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}
    >
      {/*TODO: this is deprecated, we should switch all searches to use inputs2 (which should ofc be renamed to something else)*/}
      {!inputs2 && (
        <Box>
          {Object.entries(filter)
            .filter(
              ([, value]) =>
                value !== undefined && value !== null && value !== '',
            )
            .map(([key, value]) => (
              <Chip
                icon={<FilterAltIcon />}
                label={`${key}: ${value}`}
                onDelete={() => onChange({ ...filter, [key]: undefined } as T)}
              />
            ))}
        </Box>
      )}

      {inputs2 && (
        <Box sx={{ display: 'flex', flex: 1 }}>
          {id && (
            <TextField
              value={filterIdValue}
              placeholder={id.label}
              // fullWidth
              autoFocus
              onChange={(e) => setFilterIdValue(e.target.value)}
              onKeyDown={(event) =>
                event.key === 'Enter' &&
                filterIdValue &&
                !!id &&
                handleAdd2({
                  filterKey: id.key,
                  filterValue: filterIdValue,
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => {
                      if (id) {
                        setFilterIdValue('')
                        onChange({ ...filter, [id.key]: undefined } as T)
                      }
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Clear />
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{
                mr: 2,
                '& .MuiInputBase-root': {
                  pr: 0,
                },
                '& input': {
                  minWidth: 250,
                  height: 30,
                  lineHeight: '32px',
                  fontSize: 12,
                  py: 0,
                },
              }}
            />
          )}

          {Object.entries(filter)
            .filter(
              ([, value]) =>
                value !== undefined && value !== null && value !== '',
            )
            .map(([key, value]) =>
              inputs2.find((input: InputConfig) => input.key === key) ? (
                <Box sx={{ mr: 2 }}>
                  <AddFilter
                    input={inputs2.find(
                      (input: InputConfig) => input.key === key,
                    )}
                    handleAdd={handleAdd2}
                    value={value}
                    handleDelete={(key: string) =>
                      onChange({ ...filter, [key]: undefined } as T)
                    }
                  />
                </Box>
              ) : null,
            )}

          <AddFilter inputs={inputs2} handleAdd={handleAdd2} />
        </Box>
      )}

      {!inputs2?.length && (
        <Box sx={{ display: 'flex', ml: inputs2 ? 'unset' : 'auto' }}>
          <TextField
            value={filterKey}
            onChange={(e) => setFilterKey(e.target.value)}
            select
            sx={{ minWidth: '10vw' }}
            label="Filter key"
            size="small"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {options.map((key) => (
              <MenuItem key={`filter_item_${key}`} value={key}>
                {key}
              </MenuItem>
            ))}
          </TextField>

          {inputComponent}

          <Button
            disabled={
              !filterKey ||
              filterValue === undefined ||
              filterValue === null ||
              filterValue === ''
            }
            size="small"
            type="submit"
            onClick={handleAdd}
          >
            <FilterAlt />
          </Button>
        </Box>
      )}
    </PageHeader>
  )
}

const AddFilter = ({ inputs, input, value, handleAdd, handleDelete }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedFilterInput, setSelectedFilterInput] =
    React.useState<InputConfig | null>(input)
  // const [pendingValue, setPendingValue] = React.useState<InputConfig | null>(
  //   null,
  // )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // setPendingValue(value)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (!value) {
      setSelectedFilterInput(null)
    }

    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#e2e9f9',
          color: '#3367d6',
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          cursor: 'pointer',
          py: 0,
          // px: 2,
          lineHeight: '24px',
          borderRadius: 1,
        }}
      >
        <Box onClick={handleClick} sx={{ px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!value && <Add />}

            <Typography sx={{ ml: 1, py: 1 }}>
              {selectedFilterInput ? (
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {selectedFilterInput.label} {selectedFilterInput.op}
                  </Typography>

                  {!!value && <Box sx={{ ml: 1, mr: 2 }}>{value}</Box>}
                </Box>
              ) : open ? (
                <Typography sx={{ fontWeight: 500 }}>
                  Choose attribute
                </Typography>
              ) : (
                <Typography sx={{ fontWeight: 500 }}>Add filter</Typography>
              )}
            </Typography>
          </Box>
        </Box>

        {!!value && (
          <Box
            sx={{
              display: 'flex',
              '&:hover': { backgroundColor: lighten('#e2e9f9', 0.4) },
            }}
            onClick={() => handleDelete(input.key)}
          >
            <Close />
          </Box>
        )}
      </Box>

      <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          {selectedFilterInput ? (
            <InputValue
              input={selectedFilterInput}
              handleAdd={(value: any) => {
                handleClose()
                handleAdd(value)
              }}
              value={value}
            />
          ) : (
            <InputSelect
              onClick={(input: InputConfig) => setSelectedFilterInput(input)}
              inputs={inputs}
            />
          )}
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  )
}

const InputValue = forwardRef(
  (
    {
      input,
      value,
      handleAdd,
    }: { input: InputConfig; value: any; handleAdd: any },
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState(value)

    return (
      <Box ref={ref}>
        <Box sx={{ p: 2 }}>
          {input.component === 'text' && (
            <TextField
              value={filterValue}
              fullWidth
              autoFocus
              onChange={(e) => setFilterValue(e.target.value)}
              onKeyDown={(event) =>
                event.key === 'Enter' &&
                filterValue &&
                handleAdd({ filterKey: input.key, filterValue })
              }
              size="small"
            />
          )}
        </Box>

        <Divider />

        <Box>
          <Button
            fullWidth
            disabled={!filterValue}
            onClick={() => handleAdd({ filterKey: input.key, filterValue })}
          >
            Apply filter
          </Button>
        </Box>
      </Box>
    )
  },
)

const InputSelect = forwardRef(
  ({ inputs, onClick }: { inputs: InputConfig[]; onClick: any }, ref) => (
    <Box sx={{ p: 1 }} ref={ref}>
      {inputs
        // .filter((input: InputConfig) => input.component !== FilterComponent.ID)
        .map((input: any) => (
          <MenuItem
            key={`filter_item_${input.key}`}
            value={input.key}
            onClick={() => onClick(input)}
          >
            {input.label}
          </MenuItem>
        ))}
    </Box>
  ),
)

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))
