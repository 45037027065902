import { CheckCircle, ContentCopy, Key } from '@mui/icons-material'
import { Box, IconButton, styled, Tooltip } from '@mui/material'
import { yellow } from '@mui/material/colors'
import ConditionalWrap from 'conditional-wrap'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { H2 } from './headings'

type CustomProps = {
  visible: boolean
}

type IdProps = {
  children: string
  label?: string
  fontSize?: string
  linkTo?: string
  background?: string
}

const ClipboardCopyText = styled('span', {
  shouldForwardProp: (prop) => prop !== 'visible',
})<CustomProps>(({ theme, visible }: any) => ({
  fontSize: theme.typography?.size.xs,
  opacity: visible ? 1 : 0,
  color: theme.palette.grey[600],
  transition: '0.15s opacity ease-in-out',
}))

export const Id = ({
  children,
  label,
  linkTo = '',
  fontSize = '14px',
  background = 'inherit',
}: IdProps) => {
  const [copied, setCopied] = useState(false)

  const handleClipboardCopy = (e: any) => {
    e.preventDefault()
    navigator.clipboard.writeText(children)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        boxShadow: 0,
        background,
      }}
    >
      <Key
        sx={{ color: yellow[700], transform: 'rotate(135deg)', mr: 1 }}
        fontSize={'small'}
      />

      {label && (
        <Box
          sx={{
            mr: 1,
          }}
        >
          <H2 sx={{ fontSize }}>{label}</H2>
        </Box>
      )}

      <Box component={'span'} sx={{ fontSize }}>
        <ConditionalWrap
          condition={linkTo !== ''}
          wrap={(children) => <NavLink to={linkTo}>{children}</NavLink>}
        >
          <>{children}</>
        </ConditionalWrap>
      </Box>

      {!copied ? (
        <IconButton size="small" sx={{ ml: 1 }} onClick={handleClipboardCopy}>
          <ContentCopy fontSize="inherit" />
        </IconButton>
      ) : (
        <Tooltip title="Copied" placement="right">
          <IconButton size="small" sx={{ ml: 1 }} color="primary">
            <CheckCircle fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}

      {/*<ClipboardCopyText visible={copied}>Copied</ClipboardCopyText>*/}
    </Box>
  )
}
