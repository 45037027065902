// organize-imports: getcanonicallocales, locale, displaynames, locale-data
import '@formatjs/intl-displaynames/locale-data/de'
import '@formatjs/intl-displaynames/locale-data/pt'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'

const fallbackLocale = 'en-GB'
// export const systemLocale = process.env['NX_I18N_LOCALE'] || fallbackLocale
export const systemLocale = fallbackLocale

const currencyLocales = ['de-DE', 'de-CH', 'pt-BR']
const locales = ['en-GB', ...currencyLocales]

const i18nCountryDisplayName = (locale?: string) => {
  const localeName = i18nLocale(locale)
  const country = i18nCountry(locale)

  return new Intl.DisplayNames([localeName], { type: 'region' }).of(country)
}

const i18nLocale = (locale?: string): string => {
  if (locale) {
    if (locales.find((iLocale) => iLocale === locale)) {
      return locale
    }
  }

  return systemLocale
}

const i18nLanguage = (locale?: string) => i18nLocale(locale).split('-')[0]

const i18nCountry = (locale?: string) => i18nLocale(locale).split('-')[1]

export {
  locales,
  i18nLocale,
  i18nLanguage,
  i18nCountry,
  i18nCountryDisplayName,
}
