import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { StringParam } from 'use-query-params'
import { CasesFilters } from './cases.filters'
import { CasesTable } from './cases.table'

const casesPageFilterConfig = {
  id: StringParam,
  // accountId: StringParam,
  // patientId: StringParam,
  // caseId: StringParam,
  status: StringParam,
  extendedStatus: StringParam,
  documentNumber: StringParam,
  assignee: StringParam,
  diagnosisContentAssignee: StringParam,
  email: StringParam,
  // name: StringParam,
  caseDiagnosisStatus: StringParam,
  sortDesc: StringParam,
}

export const CasesPage = () => {
  return (
    <DataFilterV2Provider config={casesPageFilterConfig}>
      <CasesPageContent />
    </DataFilterV2Provider>
  )
}

const CasesPageContent = () => {
  const { employee } = useAuth()

  if (!employee) {
    return null
  }

  return (
    <Box>
      {/*<PageHeader*/}
      {/*  sx={{ flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}*/}
      {/*>*/}
      {/*  <Filters application="tofu" category="case" />*/}
      {/*</PageHeader>*/}

      <CasesFilters />

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
          mb: 2,
        }}
      >
        <CasesTable />
      </Card>
    </Box>
  )
}
