import { Box, Icon, Typography } from '@mui/material'
import {
  HeroCard,
  HeroCardProps,
  HeroCardSection,
  KeyTag,
  Section,
  TagGroup,
  TagGroupItem,
} from '@trueskin-backoffice/components'
import { usePatient } from '@trueskin-backoffice/contexts'
import { ReactNode } from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { RiCake2Fill } from 'react-icons/ri'
import { BirthDate } from '../../../customer/components/details'

const CustomerProfile = () => {
  const { customer } = usePatient()

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{ fontWeight: 600, fontSize: 20, lineHeight: 1.2, m: 0 }}
        >
          {customer.profile?.email}
        </Typography>
      </Box>

      <Box sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
        <Typography>
          {customer.profile?.firstName} {customer.profile?.lastName}
        </Typography>
      </Box>
    </>
  )
}

export const CustomerIds = () => {
  const { customer } = usePatient()

  return (
    <TagGroup
      items={[<KeyTag sx={{ mt: 1 }} id={customer.id} />]}
      sx={{ mb: 1 }}
    ></TagGroup>
  )
}

export const CustomerTags = ({
  noIds,
  noV1UserId,
  noLocale,
}: {
  noIds?: boolean
  noV1UserId?: boolean
  noLocale?: boolean
}) => {
  const { customer } = usePatient()

  return (
    <>
      {!noIds && <CustomerIds />}

      <TagGroup
        items={[
          <TagGroupItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1, mb: -1 }}>
                <Icon component={FaPhoneAlt} sx={{ fontSize: 12 }} />
              </Box>
              <Box>{customer.profile?.phoneNumber || 'N/A'}</Box>
            </Box>
          </TagGroupItem>,
          <TagGroupItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1, mb: -1 }}>
                <Icon component={RiCake2Fill} sx={{ fontSize: 12 }} />
              </Box>
              <Box>
                <BirthDate birthDate={customer.medicalData?.birthdate} />
              </Box>
            </Box>
          </TagGroupItem>,
        ]}
      ></TagGroup>
    </>
  )
}

export const CustomerSummaryCard = (props: CustomerSummaryCard) => {
  return (
    <HeroCard>
      <HeroCardSection sx={{ mb: 2 }}>
        <CustomerProfile />
      </HeroCardSection>

      <HeroCardSection sx={{ mb: 0 }}>
        <CustomerTags />
      </HeroCardSection>

      {props.children}
    </HeroCard>
  )
}

export interface CustomerSummaryCard extends HeroCardProps {
  children?: ReactNode
}

export const PatientSummarySection = () => {
  return (
    <Section>
      <CustomerSummaryCard />
    </Section>
  )
}
