import { Box, BoxProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

export const Section = styled((props: SectionProps) => {
  const { headerLabel, headerActions, children, ...other } = props

  return (
    <Box component="section" sx={{ mb: 4 }} {...other}>
      {(props.headerLabel || props.headerActions) && (
        <SectionHeader
          label={props.headerLabel}
          actions={props.headerActions}
        />
      )}

      {children}
    </Box>
  )
})()

export interface SectionProps extends BoxProps {
  headerLabel?: string | ReactNode
  headerActions?: ReactNode
  // content: ReactNode
}

export const SectionHeader = (props: SectionHeaderProps) => (
  <Box sx={{ display: 'flex', alignItems: 'flex-end', py: 2 }}>
    <Box sx={{ flex: 1 }}>
      <Typography color="primary" fontSize={12} lineHeight={1.4}>
        <strong>{props.label}</strong>
      </Typography>
    </Box>

    {props.actions && <Box>{props.actions}</Box>}
  </Box>
)

export interface SectionHeaderProps {
  label?: string | ReactNode
  actions?: ReactNode
}
