import { Box, Card, Typography } from '@mui/material'
import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2Provider,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useRef } from 'react'
import { StringParam } from 'use-query-params'
import { NotionDiagnosesTable } from './tables/notion-diagnoses.table'
import { NotionQuestionsTable } from './tables/notion-questions.table'
import { NotionTreatmentsTable } from './tables/notion-treatments.table'

function usePreviousValue(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value //assign the value of ref to the argument
  }, [value]) //this code will run when the value of 'value' changes
  return ref.current //in the end, return the current ref value.
}

const NotionCmsFilter = () => {
  const { query: filter, setQuery } = useDataFilterV2()
  const previousCategory = usePreviousValue(filter?.category)

  useEffect(() => {
    if (previousCategory !== filter?.category) {
      setQuery({ ...filter, search: undefined })
    }
  }, [filter, setQuery, previousCategory])

  return (
    <DataFilterV2
      inputRows={[
        [
          {
            type: DataFilterV2FilterComponent.TOGGLE,
            key: 'category',
            options: [
              {
                value: 'diagnoses',
                component: (
                  <Typography sx={{ fontWeight: 500 }}>Diagnoses</Typography>
                ),
              },
              {
                value: 'treatments',
                component: (
                  <Typography sx={{ fontWeight: 500 }}>
                    Treatments & Medication
                  </Typography>
                ),
              },
              {
                value: 'questions',
                component: (
                  <Typography sx={{ fontWeight: 500 }}>Questions</Typography>
                ),
              },
            ],
          },
        ],
        // [
        //   {
        //     label: 'Search records',
        //     key: 'search',
        //     type: DataFilterV2FilterComponent.TEXT,
        //     op: DataFilterV2InputOperation.EQUALS,
        //   },
        // ],
      ]}
    />
  )
}

const notionCmsFilterConfig = {
  search: StringParam,
  category: StringParam,
}

export const NotionCmsPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={notionCmsFilterConfig}>
        <NotionCmsFilter />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
            mb: 2,
          }}
        >
          <CategoryTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}

const CategoryTable = () => {
  const { query: filter } = useDataFilterV2()

  if (!filter?.category) {
    return (
      <Typography variant="body2" color="orange">
        Select a category first
      </Typography>
    )
  }

  if (filter.category === 'diagnoses') {
    return <NotionDiagnosesTable />
  }

  if (filter.category === 'questions') {
    return <NotionQuestionsTable />
  }

  if (filter.category === 'treatments') {
    return <NotionTreatmentsTable />
  }

  return null
}
