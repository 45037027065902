import { Avatar, Box, Button, IconButton } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useAuth, useUIController } from '@trueskin-backoffice/contexts'
import { GoSignOut } from 'react-icons/go'
import { CurrentChannelButton } from './components/current-channel.button'

export const MainMenuFooter = () => {
  const { showTopRightMenu } = useUIController()
  const { logOut, employee, channel } = useAuth()
  console.log('channel = ', channel)
  const showMenuVariationBtn =
    channel === 'tofu' &&
    (employee?.roles?.includes('doctor') ||
      employee?.roles?.includes('medicalassistant'))

  if (!employee) {
    return null
  }

  return (
    <Box>
      <Box sx={{ py: 3, borderTop: '1px solid rgba(214,214,222,.4)' }}>
        {showMenuVariationBtn && (
          <Box
            sx={{ mb: 2, display: 'flex', flex: 1, justifyContent: 'center' }}
          >
            <Button
              color="info"
              size="small"
              sx={{ textTransform: 'unset' }}
              onClick={() => showTopRightMenu(true)}
            >
              Try menu variation
            </Button>
          </Box>
        )}

        <Box sx={{ mb: 2 }}>
          <CurrentChannelButton />
        </Box>

        <Box>
          <Box sx={{ px: 2, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 2 }}>
              <Avatar
                sx={{
                  width: 26,
                  height: 26,
                  backgroundColor: grey[600],
                  textTransform: 'uppercase',
                }}
              >
                {employee?.firstName?.[0]}
              </Avatar>
            </Box>

            <span>{employee.firstName}</span>

            <IconButton
              onClick={logOut}
              color="inherit"
              sx={{ ml: 'auto', p: 0 }}
            >
              <GoSignOut />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
