import { gql } from '@apollo/client'

export const subscriptionFields = gql`
  fragment SubscriptionFields on Subscription {
    id
    customerId
    orderId
    endBehaviour
    startDate
    endDate
    status
    occurrence
    metadata {
      chargebeeSubscriptionId
      v1SubscriptionId
    }
  }
`

// export const getSubscriptionById = gql`
//   ${subscriptionFields}
//   query GetSubscriptionById($subscriptionId: String!) {
//     getSubscriptionById(subscriptionId: $subscriptionId) {
//       ...SubscriptionFields
//     }
//   }
// `

export const scheduleSubscriptionToRenewMutation = gql`
  mutation ScheduleSubscriptionToRenew($subscriptionId: String!) {
    scheduleSubscriptionToRenew(subscriptionId: $subscriptionId) {
      id
    }
  }
`

export const renewSubscriptionImmediatelyMutation = gql`
  mutation RenewSubscriptionImmediately($subscriptionId: String!) {
    renewSubscriptionImmediately(subscriptionId: $subscriptionId) {
      id
    }
  }
`

export const scheduleSubscriptionToCancelMutation = gql`
  mutation ScheduleSubscriptionToCancel($subscriptionId: String!) {
    scheduleSubscriptionToCancel(subscriptionId: $subscriptionId) {
      id
    }
  }
`

export const cancelSubscriptionImmediatelyMutation = gql`
  mutation CancelSubscriptionImmediately($subscriptionId: String!) {
    cancelSubscriptionImmediately(subscriptionId: $subscriptionId) {
      id
    }
  }
`
