import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'

export interface WaitingRoomLayoutControllerState {
  hideControls: boolean
  hideWaitingRoomList: boolean
  hidePatientProfile: boolean
}

export interface WaitingRoomLayoutControllerProps {
  controller: WaitingRoomLayoutControllerState
  hideWaitingRoomList: (value: boolean) => void
  hideAll: (value: boolean) => void
}

enum WaitingRoomLayoutControllerActionKind {
  HIDE_WAITING_ROOM_LIST = 'HIDE_WAITING_ROOM_LIST',
  HIDE_PATIENT_PROFILE = 'HIDE_PATIENT_PROFILE',
}

interface WaitingRoomLayoutControllerAction {
  type: WaitingRoomLayoutControllerActionKind
  value: boolean
}

const WaitingRoomLayoutControllerContext =
  createContext<WaitingRoomLayoutControllerProps | null>(null)

function reducer(
  state: WaitingRoomLayoutControllerState,
  action: WaitingRoomLayoutControllerAction,
) {
  switch (action.type) {
    case 'HIDE_WAITING_ROOM_LIST': {
      return { ...state, hideWaitingRoomList: action.value }
    }
    case 'HIDE_PATIENT_PROFILE': {
      return { ...state, hidePatientProfile: action.value }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const WaitingRoomLayoutControllerProvider = ({
  children,
  hideControls,
}: any) => {
  const initialState: WaitingRoomLayoutControllerState = {
    hideControls: !!hideControls,
    hideWaitingRoomList: false,
    hidePatientProfile: false,
  }

  const [controller, dispatch] = useReducer(reducer, initialState)

  const hideWaitingRoomList = useCallback((show: boolean) => {
    dispatch({
      type: WaitingRoomLayoutControllerActionKind.HIDE_WAITING_ROOM_LIST,
      value: show,
    })
  }, [])
  const hideAll = useCallback((show: boolean) => {
    dispatch({
      type: WaitingRoomLayoutControllerActionKind.HIDE_WAITING_ROOM_LIST,
      value: show,
    })
    dispatch({
      type: WaitingRoomLayoutControllerActionKind.HIDE_PATIENT_PROFILE,
      value: show,
    })
  }, [])

  const value = useMemo(
    () => ({ controller, dispatch, hideWaitingRoomList, hideAll }),
    [controller, dispatch, hideWaitingRoomList, hideAll],
  )

  return (
    <WaitingRoomLayoutControllerContext.Provider value={value}>
      {children}
    </WaitingRoomLayoutControllerContext.Provider>
  )
}

export function useWaitingRoomLayoutController() {
  const context = useContext(WaitingRoomLayoutControllerContext)

  if (!context) {
    throw new Error(
      'useWaitingRoomLayoutController should be used inside the WaitingRoomLayoutControllerContext.',
    )
  }

  return context
}
