import { Box, Typography } from '@mui/material'
import {
  DateTime,
  DiscountTag,
  HeroCard,
  HorizontalList,
  HorizontalListItem,
  KeyTag,
  LightTag,
  Section,
  TagGroup,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { Link } from 'react-router-dom'

export const CustomerReferral = () => {
  const { customer } = useCustomer()

  if (!customer.referral) {
    return <Box>No Data</Box>
  }
  return (
    <Section
      headerLabel={'Referral'}
      headerActions={
        <Box ml="auto">
          <Link to={`/customers/${customer.id}/referral-rewards`}>
            View rewards
          </Link>
        </Box>
      }
    >
      <HeroCard>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <LightTag label="Code" />
          <DiscountTag label={customer.referral.code} />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <LightTag label="Invited" />
          <Typography>{customer.referral.count}</Typography>
        </Box>

        {customer.referral.referredBy && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LightTag label="Referrer" />

            <Link to={`/customers/${customer.referral.referredBy}`}>
              <TagGroup
                items={[
                  <KeyTag id={customer.id} sx={{ mt: 0, px: 1, py: 0 }} />,
                ]}
              ></TagGroup>
            </Link>

            <Typography>at</Typography>

            <Box sx={{ ml: 1 }}>
              <DateTime dateTime={customer.referral.referredAt} />
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', mb: 1 }}>
          <HorizontalList>
            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Available balance
              </Typography>

              <Typography>
                {formatCurrency(
                  customer.referral.balance.available / 100,
                  null,
                  customer.referral.balance.currency,
                )}
              </Typography>
            </HorizontalListItem>

            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Used balance
              </Typography>

              <Typography>
                {formatCurrency(
                  customer.referral.balance.used / 100,
                  null,
                  customer.referral.balance.currency,
                )}
              </Typography>
            </HorizontalListItem>
          </HorizontalList>
        </Box>

        {customer.referral.refereeDiscountGroupId && (
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Typography variant="caption" sx={{ mr: 1 }}>
              Special referee discount group
            </Typography>

            <Link
              to={`/discount-groups/${customer.referral.refereeDiscountGroupId}`}
            >
              <TagGroup
                items={[
                  <KeyTag
                    id={customer.referral.refereeDiscountGroupId}
                    sx={{ mt: 0, px: 1, py: 0 }}
                  />,
                ]}
              ></TagGroup>
            </Link>
          </Box>
        )}

        {customer.referral.referrerDiscountBonus && (
          <Box sx={{ display: 'flex' }}>
            <Typography variant="caption" sx={{ mr: 1 }}>
              Special reward amount
            </Typography>

            <Typography>
              {formatCurrency(
                customer.referral.referrerDiscountBonus.amount / 100,
                null,
                customer.referral.referrerDiscountBonus.currency,
              )}
            </Typography>
          </Box>
        )}
      </HeroCard>
    </Section>
  )
}
