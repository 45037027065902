import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import { useV2Mutation } from '@trueskin-backoffice/api-client'
import { useFormik } from 'formik'
import { noop } from 'lodash'
import { useCallback, useState } from 'react'
import { FormSection } from '../employee.components'
import { EmployeesAutocomplete } from '../employees-autocomplete'
import {
  getEmployeeById,
  removeMedicalAssistantProfileMutation,
  updateMedicalAssistantProfile,
} from '../employees.gql'
import { Employee, Role } from '../types'

interface Props {
  employee: Employee
  onSuccess?: () => void
  onCancel?: () => void
  editOnly?: boolean
}

export const MedicalAssistantProfile = ({
  employee,
  onSuccess = noop,
  onCancel = noop,
  editOnly = false,
}: Props) => {
  const profile = employee?.profile?.medicalassistant

  const [edit, setEdit] = useState(editOnly)

  const getProfileValues = useCallback(
    () => ({
      leadDoctor: profile?.leadDoctor,
    }),
    [profile],
  )

  const [
    updateProfileFields,
    { loading: profileLoading, error: profileError },
  ] = useV2Mutation(updateMedicalAssistantProfile, {
    refetchQueries: [
      {
        query: getEmployeeById,
        variables: { employeeId: employee._id },
      },
    ],
  })

  const [
    removeMedicalAssistantProfile,
    { loading: removeLoading, error: removeError },
  ] = useV2Mutation(removeMedicalAssistantProfileMutation, {
    variables: { employeeId: employee._id },
    refetchQueries: [
      {
        query: getEmployeeById,
        variables: { employeeId: employee._id },
      },
    ],
  })

  const formik = useFormik<{
    leadDoctor: Employee
  }>({
    initialValues: getProfileValues(),
    onSubmit: async (values) => {
      const { data: updatedFields } = await updateProfileFields({
        variables: {
          employeeId: employee._id,
          medicalAssistantProfile: { leadDoctorId: values.leadDoctor._id },
        },
      })

      if (updatedFields) {
        setEdit(false)
        onSuccess()
      }
    },
  })

  const handleCancelEdit = () => {
    formik.setValues(getProfileValues())
    setEdit(false)
    onCancel()
  }

  const loading = profileLoading || removeLoading
  const error = profileError || removeError

  const hasRole = employee?.roles.includes(Role.MEDICAL_ASSISTANT)

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        border: !editOnly ? 'dashed' : undefined,
        width: '100%',
        borderColor: edit ? 'primary.main' : hasRole ? 'grey' : 'orange',
        borderWidth: edit ? '3px' : '1px',
      }}
    >
      {loading && <LinearProgress />}
      {error && (
        <Typography variant="caption" color="red">
          {error?.message}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Medical Assistant</Typography>{' '}
        {!edit ? (
          <Button onClick={() => setEdit(true)}>
            <EditIcon />
          </Button>
        ) : (
          !editOnly && (
            <Button onClick={handleCancelEdit}>
              <CloseIcon color="secondary" />
            </Button>
          )
        )}
        <Box sx={{ ml: 'auto' }}>
          {!hasRole && !editOnly && (
            <Tooltip title="Remove assistant's profile">
              <IconButton onClick={() => removeMedicalAssistantProfile()}>
                <RemoveCircleIcon color="error"></RemoveCircleIcon>
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Grid container spacing={2} alignItems="center">
        <FormSection
          label="Lead Doctor"
          labelXs={2}
          value={
            <Box m={2}>
              <EmployeesAutocomplete
                employee={formik.values.leadDoctor}
                onChange={(value) => formik.setFieldValue('leadDoctor', value)}
                filter={{ role: Role.DOCTOR }}
              />
            </Box>
          }
        />
      </Grid>

      {edit && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            onClick={formik.submitForm}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleCancelEdit}
            sx={{ ml: 3 }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  )
}
