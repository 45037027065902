import {
  DescriptionList,
  DescriptionListItem,
  EditableField,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import React from 'react'
import { CustomerMutationsProvider } from '../contexts'
import { UpdateBirthNameModal } from './update-birth-date.modal'
import { UpdateEmailModal } from './update-email.modal'
import { UpdateFirstNameModal } from './update-first-name.modal'
import { UpdateLastNameModal } from './update-last-name.modal'

export const BirthDate = ({ birthDate }: { birthDate: string }) => {
  if (!birthDate) {
    return <span>N/A</span>
  }

  return (
    <span>
      {new Date(birthDate).toLocaleDateString(process.env['NX_I18N_LOCALE'], {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}
    </span>
  )
}

export const CustomerDetails = () => {
  const { customer } = useCustomer()

  return (
    <CustomerMutationsProvider>
      <DescriptionList>
        <DescriptionListItem label={'First name'}>
          <EditableField editDialog={UpdateFirstNameModal}>
            {customer.firstName}
          </EditableField>
        </DescriptionListItem>

        <DescriptionListItem label={'Last name'}>
          <EditableField editDialog={UpdateLastNameModal}>
            {customer.lastName}
          </EditableField>
        </DescriptionListItem>

        <DescriptionListItem label={'Birthdate'}>
          <EditableField editDialog={UpdateBirthNameModal}>
            <BirthDate birthDate={customer.birthDate} />
          </EditableField>
        </DescriptionListItem>

        <DescriptionListItem label={'Email'}>
          <EditableField editDialog={UpdateEmailModal}>
            {customer.email}
          </EditableField>
        </DescriptionListItem>

        <DescriptionListItem label={'Phone'}>
          {customer.phoneNumber || 'N/A'}
        </DescriptionListItem>
      </DescriptionList>
    </CustomerMutationsProvider>
  )
}
