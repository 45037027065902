import { useQuery } from '@apollo/client'
import { Visibility } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'
import {
  DateTime,
  HeroCard,
  HeroCardEmpty,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
  Section,
  TagGroupItem,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { PageContentLayout } from '../layouts/page-content.layout'
import { customerSubscriptionsQuery } from './customer.gql.bff'

export const CustomerSubscriptionsPage = () => {
  const { customer } = useCustomer()

  const {
    data: { customer: { subscriptions } } = { customer: {} },
    loading,
    error,
    refetch,
  } = useQuery(customerSubscriptionsQuery, {
    skip: !customer?.customerId,
    variables: { customerId: customer?.customerId },
    notifyOnNetworkStatusChange: true,
  })

  if (loading || !customer) {
    return null
  }

  if (!subscriptions?.length) {
    return (
      <HeroCardEmpty>No subscriptions found for this customer</HeroCardEmpty>
    )
  }

  return (
    <PageContentLayout>
      {subscriptions.map((subscription: any) => (
        <Section>
          <V1Subscription subscription={subscription} />
        </Section>
      ))}

      {/* <Section headerLabel={'Comments'}>
        <CustomerComments />
      </Section> */}
    </PageContentLayout>
  )
}

const V1Subscription = ({ subscription }: any) => {
  return (
    <HeroCard sx={{ position: 'relative' }}>
      <HeroCardSection sx={{ mb: 1 }}>
        <VerticalList>
          <VerticalListRow sx={{ display: 'flex', alignItems: 'baseline' }}>
            <VerticalListRowItem>
              <TagGroupItem
                sx={{
                  borderRadius: 1,
                  py: 0,
                  minWidth: 70,
                  mr: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box>{subscription.status}</Box>
              </TagGroupItem>
            </VerticalListRowItem>

            <VerticalListRowItem fullWidth={true}>
              <Box component="span" sx={{ fontWeight: 500 }}>
                Subscription: {subscription.type}
              </Box>

              <HorizontalList>
                <HorizontalListItem>
                  <Box sx={{ mr: 1 }}>🔑</Box>
                  <Box sx={{ mr: 1 }}>{subscription._id}</Box>
                </HorizontalListItem>

                {subscription.v2SubscriptionId && (
                  <HorizontalListItem>
                    <Box sx={{ mr: 1 }}>🔑</Box>
                    <Box sx={{ mr: 1 }}>{subscription.v2SubscriptionId}</Box>
                  </HorizontalListItem>
                )}
              </HorizontalList>

              {subscription.status !== 'created' && (
                <HorizontalList>
                  <HorizontalListItem>
                    <Typography variant="caption" sx={{ mr: 1 }}>
                      Started on
                    </Typography>
                    <DateTime
                      dateTime={subscription.startDate}
                      tooltipPlacement="top"
                    />
                  </HorizontalListItem>

                  <HorizontalListItem>
                    <Typography variant="caption" sx={{ mr: 1 }}>
                      Ends on
                    </Typography>
                    <DateTime
                      dateTime={subscription.endDate}
                      tooltipPlacement="top"
                    />
                  </HorizontalListItem>
                </HorizontalList>
              )}

              <Divider sx={{ my: 2 }} />

              <PaymentOrder subscription={subscription} />

              <Divider sx={{ my: 2 }} />

              <TreatmentPlanDetails subscription={subscription} />
            </VerticalListRowItem>

            {/*<VerticalListRowItem*/}
            {/*  sx={{ display: 'flex', flexDirection: 'column' }}*/}
            {/*>*/}
            {/*  <Typography variant="caption" sx={{ mr: 1 }}>*/}
            {/*    Started on*/}
            {/*  </Typography>*/}
            {/*  <DateTime*/}
            {/*    dateTime={subscription.startDate}*/}
            {/*    tooltipPlacement="top"*/}
            {/*  />*/}
            {/*</VerticalListRowItem>*/}

            <VerticalListRowItem
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Box>Actions</Box>
            </VerticalListRowItem>
          </VerticalListRow>
        </VerticalList>
      </HeroCardSection>

      <Divider />

      <HeroCardSection sx={{ mb: 0, mt: 2 }}>
        {(subscription.consultations || []).map(
          (consultation: any, index: number) => (
            <Box>
              <V1SubscriptionConsultation
                consultation={consultation}
                subscription={subscription}
                divider={index !== 0}
              />
            </Box>
          ),
        )}
      </HeroCardSection>
    </HeroCard>
  )
}

const V1SubscriptionConsultation = ({
  consultation,
  subscription,
  divider,
}: any) => {
  return (
    <Box sx={{ pl: 8 }}>
      {divider && <Divider />}

      <VerticalList>
        <VerticalListRow>
          <VerticalListRowItem fullWidth={true}>
            <Box component="span" sx={{ fontWeight: 500 }}>
              Consultation: {consultation.type}
            </Box>

            <HorizontalList>
              <HorizontalListItem>
                <Box sx={{ mr: 1 }}>🔑</Box>
                <Box sx={{ mr: 1 }}>{consultation._id}</Box>
              </HorizontalListItem>

              {consultation.v2ConsultationId && (
                <HorizontalListItem>
                  <Box sx={{ mr: 1 }}>🔑</Box>
                  <Box sx={{ mr: 1 }}>{consultation.v2ConsultationId}</Box>
                </HorizontalListItem>
              )}
            </HorizontalList>

            <HorizontalList>
              <HorizontalListItem>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  Created at
                </Typography>
                <DateTime
                  dateTime={consultation.createdAt}
                  tooltipPlacement="top"
                />
              </HorizontalListItem>

              <HorizontalListItem>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  Resolved at
                </Typography>
                {consultation.dateResolved ? (
                  <DateTime
                    dateTime={consultation.dateResolved}
                    tooltipPlacement="top"
                  />
                ) : (
                  '-'
                )}
              </HorizontalListItem>
            </HorizontalList>
          </VerticalListRowItem>

          <VerticalListRowItem sx={{ pr: 0 }}>
            <TagGroupItem sx={{ mt: 1, py: 0, borderRadius: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    color: '#8181ad',
                    mr: 1,
                  }}
                >
                  Status
                </Box>
                {consultation.status}
              </Box>
            </TagGroupItem>

            <TagGroupItem sx={{ my: 1, py: 0, borderRadius: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    color: '#8181ad',
                    mr: 1,
                  }}
                >
                  Assigned to
                </Box>
                {consultation.assignee?.email || '-'}
              </Box>
            </TagGroupItem>

            {['initial', 'renewal', 'repurchase'].includes(
              consultation.type,
            ) && (
              <TagGroupItem sx={{ mr: 0, py: 0, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      color: '#8181ad',
                      mr: 1,
                    }}
                  >
                    Pharmacy
                  </Box>

                  <Box>{consultation.pharmacyWarehouse || 'not set'}</Box>

                  {consultation.pharmacyWarehouse && (
                    <Box sx={{ ml: 1 }}>
                      <PharmacyOrderDetails
                        consultation={consultation}
                        treatmentPlan={subscription.treatmentPlan}
                      />
                    </Box>
                  )}
                </Box>
              </TagGroupItem>
            )}
          </VerticalListRowItem>
        </VerticalListRow>
      </VerticalList>
    </Box>
  )
}

const PaymentOrder = ({ subscription }: any) => {
  const { customer } = useCustomer()

  if (!subscription.paymentOrder) {
    return <Typography variant="caption">Order not found</Typography>
  }

  return (
    <Box>
      <Box component="span" sx={{ fontWeight: 500 }}>
        Order: #{subscription.paymentOrder.sequence} of{' '}
        {formatCurrency(
          subscription.paymentOrder.value,
          null,
          customer.preferences.locale === 'pt-BR' ? 'BRL' : 'EUR',
        )}
      </Box>

      <Box>
        {subscription.paymentOrder.vouchers?.length ? (
          <HorizontalList>
            <HorizontalListItem>Vouchers</HorizontalListItem>

            {subscription.paymentOrder.vouchers.map((voucher: any) => (
              <HorizontalListItem>{voucher.code}</HorizontalListItem>
            ))}
          </HorizontalList>
        ) : (
          <Typography variant="caption">No vouchers applied</Typography>
        )}
      </Box>
    </Box>
  )
}

const PharmacyOrderDetails = ({ consultation, treatmentPlan }: any) => {
  return (
    <Box sx={{ cursor: 'pointer', display: 'flex' }}>
      <Visibility color="primary" sx={{ fontSize: 20 }} />
    </Box>
  )
}

const TreatmentPlanDetails = ({ subscription }: any) => {
  if (!subscription.treatmentPlan) {
    return <Typography variant="caption">Treatment not issued</Typography>
  }

  return (
    <Box component="span" sx={{ fontWeight: 500 }}>
      Treatment plan: {subscription.treatmentPlan?.treatmentPlanTemplate?.name}
    </Box>
  )
}
