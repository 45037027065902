import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { OrdersFilters } from './orders.filters'
import { OrdersTable } from './orders.table'

const ORDERS_FILTER_INIT_VALUES = {
  id: StringParam,
  v1UserId: StringParam,
  v2CustomerId: StringParam,
  status: StringParam,
}

export const OrdersLegacyPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={ORDERS_FILTER_INIT_VALUES}>
        <OrdersFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <OrdersTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
