import { useMutation, useQuery } from '@apollo/client'
import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import {
  ColumnDef,
  DataFilterTextInput,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
  DataTable,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  getNotionCmsDiagnoses,
  syncNotionCmsDiagnosesMutation,
} from '../notion-cms.gql.bff'

export const NotionDiagnosesTable = () => {
  const { query: filter } = useDataFilterV2()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { notionCmsDiagnoses } = { notionCmsDiagnoses: [] },
    loading,
    error,
  } = useQuery(getNotionCmsDiagnoses)

  const [syncNotionCmsDiagnoses, { loading: syncLoading, error: syncError }] =
    useMutation(syncNotionCmsDiagnosesMutation, {
      refetchQueries: [getNotionCmsDiagnoses],
      onCompleted: (_) => {
        enqueueSnackbar('Sync completed successfully.', { variant: 'success' })
      },
    })

  const [data, setData] = useState(notionCmsDiagnoses)

  useEffect(() => {
    if (!filter?.search && data) {
      setData(notionCmsDiagnoses)
      return
    }

    const textsToSearch = filter?.search?.split(' ').filter((r: string) => !!r)

    setData(
      notionCmsDiagnoses.filter((r: any) =>
        textsToSearch.some(
          (el: string) => r._id.toLowerCase().indexOf(el) > -1,
        ),
      ),
    )
  }, [filter, setData, data, notionCmsDiagnoses])

  if (loading) {
    return <Box>Loading data</Box>
  }

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: '_id',
      header: 'Name',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'diagnosis',
      header: 'Diagnosis',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'icd10',
      header: 'ICD10',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'recommendOfflineVisit',
      header: 'Recommend offline visit',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue() ? 'true' : 'false'}</Typography>
      },
    },
    {
      accessorKey: 'candidateForCore',
      header: 'Candidate for core',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue() ? 'true' : 'false'}</Typography>
      },
    },
    {
      accessorKey: 'additionalDirections',
      header: 'Additional directions',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'diagnosisDetails',
      header: 'Diagnosis details',
      cell: ({ getValue, row }) => {
        return <ReactMarkdown>{getValue()}</ReactMarkdown>
      },
    },
    {
      accessorKey: 'skinFindings',
      header: 'Skin findings',
      cell: ({ getValue, row }) => {
        return <ReactMarkdown>{getValue()}</ReactMarkdown>
      },
    },
    {
      accessorKey: 'additionalAdviceDetails',
      header: 'Additional advice details',
      cell: ({ getValue, row }) => {
        return <ReactMarkdown>{getValue()}</ReactMarkdown>
      },
    },
  ]

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DataFilterTextInput
          input={{
            label: 'Search records',
            key: 'search',
            type: DataFilterV2FilterComponent.TEXT,
            op: DataFilterV2InputOperation.EQUALS,
          }}
        />
        <LoadingButton
          loading={syncLoading}
          loadingPosition="start"
          startIcon={syncLoading ? <Save /> : null}
          sx={{ textTransform: 'unset' }}
          onClick={() => syncNotionCmsDiagnoses()}
        >
          Sync diagnoses from notion
        </LoadingButton>
      </Box>

      <Box>
        <DataTable columns={columns} data={data} isLoading={loading} />
      </Box>
    </Box>
  )
}
