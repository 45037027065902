import { useQuery } from '@apollo/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  DateTime,
  IdTag,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useEffect, useMemo, useState } from 'react'
import { getAllCustomers } from './customers.gql.bff'

const DEFAULT_PAGE_SIZE = 25

// TODO: Aggregate customers data with subscriptions
export const CustomersTable = () => {
  const { getCustomerLink } = useAuth()
  const columns = useMemo(
    () => customersTableColumns(getCustomerLink),
    [getCustomerLink],
  )
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      customers: { rows: customers = [], totalRows: totalCustomers = 0 } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getAllCustomers, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalCustomers / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={customers}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const customersTableColumns = (getCustomerLink: any): ColumnDef<any, any>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    cell: ({ getValue }) => {
      return (
        <IdTag
          id={getValue()}
          linkTo={getCustomerLink(getValue())}
          allowCopyToClipboard
        />
      )
    },
  },
  {
    header: 'V1 User ID',
    accessorKey: 'v1UserId',
    cell: ({ getValue }) => {
      return (
        <div>
          <Typography fontSize={11}>{getValue()}</Typography>
        </div>
      )
    },
  },

  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'registrationDate',
    header: 'Registration Date',
    cell: ({ getValue }) => {
      return (
        <Box>
          <DateTime dateTime={getValue()} tooltipPlacement="right" />
        </Box>
      )
    },
  },
]
