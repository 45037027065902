import { Typography } from '@mui/material'
import { DockItem } from '@trueskin-backoffice/components'
import { PageContentDockDivider } from '../../layouts/page-content.layout'
import { CustomerMutationsProvider } from '../contexts'
import {
  DeleteCustomerDockAction,
  EditBillingAddressDockAction,
  EditBirthDateAction,
  EditEmailAction,
  EditFirstNameAction,
  EditLastNameAction,
  EditShippingAddressDockAction,
} from './actions'
import { EditAccountEmailAction } from './actions/edit-account-email.dock-action'
import { EditAccountPasswordAction } from './actions/edit-account-password.dock-action'

export const CustomerDock = () => (
  <CustomerMutationsProvider>
    <Typography sx={{ color: 'info.main' }}>Customer actions</Typography>

    <DockItem>
      <EditFirstNameAction />
    </DockItem>

    <DockItem>
      <EditLastNameAction />
    </DockItem>

    <DockItem>
      <EditEmailAction />
    </DockItem>

    <DockItem>
      <EditBirthDateAction />
    </DockItem>

    <PageContentDockDivider />

    <DockItem>
      <EditBillingAddressDockAction />
    </DockItem>

    <DockItem>
      <EditShippingAddressDockAction />
    </DockItem>

    <PageContentDockDivider />

    <DockItem>
      <EditAccountEmailAction />
    </DockItem>

    <DockItem>
      <EditAccountPasswordAction />
    </DockItem>

    <PageContentDockDivider />

    <DockItem>
      <DeleteCustomerDockAction />
    </DockItem>
  </CustomerMutationsProvider>
)
