import { ChangeCircle } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useAuth } from '@trueskin-backoffice/contexts'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CurrentChannelButton = () => {
  const navigate = useNavigate()
  const { channel, switchChannel } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Box
        id="current-channel-button"
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'unset',
          color: 'info.main',
          width: '100%',
          '&:hover': {
            backgroundColor: blue[50],
          },
        }}
        onClick={(event) => handleClick(event)}
      >
        <Box>{channel === 'web' ? 'Web/Acne' : 'ToFu'} channel</Box>

        <IconButton color="inherit" sx={{ ml: 'auto', p: 0 }}>
          <ChangeCircle />
        </IconButton>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'current-channel-button',
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {channel === 'web' ? (
          <MenuItem
            onClick={() => {
              switchChannel('tofu')
              navigate('/tofu/patients')
              handleClose()
            }}
          >
            Switch to ToFu channel
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              switchChannel('web')
              navigate('/customers')
              handleClose()
            }}
          >
            Switch to Web/Acne channel
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
