import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
} from '@mui/material'
import { useTheme } from '@mui/system'
import { useV2Mutation } from '@trueskin-backoffice/api-client'
import { H3, NumberInput } from '@trueskin-backoffice/components'
import { useState } from 'react'
import { getInvoicesByOrderId, getOrderById, initiateRefund } from '../gql'

export const InvoiceRefundDialog = ({ invoice, refundFn }: any) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [refundAmount, setRefundAmount] = useState<string | undefined>(
    `${(invoice.amountPaid - invoice.amountRefunded) / 100}`,
  )
  const [refundError, setRefundError] = useState<string>('')

  const [initiateRefundFn, { error, loading }] = useV2Mutation(initiateRefund, {
    refetchQueries: [getOrderById, getInvoicesByOrderId],
  })

  const fullyRefunded =
    invoice.amountPaid && invoice.amountPaid === invoice.amountRefunded

  const canRefund =
    invoice.status?.toLowerCase() === 'paid' &&
    !fullyRefunded &&
    invoice.amountPaid >
      invoice.amountRefundBeingProcessed + invoice.amountRefunded

  const handleRefundAmount = (e: any) => {
    if (
      Number(e.target.value) <=
      (invoice.amountPaid - invoice.amountRefunded) / 100
    ) {
      setRefundAmount(e.target.value)
      setRefundError('')
    } else {
      setRefundError('Cannot refund more than paid')
    }
  }

  const handleRefundSubmit = async () => {
    if (refundFn) {
      await refundFn({
        variables: { invoiceId: invoice.id, amount: Number(refundAmount) },
      })
    } else {
      await initiateRefundFn({
        variables: { invoiceId: invoice.id, amount: Number(refundAmount) },
      })
    }

    setOpen(false)
  }

  const handleCancel = () => {
    setRefundAmount(`${(invoice.amountPaid - invoice.amountRefunded) / 100}`)
    setConfirmation(false)
    setRefundError('')
    setOpen(false)
  }

  if (fullyRefunded) {
    return (
      <H3
        sx={{
          fontStyle: 'italic',
          color: theme.palette['success'],
          fontSize: '14px',
        }}
      >
        Fully Refunded
      </H3>
    )
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={!canRefund}>
        Refund
      </Button>

      {open && (
        <Dialog open={open}>
          <DialogTitle>Invoice refund</DialogTitle>
          <Container>
            <Box sx={{ p: 4 }}>
              <FormControl fullWidth>
                <NumberInput
                  id="refund-amount"
                  name="refund-amount"
                  label="Amount"
                  value={refundAmount}
                  fullWidth
                  suffix="EUR"
                  onChange={handleRefundAmount}
                  error={refundError !== ''}
                  helperText={refundError}
                />
              </FormControl>
              <Box
                sx={{
                  maxWidth: '300px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={confirmation}
                      onChange={(e) => setConfirmation(e.target.checked)}
                    />
                  }
                  label="I confirm to refund this amount"
                />
              </Box>
            </Box>
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 4 }}
            >
              <LoadingButton
                loading={loading}
                disabled={
                  !confirmation || refundAmount === '' || refundError !== ''
                }
                onClick={handleRefundSubmit}
              >
                Refund
              </LoadingButton>
              <Button disabled={loading} onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
          </Container>
        </Dialog>
      )}
    </>
  )
}
