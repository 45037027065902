import { CommentsContainer } from './components'
import { CommentsProvider } from './contexts/comments.context'

export interface CommentsProps {
  namespace: string
  children: React.ReactNode
}

export const Comments = (props: CommentsProps) => {
  return (
    <CommentsProvider namespace={props.namespace}>
      <CommentsContainer>{props.children}</CommentsContainer>
    </CommentsProvider>
  )
}

export * from './components'
