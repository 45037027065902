import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { DangerButton, DismissButton } from '../../buttons'
import { AccountMutationsProvider, useAccountMutations } from '../context'

export const DeleteAccountModal = ({
  accountId,
  open,
  onClose,
  onSuccess,
}: any) => {
  if (!open) {
    return null
  }

  return (
    <AccountMutationsProvider accountId={accountId}>
      <Modal opened={open} onClose={onClose} onSuccess={onSuccess} />
    </AccountMutationsProvider>
  )
}

const Modal = ({ opened, onClose, onSuccess }: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const [confirmation, setConfirmation] = useState<boolean>(false)
  const {
    deleteAccount,
    deleteAccountLoading,
    deleteAccountError,
    verifyAccountDeletionState,
    verifyAccountDeletionStateLoading,
    verifyAccountDeletionStateError,
  } = useAccountMutations()

  const canDelete =
    verifyAccountDeletionState?.account?.canDeleteV1User &&
    verifyAccountDeletionState?.account?.canDeleteTofuPatient

  if (verifyAccountDeletionStateLoading) {
    return null
  }

  return (
    <Dialog
      open={opened}
      onClose={onClose}
      keepMounted
      aria-labelledby="confirm-action-dialog-title"
    >
      <Box sx={{ m: 0, p: 0, mb: 4 }}>
        <Alert severity="warning" sx={{ px: 4 }}>
          <Typography>
            This is a destructive and irreversible action.
          </Typography>
          <Typography fontWeight={500}>
            The account will be deleted from both core and tofu.
          </Typography>
        </Alert>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          position: 'relative',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ mb: 8 }}>
            <Typography variant="h1" fontWeight={500}>
              Delete account
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            pl: 10,
            display: 'flex',
            flex: '0 0 280px',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              visibility: verifyAccountDeletionStateLoading
                ? 'hidden'
                : 'visible',
              textAlign: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <DismissButton onClick={onClose} />

            <DangerButton
              sx={{ ml: 4 }}
              onClick={async () => {
                try {
                  await deleteAccount()
                  enqueueSnackbar('Account was deleted successfully', {
                    variant: 'success',
                  })
                  onSuccess && onSuccess()
                } catch (err) {
                  enqueueSnackbar('Account deletion failed', {
                    variant: 'error',
                  })
                }
              }}
              autoFocus
              disabled={
                !confirmation ||
                !!verifyAccountDeletionStateError ||
                !canDelete ||
                deleteAccountLoading
              }
            >
              Delete
            </DangerButton>
          </Box>
        </Box>
      </Box>

      {!canDelete && (
        <Box sx={{ mt: 4 }}>
          {!verifyAccountDeletionState?.account?.canDeleteV1User && (
            <Typography sx={{ color: 'error.main' }}>
              We cannot delete the core user records.
            </Typography>
          )}

          {!verifyAccountDeletionState?.account?.canDeleteTofuPatient && (
            <Typography sx={{ color: 'error.main' }}>
              We cannot delete the tofu patient records.
            </Typography>
          )}
        </Box>
      )}

      <FormGroup sx={{ mt: 4 }}>
        <FormControlLabel
          control={
            <Checkbox
              value="delete-account-check"
              disabled={
                verifyAccountDeletionStateLoading ||
                !canDelete ||
                deleteAccountLoading
              }
              onChange={(event) => setConfirmation(event.target.checked)}
            />
          }
          label="Are you sure you want to continue?"
        />
      </FormGroup>

      {!!deleteAccountError && <pre>{JSON.stringify(deleteAccountError)}</pre>}
    </Dialog>
  )
}
