import { Delete } from '@mui/icons-material'
import { DeleteAccountModal, DockAction } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// TODO: this should be refactored in a single component (also remove the one from tofu patient) which receives the accountId
export const DeleteAccountDockAction = () => {
  const navigate = useNavigate()

  const { customer } = useCustomer()
  const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState(false)

  return (
    <>
      <DockAction
        startIcon={<Delete />}
        onClick={() => setConfirmationDialogOpened(true)}
      >
        Delete account
      </DockAction>

      {confirmationDialogOpened && (
        <DeleteAccountModal
          accountId={customer.accountId}
          open={confirmationDialogOpened}
          onClose={() => setConfirmationDialogOpened(false)}
          onSuccess={() => {
            setConfirmationDialogOpened(false)
            navigate('/customers')
          }}
        />
      )}
    </>
  )
}
