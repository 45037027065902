import { useMutation } from '@apollo/client'
import { Button, Dialog, MenuItem, Select, Typography } from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import React, { useCallback } from 'react'
import { useWaitingRoomCase } from '../waiting-room-case.context'
import {
  getWaitingRoomCase,
  rejectDiagnosisForCaseMutation,
} from '../waiting-room.gql.bff'

import { Box } from '@mui/material'
import { HeroCardSection } from '@trueskin-backoffice/components'
import { CaseRejectionReason } from '@trueskin-backoffice/gql'
import { EditorPlayground } from '@trueskin/bo/editor'

export const CaseRejectionModal = ({ onClose }: { onClose: any }) => {
  const { getDoctorProfile } = useAuth()
  const { currentCase } = useWaitingRoomCase()

  const [
    rejectDiagnosisForCase,
    {
      loading: rejectDiagnosisForCaseLoading,
      error: rejectDiagnosisForCaseError,
    },
  ] = useMutation(rejectDiagnosisForCaseMutation, {
    refetchQueries: [getWaitingRoomCase],
    onCompleted: () => {
      // setEditorHtmlValue('<p><br></p>')
    },
  })

  const handleReject = useCallback(
    async ({
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      await rejectDiagnosisForCase({
        variables: {
          customerId: currentCase.customerId,
          caseId: currentCase.id,
          doctor: getDoctorProfile(),
          rejectionReason: CaseRejectionReason.Other,
          rejectionMessageRichText: richText,
          rejectionMessageMarkdown: markdown,
        },
      })
      onClose()
    },
    [],
  )

  return (
    <Box sx={{ overflow: 'visible', p: 0, minWidth: 500 }}>
      <Box sx={{ mb: 8 }}>
        <Typography variant="h1" fontWeight={500}>
          Reject case #{currentCase.documentNumber} for{' '}
          {currentCase.customer.profile.firstName}{' '}
          {currentCase.customer.profile.lastName}
        </Typography>
      </Box>

      <HeroCardSection sx={{ mb: 1 }}>
        <Box>
          Rejection reason (internal)
          <Select
            sx={{ width: '100%' }}
            value={CaseRejectionReason.Other}
            name="gender"
            displayEmpty
            disabled={true}
            inputProps={{
              readOnly: true,
            }}
          >
            <MenuItem value={CaseRejectionReason.Other}>
              {CaseRejectionReason.Other}
            </MenuItem>
          </Select>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Box>Rejection message (customer facing)</Box>
          {/*<Editor*/}
          {/*  // initialValue={editorHtmlValue}*/}
          {/*  // value={editorHtmlValue}*/}
          {/*  onChange={onEditorContentChanged}*/}
          {/*/>*/}
          <Box
            sx={{
              mx: 0,
              my: 2,
              p: 0,
              borderRadius: 1,
              boxShadow:
                '0 0 2px 0 rgb(0 0 30 / 10%), 0 2px 4px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%)',
            }}
          >
            <EditorPlayground
              showActionsSendMessageButton
              handleSendMessage={handleReject}
            />
          </Box>
        </Box>

        {/*<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="error"*/}
        {/*    disabled={*/}
        {/*      !editorHtmlValue ||*/}
        {/*      editorHtmlValue === '<p><br></p>' ||*/}
        {/*      rejectDiagnosisForCaseLoading*/}
        {/*    }*/}
        {/*    size="small"*/}
        {/*    sx={{ borderRadius: '0 4px 4px 0', boxShadow: 'none', mt: 2 }}*/}
        {/*    onClick={async () => {*/}
        {/*      await rejectDiagnosisForCase({*/}
        {/*        variables: {*/}
        {/*          customerId: currentCase.customerId,*/}
        {/*          caseId: currentCase.id,*/}
        {/*          doctor: getDoctorProfile(),*/}
        {/*          rejectionReason: CaseRejectionReason.Other,*/}
        {/*          rejectionMessage: editorHtmlValue,*/}
        {/*        },*/}
        {/*      })*/}
        {/*      onClose()*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Reject with message*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </HeroCardSection>
    </Box>
  )
}

export const RejectDiagnosisButton = () => {
  const { currentCase } = useWaitingRoomCase()
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  if (!currentCase || currentCase.status.toLowerCase() !== 'active') {
    return null
  }

  if (!currentCase.actions.canRejectDiagnosis.enabled) {
    return null
  }

  return (
    <>
      <Button color="error" onClick={() => setShowEditDialog(true)}>
        Reject diagnosis
      </Button>

      {showEditDialog ? (
        <Dialog
          open={true}
          // keepMounted
          onClose={() => setShowEditDialog(false)}
          sx={{ minWidth: 500 }}
        >
          <CaseRejectionModal onClose={() => setShowEditDialog(false)} />
        </Dialog>
      ) : null}
    </>
  )
}
