import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { AssignConsultationsPage } from './assign-consultations.page'
import { ConsultationsPage } from './consultations.page'

import GroupsIcon from '@mui/icons-material/Groups'
import ListIcon from '@mui/icons-material/List'
import SettingsIcon from '@mui/icons-material/Settings'
import { useAuth } from '@trueskin-backoffice/contexts'
import { ROLE_ID_MAP, Role } from '../employees/types'
import { ConsultationsSettingsPage } from './consultations-settings.page'

export const ConsultationsRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { employee, loading } = useAuth()

  const isMedicalLead = employee?.roles.includes(ROLE_ID_MAP[Role.MEDICAL_LEAD])

  if (!employee || loading) {
    return
  }

  return (
    <Box>
      <Box sx={{ mb: '60px' }}>
        <Routes>
          <Route path={'/'} element={<ConsultationsPage />} />
          {isMedicalLead && (
            <Route path={'/assign'} element={<AssignConsultationsPage />} />
          )}
          {isMedicalLead && (
            <Route path={'/settings'} element={<ConsultationsSettingsPage />} />
          )}
        </Routes>
      </Box>

      {isMedicalLead && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: '43%',
            pt: -30,
          }}
        >
          <BottomNavigation
            sx={{
              boxShadow:
                '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            }}
            showLabels
            value={location.pathname}
            onChange={(event, newValue) => {
              navigate(newValue)
            }}
          >
            <BottomNavigationAction
              label="Consultations"
              value={'/consultations'}
              icon={<ListIcon />}
            />
            <BottomNavigationAction
              label="Assign"
              value={'/consultations/assign'}
              icon={<GroupsIcon />}
            />
            <BottomNavigationAction
              label="Settings"
              value={'/consultations/settings'}
              icon={<SettingsIcon />}
            />
          </BottomNavigation>
        </Box>
      )}
    </Box>
  )
}
