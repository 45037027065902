import { Box, Grid, List, ListItem, ListItemText, Paper } from '@mui/material'
import { grey } from '@mui/material/colors'
import { H2, HeroCard, OrderItem } from '@trueskin-backoffice/components'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { NavLink } from 'react-router-dom'

export const OrderGeneralInformation = ({ order }: any) => {
  return (
    <HeroCard>
      <Grid
        container
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <Grid xs={9}>
          <H2>General information</H2>

          <List dense>
            <ListItem>
              <ListItemText
                primary={`Preset: ${order.preset}`}
                secondary={`Occurrence: ${order.occurrence}`}
              />
            </ListItem>
            {order?.subscription?.id && (
              <ListItem>
                <NavLink
                  to={`/customers/${order.customerId}/subscriptions/${order.subscription.id}`}
                >
                  {order.subscription.id}
                </NavLink>
              </ListItem>
            )}
          </List>
        </Grid>

        <Grid xs={3}>
          <Paper sx={{ p: 2, backgroundColor: grey['50'] }}>
            <OrderPriceSummary order={order} />
          </Paper>
        </Grid>
      </Grid>

      <Box
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <H2>Items</H2>

        <Box sx={{ display: 'flex', fontSize: 14, mt: 2, ml: 4 }}>
          {order.items?.map(({ product, quantity }: any) => (
            <OrderItem product={product} quantity={quantity} />
          ))}
        </Box>
      </Box>
    </HeroCard>
  )
}

export const OrderPriceSummary = ({ order }: any) => (
  <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>Subtotal</span>
      <span>
        {formatCurrency(
          order.values.totalWithoutDiscounts / 100,
          order.locale,
          order.currency,
        )}
      </span>
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>Discounts</span>
      <span>
        {formatCurrency(
          order.values.discount / 100,
          order.locale,
          order.currency,
        )}
      </span>
    </Box>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${grey['300']}`,
        mt: 1,
        pt: 1,
      }}
    >
      <span>Total</span>
      <span>
        {formatCurrency(order.values.total / 100, order.locale, order.currency)}
      </span>
    </Box>
  </>
)
