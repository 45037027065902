import { Box, Button, ButtonProps, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DockAction = styled(
  (props: ButtonProps & { tooltip?: string }) => (
    <Tooltip title={props.tooltip} placement="right">
      <Box>
        <Button
          sx={{
            fontSize: 12,
            fontWeight: 600,
            m: 0,
            py: 0,
            lineHeight: 1,
            textTransform: 'unset',
          }}
          variant="text"
          disableRipple
          disableFocusRipple
          disableElevation
          color="info"
          {...props}
        />
      </Box>
    </Tooltip>
  ),
)()
