import { Box, darken, lighten, useTheme } from '@mui/material'
import {
  InvoiceStatus,
  OrderStatus,
  SubscriptionEndBehaviour,
  SubscriptionStatus,
  TransactionStatus,
} from '@trueskin-backoffice/contexts/types'

/* these are defined in theme/base/colors */
export const GenericStatusColorMap = {
  created: 'created',
  in_progress: 'in_progress',
  succeeded: 'succeeded',
  failed: 'failed',
  action_required: 'action_required',

  // active: 'active',
  // waiting_payment: 'waiting_payment',
}

export const StatusBadge = ({
  status,
  fullWidth,
  // statusKey,
  isRounded = true,
}: {
  status: string
  // statusKey?: string
  isRounded?: boolean
  fullWidth?: boolean
}) => {
  const theme: any = useTheme()
  const statusKey = status?.toLowerCase()
  const color =
    theme.palette?.custom[statusKey as keyof typeof GenericStatusColorMap] ||
    theme.palette?.custom?.default

  const statusText =
    TransactionStatus[statusKey as keyof typeof TransactionStatus] ||
    OrderStatus[statusKey as keyof typeof OrderStatus] ||
    SubscriptionStatus[statusKey as keyof typeof SubscriptionStatus] ||
    SubscriptionEndBehaviour[
      statusKey as keyof typeof SubscriptionEndBehaviour
    ] ||
    InvoiceStatus[statusKey as keyof typeof InvoiceStatus]

  const backgroundColor = lighten(color, 0.5)
  const textColor = darken(color, 0.6)

  return (
    <Box
      component="span"
      sx={{
        color: textColor,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        fontWeight: 'bold',
        fontSize: '14px',
        width: fullWidth ? '100%' : 'fit-content',
        borderRadius: isRounded ? 1 : 0,
      }}
    >
      {statusText}
    </Box>
  )
}
