import { gql } from '@apollo/client'

const paymentMethodFields = gql`
  fragment PaymentMethodFields on PaymentMethod {
    id
    type
    status
    gateway
    deleted
    paypal {
      email
      billingAgreementId
    }
    card {
      expiryMonth
      expiryYear
      lastFourDigits
      paymentMethod
      paymentMethodVariant
    }
    sepaDirectDebit {
      iban
      ownerName
    }
  }
`

// export const getUpcomingOrderForSubscription = gql`
//   query GetUpcomingOrderForSubscription($v1UserId: String!) {
//     getUpcomingOrderForSubscription(v1UserId: $v1UserId) {
//       _id
//     }
//   }
// `

export const createUpcomingOrderForSubscriptionMutation = gql`
  mutation CreateUpcomingOrderForSubscription($v1UserId: String!) {
    createUpcomingOrderForSubscription(v1UserId: $v1UserId) {
      _id
    }
  }
`

export const getPaymentSourceByCustomerId = gql`
  ${paymentMethodFields}
  query GetPaymentSource($customerId: String!) {
    getPaymentSource(customerId: $customerId) {
      id
      primaryPaymentMethodId
      customerId
      paymentMethods {
        ...PaymentMethodFields
      }
    }
  }
`

export const addTnxFailureCardMutation = gql`
  mutation AddTnxFailureCard($customerId: String!) {
    addTnxFailureCard(customerId: $customerId) {
      id
    }
  }
`

export const setPaymentMethodAsPrimaryMutation = gql`
  mutation SetPaymentMethodAsPrimary(
    $customerId: String!
    $paymentMethodId: String!
  ) {
    setPaymentMethodAsPrimary(
      customerId: $customerId
      paymentMethodId: $paymentMethodId
    ) {
      id
    }
  }
`
