import { useMutation } from '@apollo/client'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Icon,
  Popper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import React from 'react'
import { unwatchCaseMutation, watchCaseMutation } from '../waiting-room.gql.bff'

export const WatchOptions = ({ currentCase }: any) => {
  const { employee } = useAuth()
  const caseWatchList = currentCase?.metadata?.watchList || []
  const isCurrentEmployeeWatching = caseWatchList.find(
    ({ employeeId }: any) => employeeId === employee?.id,
  )
  const VisibilityIcon = isCurrentEmployeeWatching ? (
    <Icon component={Visibility} sx={{ fontSize: 16 }} />
  ) : (
    <Icon component={VisibilityOff} sx={{ fontSize: 16 }} />
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const [watchCase, { loading: watchCaseLoading, error: watchCaseError }] =
    useMutation(watchCaseMutation, {
      variables: {
        customerId: currentCase.customerId,
        caseId: currentCase.id,
        employeeId: employee?.id,
        email: employee?.email,
      },
    })

  const [
    unwatchCase,
    { loading: unwatchCaseLoading, error: unwatchCaseError },
  ] = useMutation(unwatchCaseMutation, {
    variables: {
      customerId: currentCase.customerId,
      caseId: currentCase.id,
      employeeId: employee?.id,
    },
  })

  return (
    <Box>
      <Tooltip title="Watch options" placement="top">
        <Button
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            handleClick(event)
          }}
          color="info"
          sx={{ py: 0, px: 1, minWidth: 'unset' }}
        >
          {VisibilityIcon}
          <Typography sx={{ ml: 1 }}>{caseWatchList.length}</Typography>
        </Button>
      </Tooltip>

      <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Box>
              {isCurrentEmployeeWatching ? (
                <Button
                  sx={{ textTransform: 'unset' }}
                  color="info"
                  fullWidth
                  onClick={() => unwatchCase()}
                >
                  Stop watching
                </Button>
              ) : (
                <Button
                  sx={{ textTransform: 'unset' }}
                  color="info"
                  fullWidth
                  onClick={() => watchCase()}
                >
                  Start watching
                </Button>
              )}
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <WatchList watchList={caseWatchList} />
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  )
}

const WatchList = ({ watchList }: any) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="caption" fontWeight={600} sx={{ mb: 1 }}>
        Watching this case
      </Typography>

      {!watchList?.length ? (
        <Typography variant="caption">Nobody</Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {watchList.map(({ email }: any) => (
            <Typography>{email}</Typography>
          ))}
        </Box>
      )}
    </Box>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,

  // width: 200,
  zIndex: theme.zIndex.modal,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))
