import { Box, useTheme } from '@mui/material'
import { lighten } from '@mui/system'
import { OrderStatus } from '@trueskin-backoffice/contexts/types'
import { CurrencyAmount } from './currency-amount'
import { StatusBadge } from './status-badge'

/* these are defined in theme/base/colors */
export const OrderStatusColorMap = {
  payment_due: 'payment_due',
  paid: 'paid',
  upcoming: 'upcoming',
  open: 'open',
  cancelled: 'cancelled',
  not_paid: 'not_paid',
  voided: 'voided',
}

export const PaymentInfo = ({
  status,
  amount,
  currency,
}: {
  status: string
  amount?: number
  currency?: string
}) => {
  const theme: any = useTheme()
  const statusKey = status?.toLowerCase()
  const color =
    theme.palette?.custom[statusKey as keyof typeof OrderStatusColorMap] ||  theme.palette?.custom?.default

  const statusText = OrderStatus[statusKey as keyof typeof OrderStatus]

  const backgroundColor = lighten(color, 0.5)

  return (
    <Box
      sx={{
        display: 'flex',
        borderColor: backgroundColor,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      {amount ? <CurrencyAmount amount={amount} currency={currency} /> : null}
      <StatusBadge status={status} fullWidth />
    </Box>
  )
}
