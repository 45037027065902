import { ApolloError } from '@apollo/client'
import { useV2Query } from '@trueskin-backoffice/api-client'
import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { getOrderById } from '../gql'

interface StandardProps {
  children?: ReactNode
}

export interface OrderContextProps {
  loading: boolean
  error: string | undefined | ApolloError | Error

  order?: any
  reFetchOrder: any
}

const OrderContext = React.createContext<OrderContextProps | undefined>(
  undefined,
)

function OrderProvider(props: StandardProps) {
  const { orderId } = useParams<{ orderId: string }>()

  const {
    data: { getOrderById: order } = {},
    loading,
    error,
    refetch,
  } = useV2Query(getOrderById, {
    skip: !orderId,
    variables: { orderId },
  })

  React.useEffect(() => {
    refetch()
  }, [orderId])

  const value = React.useMemo(
    () => ({
      loading,
      error,
      order,
      reFetchOrder: refetch,
    }),
    [loading, error, order],
  )

  return (
    <OrderContext.Provider value={value}>
      {props.children}
    </OrderContext.Provider>
  )
}

function useOrder(): OrderContextProps {
  const context = React.useContext(OrderContext)
  if (context === undefined) {
    throw new Error(`useOrder must be used within a OrderProvider`)
  }
  return context
}

export { OrderProvider, useOrder }
