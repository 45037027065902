import { gql } from '@apollo/client'
export const getCustomerReferralRewards = gql`
  query GetCustomerReferralRewards($customerId: String!) {
    customer(customerId: $customerId) {
      id
      referralRewards {
        _id
        referrer
        referee
        amount
        currency
        usedOn
        addedOn
        reason
      }
    }
  }
`
