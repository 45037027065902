import { Clear } from '@mui/icons-material'
import { DockAction } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import React, { useMemo } from 'react'
import { useSubscriptionMutations } from '../../contexts'
import { ConfirmSubscriptionActionDialog } from './confirm-subscription-action-dialog'

export const CancelSubscriptionAction = ({ subscription }: any) => {
  const { customer } = useCustomer()

  const { actions } = subscription
  const [showEditDialog, setShowEditDialog] = React.useState(false)
  const { scheduleToCancel, cancelImmediately, error } =
    useSubscriptionMutations()

  const dunning = false // TODO: this needs fixing

  const options = useMemo(
    () => [
      {
        rule: actions.canCancel.immediately.enabled,
        action: 'Cancel immediately',
        onSubmit: cancelImmediately,
      },
      {
        rule: actions.canCancel.atEndOfTerm.enabled,
        action: 'Cancel at end of term',
        onSubmit: scheduleToCancel,
      },
    ],
    [subscription],
  )

  return (
    <>
      <DockAction
        startIcon={<Clear />}
        disabled={!actions.canCancel.enabled}
        onClick={() => setShowEditDialog(true)}
      >
        Cancel subscription
      </DockAction>

      {showEditDialog ? (
        <ConfirmSubscriptionActionDialog
          header={`Cancel subscription for ${customer.firstName} ${customer.lastName}`}
          description={'When do you want to cancel the subscription?'}
          onClose={() => setShowEditDialog(false)}
          opened={showEditDialog}
          confirmText={
            dunning
              ? [
                  'Submitting this will affect the customers cancellation date. Select to confirm you want to make the changes?',
                  'The current invoice is in dunning. Cancelling the subscription will also void the invoice and stop dunning process',
                ]
              : [
                  'Submitting this will affect the customers cancellation date. Select to confirm you want to make the changes?',
                ]
          }
          options={options}
          submitLabel={'Schedule cancellation'}
          error={error?.toString()}
        />
      ) : null}
    </>
  )
}
