import { useMutation, useQuery } from '@apollo/client'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import {
  ColumnDef,
  DataFilterTextInput,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
  DataTable,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  getNotionCmsQuestions,
  syncNotionCmsQuestionsMutation,
} from '../notion-cms.gql.bff'

export const NotionQuestionsTable = () => {
  const { query: filter } = useDataFilterV2()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { notionCmsQuestions } = { notionCmsQuestions: [] },
    loading,
    error,
  } = useQuery(getNotionCmsQuestions)

  const [syncNotionCmsQuestions, { loading: syncLoading, error: syncError }] =
    useMutation(syncNotionCmsQuestionsMutation, {
      refetchQueries: [getNotionCmsQuestions],
      onCompleted: (_) => {
        enqueueSnackbar('Sync completed successfully.', { variant: 'success' })
      },
    })

  const [data, setData] = useState(notionCmsQuestions)

  useEffect(() => {
    if (!filter?.search && data) {
      setData(notionCmsQuestions)
      return
    }

    const textsToSearch = filter?.search?.split(' ').filter((r: string) => !!r)

    setData(
      notionCmsQuestions.filter((r: any) =>
        textsToSearch.some(
          (el: string) => r._id.toLowerCase().indexOf(el) > -1,
        ),
      ),
    )
  }, [filter, setData, data, notionCmsQuestions])

  if (loading) {
    return <Box>Loading data</Box>
  }

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: '_id',
      header: 'Name',
      cell: ({ getValue, row }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'mdContent',
      header: 'Content',
      cell: ({ getValue, row }) => {
        // return <Typography>{getValue()}</Typography>

        return <ReactMarkdown>{getValue()}</ReactMarkdown>
      },
    },
  ]

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DataFilterTextInput
          input={{
            label: 'Search records',
            key: 'search',
            type: DataFilterV2FilterComponent.TEXT,
            op: DataFilterV2InputOperation.EQUALS,
          }}
        />
        <LoadingButton
          loading={syncLoading}
          loadingPosition="start"
          startIcon={syncLoading ? <Save /> : null}
          sx={{ textTransform: 'unset' }}
          onClick={() => syncNotionCmsQuestions()}
        >
          Sync questions from notion
        </LoadingButton>
      </Box>

      <Box>
        <DataTable columns={columns} data={data} isLoading={loading} />
      </Box>
    </Box>
  )
}
