import { Box } from '@mui/material'
import { CustomerProvider } from '@trueskin-backoffice/contexts'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'
import {
  AuditPage,
  CronJobsPage,
  CustomerPage,
  CustomersPage,
  ExternalWebhooksPage,
  NewPagesRouter,
  OrdersLegacyPage,
  SubscriptionPage,
  SubscriptionsPage,
  WebhooksPage,
} from '../pages'
import { ConsultationsRouter } from '../pages/consultations'
import { CustomerHeader } from '../pages/customer/customer-header'
import { CustomerSubscriptionsPage } from '../pages/customer/customer-subscriptions.page'
import { DashboardPage } from '../pages/dashboard'
import { DiscountGroupPage } from '../pages/discount-group'
import { DiscountGroupsPage } from '../pages/discount-groups'
import { EmployeesRouter } from '../pages/employees/employees.router'
import { InvoicesPage } from '../pages/invoices'
import { MedicalLibraryPage } from '../pages/medical-library/medical-library.page'
import { MedicalDashboard } from '../pages/medical/dashboard/medical-dashboard'
import { OrderProvider } from '../pages/order/contexts'
import OrderPage from '../pages/order/order.page'
import { PrescriptionsPage } from '../pages/prescriptions'
import { PresetListsPage } from '../pages/preset-lists'
import { PresetsPage } from '../pages/presets'
import { PricingPage } from '../pages/pricing'
import { ProductsPage } from '../pages/products'
import { ReferralRewardsPage } from '../pages/referral-rewards'
import { TofuRoutes } from '../pages/tofu'
import { VoucherPage } from '../pages/voucher'
import { VouchersPage } from '../pages/vouchers'
import { DefaultLayout } from './default.layout'
import './lightbox.css'
import { Login } from './login'
import { Logout } from './logout'
import { MedicalLayout } from './medical.layout'

export function App() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Routes>
        <Route path={'/'} element={<Navigate to="/customers" />} />

        <Route
          path={'/m/*'}
          element={
            <MedicalLayout>
              <Outlet />
            </MedicalLayout>
          }
        >
          <Route path="" element={<MedicalDashboard />} />
          <Route
            path="customers/:customerId/*"
            element={
              <CustomerProvider>
                {/*<Box>*/}
                {/*  <CustomerHeader />*/}

                {/*  /!*<Outlet />*!/*/}
                {/*</Box>*/}

                {/*<HealthRecordProvider>*/}
                <CustomerHeader />
                {/*<CustomerHealthRecord />*/}
                {/*</HealthRecordProvider>*/}
              </CustomerProvider>
            }
          >
            {/*<Route path="" element={<HeroCardEmpty />} />*/}
            <Route path="consultations/:consultationId" element={<></>} />
          </Route>
        </Route>

        <Route path={'/login'} element={<Login />} />
        <Route path={'/logout'} element={<Logout />} />

        <Route
          path={'/*'}
          element={
            <DefaultLayout>
              <Outlet />
            </DefaultLayout>
          }
        >
          <Route path={'dashboard'} element={<DashboardPage />} />

          <Route path={'customers'} element={<CustomersPage />} />

          <Route
            path={'customers/:customerId'}
            element={
              <CustomerProvider>
                {/*<HealthRecordProvider>*/}
                <Outlet></Outlet>
                {/*</HealthRecordProvider>*/}
              </CustomerProvider>
            }
          >
            <Route path={''} element={<CustomerPage />} />

            <Route
              path={'*'}
              element={
                <Box>
                  <CustomerHeader />

                  <Outlet />
                </Box>
              }
            >
              <Route path={'subscriptions/*'}>
                <Route path={''} element={<CustomerSubscriptionsPage />} />
                <Route
                  path={':subscriptionId'}
                  element={<SubscriptionPage />}
                />
              </Route>

              <Route
                path={'referral-rewards'}
                element={<ReferralRewardsPage />}
              />

              <Route
                path={'orders/:orderId'}
                element={
                  <OrderProvider>
                    <Outlet />
                  </OrderProvider>
                }
              >
                <Route path={''} element={<OrderPage />} />
                <Route />
              </Route>
            </Route>
          </Route>

          <Route path={'subscriptions'} element={<SubscriptionsPage />} />
          <Route path={'prescriptions'} element={<PrescriptionsPage />} />
          <Route path={'orders'} element={<OrdersLegacyPage />} />
          <Route path={'employees/*'} element={<EmployeesRouter />} />
          <Route path={'consultations/*'} element={<ConsultationsRouter />} />

          <Route
            path={'external-webhooks'}
            element={<ExternalWebhooksPage />}
          />
          <Route path={'cron-jobs'} element={<CronJobsPage />} />
          <Route path={'webhooks'} element={<WebhooksPage />} />
          <Route path={'invoices'} element={<InvoicesPage />} />
          <Route path={'audit'} element={<AuditPage />} />
          <Route path={'vouchers'} element={<VouchersPage />} />
          <Route path={'vouchers/:voucherId'} element={<VoucherPage />} />
          <Route path={'pricing'} element={<PricingPage />} />
          <Route path={'products'} element={<ProductsPage />} />
          <Route path={'discount-groups'} element={<DiscountGroupsPage />} />
          <Route
            path={'discount-groups/:discountGroupId'}
            element={<DiscountGroupPage />}
          />
          <Route path={'presets'} element={<PresetsPage />} />
          <Route path={'preset-lists'} element={<PresetListsPage />} />

          <Route path={'tofu/*'} element={<TofuRoutes />} />
          <Route path={'medical-library/*'} element={<MedicalLibraryPage />} />

          <Route path={'new-pages/*'} element={<NewPagesRouter />} />
        </Route>
      </Routes>
    </QueryParamProvider>
  )
}

export default App
