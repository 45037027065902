import { Card, CardProps } from '@mui/material'
import { styled } from '@mui/system'
import { ReactNode } from 'react'

export const HeroCard = styled(({ children, ...other }: HeroCardProps) => (
  <Card
    sx={{
      boxShadow:
        '0 0 2px 0 rgb(0 0 30 / 10%), 0 2px 4px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%)',
      position: 'relative',
      border: 0,
      borderRadius: 1,
      width: '100%',
      py: 3,
      px: 4,
    }}
    {...other}
  >
    {children}
  </Card>
))()

export interface HeroCardProps extends CardProps {
  children?: ReactNode
}
