import PersonOffIcon from '@mui/icons-material/PersonOff'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import {
  Box,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material'
import { useV2Mutation, useV2Query } from '@trueskin-backoffice/api-client'
import { H2, Id, MultiPick } from '@trueskin-backoffice/components'
import { get } from 'lodash'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CONCURRENT_ROLES } from './constants'
import { FormSection, ReadOnlyTextField } from './employee.components'
import {
  activateEmployeeMutation,
  addMemberToTeamMutation,
  assignRoleMutation,
  blockEmployeeMutation,
  getAllRoles,
  getAllTeams,
  getEmployeeById,
  removeMemberFromTeamMutation,
  removeRoleMutation,
} from './employees.gql'
import { DoctorProfile } from './profiles/doctor-profile'
import { MedicalAssistantProfile } from './profiles/medical-assistant-profile'
import { Profile } from './profiles/profile'
import { Employee, ROLE_ID_MAP, Role, RoleDefinition, Team } from './types'

interface Props {
  createNew?: boolean
}

const ROLE_PROFILES_COMPONENTS = {
  doctor: DoctorProfile,
  medicalassistant: MedicalAssistantProfile,
}

export const EmployeePage = ({ createNew }: Props) => {
  const [profileRoleDialog, setProfileRoleDialog] = useState<string | null>(
    null,
  )
  const { employeeId } = useParams<{ employeeId: string }>()

  const {
    data: employeeData,
    loading: employeeLoading,
    error: employeeError,
  } = useV2Query(getEmployeeById, {
    variables: { employeeId },
  })

  const {
    data: teamsData,
    loading: teamsLoading,
    error: teamsError,
  } = useV2Query(getAllTeams)

  const {
    data: rolesData,
    loading: rolesLoading,
    error: rolesError,
  } = useV2Query(getAllRoles)

  const employee: Employee = employeeData?.getEmployeeById
  const teams: Team[] = teamsData?.getAllTeams
  const roles: RoleDefinition[] = rolesData?.getAllRoles

  const [addTeam, { loading: addTeamLoading, error: addTeamError }] =
    useV2Mutation(addMemberToTeamMutation, {
      refetchQueries: [getEmployeeById],
    })
  const [removeTeam, { loading: removeTeamLoading, error: removeTeamError }] =
    useV2Mutation(removeMemberFromTeamMutation, {
      refetchQueries: [getEmployeeById],
    })

  const [assignRole, { loading: assignRoleLoading, error: assignRoleError }] =
    useV2Mutation(assignRoleMutation, { refetchQueries: [getEmployeeById] })
  const [removeRole, { loading: removeRoleLoading, error: removeRoleError }] =
    useV2Mutation(removeRoleMutation, { refetchQueries: [getEmployeeById] })

  const [block, { loading: blockLoading, error: blockError }] = useV2Mutation(
    blockEmployeeMutation,
    { refetchQueries: [getEmployeeById] },
  )
  const [activate, { loading: activateLoading, error: activateError }] =
    useV2Mutation(activateEmployeeMutation, {
      refetchQueries: [getEmployeeById],
    })

  const loading = employeeLoading || teamsLoading || rolesLoading
  const error = employeeError || teamsError || rolesError

  const updateLoading = blockLoading || activateLoading
  const updateError = blockError || activateError

  const roleLoading = removeRoleLoading || assignRoleLoading
  const roleError = removeRoleError || assignRoleError

  const teamLoading = removeTeamLoading || addTeamLoading
  const teamError = addTeamError || removeTeamError

  const displayProfile = useMemo(
    () =>
      Boolean(
        Object.entries(employee?.profile || {}).filter(
          ([k, v]) => !k.startsWith('_') && Boolean(v),
        ).length > 0,
      ),
    [employee?.profile],
  )

  const handleBlockOrRestore = () => {
    const body = { variables: { employeeId: employee._id } }
    employee.active ? block(body) : activate(body)
  }

  const handleAssignRole = (roleDef: RoleDefinition) => {
    if (
      Object.keys(ROLE_PROFILES_COMPONENTS).includes(roleDef._id) &&
      !get(employee?.profile, roleDef._id)
    ) {
      setProfileRoleDialog(roleDef._id)
    } else {
      assignRole({
        variables: { employeeId: employee._id, role: roleDef._id },
      })
    }
  }

  const RoleDialogComponent =
    ROLE_PROFILES_COMPONENTS[
      profileRoleDialog as keyof typeof ROLE_PROFILES_COMPONENTS
    ]

  const rolesValues = useMemo(
    () =>
      roles?.filter((r) =>
        employee?.roles?.find((er) => r._id === ROLE_ID_MAP[er]),
      ) || [],
    [employee?.roles, roles],
  )

  const concurrentRoles = useMemo(
    () =>
      CONCURRENT_ROLES.reduce((acc, [first, second]) => {
        if (rolesValues.find((rv) => rv._id === first)) {
          acc.push(second)
        } else if (rolesValues.find((rv) => rv._id === second)) {
          acc.push(first)
        }
        return acc
      }, [] as string[]),
    [rolesValues],
  )

  const rolesOptions = useMemo(
    () =>
      roles?.filter(
        (r) =>
          employee?.memberOf
            .flatMap((m) => m.availableRoles)
            .includes(r._id as Role) &&
          !concurrentRoles.find((cr) => r._id === cr),
      ) || [],
    [concurrentRoles, employee?.memberOf, roles],
  )

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <Modal
        open={Boolean(profileRoleDialog)}
        onClose={() => setProfileRoleDialog(null)}
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60vw',
          }}
        >
          <Box>
            {RoleDialogComponent && (
              <RoleDialogComponent
                employee={employee}
                editOnly={true}
                onCancel={() => setProfileRoleDialog(null)}
                onSuccess={() =>
                  assignRole({
                    variables: {
                      employeeId: employee._id,
                      role: profileRoleDialog,
                    },
                  }).then(() => setProfileRoleDialog(null))
                }
              />
            )}
          </Box>
        </Card>
      </Modal>
      <Box sx={{ m: 2, display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <H2>Employee</H2>
          <Id>{employee._id}</Id>
        </Box>
        {updateLoading && <LinearProgress />}
        {updateError && (
          <Typography color={'red'}>{updateError.message}</Typography>
        )}
        <IconButton
          sx={{ ml: 'auto' }}
          onClick={handleBlockOrRestore}
          color={employee.active ? 'error' : 'primary'}
        >
          {employee.active ? (
            <Tooltip title="Block user">
              <PersonOffIcon color="error" />
            </Tooltip>
          ) : (
            <Tooltip title="Activate user">
              <RestoreFromTrashIcon />
            </Tooltip>
          )}
        </IconButton>
      </Box>
      <Card sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <FormSection
            label="First Name"
            value={<ReadOnlyTextField value={employee.firstName} />}
          />
          <FormSection
            label="Last Name"
            value={<ReadOnlyTextField value={employee.lastName} />}
          />
          <FormSection
            label="Email"
            value={<ReadOnlyTextField value={employee.email} />}
          />

          {employee?.locales?.length > 0 && (
            <FormSection
              label="Locales"
              value={<MultiPick values={employee.locales || []} />}
            />
          )}

          <FormSection
            label="Teams"
            value={
              <Box>
                {teamLoading && <LinearProgress />}
                {teamError && (
                  <Typography color={'red'}>{teamError.message}</Typography>
                )}
                <MultiPick<Team>
                  values={employee.memberOf}
                  accessor="name"
                  options={teams}
                  onAdd={(item) =>
                    addTeam({
                      variables: { employeeId: employee._id, teamId: item._id },
                    })
                  }
                  onDelete={(item) =>
                    removeTeam({
                      variables: { employeeId: employee._id, teamId: item._id },
                    })
                  }
                />
              </Box>
            }
          />

          <FormSection
            label="Roles"
            value={
              <Box>
                {roleLoading && <LinearProgress />}
                {roleError && (
                  <Typography color={'red'}>{roleError.message}</Typography>
                )}
                <MultiPick<RoleDefinition>
                  values={rolesValues}
                  accessor="name"
                  options={rolesOptions}
                  onAdd={handleAssignRole}
                  onDelete={(item) =>
                    removeRole({
                      variables: { employeeId: employee._id, role: item._id },
                    })
                  }
                />
              </Box>
            }
          />

          {displayProfile && (
            <FormSection
              label="Profiles"
              value={<Profile employee={employee} />}
            />
          )}
        </Grid>
        <Typography sx={{ mt: 2 }} color="orange">
          &#1645; Updates and actions on employee will get saved without
          confirmation
        </Typography>
      </Card>
    </Box>
  )
}
