import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { SubscriptionStatus } from '@trueskin-backoffice/contexts/types'

export const SubscriptionsFilters = () => (
  <DataFilterV2
    inputRows={[
      [
        {
          label: 'Search for subscription id',
          key: 'id',
          type: DataFilterV2FilterComponent.ID,
        },
        {
          type: DataFilterV2FilterComponent.DIVIDER,
          borderVisible: false,
        },
        {
          label: 'Search for customer id',
          type: DataFilterV2FilterComponent.ID,
          key: 'customerId',
        },
        {
          type: DataFilterV2FilterComponent.DIVIDER,
          borderVisible: false,
        },
      ],
    ]}
    additionalFilters={[
      {
        label: 'V1 user id',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'v1UserId',
        op: DataFilterV2InputOperation.EQUALS,
      },
      {
        label: 'Status',
        component: DataFilterV2FilterComponent.SELECT,
        key: 'status',
        op: DataFilterV2InputOperation.EQUALS,
        options: Object.keys(SubscriptionStatus).map((status) => ({
          label: SubscriptionStatus[status as keyof typeof SubscriptionStatus],
          value: status,
        })),
      },
    ]}
  />
)
