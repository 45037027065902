import { useQuery } from '@apollo/client'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Alert, Box, Button, Icon, lighten, Skeleton } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import {
  DateTimeDistanceToNow,
  HeroCardEmpty,
  TagGroupItem,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { CaseGoals } from './components/case-goals'
import { CasePhotosCountPreview } from './components/case-photos-count-preview'
import { WatchOptions } from './components/watch-options'
import { getPatientFullName } from './utils'
import { getWaitingRoomCases } from './waiting-room.gql.bff'

const DEFAULT_PAGE_SIZE = 1000

const CaseListCard = ({ currentCase, divider }: any) => {
  const [caseId, setCaseId] = useQueryParam('caseId', StringParam)
  const customerFullName = getPatientFullName(currentCase.customer)
  const caseSelected = caseId === currentCase.id

  return (
    <>
      {divider && <Divider component="li" />}

      <ListItem
        sx={{ cursor: 'pointer' }}
        onClick={() => setCaseId(currentCase.id)}
      >
        <ListItemText
          secondary={
            <React.Fragment>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: 16,
                  fontWeight: 500,
                }}
                component="span"
                color={caseSelected ? 'primary' : 'text.primary'}
              >
                <span>Case # {currentCase.documentNumber}</span>
              </Typography>

              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // fontSize: 16,
                  // fontWeight: 500,
                  fontStyle: 'italic',
                }}
                component="span"
                color={caseSelected ? 'primary' : 'text.primary'}
              >
                <span>{customerFullName}</span>
                {caseSelected ? (
                  <Icon component={ArrowForwardIosIcon} sx={{ fontSize: 12 }} />
                ) : null}
              </Typography>

              <CaseGoals currentCase={currentCase} />

              <CasePhotosCountPreview
                label="# photos"
                currentCase={currentCase}
                uploadedPhotosCount={currentCase.uploadedPhotosCount}
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <TagGroupItem sx={{ my: 1, py: 0, borderRadius: 1 }}>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={{
                        color: '#8181ad',
                        mr: 1,
                      }}
                    >
                      {currentCase.extendedStatus
                        .replaceAll('_', ' ')
                        .toLowerCase()}
                    </Box>
                  </Box>
                </TagGroupItem>

                {caseSelected && <WatchOptions currentCase={currentCase} />}
              </Box>

              {currentCase.postDiagnosisMetadata?.lastCustomerMessageAt && (
                <Box sx={{ display: 'flex' }}>
                  Last message received&nbsp;
                  <DateTimeDistanceToNow
                    dateTime={
                      currentCase.postDiagnosisMetadata?.lastCustomerMessageAt
                    }
                    tooltipPlacement="right"
                    strict={true}
                  />
                </Box>
              )}

              {currentCase.metadata?.activatedAt &&
                currentCase.status.toLowerCase() === 'active' && (
                  <Box sx={{ display: 'flex', fontSize: 12 }}>
                    Activated&nbsp;
                    <DateTimeDistanceToNow
                      dateTime={currentCase.metadata?.activatedAt}
                      tooltipPlacement="right"
                    />
                  </Box>
                )}
            </React.Fragment>
          }
        />
      </ListItem>
    </>
  )
}

export const WaitingRoomList = () => {
  const {
    employee,
    isCurrentEmployeeDoctor,
    isCurrentEmployeeMedicalAssistant,
  } = useAuth()
  const { query } = useDataFilterV2()
  const baseQuery = isCurrentEmployeeDoctor()
    ? {
        assignee: employee?.id ? [employee.id] : null,
        status: 'ACTIVE',
      }
    : isCurrentEmployeeMedicalAssistant()
    ? { status: 'ACTIVE', diagnosisContentAssignee: employee?.id }
    : {}

  const filter = Object.keys(query || {}).reduce(
    (prev: any, crtKey: string) => {
      if (query[crtKey]) {
        return {
          ...prev,
          [crtKey]: query[crtKey],
        }
      }
      return prev
    },
    baseQuery,
  )

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      waitingRoomCases: {
        rows: waitingRoomCases = [],
        totalRows: totalWaitingRoomCases = 0,
      } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getWaitingRoomCases, {
    notifyOnNetworkStatusChange: true,
    skip: !employee,
    variables: {
      offset: 0,
      limit: pageSize,
      // filter: {
      //   ...filter,
      //   // ...baseQuery,
      // },
      filter,
    },
  })

  // const totalPages = Math.ceil(totalWaitingRoomCases / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        // filter: {
        //   ...baseQuery,
        //   ...query,
        // },
        filter,
      })
    }
  }, [pageSize, currentPage, query])

  // useEffect(() => {
  //   if (!loading) {
  //     setCurrentPage(1)
  //   }
  // }, [filter])
  //
  // useEffect(() => {
  //   if (!loading) {
  //     setCurrentPage(1)
  //   }
  // }, [filter])

  if (error) {
    return <Alert color="error">{error.message}</Alert>
  }

  if (loading) {
    return (
      <Skeleton variant="rectangular" height={550} sx={{ borderRadius: 2 }} />
    )
  }

  if (!loading && !error && waitingRoomCases && !waitingRoomCases?.length) {
    return (
      <HeroCardEmpty sx={{ borderRadius: 1, textAlign: 'center' }}>
        <Typography variant="caption">
          No cases found which are assigned to you, are active and fulfil the
          current search criteria
        </Typography>

        <Button
          size="small"
          component={NavLink}
          variant="text"
          fullWidth
          to={`/tofu/cases?${
            isCurrentEmployeeMedicalAssistant()
              ? 'caseDiagnosisStatus=REQUIRES_CONTENT'
              : 'extendedStatus=AWAITING_DIAGNOSIS&assignee=UNASSIGNED'
          }`}
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            px: 2,
            textTransform: 'unset',

            '&.active': {
              color: '#fff',
              background: lighten('#005858', 0.2),
              fontWeight: 600,
            },
          }}
        >
          View list of cases which require input
        </Button>
      </HeroCardEmpty>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          height: '100%',
          maxHeight: '100%',
          display: 'flex',
          overflowX: 'hidden',
          overflowY: 'scroll',
          flex: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            height: '85vh',
            // maxHeight: '100%',
            overflowX: 'hidden',
            overflowY: 'scroll',
            borderRadius: 1,
          }}
        >
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              p: 0,
            }}
          >
            {waitingRoomCases.map((currentCase: any, index: number) => (
              <CaseListCard
                key={currentCase.id}
                divider={index !== 0}
                currentCase={currentCase}
              />
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  )
}
