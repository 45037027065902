import { Box, Button, InputAdornment, TextField } from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
import * as yup from 'yup'
import { useComments } from '../contexts/comments.context'

const validationSchema = yup.object({
  text: yup.string().max(500),
})

export const CreateComment = () => {
  const { createComment } = useComments()

  return (
    <Formik
      initialValues={{
        text: '',
      }}
      enableReinitialize
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={async (values, helpers) => {
        await createComment({
          variables: {
            content: { text: values.text },
          },
        })
        helpers.resetForm()
      }}
    >
      {({ touched, errors, values, handleChange }) => (
        <Form noValidate>
          <Box display="flex" alignItems="flex-start">
            <Box sx={{ width: '100%', display: 'flex' }}>
              <TextField
                fullWidth
                size="small"
                type="textarea"
                multiline
                name="text"
                maxRows={5}
                label="Write a comment"
                value={values.text}
                onChange={handleChange}
                error={touched.text && Boolean(errors.text)}
                inputProps={{
                  maxLength: 500,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button type="submit" disabled={!values.text}>
                        Create
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ color: 'red' }}>
                <ErrorMessage name="text" />
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
