import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  bffGqlClient,
  getJwt,
  removeJwt,
  saveJwt,
  v2GqlClient,
} from '@trueskin-backoffice/api-client'
import { DoctorProfileInput, Employee } from '@trueskin-backoffice/gql'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  employeeGoogleLoginMutation,
  getCurrentEmployeeQuery,
} from './current-employee.gql.bff'
import { useUIController } from './ui-controller.context'

//todo: this is duplicated in app.context (find a single place for it)
interface StandardProps {
  children?: ReactNode
}

export interface AuthContextProps {
  loading: boolean
  error: string | undefined | ApolloError
  employee: Employee | null
  // authenticated: boolean
  loginWithGoogle: (options: any) => void
  logOut: () => void
  getCustomerLink: (customerId: string) => string
  switchChannel: (channel: string) => void
  channel: string
  getDoctorProfile: () => DoctorProfileInput | null
  getMedicalAssistantProfile: () => DoctorProfileInput | null
  isCurrentEmployeeDoctor: () => boolean
  isCurrentEmployeeMedicalAssistant: () => boolean
}

export interface LocationState {
  from: string
}

const AuthContext = React.createContext<AuthContextProps | undefined>(undefined)

function AuthProvider(props: StandardProps) {
  const { showTopRightMenu } = useUIController()
  const location = useLocation()
  const navigate = useNavigate()

  const [channel, setChannel] = useState(
    location.pathname.startsWith('/tofu/') ? 'tofu' : 'web',
  )
  const [currentEmployee, setCurrentEmployee] = useState<Employee | null>(null)
  const jwt = getJwt()

  const { from } = (location.state as LocationState) || {}

  const isCurrentEmployeeDoctor = useCallback(
    () => !!currentEmployee?.roles?.includes('doctor'),
    [currentEmployee],
  )
  const isCurrentEmployeeMedicalAssistant = useCallback(
    () => !!currentEmployee?.roles?.includes('medicalassistant'),
    [currentEmployee],
  )

  const isMedical = useCallback(
    () => isCurrentEmployeeDoctor() || isCurrentEmployeeMedicalAssistant(),
    [currentEmployee],
  )

  const [
    getCurrentEmployee,
    { loading: currentEmployeeLoading, data, error: currentEmployeeError },
  ] = useLazyQuery(getCurrentEmployeeQuery)

  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    employeeGoogleLoginMutation,
    // {
    //   onCompleted: ({ googleAuth: { jwt } }) => {
    //     saveJwt(jwt)
    //   },
    // },
  )

  const loginWithGoogle = useCallback(
    async (options: any) => {
      const loginResponse: any = await login(options)
      // console.log('loginResponse = ', loginResponse)
      saveJwt(loginResponse.data.employeeGoogleLogin.jwt)
      // setCurrentEmployee(loginResponse.data.googleAuth.employee)
    },
    [login],
  )

  const loading = loginLoading || currentEmployeeLoading
  const error = loginError || currentEmployeeError

  const logOut = useCallback(async () => {
    await bffGqlClient.clearStore()
    await v2GqlClient.clearStore()

    removeJwt()
    setCurrentEmployee(null)
  }, [])

  useEffect(() => {
    // if (!jwt && !!currentEmployee) {
    // } else
    if (!jwt && !currentEmployee) {
      navigate('/login', {
        replace: true,
        state: { from: location.pathname },
      })
    } else if (jwt && !currentEmployee) {
      // console.log('effect', [jwt, currentEmployee])
      getCurrentEmployee().then(({ data }) =>
        setCurrentEmployee(data?.currentEmployee),
      )
    } else {
      // console.log('currentEmployee = ', currentEmployee)
      if (location.pathname.startsWith('/login')) {
        navigate(
          !from || from === '/login'
            ? JSON.stringify(currentEmployee?.roles) ===
              JSON.stringify(['doctor'])
              ? '/tofu/waiting-room'
              : '/'
            : from,
          { replace: true },
        )
      }
    }
  }, [jwt, currentEmployee, getCurrentEmployee, navigate])

  const getCustomerLink = useCallback(
    (customerId: string) => {
      const channelPrefix = channel === 'tofu' ? '/tofu' : ''
      // const medicalPrefix = isMedical() ? '/m' : ''
      const urlContext = channel === 'web' ? '/customers' : '/patients'

      // return `${channelPrefix}${medicalPrefix}${urlContext}/${customerId}`
      return `${channelPrefix}${urlContext}/${customerId}`
    },

    [isMedical, channel],
  )

  const switchChannel = useCallback((channel: string) => {
    setChannel(channel)
    if (channel !== 'tofu') {
      showTopRightMenu(false)
    }
  }, [])

  const getDoctorProfile = useCallback(() => {
    if (!currentEmployee || !currentEmployee.roles?.includes('doctor')) {
      return null
    }

    const x: DoctorProfileInput = {
      id: currentEmployee.id,
      gender: currentEmployee.profile.doctor.gender,
      firstName: currentEmployee.firstName,
      lastName: currentEmployee.lastName,
      title: currentEmployee.profile.doctor.title,
      avatarUrl: currentEmployee.profile.doctor.profileImage.locationUrl,
    }

    return x
  }, [currentEmployee])

  const getMedicalAssistantProfile = useCallback(() => {
    if (
      !currentEmployee ||
      !currentEmployee.roles?.includes('medicalassistant')
    ) {
      return null
    }

    const x: DoctorProfileInput = {
      id: currentEmployee.id,
      gender: currentEmployee.profile?.doctor?.gender,
      firstName: currentEmployee.firstName,
      lastName: currentEmployee.lastName,
      title: currentEmployee.profile.doctor?.title,
      avatarUrl: currentEmployee.profile.doctor?.profileImage?.locationUrl,
    }

    return x
  }, [currentEmployee])

  const value = React.useMemo(
    () => ({
      loading,
      employee: currentEmployee,
      error,
      loginWithGoogle,
      logOut,
      getCustomerLink,
      switchChannel,
      channel,
      getDoctorProfile,
      getMedicalAssistantProfile,
      isCurrentEmployeeDoctor,
      isCurrentEmployeeMedicalAssistant,
    }),
    [
      loading,
      data,
      error,
      currentEmployee,
      jwt,
      loginWithGoogle,
      logOut,
      channel,
      getDoctorProfile,
      getMedicalAssistantProfile,
      isCurrentEmployeeDoctor,
      isCurrentEmployeeMedicalAssistant,
    ],
  )

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth(): AuthContextProps {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
