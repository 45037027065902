export function stringAvatar({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}) {
  return {
    sx: {
      width: 24,
      height: 24,
      fontSize: 14,
      color: '#fff',
    },
    children: `${firstName[0]}${lastName[0]}`,
  }
}
