import { Box, Divider } from '@mui/material'
import { Fragment } from 'react'
import { useComments } from '../contexts/comments.context'
import { CommentItem } from './comment.item'

export const CommentsList = () => {
  const { comments } = useComments()

  if (!comments?.length) {
    return null
  }

  return (
    <Box
      sx={{ maxHeight: 500, overflowY: 'auto', mt: 4 }}
      gap={2}
      display="flex"
      flexDirection="column"
    >
      {comments?.map((comment, index) => (
        <Fragment key={comment.id}>
          <CommentItem comment={comment} />
          {index !== comments?.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Box>
  )
}
