export enum DataFilterV2FilterComponent {
  DIVIDER = 'divider',
  SELECT = 'select',
  TEXT = 'text',
  TOGGLE = 'toggle',
  ID = 'id',
}

export enum DataFilterV2InputOperation {
  EQUALS = 'equals',
  CONTAINS = 'contains',
  IS = 'is',
}
