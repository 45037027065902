import { Box, BoxProps, styled } from '@mui/material'
import React, { ReactNode } from 'react'

export const PageContentLayout = (props: PageContentLayoutProps) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      overflowY: 'auto',
    }}
  >
    <Box
      sx={{
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '0 auto',
              px: 12,
              py: 8,
              zIndex: 2,
              maxWidth: 1200,
            }}
          >
            <Box
              sx={{
                width: 'calc(75% - 8px)',
                zIndex: 1,
              }}
            >
              {props.children}
            </Box>

            <Box
              sx={{
                position: 'sticky',
                top: 0,
                width: 'calc(25% - 8px)',
                alignSelf: 'flex-start',
              }}
            >
              <Box sx={{ py: 2 }}>{props.sidebar}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
)

export interface PageContentLayoutProps extends BoxProps {
  children?: React.ReactNode
  sidebar?: ReactNode
}

// export const PageContentDockDivider = styled((props: BoxProps) => (
//   <Box
//     sx={{
//       minHeight: '1px',
//       mx: 2,
//       my: 4,
//       minWidth: 'calc(100% - 48px)',
//       backgroundColor: '#d4d4e8',
//     }}
//     {...props}
//   />
// ))()

export const PageContentDockDivider = styled(Box)({
  minHeight: '1px',
  mx: 2,
  my: 4,
  minWidth: 'calc(100% - 48px)',
  backgroundColor: '#d4d4e8',
})
