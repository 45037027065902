import { Box, Button, TextField } from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'
import { H1 } from '../../headings'
import { AccountMutationsProvider, useAccountMutations } from '../context'

export const EditAccountEmailModal = ({
  accountId,
  email,
  open,
  onClose,
  onComplete,
}: any) => {
  if (!open) {
    return null
  }

  return (
    <AccountMutationsProvider accountId={accountId}>
      <Modal email={email} onClose={onClose} onComplete={onComplete} />
    </AccountMutationsProvider>
  )
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
})

const Modal = ({
  email,
  onClose,
  onComplete,
}: {
  email: string
  onClose?: any
  onComplete?: any
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    updateAccountEmail,
    updateAccountEmailLoading,
    updateAccountEmailError,
  } = useAccountMutations()

  return (
    <Formik
      initialValues={{
        email,
      }}
      validateOnChange={true}
      validationSchema={validationSchema}
      // onSubmit={async (values) => {
      //   await updateAccountEmail(values.email)
      //   onClose()
      // }}
      onSubmit={async (values) => {
        try {
          await updateAccountEmail(values)
          enqueueSnackbar('Email updated successfully.', {
            variant: 'success',
          })
          onComplete?.()
          onClose?.()
        } catch (err) {
          enqueueSnackbar(
            `Email update failed. ${JSON.stringify(
              updateAccountEmailError || err,
            )}`,
            {
              variant: 'error',
            },
          )
        }
      }}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          <Box
            sx={{
              minWidth: 500,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <H1>Update customer email address</H1>

            <Box>
              <Box sx={{ mb: 4 }}>Current email address value: {email}</Box>

              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
              />

              <Box sx={{ color: 'red' }}>
                <ErrorMessage name="email" />
              </Box>
            </Box>

            <Box>
              <Button color="secondary" onClick={() => onClose?.()}>
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={!values.email || updateAccountEmailLoading}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
