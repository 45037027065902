import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'

export const PatientsFilters = () => (
  <DataFilterV2
    inputRows={[
      [
        {
          label: 'Search for account id / patient id',
          key: 'id',
          type: DataFilterV2FilterComponent.ID,
        },
        {
          type: DataFilterV2FilterComponent.DIVIDER,
          borderVisible: false,
        },
      ],
    ]}
    additionalFilters={[
      {
        label: 'Email',
        component: DataFilterV2FilterComponent.TEXT,
        key: 'email',
        op: DataFilterV2InputOperation.EQUALS,
      },
    ]}
  />
)
