import { gql } from '@apollo/client'

const webhookFields = gql`
  fragment WebhookFields on Webhook {
    _id
    source
    externalEventId
    jsonBody
    status
    customerId
    createdAt
    updatedAt
  }
`

export const getAllWebhooks = gql`
  ${webhookFields}
  query GetAllWebhooks($limit: Int, $offset: Int, $filter: WebhooksFilter) {
    getAllWebhooks(limit: $limit, offset: $offset, filter: $filter) {
      ...WebhookFields
    }
  }
`
