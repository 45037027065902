import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { LightTag } from './tags'

interface Props<T> {
  values: T[]
  options?: T[]
  onDelete?: (item: T) => void
  onAdd?: (values: T) => void
  accessor?: Extract<keyof T, string> | ((item: T) => string)
  disabled?: boolean
  createLink?: (item: T) => string
}

export const MultiPick = <T extends object | string = string>({
  values,
  options,
  accessor,
  onDelete,
  onAdd,
  disabled,
  createLink,
}: Props<T>) => {
  const getValue = useCallback(
    (value: T): string => {
      return (
        typeof value === 'string'
          ? value
          : typeof accessor === 'string'
          ? value[accessor]
          : typeof accessor === 'function'
          ? accessor(value)
          : ''
      ) as string
    },
    [accessor],
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePick = (item: T) => {
    if (onAdd) {
      onAdd(item)
    }
    handleClose()
  }

  const buttonId = useMemo(() => uuid(), [])
  const menuId = useMemo(() => uuid(), [])

  const restOptions = useMemo(() => {
    if (!options) {
      return []
    }
    const currentValues = values.map(getValue)

    return options.filter((pv) => !currentValues.includes(getValue(pv)))
  }, [getValue, options, values])

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid lightgrey',
        borderRadius: '5px',
        width: '100%',
      }}
    >
      {values &&
        values.length > 0 &&
        values.map((v) => {
          const displayValue = getValue(v)
          return (
            <LightTag
              key={displayValue}
              label={
                <Box>
                  {createLink ? (
                    <NavLink to={createLink(v)}>
                      <Typography variant="caption">{displayValue}</Typography>
                    </NavLink>
                  ) : (
                    <Typography variant="caption">{displayValue}</Typography>
                  )}
                  {!disabled && onDelete && (
                    <IconButton
                      sx={{ ml: 1 }}
                      size="small"
                      onClick={() => onDelete(v)}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              }
            />
          )
        })}

      {onAdd && (
        <IconButton
          disabled={!restOptions.length}
          color="primary"
          size="small"
          id={buttonId}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenMenu}
        >
          <AddIcon />
        </IconButton>
      )}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
      >
        {restOptions.map((ro) => {
          const displayValue = getValue(ro)
          return (
            <MenuItem key={displayValue} onClick={() => handlePick(ro)}>
              {displayValue}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
