const dialog = {
  styleOverrides: {
    root: {
      // '& .MuiDialog-container': { height: 'fit-content' },
      '& .MuiPaper-root': {
        marginTop: 64,
        padding: '32px 40px',
        maxWidth: 'unset',
      },
    },
  },
}

export default dialog
