import { useMutation, useQuery } from '@apollo/client'
import { Alert, Box, Button, Divider, Drawer, Typography } from '@mui/material'
import { orange, red } from '@mui/material/colors'
import {
  DateTime,
  HeroCard,
  HeroCardDivider,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
} from '@trueskin-backoffice/components'
import { NotionCmsProvider, useAuth } from '@trueskin-backoffice/contexts'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useWaitingRoomCaseDiagnosis } from '../waiting-room-case-diagnosis.context'
import { useWaitingRoomCase } from '../waiting-room-case.context'
import {
  getWaitingRoomCaseDiagnosisPreviewQuery,
  getWaitingRoomCaseDiagnosisQuery,
  publishDiagnosisForCaseMutation,
} from '../waiting-room.gql.bff'
import { ConditionDiagnosis } from './condition-diagnosis'
import {
  DiagnosisAndTreatmentHeadingNotes,
  MedicalDiagnosisContent,
} from './diagnosis-record-content.form'
import { SelectForCoreTreatment } from './inputs'
import { AddDiagnosisButton2 } from './inputs/add-diagnosis-button/add-diagnosis2.button'
import { AddMedicationButton2 } from './inputs/add-medication-button/add-medication2.button'
import { PrefillContentButton } from './inputs/prefill-content-button'
import { Medication } from './medication'

export const DiagnosisRecordForm = () => {
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord, loading } = useWaitingRoomCaseDiagnosis()

  if (
    !diagnosisRecord ||
    diagnosisRecord.status === 'published' ||
    currentCase.status.toLowerCase() === 'rejected'
  ) {
    return null
  }

  // if (loading) {
  //   // TODO: probably it is better to return a skeleton
  //   return null
  // }

  return (
    <NotionCmsProvider>
      <Box>
        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            // mt: 2,
            m: 0,
            mb: 2,
            py: 1,
          }}
        >
          <HorizontalList>
            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Id
              </Typography>
              {diagnosisRecord.id}
            </HorizontalListItem>

            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Created on
              </Typography>
              <DateTime
                dateTime={diagnosisRecord.createdAt}
                tooltipPlacement="top"
              />
            </HorizontalListItem>

            <HorizontalListItem>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Status
              </Typography>
              <Box sx={{ fontStyle: 'italic' }}>{diagnosisRecord.status}</Box>
            </HorizontalListItem>
          </HorizontalList>
        </HeroCard>

        <DiagnosisAndTreatmentHeadingNotes
        // initialEditorState={
        //   diagnosisRecord?.rawContent?.headingNote?.editorState
        // }
        />

        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            mb: 2,
            py: 1,
            backgroundColor: red[50],
          }}
        >
          {diagnosisRecord?.conditionDiagnosis
            ?.filter((cd: any) => cd.type === 'diagnosis')
            .map((cd: any, index: number) => (
              <>
                {index !== 0 && <HeroCardDivider />}

                <HeroCardSection sx={{ m: 0, width: '100%' }}>
                  <ConditionDiagnosis conditionDiagnosis={cd} editable />
                </HeroCardSection>
              </>
            ))}

          <AddDiagnosisButton2
            type="diagnosis"
            label="Diagnosis"
            showTopDivider={
              !!diagnosisRecord?.conditionDiagnosis?.filter(
                (cd: any) => cd.type === 'diagnosis',
              )?.length
            }
            required={
              !diagnosisRecord?.conditionDiagnosis?.filter(
                (cd: any) => cd.type === 'diagnosis',
              )?.length
            }
            disabled={!diagnosisRecord?.actions?.canDiagnose.enabled}
          />
        </HeroCard>

        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            mb: 2,
            py: 1,
            backgroundColor: orange[50],
          }}
        >
          {diagnosisRecord?.conditionDiagnosis
            ?.filter((cd: any) => cd.type === 'differential_diagnosis')
            .map((cd: any, index: number) => (
              <>
                <HeroCardSection sx={{ m: 0, width: '100%' }}>
                  <ConditionDiagnosis conditionDiagnosis={cd} editable />
                </HeroCardSection>

                <HeroCardDivider />
              </>
            ))}

          <AddDiagnosisButton2
            type="differential_diagnosis"
            label="Differential diagnosis"
            showTopDivider={
              !!diagnosisRecord?.conditionDiagnosis?.filter(
                (cd: any) => cd.type === 'differential_diagnosis',
              )?.length
            }
            disabled={!diagnosisRecord?.actions?.canDiagnose.enabled}
          />
        </HeroCard>

        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            mb: 2,
            py: 1,
            backgroundColor: orange[50],
          }}
        >
          {diagnosisRecord?.conditionDiagnosis
            ?.filter((cd: any) => cd.type === 'secondary_findings')
            .map((cd: any, index: number) => (
              <>
                <HeroCardSection sx={{ m: 0, width: '100%' }}>
                  <ConditionDiagnosis conditionDiagnosis={cd} editable />
                </HeroCardSection>

                <HeroCardDivider />
              </>
            ))}

          <AddDiagnosisButton2
            type="secondary_findings"
            label="Secondary findings"
            showTopDivider={
              !!diagnosisRecord?.conditionDiagnosis?.filter(
                (cd: any) => cd.type === 'secondary_findings',
              )?.length
            }
            disabled={!diagnosisRecord?.actions?.canDiagnose.enabled}
          />
        </HeroCard>

        <PrefillContentButton />

        <HeroCard
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            mb: 2,
            py: 1,
          }}
        >
          {diagnosisRecord?.medication?.map((md: any, index: number) => (
            <>
              {index !== 0 && <HeroCardDivider />}

              <HeroCardSection sx={{ m: 0, width: '100%' }}>
                <Medication medication={md} editable />
              </HeroCardSection>
            </>
          ))}

          <AddMedicationButton2
            showTopDivider={!!diagnosisRecord?.medication?.length}
            required={!diagnosisRecord?.medication?.length}
            disabled={!diagnosisRecord?.actions?.canDiagnose.enabled}
          />
        </HeroCard>

        <HeroCard
          sx={{
            display: 'flex',
            borderRadius: 1,
            alignItems: 'center',
            m: 0,
            mb: 2,
            py: 1,
          }}
        >
          <SelectForCoreTreatment />
        </HeroCard>

        <MedicalDiagnosisContent />

        <PublishDiagnosisButton />
      </Box>
    </NotionCmsProvider>
  )
}

const PublishDiagnosisButton = () => {
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()
  const [showDiagnosisPreview, setShowDiagnosisPreview] = useState(false)

  const [
    publishDiagnosisForCase,
    {
      loading: publishDiagnosisForCaseLoading,
      error: publishDiagnosisForCaseError,
    },
  ] = useMutation(publishDiagnosisForCaseMutation, {
    variables: {
      customerId: currentCase.customerId,
      caseId: currentCase.id,
      doctor: getDoctorProfile(),
      diagnosisRecordId: diagnosisRecord.id,
    },
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  const isPublishingDisabled =
    !(
      diagnosisRecord?.conditionDiagnosis?.length &&
      diagnosisRecord?.conditionDiagnosis?.filter(
        (cd: any) => cd.type === 'diagnosis',
      ).length &&
      // diagnosisRecord?.medicalCondition &&
      (
        diagnosisRecord?.diagnosisAndTreatmentContent?.headingNote?.markdown ||
        ''
      ).trim() !== '' &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.headingNote?.richText &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.headingNote?.richText !==
      //   '<p><br></p>' &&
      (
        diagnosisRecord?.diagnosisAndTreatmentContent?.diagnosisDetails
          ?.markdown || ''
      ).trim() !== '' &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.diagnosisDetails
      //   ?.richText &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.diagnosisDetails
      //   ?.richText !== '<p><br></p>' &&
      (
        diagnosisRecord?.diagnosisAndTreatmentContent?.skinFindings?.markdown ||
        ''
      ).trim() !== '' &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.skinFindings?.richText &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.skinFindings?.richText !==
      //   '<p><br></p>' &&
      // diagnosisRecord?.diagnosisAndTreatment?.prescription?.richText &&
      // diagnosisRecord?.diagnosisAndTreatment?.prescription?.richText !==
      //   '<p><br></p>' &&
      (
        diagnosisRecord?.diagnosisAndTreatmentContent?.treatmentPlan
          ?.markdown || ''
      ).trim() !== '' &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.treatmentPlan?.richText &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.treatmentPlan?.richText !==
      //   '<p><br></p>' &&
      (
        diagnosisRecord?.diagnosisAndTreatmentContent?.additionalAdvice
          ?.markdown || ''
      ).trim() !== '' &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.additionalAdvice
      //   ?.richText &&
      // diagnosisRecord?.diagnosisAndTreatmentContent?.additionalAdvice
      //   ?.richText !== '<p><br></p>' &&
      diagnosisRecord?.medication?.length
    ) ||
    diagnosisRecord?.status === 'requires_content' ||
    publishDiagnosisForCaseLoading

  if (
    isPublishingDisabled ||
    !currentCase.actions.canPublishDiagnosis.enabled
  ) {
    return null
  }

  return (
    <HeroCard
      sx={{
        display: 'flex',
        borderRadius: 1,
        alignItems: 'center',
        m: 0,
        mb: 2,
        py: 1,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Button
          fullWidth
          variant="text"
          disabled={isPublishingDisabled}
          onClick={() => setShowDiagnosisPreview(true)}
          sx={{ fontSize: 12, textTransform: 'unset' }}
        >
          Preview diagnosis and publish
        </Button>

        <Drawer
          anchor="right"
          open={showDiagnosisPreview}
          onClose={() => setShowDiagnosisPreview(false)}
        >
          <Box sx={{ minWidth: 300, maxWidth: 350 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                my: 2,
              }}
            >
              <Typography variant="caption" textAlign="center">
                Diagnosis preview (approximation)
              </Typography>
            </Box>

            {showDiagnosisPreview && (
              <Box>
                <DiagnosisPreview />
              </Box>
            )}

            <Button
              fullWidth
              disabled={isPublishingDisabled}
              onClick={async () => {
                await publishDiagnosisForCase()
                setShowDiagnosisPreview(false)
              }}
            >
              Publish diagnosis
            </Button>
          </Box>
        </Drawer>
      </Box>
    </HeroCard>
  )
}

const DiagnosisPreview = () => {
  const { currentCase } = useWaitingRoomCase()

  const {
    data: { waitingRoomCase } = {},
    loading,
    error,
  } = useQuery(getWaitingRoomCaseDiagnosisPreviewQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !currentCase?.id,
    fetchPolicy: 'no-cache',
    variables: {
      caseId: currentCase?.id,
    },
  })

  console.log('DiagnosisPreview', waitingRoomCase?.diagnosisPreview?.sections)

  if (loading) {
    return null
  }

  if (error) {
    return <Alert severity="error">{JSON.stringify(error)}</Alert>
  }

  return (
    <Box>
      {waitingRoomCase?.diagnosisPreview?.sections?.map((s: any) => {
        if (s.type === 'diagnosis_and_treatment') {
          return <DiagnosisPreviewDiagnosisAndTreatmentSection section={s} />
        }

        if (s.type === 'medication') {
          return <DiagnosisPreviewMedicationSection section={s} />
        }

        if (s.type === 'personalized_treatment') {
          return <DiagnosisPreviewPersonalizedSection section={s} />
        }

        return null
      })}
    </Box>
  )
}

const DiagnosisPreviewDiagnosisAndTreatmentSection = ({ section }: any) => {
  return (
    <Box
      sx={{
        mx: 2,
        borderWidth: '0px 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(203, 211, 231, 0.5)',
      }}
    >
      <Box sx={{ backgroundColor: '#E5E9F3' }}>
        <Typography sx={{ p: 2 }} fontWeight={600}>
          {section.title}
        </Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        {section.subsections.map((ss: any, index: number) => {
          return (
            <>
              {index !== 0 && <Divider sx={{ my: 1 }} />}
              <Box>
                <Typography fontWeight={500} sx={{ mb: 1 }}>
                  {ss.title}
                </Typography>
                <Box
                  sx={{
                    pl:
                      ss.type === 'prescription' || ss.type === 'diagnosis'
                        ? 6
                        : 0,
                  }}
                >
                  <ReactMarkdown>{ss.markdown}</ReactMarkdown>
                </Box>
              </Box>
            </>
          )
        })}
      </Box>
    </Box>
  )
}

const DiagnosisPreviewPersonalizedSection = ({ section }: any) => {
  return (
    <Box
      sx={{
        mx: 2,
        my: 2,
        borderWidth: '0px 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(203, 211, 231, 0.5)',
      }}
    >
      <Box sx={{ backgroundColor: '#E5E9F3' }}>
        <Typography sx={{ p: 2 }} fontWeight={600}>
          {section.title}
        </Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        <Box>
          <Typography>{section.description}</Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography>
            {section.voucherCodeDescription} {section.voucherCode}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const DiagnosisPreviewMedicationSection = ({ section }: any) => {
  return (
    <Box
      sx={{
        mx: 2,
        borderWidth: '0px 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(203, 211, 231, 0.5)',
      }}
    >
      <Box sx={{ backgroundColor: '#E5E9F3' }}>
        <Typography sx={{ p: 2 }} fontWeight={600}>
          {section.title}
        </Typography>
      </Box>

      <Box sx={{ p: 2, pl: 6 }}>
        <ol type="1">
          {section.medication.map((m: any) => (
            <li key={m}>{m}</li>
          ))}
        </ol>
      </Box>
    </Box>
  )
}
