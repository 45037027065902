import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
interface ConfirmActionOption {
  action: string
  onSubmit: (...props: any) => any | Promise<any>
}

interface Props {
  title?: string
  contentText?: ReactElement | string
  error?: string
  opened: boolean
  onClose: () => void
  options: ConfirmActionOption[]
  confirmText: string[]
  loading?: boolean
}

export const ConfirmActionDialog = ({
  title = '',
  contentText = '',
  loading,
  error,
  opened,
  onClose,
  options,
  confirmText,
}: Props) => {
  const [confirmation, setConfirmation] = useState<boolean[]>(
    new Array(confirmText.length).fill(false),
  )
  const [value, setValue] = useState<ConfirmActionOption | null>(null)
  const handleConfirm = (value: boolean, index: number) => {
    const copy = [...confirmation]

    copy[index] = value

    setConfirmation(copy)
  }

  useEffect(() => {
    if (!opened) {
      setConfirmation(new Array(confirmText.length).fill(false))
      setValue(null)
    }
  }, [opened])

  useEffect(() => {
    if (options.length === 1) {
      setValue(options[0])
    }
  }, [opened, options])

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={opened}
      onClose={onClose}
      aria-labelledby="confirm-action-dialog-title"
    >
      <DialogTitle id="confirm-action-dialog-title">{title}</DialogTitle>

      <DialogContent>
        {loading && <LinearProgress />}
        {error && <Typography color={'red'}>{error}</Typography>}
        <DialogContentText sx={{ mb: 2 }}>
          {contentText}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {options.map((o) => (
              <Chip
                sx={{ m: 1 }}
                key={o.action}
                label={o.action}
                variant={o.action === value?.action ? 'filled' : 'outlined'}
                color={o.action === value?.action ? 'primary' : 'default'}
                onClick={() => setValue(o)}
              />
            ))}
          </Box>
        </DialogContentText>

        <FormGroup>
          {confirmText.map((text, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={confirmation}
                  onChange={(event) =>
                    handleConfirm(event.target.checked, index)
                  }
                />
              }
              label={text}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await value?.onSubmit()
            onClose()
          }}
          autoFocus
          disabled={confirmation.some((c) => !c) || !value || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
