import { useQuery } from '@apollo/client'
import { Alert, Box, LinearProgress, Typography } from '@mui/material'
import {
  DiscountTag,
  HeroCard,
  HeroCardEmpty,
  TagGroupItem,
} from '@trueskin-backoffice/components'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { getDiscountGroupVariant } from '../../discount-group'
import { RemoveVoucher } from '../components/remove-voucher'
import { useOrder } from '../contexts'

export const OrderDiscountsSummary = () => {
  const { order } = useOrder()

  if (!order.discounts.length && !order.vouchers.length) {
    return (
      <HeroCardEmpty>
        <Typography fontSize={12}>
          No discounts are applied to this order
        </Typography>
      </HeroCardEmpty>
    )
  }

  return (
    <HeroCard>
      <Typography sx={{ fontWeight: 600, mb: 2 }}>Discount details</Typography>
      <OrderDiscounts />
      <OrderVouchers />
    </HeroCard>
  )
}

const OrderDiscounts = () => {
  const { order } = useOrder()

  if (!order.discounts.length) {
    return null
  }

  return (
    <Box>
      {order.discounts.map((discount: any) => (
        <Discount
          discount={discount}
          vouchers={order.vouchers}
          occurrence={order.occurrence}
          canDeleteDiscounts={
            order.payment.status === 'upcoming' ||
            order.payment.status === 'open'
          }
        />
      ))}
    </Box>
  )
}

const OrderVouchers = () => {
  const { order } = useOrder()

  const vouchersNotUsed = order.vouchers.filter(({ used }: any) => !used)

  if (!vouchersNotUsed.length) {
    return null
  }

  return vouchersNotUsed.map((voucher: any) => (
    <VoucherDiscount discount={voucher} occurrence={order.occurrence} />
  ))
}

const Discount = ({
  discount,
  vouchers,
  occurrence,
  canDeleteDiscounts,
}: any) => {
  if (discount.ref === 'voucher') {
    const voucher = vouchers.find(
      ({ voucher }: any) => voucher._id === discount.refId,
    )
    return (
      <VoucherDiscount
        discount={voucher}
        occurrence={occurrence}
        canDeleteDiscounts={canDeleteDiscounts}
      />
    )
  }

  if (discount.ref === 'referralCode') {
    return <ReferralCodeDiscount discount={discount} />
  }

  if (discount.ref === 'referralReward') {
    return <ReferralRewardDiscount discount={discount} />
  }

  return <WalletDiscount discount={discount} />
}

const DiscountWrapper = ({ children }: any) => (
  <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>{children}</Box>
)

const VoucherDiscount = ({ discount, occurrence, canDeleteDiscounts }: any) => {
  const { voucher } = discount

  const {
    data: { discountGroupVariant = {} } = {},
    loading,
    error,
  } = useQuery(getDiscountGroupVariant, {
    variables: {
      id: voucher.discountGroupId,
      occurrence,
    },
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <DiscountWrapper>
      <DiscountTag label={voucher.code} />

      <Box sx={{ flex: 1 }}>
        {discountGroupVariant ? (
          formatCurrency(
            discountGroupVariant.discount.amount / 100,
            null,
            discountGroupVariant.discount.currency,
          )
        ) : (
          <Alert sx={{ pb: 0, pt: 0, width: '300px' }} severity="warning">
            No discount group variant found
          </Alert>
        )}
      </Box>

      {!discount.systemDefault && canDeleteDiscounts && (
        <RemoveVoucher voucher={voucher} />
      )}

      {!discount.used && (
        <TagGroupItem
          variant="light-error"
          sx={{
            p: 0,
            px: 1,
            height: 'fit-content',
            borderRadius: 1,
            textTransform: 'uppercase',
            ml: 2,
          }}
        >
          exhausted
        </TagGroupItem>
      )}
    </DiscountWrapper>
  )
}

const WalletDiscount = ({ discount }: any) => {
  return (
    <DiscountWrapper>
      <DiscountTag label="wallet" />

      <Box sx={{ flex: 1 }}>
        {formatCurrency(discount.amount / 100, null, discount.currency)}
      </Box>
    </DiscountWrapper>
  )
}

const ReferralCodeDiscount = ({ discount }: any) => {
  return (
    <DiscountWrapper>
      <DiscountTag label="referral-code" />

      <Box sx={{ flex: 1 }}>
        {formatCurrency(discount.amount / 100, null, discount.currency)}
      </Box>
    </DiscountWrapper>
  )
}

const ReferralRewardDiscount = ({ discount }: any) => {
  return (
    <DiscountWrapper>
      <DiscountTag label="referral-reward" />

      <Box sx={{ flex: 1 }}>
        {formatCurrency(discount.amount / 100, null, discount.currency)}
      </Box>
    </DiscountWrapper>
  )
}
