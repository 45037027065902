import { gql } from '@apollo/client'

export const getNotionCmsQuestions = gql`
  query NotionCmsQuestions {
    notionCmsQuestions {
      _id
      mdContent
    }
  }
`

export const getNotionCmsTreatments = gql`
  query NotionCmsTreatments {
    notionCmsTreatments {
      _id
      mdContent
      medication
    }
  }
`

export const getNotionCmsDiagnoses = gql`
  query NotionCmsDiagnoses {
    notionCmsDiagnoses {
      _id
      diagnosis
      icd10
      recommendOfflineVisit
      candidateForCore
      additionalDirections
      diagnosisDetails
      skinFindings
      additionalAdviceDetails

      createdTime
      lastEditedTime

      createdAt
      updatedAt
    }
  }
`

export const syncNotionCmsDiagnosesMutation = gql`
  mutation SyncNotionCmsDiagnoses {
    syncNotionCmsDiagnoses {
      _id
    }
  }
`

export const syncNotionCmsTreatmentsMutation = gql`
  mutation SyncNotionCmsTreatments {
    syncNotionCmsTreatments {
      _id
    }
  }
`

export const syncNotionCmsQuestionsMutation = gql`
  mutation SyncNotionCmsQuestions {
    syncNotionCmsQuestions {
      _id
    }
  }
`
