import { Button, lighten } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const NavLinkButton = (props: any) => (
  <Button
    size="small"
    component={NavLink}
    variant="text"
    fullWidth
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      px: 2,
      textTransform: 'unset',
      minWidth: 'unset',

      '&.active': {
        color: '#fff',
        background: lighten('#005858', 0.2),
        fontWeight: 600,
      },
    }}
    {...props}
  />
)
