import { Add, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  MenuItem,
  Popper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from '@mui/material'
import { lighten } from '@mui/system'
import React, { ReactNode, forwardRef, useState } from 'react'
import { PageHeader } from '../page-header'
import {
  DataFilterV2Divider,
  DataFilterV2InputConfigId,
  DataFilterV2InputConfigText,
  DataFilterV2InputConfigToggle,
  DataFilterV2InputConfigV2,
} from './data-filter-input-config'
import { useDataFilterV2 } from './data-filter.context'
import {
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from './data-filter.enums'
import {
  DataFilterDivider,
  DataFilterIdInput,
  DataFilterTextInput,
  DataFilterToggleInput,
} from './inputs'

interface DataFilterV2FilterOption {
  label?: string
  value: any
}

export interface DataFilterV2InputFilterProps {
  component: DataFilterV2FilterComponent
  options?: DataFilterV2FilterOption[] | undefined
}

export interface DataFilterV2InputConfig {
  component:
    | DataFilterV2FilterComponent.TEXT
    | DataFilterV2FilterComponent.SELECT
  label: string
  key: string
  op: DataFilterV2InputOperation
  options?: { label: string; value: any }[]
}

export interface DataFilterV2IdInputConfig {
  // component: FilterComponent
  label: string
  key: string
}

interface DataFilterV2Props<T, P> {
  // names: string[]
  filter?: T
  onChange?: (filter: T) => void
  inputRows?: DataFilterV2InputConfigV2[][]
  additionalFilters?: DataFilterV2InputConfig[]
  id?: DataFilterV2IdInputConfig
  children?: ReactNode
  wrapper?: React.ElementType
}

export const DataFilterV2 = <T extends object, P extends object>({
  // filter,
  // onChange,
  inputRows,
  additionalFilters,
  children,
  wrapper: Wrapper = DefaultWrapper,
}: // id,
DataFilterV2Props<T, P>) => {
  const { query, setQuery } = useDataFilterV2()
  // const [filterKey, setFilterKey] = useState('')
  // const [filterValue, setFilterValue] = useState('')
  // @ts-ignore
  // const [filterIdValue, setFilterIdValue] = useState<string>(filter?.[id?.key])

  // const options = names.filter(
  //   (n) => !Object.entries(filter).find(([key, value]) => key === n && value),
  // )

  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     if (!id) {
  //       return
  //     }
  //     if (filterIdValue === '') {
  //       handleAdd2({ filterKey: id.key, [id.key]: undefined })
  //     } else {
  //       handleAdd2({ filterKey: id.key, filterValue: filterIdValue })
  //     }
  //   }, 250)
  //
  //   return () => clearTimeout(getData)
  // }, [filterIdValue])

  // const handleAdd = () => {
  //   onChange({ ...filter, [filterKey]: filterValue })
  //   setFilterKey('')
  //   setFilterValue('')
  // }
  const handleAdd2 = ({ filterKey, filterValue }: any) => {
    // onChange({ ...filter, [filterKey]: filterValue })
    setQuery({ ...query, [filterKey]: filterValue })
  }

  // const inputComponent = useMemo(() => {
  //   // const DEFAULT_COMPONENT = (
  //   //   <TextField
  //   //     label="Filter value"
  //   //     value={filterValue}
  //   //     onChange={(e) => setFilterValue(e.target.value)}
  //   //     onKeyDown={(event) =>
  //   //       event.key === 'Enter' && filterKey && filterValue && handleAdd()
  //   //     }
  //   //     size="small"
  //   //     sx={{ ml: 2, minWidth: '18vw' }}
  //   //   />
  //   // )
  //   //
  //   // if (!inputs) {
  //   //   return DEFAULT_COMPONENT
  //   // }
  //
  //   // @ts-ignore
  //   const inputFilter: InputFilterProps = inputs[filterKey]
  //   const filterOptions: FilterOption[] | undefined = inputFilter?.options
  //
  //   switch (inputFilter?.component) {
  //     case FilterComponent.SELECT:
  //       return (
  //         <TextField
  //           value={filterValue}
  //           onChange={(e) => setFilterValue(e.target.value)}
  //           select
  //           sx={{ ml: 2, minWidth: '18vw' }}
  //           label="Filter value"
  //           size="small"
  //         >
  //           <MenuItem value="">
  //             <em>None</em>
  //           </MenuItem>
  //           {filterOptions?.map(({ label, value }: FilterOption) => (
  //             <MenuItem key={`filter_item_${value}`} value={value}>
  //               {label}
  //             </MenuItem>
  //           ))}
  //         </TextField>
  //       )
  //     default:
  //       return DEFAULT_COMPONENT
  //   }
  // }, [filterKey, filterValue, inputs])

  return (
    <Wrapper>
      {inputRows?.map((inputRow, index) => (
        <Box
          key={`row_${index}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: index !== inputRows.length - 1 ? 2 : 0,
            width: '100%',
          }}
        >
          {inputRow.map((input, index) => {
            if (input.type === DataFilterV2FilterComponent.DIVIDER) {
              return (
                <DataFilterDivider
                  key={index}
                  borderVisible={(input as DataFilterV2Divider).borderVisible}
                />
              )
            }

            if (input.type === DataFilterV2FilterComponent.ID) {
              return (
                <DataFilterIdInput
                  key={index}
                  input={input as DataFilterV2InputConfigId}
                />
              )
            }

            if (input.type === DataFilterV2FilterComponent.TEXT) {
              return (
                <DataFilterTextInput
                  key={index}
                  input={input as DataFilterV2InputConfigText}
                />
              )
            }

            if (input.type === DataFilterV2FilterComponent.TOGGLE) {
              return (
                <DataFilterToggleInput
                  key={index}
                  input={input as DataFilterV2InputConfigToggle}
                />
              )
            }

            return null
          })}

          {index === inputRows.length - 1 && additionalFilters?.length && (
            <>
              {Object.entries(query)
                .filter(
                  ([, value]) =>
                    value !== undefined && value !== null && value !== '',
                )
                .map(([key, value]) =>
                  additionalFilters?.find(
                    (input: DataFilterV2InputConfig) => input.key === key,
                  ) ? (
                    <Box key={key} sx={{ mr: 1.5 }}>
                      <AddFilter
                        input={additionalFilters.find(
                          (input: DataFilterV2InputConfig) => input.key === key,
                        )}
                        handleAdd={handleAdd2}
                        value={value}
                        handleDelete={(key: string) =>
                          setQuery({ ...query, [key]: undefined } as T)
                        }
                      />
                    </Box>
                  ) : null,
                )}

              <AddFilter inputs={additionalFilters} handleAdd={handleAdd2} />
            </>
          )}

          {children && <Box sx={{ ml: 'auto' }}>{children}</Box>}
        </Box>
      ))}
    </Wrapper>
  )
}

const DefaultWrapper: React.ElementType = ({
  children,
}: React.PropsWithChildren) => (
  <PageHeader sx={{ flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
    {children}
  </PageHeader>
)

const AddFilter = ({ inputs, input, value, handleAdd, handleDelete }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedFilterInput, setSelectedFilterInput] =
    React.useState<DataFilterV2InputConfig | null>(input)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  console.log('input = ', input, value)

  const handleClose = () => {
    if (!value) {
      setSelectedFilterInput(null)
    }

    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#e2e9f9',
          color: '#3367d6',
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          cursor: 'pointer',
          py: 0,
          // px: 2,
          lineHeight: '24px',
          borderRadius: 1,
        }}
      >
        <Box onClick={handleClick} sx={{ px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!value && <Add />}

            <Box sx={{ ml: 1, py: 1 }}>
              {selectedFilterInput ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {selectedFilterInput.label} {selectedFilterInput.op}
                  </Typography>

                  {/*{typeof value === 'boolean' ? (*/}
                  {/*  <Box sx={{ ml: 1, mr: 2 }}>{value.toString()}</Box>*/}
                  {/*) : (*/}
                  {/*  !!value && <Box sx={{ ml: 1, mr: 2 }}>{value}</Box>*/}
                  {/*)}*/}
                  {!!value && <Box sx={{ ml: 1, mr: 2 }}>{value}</Box>}
                </Box>
              ) : open ? (
                <Typography sx={{ fontWeight: 500 }}>
                  Choose attribute
                </Typography>
              ) : (
                <Typography sx={{ fontWeight: 500 }}>Add filter</Typography>
              )}
            </Box>
          </Box>
        </Box>

        {!!value && (
          <Box
            sx={{
              display: 'flex',
              '&:hover': { backgroundColor: lighten('#e2e9f9', 0.4) },
            }}
            onClick={() => handleDelete(input.key)}
          >
            <Close />
          </Box>
        )}
      </Box>

      <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          {selectedFilterInput ? (
            <InputValue
              input={selectedFilterInput}
              handleAdd={(value: any) => {
                handleClose()
                handleAdd(value)
              }}
              value={value}
            />
          ) : (
            <InputSelect
              onClick={(input: DataFilterV2InputConfig) =>
                setSelectedFilterInput(input)
              }
              inputs={inputs}
            />
          )}
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  )
}

const InputValue = forwardRef(
  (
    {
      input,
      value,
      handleAdd,
    }: { input: DataFilterV2InputConfig; value: any; handleAdd: any },
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState(value)

    return (
      <Box ref={ref}>
        <Box sx={{ p: 2 }}>
          {input.component === DataFilterV2FilterComponent.TEXT && (
            <TextField
              value={filterValue}
              fullWidth
              autoFocus
              onChange={(e) => setFilterValue(e.target.value)}
              onKeyDown={(event) =>
                event.key === 'Enter' &&
                filterValue &&
                handleAdd({ filterKey: input.key, filterValue })
              }
              size="small"
            />
          )}

          {input.component === DataFilterV2FilterComponent.SELECT && (
            <StyledToggleButtonGroup
              value={filterValue}
              exclusive
              onChange={(x, nextView) =>
                handleAdd({ filterKey: input.key, filterValue: nextView })
              }
              aria-label="filter-select"
              orientation="vertical"
              size="small"
              fullWidth
            >
              {input.options?.map((option) => (
                <ToggleButton
                  key={option.value}
                  value={option.value}
                  aria-label={`filter-select-${option.value}`}
                >
                  {option.label}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          )}
        </Box>

        {input.component !== 'select' && (
          <>
            <Divider />

            <Box>
              <Button
                fullWidth
                disabled={!filterValue}
                onClick={() => handleAdd({ filterKey: input.key, filterValue })}
              >
                Apply filter
              </Button>
            </Box>
          </>
        )}
      </Box>
    )
  },
)

const InputSelect = forwardRef(
  (
    { inputs, onClick }: { inputs: DataFilterV2InputConfig[]; onClick: any },
    ref,
  ) => (
    <Box sx={{ p: 1 }} ref={ref}>
      {inputs
        // .filter((input: InputConfig) => input.component !== FilterComponent.ID)
        .map((input: any) => (
          <MenuItem
            key={`filter_item_${input.key}`}
            value={input.key}
            onClick={() => onClick(input)}
          >
            {input.label}
          </MenuItem>
        ))}
    </Box>
  ),
)

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,
  // width: 300,
  width: 'fit-content',
  minWidth: 250,
  zIndex: theme.zIndex.modal,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))
