import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useWaitingRoomCase } from '../waiting-room-case.context'
import {
  createDraftDiagnosisForCaseMutation,
  getWaitingRoomCase,
} from '../waiting-room.gql.bff'

export const CreateDraftDiagnosisButton = () => {
  const { currentCase } = useWaitingRoomCase()
  const { getDoctorProfile } = useAuth()

  const [
    createDraftDiagnosisForCase,
    {
      loading: createDraftDiagnosisForCaseLoading,
      error: createDraftDiagnosisForCaseError,
    },
  ] = useMutation(createDraftDiagnosisForCaseMutation, {
    variables: {
      customerId: currentCase.customerId,
      caseId: currentCase.id,
      doctor: getDoctorProfile(),
    },
    refetchQueries: [getWaitingRoomCase],
  })

  return (
    <Button
      fullWidth
      onClick={() => createDraftDiagnosisForCase()}
      disabled={
        createDraftDiagnosisForCaseLoading ||
        !currentCase?.actions.canCreateDiagnosisDraft.enabled
      }
    >
      Create diagnosis draft
    </Button>
  )
}
