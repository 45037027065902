import { useQuery } from '@apollo/client'
import { Visibility } from '@mui/icons-material'
import {
  Box,
  Button,
  Icon,
  ImageList,
  ImageListItem,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { TagGroupItem } from '@trueskin-backoffice/components'
import { useEffect, useState } from 'react'
import { getWaitingRoomCaseAttachments } from '../waiting-room.gql.bff'

export const CasePhotosCountPreview = ({
  label,
  uploadedPhotosCount,
  currentCase,
}: any) => {
  return (
    <CaseAttachmentsPreview currentCase={currentCase}>
      <TagGroupItem sx={{ m: 0, py: 0, borderRadius: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#8181ad',
            fontSize: 12,
          }}
        >
          {!!label && (
            <Typography sx={{ pr: 1, fontSize: 12 }}>{label}</Typography>
          )}

          <Typography sx={{ pr: 1, fontSize: 12 }}>
            {uploadedPhotosCount}
          </Typography>

          <Icon component={Visibility} sx={{ fontSize: 16, ml: 1 }} />
        </Box>
      </TagGroupItem>
    </CaseAttachmentsPreview>
  )
}

const CaseAttachmentsPreview = ({ currentCase, children }: any) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const {
    data: { waitingRoomCase } = {},
    loading,
    error,
    refetch,
  } = useQuery(getWaitingRoomCaseAttachments, {
    // notifyOnNetworkStatusChange: true,
    skip: !currentCase || !open,
    variables: {
      caseId: currentCase.id,
    },
  })

  useEffect(() => {
    if (open) {
      refetch()
    }
  }, [open])

  return (
    <Box>
      <HtmlTooltip
        // title="Watch options"
        placement="right"
        // PopperProps={{
        //   disablePortal: true,
        // }}
        disableFocusListener
        open={open}
        arrow
        title={
          loading ? (
            'loading'
          ) : (
            // <CaseImages attachments={waitingRoomCase?.attachments} />
            // <Box sx={{ minWidth: 500, backgroundColor: '#f5f5f9' }}>
            <ImageList
              cols={3}
              // rowHeight={IMAGE_HEIGHT}
              sx={{ overflowY: 'unset', gridAutoRows: 160 }}
            >
              {waitingRoomCase?.attachments.map((image: any) => (
                <ImageListItem
                  key={image.file?.url}
                  sx={{
                    cursor: 'pointer',
                    '& .MuiImageListItem-img': {
                      maxHeight: 160,
                    },
                  }}
                  // onClick={() => openCarousel(image.carouselIndex)}
                >
                  <img
                    src={image.file?.url}
                    alt={'case-image-' + image.id}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
            // </Box>
          )
        }
      >
        <Button
          color="info"
          sx={{ p: 0, minWidth: 'unset', textTransform: 'unset' }}
          onMouseEnter={() => handleTooltipOpen()}
          onMouseLeave={() => handleTooltipClose()}
        >
          {children}
        </Button>
      </HtmlTooltip>
    </Box>
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 220,
    minWidth: 500,
    // fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
  },
}))
