import { ApolloError, useQuery } from '@apollo/client'
import React, { ReactNode, useEffect } from 'react'
import { getWaitingRoomCase } from './waiting-room.gql.bff'

//todo: this is duplicated in app.context (find a single place for it)
interface StandardProps {
  caseId: string | null | undefined
  children?: ReactNode
}

export interface WaitingRoomCaseProps {
  loading: boolean
  error: string | undefined | ApolloError | Error
  currentCase: any
  caseId: string | undefined | null
  // setCaseId: any
}

const WaitingRoomCaseContext = React.createContext<
  WaitingRoomCaseProps | undefined
>(undefined)

function WaitingRoomCaseProvider(props: StandardProps) {
  const { caseId } = props

  const {
    data: { waitingRoomCase: currentCase } = {},
    loading,
    error,
    refetch,
  } = useQuery(getWaitingRoomCase, {
    notifyOnNetworkStatusChange: true,
    skip: !caseId,
    variables: {
      caseId,
    },
  })

  useEffect(() => {
    if (caseId) {
      refetch({
        caseId,
      })
    }
  }, [caseId])

  const value = React.useMemo(
    () => ({
      loading,
      currentCase,
      error,
      caseId,
      // setCaseId,
    }),
    [
      loading,
      currentCase,
      caseId,
      // setCaseId,
      error,
    ],
  )

  return <WaitingRoomCaseContext.Provider value={value} {...props} />
}

function useWaitingRoomCase(): WaitingRoomCaseProps {
  const context = React.useContext(WaitingRoomCaseContext)
  if (context === undefined) {
    throw new Error(
      `useWaitingRoomCase must be used within a WaitingRoomCaseProvider`,
    )
  }
  return context
}

export { WaitingRoomCaseProvider, useWaitingRoomCase }
