import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { PresetsFilters } from './presets.filters'
import { PresetsTable } from './presets.table'

const PRESETS_FILTER_INIT_VALUES = {
  _id: StringParam,
  tags: StringParam,
  locales: StringParam,
  occurrence: StringParam,
}

export const PresetsPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={PRESETS_FILTER_INIT_VALUES}>
        <PresetsFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <PresetsTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
