import { useQuery } from '@apollo/client'
import { createContext, useContext, useMemo } from 'react'
import { getNotionCmsQuestionsPrefillsQuery } from './gql/notion-cms.gql.bff'

export interface NotionCmsQuestionsProps {
  loadingNotionCmsPrefills: boolean
  notionCmsQuestions: any[]
}

const NotionCmsQuestionsContext = createContext<NotionCmsQuestionsProps | null>(
  null,
)

export const NotionCmsQuestionsProvider = ({ children }: any) => {
  const {
    data: { notionCmsQuestions } = {
      notionCmsQuestions: [],
    },
    loading: loadingNotionCmsPrefills,
    error: errorLoadingNotionCmsPrefills,
  } = useQuery(getNotionCmsQuestionsPrefillsQuery)

  const value = useMemo(
    () => ({
      loadingNotionCmsPrefills,
      notionCmsQuestions,
    }),
    [loadingNotionCmsPrefills, notionCmsQuestions],
  )

  return (
    <NotionCmsQuestionsContext.Provider value={value}>
      {children}
    </NotionCmsQuestionsContext.Provider>
  )
}

export function useNotionCmsQuestions() {
  const context = useContext(NotionCmsQuestionsContext)

  if (!context) {
    throw new Error(
      'useNotionCmsQuestions should be used inside the NotionCmsQuestionsContext.',
    )
  }

  return context
}
