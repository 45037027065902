import { Box, Skeleton } from '@mui/material'
import { PageHeader } from '@trueskin-backoffice/components'
import { usePatient } from '@trueskin-backoffice/contexts'
import { NavLink } from 'react-router-dom'

export const PatientHeader = () => {
  const { customer, loading } = usePatient()

  if (loading) {
    return <Skeleton variant="rectangular" height={60} />
  }

  if (!customer) {
    return null
  }

  return (
    <PageHeader sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ mr: 8 }}>
        <NavLink to={''}>{customer.profile?.email}</NavLink>

        <Box>
          {customer.profile?.firstName} {customer.profile?.lastName}
        </Box>
      </Box>
    </PageHeader>
  )
}
