import { gql } from '@apollo/client'

// TODO: improve query to not load all fields by default
export const getAllRegistrations = gql`
  query AllRegistrationsQuery(
    $offset: Int!
    $limit: Int!
    $filter: RegistrationsFilter
  ) {
    registrations(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
      }
      totalRows
    }
  }
`
