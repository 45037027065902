import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import { HeroCardProps } from './hero-card'

export const HeroCardEmpty = styled(
  ({ children, ...others }: HeroCardProps) => (
    <Card
      sx={{
        boxShadow: 'none',
        position: 'relative',
        border: 0,
        borderRadius: 1,
        width: '100%',
        py: 3,
        px: 4,
        background: '#f4f7fc',
      }}
      {...others}
    >
      {children}
    </Card>
  ),
)()
