import { useQuery } from '@apollo/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  Pagination,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { DecodedValueMap } from 'use-query-params'
import { getAllRegistrations } from './registrations.gql.bff'
import { RegistrationsFilterProps } from './registrations.page'

interface RegistrationsTableProps {
  filter: DecodedValueMap<RegistrationsFilterProps>
}

const DEFAULT_PAGE_SIZE = 25

// TODO: Aggregate customers data with subscriptions
export const RegistrationsTable = ({ filter }: RegistrationsTableProps) => {
  const { getCustomerLink } = useAuth()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      registrations: {
        rows: registrations = [],
        totalRows: totalRegistration = 0,
      } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getAllRegistrations, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalRegistration / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'id',
      header: 'Id',
      cell: ({ getValue }) => {
        return (
          <div>
            <NavLink to={getCustomerLink(getValue())}>
              <Typography fontSize={11}>{getValue()}</Typography>
            </NavLink>
          </div>
        )
      },
    },
    {
      header: 'Account Id',
      accessorKey: 'accountId',
      cell: ({ getValue }) => {
        return (
          <div>
            <Typography fontSize={11}>{getValue()}</Typography>
          </div>
        )
      },
    },
    {
      accessorKey: 'profile.firstName',
      header: 'First Name',
    },
    {
      accessorKey: 'profile.lastName',
      header: 'Last Name',
    },
    // {
    //   accessorKey: 'medicalData.birthdate',
    //   header: 'Bi Date',
    //   cell: ({ getValue }) => {
    //     return (
    //       <Box>
    //         <DateTime dateTime={getValue()} tooltipPlacement="right" />
    //       </Box>
    //     )
    //   },
    // },
  ]

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={registrations}
        isLoading={loading}
        page={currentPage}
        totalPages={100}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}
