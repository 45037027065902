import { gql } from '@apollo/client'

export const employeesFields = gql`
  fragment EmployeesFields on Employee {
    _id
    firstName
    lastName
    email
    active
    locales
    memberOf {
      _id
      name
      availableRoles
    }
    roles
    profile {
      doctor {
        gender
        title
        bannerImage {
          locationUrl
        }
        signatureImage {
          locationUrl
        }
        profileImage {
          locationUrl
        }
      }
      medicalassistant {
        leadDoctor {
          _id
          firstName
          lastName
        }
      }
    }
  }
`

export const getAllTeams = gql`
  query GetAllTeams {
    getAllTeams {
      _id
      name
      managers
      availableRoles
    }
  }
`

export const getTeamById = gql`
  query GetTeamById($teamId: String!) {
    getTeamById(teamId: $teamId) {
      _id
      name
      managers
      availableRoles
    }
  }
`

export const getRoleById = gql`
  query GetRoleById($roleId: String!) {
    getRoleById(roleId: $roleId) {
      _id
      name
      description
    }
  }
`

export const getAllRoles = gql`
  query GetAllRoles {
    getAllRoles {
      _id
      name
      description
    }
  }
`

export const getEmployeeById = gql`
  ${employeesFields}
  query GetById($employeeId: String!) {
    getEmployeeById(employeeId: $employeeId) {
      ...EmployeesFields
    }
  }
`

export const updateDoctorProfile = gql`
  ${employeesFields}
  mutation UpdateDoctorProfile(
    $employeeId: String!
    $doctorProfile: DoctorProfileUpdate!
  ) {
    updateDoctorProfile(
      employeeId: $employeeId
      doctorProfile: $doctorProfile
    ) {
      ...EmployeesFields
    }
  }
`

export const updateMedicalAssistantProfile = gql`
  ${employeesFields}
  mutation UpdateMedicalAssistantProfile(
    $employeeId: String!
    $medicalAssistantProfile: MedicalAssistantProfileUpdate!
  ) {
    updateMedicalAssistantProfile(
      employeeId: $employeeId
      medicalassitantProfile: $medicalAssistantProfile
    ) {
      ...EmployeesFields
    }
  }
`

export const getAllEmployees = gql`
  ${employeesFields}
  query GetAllEmployees(
    $offset: Int
    $limit: Int
    $filter: EmployeeFilter
    $search: String
  ) {
    getAllEmployees(
      offset: $offset
      limit: $limit
      filter: $filter
      search: $search
    ) {
      data {
        ...EmployeesFields
      }
      total
    }
  }
`

export const createEmployeeMutation = gql`
  mutation CreateEmployee($employee: EmployeeCreate!) {
    createEmployee(employee: $employee) {
      _id
    }
  }
`

export const activateEmployeeMutation = gql`
  mutation ActivateEmployee($employeeId: String!) {
    activateEmployee(employeeId: $employeeId) {
      _id
    }
  }
`

export const blockEmployeeMutation = gql`
  mutation BlockEmployee($employeeId: String!) {
    blockEmployee(employeeId: $employeeId) {
      _id
    }
  }
`

export const assignRoleMutation = gql`
  mutation AssignRole($employeeId: String!, $role: String!) {
    assignRole(employeeId: $employeeId, role: $role) {
      _id
    }
  }
`

export const removeRoleMutation = gql`
  mutation RemoveRole($employeeId: String!, $role: String!) {
    removeRole(employeeId: $employeeId, role: $role) {
      _id
    }
  }
`

export const addMemberToTeamMutation = gql`
  mutation AddMemberToTeam($employeeId: String!, $teamId: String!) {
    addMemberToTeam(employeeId: $employeeId, teamId: $teamId) {
      _id
    }
  }
`

export const removeMemberFromTeamMutation = gql`
  mutation RemoveMemberFromTeam($employeeId: String!, $teamId: String!) {
    removeMemberFromTeam(employeeId: $employeeId, teamId: $teamId) {
      _id
    }
  }
`

export const addManagerToTeamMutation = gql`
  mutation AddManagerToTeam($employeeId: String!, $teamId: String!) {
    addManagerToTeam(employeeId: $employeeId, teamId: $teamId) {
      _id
    }
  }
`

export const removeManagerFromTeamMutation = gql`
  mutation RemoveManagerFromTeam($employeeId: String!, $teamId: String!) {
    removeManagerFromTeam(employeeId: $employeeId, teamId: $teamId) {
      _id
    }
  }
`

export const removeDoctorProfileMutation = gql`
  mutation RemoveDoctorProfile($employeeId: String!) {
    removeDoctorProfile(employeeId: $employeeId) {
      _id
    }
  }
`

export const removeMedicalAssistantProfileMutation = gql`
  mutation RemoveMedicalAssistantProfile($employeeId: String!) {
    removeMedicalAssistantProfile(employeeId: $employeeId) {
      _id
    }
  }
`

export const importAvailabilitiesMutation = gql`
  mutation ImportAvailabilitiesMutation($save: Boolean) {
    importAvailabilitiesMutation(save: $save) {
      _id
      employeeId
      hours
      date
      value
      importDate
    }
  }
`

export const getLatestAvailabilitiesQuery = gql`
  query GetLatestAvailabilities {
    getLatestAvailabilities {
      _id
      employeeId
      hours
      date
      value
      importDate
    }
  }
`

export const getAvailabilitiesSpreadsheetLinkQuery = gql`
  query GetAvailabilitiesSpreadsheetLink {
    getAvailabilitiesSpreadsheetLink {
      url
    }
  }
`
