import { gql } from '@apollo/client'

const commandFields = gql`
  fragment CommandFields on Command {
    _id
    name
    correlationId
    sourceType
    status
  }
`

const commandsPaginationFields = gql`
  ${commandFields}
  fragment CommandsPaginationFields on PaginatedCommands {
    rows {
      ...CommandFields
    }
    totalRows
  }
`

export const getAllCommandsQuery = gql`
  ${commandsPaginationFields}
  query GetAllCommands($limit: Int!, $offset: Int!, $filter: CommandFilter) {
    getAllCommands(limit: $limit, offset: $offset, filter: $filter) {
      ...CommandsPaginationFields
    }
  }
`
