import { Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  InputOperation,
} from '@trueskin-backoffice/components'
import { StringParam, useQueryParams } from 'use-query-params'
import { RegistrationsTable } from './registrations.table'

const REGISTRATIONS_FILTER_INIT_VALUES = {
  // id: StringParam,
  // v1UserId: StringParam,
  // email: StringParam,
  // paypalEmail: StringParam,
  // name: StringParam,
  accountId: StringParam,
  patientId: StringParam,
}
export type RegistrationsFilterProps = typeof REGISTRATIONS_FILTER_INIT_VALUES

export const RegistrationsPage = () => {
  const [filter, setFilter] = useQueryParams<RegistrationsFilterProps>(
    REGISTRATIONS_FILTER_INIT_VALUES,
  )

  return (
    <Box>
      <DataFilter
        filter={filter}
        onChange={setFilter}
        names={Object.keys(REGISTRATIONS_FILTER_INIT_VALUES)}
        inputs2={[
          {
            label: 'Email',
            component: FilterComponent.TEXT,
            key: 'email',
            op: InputOperation.EQUALS,
          },
          {
            label: 'First / Last name',
            component: FilterComponent.TEXT,
            key: 'name',
            op: InputOperation.CONTAINS,
          },
        ]}
        id={{
          label: 'Search for account id / patient id',
          key: 'id',
        }}
      />

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        <RegistrationsTable filter={filter} />
      </Card>
    </Box>
  )
}
