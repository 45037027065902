import { Box } from '@mui/material'
import { Id, PaymentInfo, Section } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { PageContentLayout } from '../layouts/page-content.layout'
import { useOrder } from './contexts'
import { OrderDock } from './dock/order-dock'
import { OrderPageSkeleton } from './order-page.skeleton'
import { OrderDiscountsSummary } from './sub-sections/order-discounts-summary'
import { OrderGeneralInformation } from './sub-sections/order-general-information'
import { OrderInvoiceInformation } from './sub-sections/order-invoice-information'

const OrderPage = () => {
  const { loading: customerLoading } = useCustomer()
  const { order, loading: orderLoading } = useOrder()

  if (orderLoading || customerLoading) {
    return (
      <PageContentLayout>
        <OrderPageSkeleton />
      </PageContentLayout>
    )
  }

  console.log(' order = ', order)

  return (
    <PageContentLayout sidebar={<OrderDock />}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Id label="Order" fontSize="1rem">
          {order?._id}
        </Id>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PaymentInfo
            amount={order?.values?.total}
            currency={order?.values.currency || order?.currency}
            status={order?.payment?.status}
          />
        </Box>
      </Box>

      <Section>
        <OrderGeneralInformation order={order} />
      </Section>

      {/*<OrderVoucherInformationSection*/}
      {/*  vouchers={order?.vouchers}*/}
      {/*  discounts={order?.discounts}*/}
      {/*  customerId={order?.customer}*/}
      {/*  orderId={order?._id}*/}
      {/*  status={order?.payment.status}*/}
      {/*/>*/}

      <Section>
        <OrderDiscountsSummary />
      </Section>

      <OrderInvoiceInformation order={order} />
    </PageContentLayout>
  )
}

export default OrderPage
