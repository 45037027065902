import { ApolloError, useQuery } from '@apollo/client'
import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { customerQuery } from './gql'

//todo: this is duplicated in app.context (find a single place for it)
interface StandardProps {
  children?: ReactNode
  maxSnackStack?: number
}

export interface CustomerContextProps {
  loading: boolean
  error: string | undefined | ApolloError | Error
  customer: any
}

const CustomerContext = React.createContext<CustomerContextProps | undefined>(
  undefined,
)

function CustomerProvider(props: StandardProps) {
  const { customerId } = useParams<{ customerId: string }>()

  const {
    data: { customer } = {},
    loading,
    error,
  } = useQuery(customerQuery, {
    variables: {
      customerId,
    },
  })

  const value = React.useMemo(
    () => ({
      loading,
      customer,
      error,
    }),
    [loading, customer, error],
  )

  return <CustomerContext.Provider value={value} {...props} />
}

function useCustomer(): CustomerContextProps {
  const context = React.useContext(CustomerContext)
  if (context === undefined) {
    throw new Error(`useCustomer must be used within a CustomerProvider`)
  }
  return context
}

export { CustomerProvider, useCustomer }
