import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Typography } from '@mui/material'
import {
  DateTime,
  HeroCardEmpty,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { ConsultationQuestion } from '@trueskin-backoffice/gql'
import { CaseImages } from './case-images'
import { useWaitingRoomCase } from './waiting-room-case.context'

// TODO: search for duplicate and move to components lib
export const ConsultationQuestionnaire = ({ label, questionnaire }: any) => {
  if (!questionnaire?.length) {
    return null
  }

  return (
    <ol>
      {questionnaire.map((c: ConsultationQuestion, index: number) => (
        <VerticalList key={index} sx={{ ml: 2 }}>
          <VerticalListRow>
            <VerticalListRowItem sx={{ pb: 0 }}>
              <Box component="li">
                <Typography variant="caption" fontSize={14}>
                  {c.question}
                </Typography>
                <Typography>{c.answer}</Typography>
              </Box>
            </VerticalListRowItem>
          </VerticalListRow>
        </VerticalList>
      ))}
    </ol>
  )
}

export const CaseQuestionnaireTimelineItem = () => {
  const { currentCase } = useWaitingRoomCase()

  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{
          flex: 'unset',
          pl: 0,
        }}
      >
        <Typography
          variant="caption"
          // color={consultationId === c.id ? 'primary' : 'default'}
          sx={{ display: 'flex', minWidth: 75, justifyContent: 'flex-end' }}
        >
          <DateTime dateTime={currentCase.metadata.activatedAt} />
        </Typography>
      </TimelineOppositeContent>

      <TimelineSeparator>
        <TimelineDot color="grey" />
        {/*<TimelineConnector />*/}
        {Boolean(currentCase.messages?.length) && <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent sx={{ pr: 0 }}>
        <HeroCardEmpty>
          <ConsultationQuestionnaire
            questionnaire={currentCase.questionnaireSummary}
          />

          <CaseImages attachments={currentCase.attachments} />
        </HeroCardEmpty>
      </TimelineContent>
    </TimelineItem>
  )
}
