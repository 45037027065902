import { useQuery } from '@apollo/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import {
  DateTime,
  HeroCard,
  HeroCardDivider,
  HeroCardEmpty,
  HeroCardSection,
  Id,
  TagGroupItem,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useAuth, usePatient } from '@trueskin-backoffice/contexts'
import { formatCurrency } from '@trueskin-backoffice/functions'
import { NavLink } from 'react-router-dom'
import { getCasesByCustomerId } from '../patient.gql.bff'

export const PatientCasesSummary = () => {
  const { customer } = usePatient()

  const {
    data: { patient } = {},
    loading,
    error,
  } = useQuery(getCasesByCustomerId, {
    skip: !customer,
    variables: {
      customerId: customer.id,
    },
  })

  if (loading) {
    return <LinearProgress />
  }

  if (!patient.cases?.length) {
    return (
      <HeroCardEmpty>
        <Typography fontSize={12}>No cases found</Typography>
      </HeroCardEmpty>
    )
  }

  if (error) {
    return (
      <HeroCardEmpty>
        <Typography color={'red'}>{error.message}</Typography>
      </HeroCardEmpty>
    )
  }

  // TODO: Add type
  const cases: [any] = patient.cases || []

  return (
    <HeroCard>
      {cases.map((someCase: any, index: number) => (
        <>
          <HeroCardSection sx={{ m: 0 }} key={someCase.id}>
            <CaseSummary order={someCase} />
          </HeroCardSection>

          {index < cases.length - 1 ? <HeroCardDivider /> : null}
        </>
      ))}
    </HeroCard>
  )
}

const CaseSummary = ({ order: someCase }: any) => {
  const {
    isCurrentEmployeeMedicalAssistant,
    isCurrentEmployeeDoctor,
    getCustomerLink,
  } = useAuth()

  return (
    <>
      <VerticalList>
        <VerticalListRow>
          <VerticalListRowItem sx={{ minWidth: 100 }}>
            {/*<StatusBadge status={someCase.paymentObject?.invoiceStatus} />*/}
            <TagGroupItem
              sx={{ textTransform: 'uppercase', borderRadius: 1, py: 0, px: 1 }}
            >
              {someCase.status}
            </TagGroupItem>

            <TagGroupItem
              sx={{
                textTransform: 'uppercase',
                borderRadius: 1,
                py: 0,
                px: 1,
                mt: 1,
              }}
            >
              {someCase.extendedStatus}
            </TagGroupItem>
          </VerticalListRowItem>

          <VerticalListRowItem fullWidth={true}>
            {someCase.documentNumber && (
              <Box sx={{ fontWeight: 500, fontSize: 16 }}>
                # {someCase.documentNumber}
              </Box>
            )}

            <Box>
              <Id linkTo={`cases/${someCase.id}`}>{someCase.id}</Id>
            </Box>

            {(isCurrentEmployeeMedicalAssistant() ||
              isCurrentEmployeeDoctor()) && (
              <Box>
                <NavLink
                  to={getCustomerLink(
                    `${someCase.customerId}/cases/${someCase.id}/medical-view`,
                  )}
                >
                  <Typography fontSize={11}>Go to medical view</Typography>
                </NavLink>
              </Box>
            )}
          </VerticalListRowItem>

          <VerticalListRowItem>
            <Box sx={{ display: 'flex', minWidth: 'max-content' }}>
              {/*<Box sx={{ mr: 1, fontWeight: 500 }}>*/}
              {/*  {!!someCase.values.total &&*/}
              {/*    formatCurrency(*/}
              {/*      someCase.values.total / 100,*/}
              {/*      null,*/}
              {/*      someCase.values.currency || someCase.currency,*/}
              {/*    )}*/}
              {/*</Box>*/}

              {someCase.paymentObject?.paidAt && (
                <>
                  <Box sx={{ mr: 1 }}>paid at</Box>
                  <DateTime
                    dateTime={someCase.paymentObject.paidAt}
                    tooltipPlacement="right"
                  />
                </>
              )}
            </Box>

            {!!someCase.paymentObject?.amountRefunded && (
              <Typography variant="caption">
                Refunded&nbsp;
                {formatCurrency(
                  someCase.payment.amountRefunded / 100,
                  null,
                  someCase.payment.currency,
                )}
              </Typography>
            )}
          </VerticalListRowItem>
        </VerticalListRow>
      </VerticalList>
    </>
  )
}
