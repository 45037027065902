import { gql } from '@apollo/client'

export const getNotionCmsDiagnosisPrefillsQuery = gql`
  query NotionCmsDiagnosisPrefills {
    notionCmsTreatments {
      _id
      mdContent
      medication
    }

    notionCmsDiagnoses {
      _id
      diagnosis
      icd10
      recommendOfflineVisit
      additionalDirections
      candidateForCore
    }
  }
`

export const getNotionCmsQuestionsPrefillsQuery = gql`
  query NotionCmsQuestionsPrefills {
    notionCmsQuestions {
      _id
      mdContent
    }
  }
`

export const getNotionCmsTreatmentBlocksQuery = gql`
  query NotionCmsTreatmentBlocks($prefillIds: [String!]) {
    notionCmsTreatments(prefillIds: $prefillIds) {
      _id
      mdContent
      medication
    }
  }
`

export const getNotionCmsDiagnosisContentBlocksQuery = gql`
  query NotionCmsDiagnosisContentBlocks($prefillIds: [String!]) {
    notionCmsDiagnoses(prefillIds: $prefillIds) {
      _id

      diagnosisDetails
      skinFindings
      additionalAdviceDetails
    }
  }
`
