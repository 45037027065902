export const formatDate = (date: string) =>
  date
    ? new Intl.DateTimeFormat(process.env['NX_I18N_LOCALE'], {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(new Date(date))
    : null

export const formatDateTime = (date: string, overrides: any = {}) =>
  date
    ? new Intl.DateTimeFormat(process.env['NX_I18N_LOCALE'], {
        ...{
          day: 'numeric',
          month: 'short',
          year: 'numeric',

          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        },
        ...overrides,
      }).format(new Date(date))
    : null

export const formatCurrency = (
  value: number,
  locale: string | null,
  currency: string,
  minimumFractionDigits = 2,
) => {
  if (!currency) {
    return value
  }
  return new Intl.NumberFormat(locale || process.env['NX_I18N_LOCALE'], {
    style: 'currency',
    currency,
    minimumFractionDigits,
  }).format(value)
}

export const formatDateISO = (date: string) =>
  date && new Date(date).toISOString().split('T')[0]

export const formatDateTimeISO = (date: string) =>
  date && new Date(date).toISOString()
