import { TextField } from '@mui/material'
import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  prefix?: string
  suffix?: string
}

const NumberFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { prefix, suffix, onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
        valueIsNumericString
        allowNegative={false}
        prefix={prefix}
        suffix={suffix}
      />
    )
  },
)

export const NumberInput = ({ ...props }) => {
  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
      {...props}
    />
  )
}
