import { bffGqlClient, useV2Mutation } from '@trueskin-backoffice/api-client'
import { DockAction } from '@trueskin-backoffice/components'
import React from 'react'
import { CgPlayListAdd } from 'react-icons/cg'
import { createUpcomingOrderForSubscriptionMutation } from '../../../customer/customer.gql'
import { getSubscriptionById } from '../../subscription.gql.bff'

export const ComputeUpcomingOrderAction = ({ subscription }: any) => {
  const canComputeUpcomingOrder =
    subscription.endBehaviour === 'RENEW' &&
    subscription.status === 'ACTIVE' &&
    !subscription.upcomingOrder

  const [
    createUpcomingOrderForSubscription,
    {
      loading: createUpcomingOrderForSubscriptionLoading,
      error: createUpcomingOrderForSubscriptionError,
    },
  ] = useV2Mutation(createUpcomingOrderForSubscriptionMutation, {
    variables: { v1UserId: subscription?.order?.customer },
    // refetchQueries: [getUpcomingOrderForSubscription],
  })

  return (
    <>
      <DockAction
        startIcon={<CgPlayListAdd />}
        disabled={!canComputeUpcomingOrder}
        onClick={async () => {
          await createUpcomingOrderForSubscription()
          await bffGqlClient.refetchQueries({
            include: [getSubscriptionById],
          })
        }}
      >
        Compute upcoming order
      </DockAction>
    </>
  )
}
