import { Edit } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import { DockAction } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import React from 'react'
import 'react-day-picker/dist/style.css'
import { UpdateEmailModal } from '../../components/update-email.modal'

export const EditEmailAction = () => {
  const { customer } = useCustomer()
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  return (
    <>
      <DockAction
        startIcon={<Edit />}
        onClick={() => setShowEditDialog(true)}
        disabled={customer?.accountId}
      >
        E-mail
      </DockAction>

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <UpdateEmailModal onClose={() => setShowEditDialog(false)} />
        </Dialog>
      ) : null}
    </>
  )
}
