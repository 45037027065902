import colors from './colors'

const { info, dark, background } = colors

const globals = {
  html: {
    scrollBehavior: 'smooth',
  },
  body: {
    minWidth: 320,
    minHeight: '100vh',
    backgroundColor: `${background.default}`,
    fontSize: 14,
  },
  '#root': {
    minWidth: 768,
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important',
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${dark.main} !important`,
    transition: 'color 150ms ease-in !important',
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${info.main} !important`,
  },
}

export default globals
