import { gql } from '@apollo/client'

const externalWebhookExecutionFields = gql`
  fragment ExternalWebhookExecutionFields on ExternalWebhookExecution {
    statusCode
    payload
    responseHeaders
  }
`

const externalWebhookFields = gql`
  ${externalWebhookExecutionFields}
  fragment ExternalWebhookFields on ExternalWebhook {
    id
    status
    url
    body
    headers
    maxNumberOfRetries
    executions {
      ...ExternalWebhookExecutionFields
    }
    customerId
    createdAt
    updatedAt
  }
`

export const getAllExternalWebhooks = gql`
  ${externalWebhookFields}
  query GetAllExternalWebhooks(
    $limit: Int!
    $offset: Int!
    $filter: ExternalWebhookFilter
  ) {
    getAllExternalWebhooks(limit: $limit, offset: $offset, filter: $filter) {
      ...ExternalWebhookFields
    }
  }
`
