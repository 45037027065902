import { ApolloError } from '@apollo/client'
import { useV2Mutation } from '@trueskin-backoffice/api-client'
import React from 'react'
import { getSubscriptionById } from '../subscription.gql.bff'
import {
  cancelSubscriptionImmediatelyMutation,
  renewSubscriptionImmediatelyMutation,
  scheduleSubscriptionToCancelMutation,
  scheduleSubscriptionToRenewMutation,
} from './subscription.gql'

export interface CustomerMutationsContextProps {
  loading: boolean
  error: string | undefined | ApolloError | Error

  scheduleToRenew: () => void
  renewImmediately: () => void
  scheduleToCancel: () => void
  cancelImmediately: () => void
}

const SubscriptionMutationsContext = React.createContext<
  CustomerMutationsContextProps | undefined
>(undefined)

const SubscriptionMutationsProvider = ({ children, subscription }: any) => {
  const [
    scheduleToRenewFn,
    { loading: scheduleToRenewLoading, error: scheduleToRenewError },
  ] = useV2Mutation(scheduleSubscriptionToRenewMutation, {
    variables: { subscriptionId: subscription?.id },
    refetchQueries: [getSubscriptionById],
  })

  const [
    renewImmediatelyFn,
    { loading: renewImmediatelyLoading, error: renewImmediatelyError },
  ] = useV2Mutation(renewSubscriptionImmediatelyMutation, {
    variables: { subscriptionId: subscription?.id },
    refetchQueries: [getSubscriptionById],
  })

  const [
    scheduleToCancelAtEndOfTerm,
    { loading: scheduleToCancelLoading, error: scheduleToCancelError },
  ] = useV2Mutation(scheduleSubscriptionToCancelMutation, {
    variables: { subscriptionId: subscription?.id },
    refetchQueries: [getSubscriptionById],
  })

  const [
    cancelImmediatelyFn,
    { loading: cancelImmediatelyLoading, error: cancelImmediatelyError },
  ] = useV2Mutation(cancelSubscriptionImmediatelyMutation, {
    variables: { subscriptionId: subscription?.id },
    refetchQueries: [getSubscriptionById],
  })

  const loading =
    scheduleToRenewLoading ||
    renewImmediatelyLoading ||
    scheduleToCancelLoading ||
    cancelImmediatelyLoading
  const error =
    scheduleToRenewError ||
    renewImmediatelyError ||
    scheduleToCancelError ||
    cancelImmediatelyError

  return (
    <SubscriptionMutationsContext.Provider
      value={{
        scheduleToRenew: () => scheduleToRenewFn(),
        renewImmediately: () => renewImmediatelyFn(),
        scheduleToCancel: () => scheduleToCancelAtEndOfTerm(),
        cancelImmediately: () => cancelImmediatelyFn(),
        loading,
        error,
      }}
    >
      {children}
    </SubscriptionMutationsContext.Provider>
  )
}

function useSubscriptionMutations(): CustomerMutationsContextProps {
  const context = React.useContext(SubscriptionMutationsContext)
  if (context === undefined) {
    throw new Error(
      `useSubscriptionMutations must be used within a SubscriptionMutationsContext`,
    )
  }
  return context
}

export { SubscriptionMutationsProvider, useSubscriptionMutations }
