import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { useNavigate } from 'react-router-dom'

export const DiscountGroupsFilters = () => {
  const navigate = useNavigate()

  return (
    <DataFilterV2
      inputRows={[[]]}
      additionalFilters={[
        {
          label: 'Available',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'available',
          op: DataFilterV2InputOperation.EQUALS,
          options: [true, false].map((item: boolean) => ({
            label: item.toString(),
            value: item.toString(),
          })),
        },
      ]}
    >
      <Button
        onClick={() => navigate('/discount-groups/create')}
        startIcon={<Add />}
      >
        Discount group
      </Button>
    </DataFilterV2>
  )
}
