import { Box, Button, TextField } from '@mui/material'
import { H1 } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { useCustomerMutations } from '../contexts'

const validationSchema = yup.object({
  lastName: yup.string().required('Last name is required'),
})

export const UpdateLastNameModal = ({ onClose }: { onClose: any }) => {
  const { customer } = useCustomer()
  const { updateCustomerLastName } = useCustomerMutations()

  return (
    <Formik
      initialValues={{
        lastName: customer.lastName,
      }}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await updateCustomerLastName(values.lastName)
        onClose()
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              minWidth: 500,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <H1>Update customer first name</H1>

            <Box>
              <Box sx={{ mb: 4 }}>
                Current last name value: {customer.lastName}
              </Box>

              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Last name"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && Boolean(errors.lastName)}
              />

              <Box sx={{ color: 'red' }}>
                <ErrorMessage name="lastName" />
              </Box>
            </Box>

            <Box>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
