import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'

export const OrderItem = ({ quantity, product }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        borderColor: grey['300'],
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        mr: 2,
      }}
    >
      <Box sx={{ backgroundColor: grey['300'], px: 1 }}>{quantity}x</Box>
      <Box sx={{ px: 2 }}>{product}</Box>
    </Box>
  )
}
