import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

export const HorizontalListItem = styled((props: BoxProps) => {
  return (
    <Box
      sx={{
        maxWidth: 300,
        wordBreak: 'break-all',
        textAlign: 'left',
        fontSize: 12,
        display: 'inline-flex',
        flexGrow: 1,
        alignItems: 'center',
        pr: 5,
        position: 'relative',
        verticalAlign: 'middle',
        '::before': {
          backgroundColor: '#64648c',
          borderRadius: '50%',
          content: '""',
          height: 4,
          width: 4,
          position: 'absolute',
          left: -10,
          top: '50%',
          transform: 'translate(-50%,-50%)',
        },
      }}
      {...props}
    />
  )
})()

export const HorizontalList = styled((props: BoxProps) => {
  return (
    <Box
      sx={{
        listStyleType: 'none',
        overflow: 'hidden',
        m: 0,
      }}
      {...props}
    />
  )
})()
