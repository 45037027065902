import { Box, Button, TextField } from '@mui/material'
import { H1 } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { ErrorMessage, Form, Formik } from 'formik'
import { useCallback } from 'react'
import * as yup from 'yup'
import { useCustomerMutations } from '../contexts'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
})

export const UpdateEmailModal = ({ onClose }: { onClose: any }) => {
  const { customer } = useCustomer()
  const {
    updateCustomerEmail,
    updateCustomerEmailLoading,
    updateCustomerEmailError,
    resetUpdateCustomerEmailMutation,
  } = useCustomerMutations()

  const handleClose = useCallback(() => {
    resetUpdateCustomerEmailMutation()
    onClose?.()
  }, [resetUpdateCustomerEmailMutation, onClose])

  return (
    <Formik
      initialValues={{
        email: customer.email,
      }}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await updateCustomerEmail(values.email)
        handleClose()
      }}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          <Box
            sx={{
              minWidth: 500,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <H1>Update customer email address</H1>

            <Box>
              <Box sx={{ mb: 4 }}>
                Current email address value: {customer.email}
              </Box>

              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
              />

              <Box sx={{ color: 'red' }}>
                <ErrorMessage name="email" />
              </Box>
            </Box>

            <Box>
              <Button color="secondary" onClick={() => handleClose()}>
                Cancel
              </Button>

              <Button type="submit" disabled={updateCustomerEmailLoading}>
                Save
              </Button>
            </Box>

            {!!updateCustomerEmailError && (
              <pre>{JSON.stringify(updateCustomerEmailError)}</pre>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  )
}
