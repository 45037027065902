import { Box, Skeleton } from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import { ReactNode } from 'react'
import { MainMenu } from './main-menu'
import { RightHandSideMenu } from './menu/right-hand-side-menu'

// TODO: move to better folder
export const DefaultLayout = (props: LayoutProps) => {
  const { children } = props

  const { employee, loading } = useAuth()

  if (loading) {
    return (
      <DefaultLayoutRootContainer>
        <Box sx={{ display: 'flex' }}>
          <Skeleton variant="rectangular" height="100%" sx={{ mr: 2 }} />
        </Box>

        <DefaultLayoutMainContainer>
          <Skeleton variant="rectangular" height="100%" />
        </DefaultLayoutMainContainer>
      </DefaultLayoutRootContainer>
    )
  }

  return (
    <DefaultLayoutRootContainer>
      {employee && <MainMenu />}

      <DefaultLayoutMainContainer>{children}</DefaultLayoutMainContainer>
    </DefaultLayoutRootContainer>
  )
}

const DefaultLayoutRootContainer = (props: LayoutProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <RightHandSideMenu />

      <Box sx={{ flex: '1 1 auto', display: 'flex', overflow: 'hidden' }}>
        {props.children}
      </Box>
    </Box>
  )
}

const DefaultLayoutMainContainer = (props: LayoutProps) => {
  return (
    <Box
      component="main"
      sx={{
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      {props.children}
    </Box>
  )
}

export interface LayoutProps {
  children: ReactNode
}

DefaultLayout.defaultProps = {
  gutter: 2,
}
