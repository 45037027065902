import { Edit } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import { bffGqlClient } from '@trueskin-backoffice/api-client'
import {
  DockAction,
  EditAccountPasswordModal,
} from '@trueskin-backoffice/components'
import { customerQuery, useCustomer } from '@trueskin-backoffice/contexts'
import React from 'react'
import 'react-day-picker/dist/style.css'

export const EditAccountPasswordAction = () => {
  const { customer } = useCustomer()
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  return (
    <>
      <DockAction
        startIcon={<Edit fontSize="small" />}
        onClick={() => setShowEditDialog(true)}
        tooltip="Edit account password"
        disabled={!customer?.accountId}
      >
        Account password
      </DockAction>

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <EditAccountPasswordModal
            accountId={customer.accountId}
            open={showEditDialog}
            onClose={() => setShowEditDialog(false)}
            onComplete={() =>
              bffGqlClient.refetchQueries({ include: [customerQuery] })
            }
          />
        </Dialog>
      ) : null}
    </>
  )
}
