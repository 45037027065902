import { MoreHoriz, MoreVert } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import Menu from '@mui/material/Menu'
import * as React from 'react'

export const MoreActionsButton = ({
  children,
  size,
  buttonProps,
  horizontalIcon,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size={size || 'medium'}
        sx={buttonProps || {}}
      >
        {horizontalIcon ? <MoreHoriz /> : <MoreVert />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </Menu>
    </Box>
  )
}
