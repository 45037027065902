import { useMutation } from '@apollo/client'
import { Search } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Popper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import {
  HeroCard,
  HeroCardSection,
  TextTooltip,
} from '@trueskin-backoffice/components'
import { useAuth, useNotionCms } from '@trueskin-backoffice/contexts'
import { EditorPlayground } from '@trueskin/bo/editor'
import React, { useCallback } from 'react'
import { useWaitingRoomCaseDiagnosis } from '../waiting-room-case-diagnosis.context'
import { useWaitingRoomCase } from '../waiting-room-case.context'
import {
  getWaitingRoomCase,
  getWaitingRoomCaseDiagnosisQuery,
  requestDiagnosisContentMutation,
  requestDiagnosisContentReviewMutation,
  setAdditionalAdviceNotesForDiagnosisMutation,
  setDiagnosisDetailsNotesForDiagnosisMutation,
  setHeadingNotesForDiagnosisMutation,
  setSkinFindingsNodesForDiagnosisMutation,
  setTreatmentPlanNotesForDiagnosisMutation,
} from '../waiting-room.gql.bff'
import { PrefillTreatmentContentButton } from './inputs/prefill-treatment-content-button'

const MedicalDiagnosisContentDetails = () => {
  const { employee } = useAuth()
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  // const {
  //   setInjectDiagnosisDetailsContent,
  //   setInjectSkinFindingsContent,
  //   setInjectAdditionalAdviceDetailsContent,
  // } = useNotionCms()
  //
  // const {
  //   refetch: getNotionCmsDiagnosisContentBlocks,
  //   // loading: loadingNotionCmsPrefills,
  //   // error: errorLoadingNotionCmsPrefills,
  // } = useQuery(getNotionCmsDiagnosisContentBlocksQuery, {
  //   skip: true,
  //   // variables: {
  //   //   prefillIds: ['', ''],
  //   // },
  // })

  const canRequestContentReview =
    diagnosisRecord?.diagnosisAndTreatmentContent?.headingNote?.richText &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.headingNote?.richText !==
      '<p><br></p>' &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.diagnosisDetails?.richText &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.diagnosisDetails
      ?.richText !== '<p><br></p>' &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.skinFindings?.richText &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.skinFindings?.richText !==
      '<p><br></p>' &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.treatmentPlan?.richText &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.treatmentPlan?.richText !==
      '<p><br></p>' &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.additionalAdvice?.richText &&
    diagnosisRecord?.diagnosisAndTreatmentContent?.additionalAdvice
      ?.richText !== '<p><br></p>' &&
    diagnosisRecord?.status === 'requires_content'

  const [
    requestDiagnosisContentReview,
    {
      loading: requestDiagnosisContentReviewLoading,
      error: requestDiagnosisContentReviewError,
    },
  ] = useMutation(requestDiagnosisContentReviewMutation, {
    variables: {
      customerId: diagnosisRecord.customerId,
      caseId: diagnosisRecord.caseId,
      employeeId: employee?.id,
      diagnosisRecordId: diagnosisRecord.id,
    },
    refetchQueries: [getWaitingRoomCase, getWaitingRoomCaseDiagnosisQuery],
  })

  if (
    !employee ||
    !currentCase ||
    !(
      currentCase.assignedTo?.id === employee.id ||
      diagnosisRecord.contentRequest?.assignedTo?.id === employee.id
    )
  ) {
    return null
  }

  return (
    <Box>
      <HeroCard
        sx={{
          display: 'flex',
          borderRadius: 1,
          alignItems: 'center',
          m: 0,
          mb: 2,
          py: 1,
        }}
      >
        {diagnosisRecord.status === 'requires_content' &&
          !diagnosisRecord.contentRequest?.assignedTo && (
            <HeroCardSection sx={{ textAlign: 'center', mb: 0 }}>
              <Alert icon={false} color="warning">
                The diagnosis content will be filled in by somebody else. Their
                updates will be visible here.
              </Alert>
            </HeroCardSection>
          )}

        {diagnosisRecord.status === 'requires_content' &&
          diagnosisRecord.contentRequest?.assignedTo && (
            <HeroCardSection sx={{ textAlign: 'center', mb: 0 }}>
              <Alert icon={false} color="warning">
                The diagnosis content is being created by{' '}
                {diagnosisRecord.contentRequest.assignedTo.title}{' '}
                {diagnosisRecord.contentRequest.assignedTo.firstName}{' '}
                {diagnosisRecord.contentRequest.assignedTo.lastName}
              </Alert>

              {canRequestContentReview && (
                <Button
                  sx={{ textTransform: 'unset' }}
                  onClick={async () => await requestDiagnosisContentReview()}
                >
                  Request review
                </Button>
              )}
            </HeroCardSection>
          )}

        {diagnosisRecord.status === 'requires_doctor_review' && (
          <Alert icon={false} color="info" sx={{ my: 2, textAlign: 'left' }}>
            Please review the diagnosis content, make updates to it if required
            and publish it for the customer.
          </Alert>
        )}

        {diagnosisRecord.status === 'draft' &&
          currentCase.assignedTo?.id === employee?.id && (
            <HeroCardSection
              sx={{ display: 'flex', alignItems: 'center', flex: 1, mb: 0 }}
            >
              <RequestDiagnosisContentButton
                diagnosisRecord={diagnosisRecord}
              />

              <Box sx={{ ml: 'auto' }}>
                <TextTooltip
                  title={
                    'Hand over the diagnosis content creation to be filled in by somebody else'
                  }
                  placement="left"
                >
                  <InfoIcon fontSize={'small'} />
                </TextTooltip>
              </Box>
            </HeroCardSection>
          )}
      </HeroCard>
    </Box>
  )
}

export const MedicalDiagnosisContent = () => {
  // const { currentCase } = useWaitingRoomCase()
  // const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  // const { isCurrentEmployeeDoctor } = useAuth()
  // const showButtons =
  //   diagnosisRecord.status === 'draft' && isCurrentEmployeeDoctor()

  // const [expanded, setExpanded] = React.useState(false)

  // const handleExpandClick = () => {
  //   setExpanded(!expanded)
  // }

  // if (showButtons && !expanded) {
  //   if (!currentCase.actions.canDiagnose.enabled) {
  //     return null
  //   }
  //
  //   return (
  //     <HeroCard
  //       sx={{
  //         display: 'flex',
  //         borderRadius: 1,
  //         alignItems: 'center',
  //         m: 0,
  //         mb: 2,
  //         py: 1,
  //       }}
  //     >
  //       <HeroCardSection sx={{ textAlign: 'center' }}>
  //         <Alert
  //           icon={false}
  //           action={
  //             <Button
  //               color="inherit"
  //               size="small"
  //               onClick={handleExpandClick}
  //               sx={{
  //                 boxShadow: 1,
  //               }}
  //             >
  //               Create content
  //             </Button>
  //           }
  //           color="error"
  //           sx={{ my: 2, textAlign: 'left' }}
  //         >
  //           Create the diagnosis content yourself if the case resolution is
  //           urgent
  //         </Alert>
  //
  //         <Alert
  //           icon={false}
  //           action={
  //             <RequestDiagnosisContentButton
  //               diagnosisRecord={diagnosisRecord}
  //             />
  //           }
  //           sx={{ textAlign: 'left' }}
  //         >
  //           Hand over the diagnosis content to be filled in by somebody else
  //         </Alert>
  //       </HeroCardSection>
  //     </HeroCard>
  //   )
  // }

  return (
    <>
      <MedicalDiagnosisContentDetails />

      <MedicalDiagnosisContentInputs />
    </>
  )
}

const MedicalDiagnosisContentInputs = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <DiagnosisAndTreatmentDiagnosisDetailsNotes />

      <DiagnosisAndTreatmentSkinFindingsNotes />

      <DiagnosisAndTreatmentTreatmentPlanNotes />

      <DiagnosisAndTreatmentAdditionalAdviceNotes />
    </Box>
  )
}

const RequestDiagnosisContentButton = ({ diagnosisRecord }: any) => {
  const { employee } = useAuth()

  const [
    requestDiagnosisContent,
    {
      loading: createDraftDiagnosisForCaseLoading,
      error: createDraftDiagnosisForCaseError,
    },
  ] = useMutation(requestDiagnosisContentMutation, {
    variables: {
      customerId: diagnosisRecord.customerId,
      caseId: diagnosisRecord.caseId,
      employeeId: employee?.id,
      diagnosisRecordId: diagnosisRecord.id,
    },
    refetchQueries: [getWaitingRoomCase, getWaitingRoomCaseDiagnosisQuery],
  })

  return (
    <Button
      // color="success"
      size="small"
      onClick={() => requestDiagnosisContent()}
      sx={{ textTransform: 'unset' }}
      disabled={createDraftDiagnosisForCaseLoading}
    >
      Hand over diagnosis content creation
    </Button>
  )
}

export const DiagnosisAndTreatmentHeadingNotes = () => {
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()

  const initialEditorState = diagnosisRecord?.rawContent?.headingNote?.markdown

  const canEditContent =
    diagnosisRecord?.actions?.canEditDiagnosisContent?.enabled

  const [
    setHeadingNotesForDiagnosis,
    {
      loading: setHeadingNotesForDiagnosisLoading,
      error: setHeadingNotesForDiagnosisError,
    },
  ] = useMutation(setHeadingNotesForDiagnosisMutation, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  const handleSave = useCallback(
    async ({
      editorState,
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      await setHeadingNotesForDiagnosis({
        variables: {
          customerId: diagnosisRecord.customerId,
          caseId: diagnosisRecord.caseId,
          doctor: getDoctorProfile(),
          diagnosisRecordId: diagnosisRecord.id,
          editorState,
          richText,
          markdown,
        },
      })
    },
    [],
  )

  return (
    <Box sx={{ py: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: '#eaeaf4',
            px: 2,
            fontWeight: 500,
            display: 'flex',
          }}
        >
          Heading message notes
          <Typography variant="caption" color="error">
            *
          </Typography>
          {setHeadingNotesForDiagnosisLoading && (
            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
              (Auto-saving)
            </Typography>
          )}
        </Typography>
      </Box>

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          m: 0,
          mb: 2,
          p: 0,
          overflow: 'visible',
        }}
      >
        <Box sx={{ mt: 4 }}>
          <EditorPlayground
            autoSave={true}
            readOnly={!canEditContent}
            showActionsSaveButton={canEditContent}
            initialState={initialEditorState}
            handleSave={handleSave}
          />
        </Box>
      </HeroCard>
    </Box>
  )
}

const DiagnosisAndTreatmentDiagnosisDetailsNotes = () => {
  const { injectDiagnosisDetailsContent } = useNotionCms()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()

  const initialEditorState =
    diagnosisRecord?.rawContent?.diagnosisDetails?.markdown

  const canEditContent =
    diagnosisRecord?.actions?.canEditDiagnosisContent?.enabled

  const [
    setDiagnosisDetailsNotesForDiagnosis,
    {
      loading: setDiagnosisDetailsNotesForDiagnosisLoading,
      error: setDiagnosisDetailsNotesForDiagnosisError,
    },
  ] = useMutation(setDiagnosisDetailsNotesForDiagnosisMutation, {
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  const handleSave = useCallback(
    async ({
      editorState,
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      await setDiagnosisDetailsNotesForDiagnosis({
        variables: {
          customerId: diagnosisRecord.customerId,
          caseId: diagnosisRecord.caseId,
          doctor: getDoctorProfile(),
          diagnosisRecordId: diagnosisRecord.id,
          editorState,
          richText,
          markdown,
        },
      })
    },
    [],
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: '#eaeaf4',
            px: 2,
            fontWeight: 500,
            display: 'flex',
          }}
        >
          Diagnosis details
          <Typography variant="caption" color="error">
            *
          </Typography>
          {setDiagnosisDetailsNotesForDiagnosisLoading && (
            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
              (Auto-saving)
            </Typography>
          )}
        </Typography>
      </Box>

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          // mt: 2,
          m: 0,
          mb: 2,
          // py: 1,
          p: 0,
          overflow: 'visible',
        }}
      >
        <Box sx={{ mt: 4 }}>
          <EditorPlayground
            autoSave={true}
            readOnly={!canEditContent}
            showActionsSaveButton={canEditContent}
            initialState={initialEditorState}
            handleSave={handleSave}
            injectContent={injectDiagnosisDetailsContent}
          />
        </Box>
      </HeroCard>
    </Box>
  )
}

const DiagnosisAndTreatmentSkinFindingsNotes = () => {
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()
  const { injectSkinFindingsContent } = useNotionCms()

  const initialEditorState = diagnosisRecord?.rawContent?.skinFindings?.markdown

  const canEditContent =
    diagnosisRecord?.actions?.canEditDiagnosisContent?.enabled

  const [
    setSkinFindingsNodesForDiagnosis,
    {
      loading: setSkinFindingsNodesForDiagnosisLoading,
      error: setSkinFindingsNodesForDiagnosisError,
    },
  ] = useMutation(setSkinFindingsNodesForDiagnosisMutation, {
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  const handleSave = useCallback(
    async ({
      editorState,
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      await setSkinFindingsNodesForDiagnosis({
        variables: {
          customerId: diagnosisRecord.customerId,
          caseId: diagnosisRecord.caseId,
          doctor: getDoctorProfile(),
          diagnosisRecordId: diagnosisRecord.id,
          editorState,
          richText,
          markdown,
        },
      })
    },
    [],
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: '#eaeaf4',
            px: 2,
            fontWeight: 500,
            display: 'flex',
          }}
        >
          Skin findings
          <Typography variant="caption" color="error">
            *
          </Typography>
          {setSkinFindingsNodesForDiagnosisLoading && (
            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
              (Auto-saving)
            </Typography>
          )}
        </Typography>
      </Box>

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          m: 0,
          mb: 2,
          p: 0,
          overflow: 'visible',
        }}
      >
        <Box sx={{ mt: 4 }}>
          <EditorPlayground
            autoSave={true}
            readOnly={!canEditContent}
            showActionsSaveButton={canEditContent}
            initialState={initialEditorState}
            handleSave={handleSave}
            injectContent={injectSkinFindingsContent}
          />
        </Box>
      </HeroCard>
    </Box>
  )
}

const DiagnosisAndTreatmentTreatmentPlanNotes = () => {
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()
  // const [abc, setAbc] = useState<{
  //   mdContent?: string
  //   revision?: number
  //   append?: boolean
  // }>({})
  const {
    notionCmsTreatments,
    injectTreatmentContent,
    setInjectTreatmentContent,
  } = useNotionCms()

  const [diagnosisPrefillsAnchorEl, setDiagnosisPrefillsAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const initialEditorState =
    diagnosisRecord?.rawContent?.treatmentPlan?.markdown

  const canEditContent =
    diagnosisRecord?.actions?.canEditDiagnosisContent?.enabled

  const [
    setTreatmentPlanNotesForDiagnosis,
    {
      loading: setTreatmentPlanNotesForDiagnosisLoading,
      error: setTreatmentPlanNotesForDiagnosisError,
    },
  ] = useMutation(setTreatmentPlanNotesForDiagnosisMutation, {
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  const handleSave = useCallback(
    async ({
      editorState,
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      await setTreatmentPlanNotesForDiagnosis({
        variables: {
          customerId: diagnosisRecord.customerId,
          caseId: diagnosisRecord.caseId,
          doctor: getDoctorProfile(),
          diagnosisRecordId: diagnosisRecord.id,
          editorState,
          richText,
          markdown,
        },
      })
    },
    [],
  )

  const handleClose = () => {
    if (diagnosisPrefillsAnchorEl) {
      diagnosisPrefillsAnchorEl.focus()
    }
    setDiagnosisPrefillsAnchorEl(null)
  }

  const handleSearchDiagnosisPrefillsClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    if (diagnosisPrefillsAnchorEl) {
      handleClose()
    } else {
      setDiagnosisPrefillsAnchorEl(event.currentTarget)
    }
  }

  const handlePrefillSelected = (event: any, newValue: any) => {
    setInjectTreatmentContent({ mdContent: newValue.mdContent, append: true })
    handleClose()
  }

  return (
    <Box>
      <PrefillTreatmentContentButton />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: '#eaeaf4',
            px: 2,
            fontWeight: 500,
            display: 'flex',
          }}
        >
          Treatment plan details
          <Typography variant="caption" color="error">
            *
          </Typography>
          {setTreatmentPlanNotesForDiagnosisLoading && (
            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
              (Auto-saving)
            </Typography>
          )}
        </Typography>

        {canEditContent && (
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
              backgroundColor: '#eaeaf4',
              px: 2,
              fontWeight: 500,
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={handleSearchDiagnosisPrefillsClick}
            >
              Prefill
              <Search sx={{ fontSize: 14, ml: 2 }} />
            </Box>

            <StyledPopper
              disablePortal={false}
              open={
                Boolean(diagnosisPrefillsAnchorEl) &&
                Boolean(diagnosisPrefillsAnchorEl)
              }
              anchorEl={diagnosisPrefillsAnchorEl}
            >
              {Boolean(diagnosisPrefillsAnchorEl) && (
                <Autocomplete
                  fullWidth
                  id="diagnosis-prefills"
                  size="small"
                  options={notionCmsTreatments || []}
                  getOptionLabel={(option: any) => option._id}
                  onChange={handlePrefillSelected}
                  autoComplete
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      {...params}
                      label="Search treatment prefills"
                    />
                  )}
                />
              )}
            </StyledPopper>
          </Typography>
        )}
      </Box>

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          m: 0,
          mb: 2,
          p: 0,
          overflow: 'visible',
        }}
      >
        <Box sx={{ mt: 4 }}>
          <EditorPlayground
            autoSave={true}
            readOnly={!canEditContent}
            showActionsSaveButton={canEditContent}
            initialState={initialEditorState}
            handleSave={handleSave}
            injectContent={injectTreatmentContent}
          />
        </Box>
      </HeroCard>
    </Box>
  )
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 0 2px 0 rgb(0 0 30 / 10%), 0 4px 8px 0 rgb(0 0 30 / 10%), 0 8px 16px 0 rgb(0 0 30 / 10%)`,
  borderRadius: 1,
  minWidth: 400,
  padding: 8,
  zIndex: theme.zIndex.modal,
  fontSize: 12,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  inset: '4px auto auto 0px !important',
}))

const DiagnosisAndTreatmentAdditionalAdviceNotes = () => {
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()
  const { injectAdditionalAdviceDetailsContent } = useNotionCms()

  const initialEditorState =
    diagnosisRecord?.rawContent?.additionalAdvice?.markdown

  const canEditContent =
    diagnosisRecord?.actions?.canEditDiagnosisContent?.enabled

  const [
    setAdditionalAdviceNoteForDiagnosis,
    {
      loading: setAdditionalAdviceNoteForDiagnosisLoading,
      error: setAdditionalAdviceNoteForDiagnosisError,
    },
  ] = useMutation(setAdditionalAdviceNotesForDiagnosisMutation, {
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
  })

  const handleSave = useCallback(
    async ({
      editorState,
      richText,
      markdown,
    }: {
      editorState: string
      richText: string
      markdown: string
    }) => {
      await setAdditionalAdviceNoteForDiagnosis({
        variables: {
          customerId: diagnosisRecord.customerId,
          caseId: diagnosisRecord.caseId,
          doctor: getDoctorProfile(),
          diagnosisRecordId: diagnosisRecord.id,
          editorState,
          richText,
          markdown,
        },
      })
    },
    [],
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: '#eaeaf4',
            px: 2,
            fontWeight: 500,
            display: 'flex',
          }}
        >
          Additional advice details
          <Typography variant="caption" color="error">
            *
          </Typography>
          {setAdditionalAdviceNoteForDiagnosisLoading && (
            <Typography variant="caption" color="error" sx={{ ml: 1 }}>
              (Auto-saving)
            </Typography>
          )}
        </Typography>
      </Box>

      <HeroCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          m: 0,
          mb: 2,
          p: 0,
          overflow: 'visible',
        }}
      >
        <Box sx={{ mt: 4 }}>
          <EditorPlayground
            autoSave={true}
            readOnly={!canEditContent}
            showActionsSaveButton={canEditContent}
            initialState={initialEditorState}
            handleSave={handleSave}
            injectContent={injectAdditionalAdviceDetailsContent}
          />
        </Box>
      </HeroCard>
    </Box>
  )
}
