import { useMutation } from '@apollo/client'
import { Delete } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import {
  TagGroupItem,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useSnackbar } from 'notistack'
import { useWaitingRoomCaseDiagnosis } from '../waiting-room-case-diagnosis.context'
import { useWaitingRoomCase } from '../waiting-room-case.context'
import {
  getWaitingRoomCaseDiagnosisQuery,
  RemoveConditionDiagnosisMutation,
} from '../waiting-room.gql.bff'
import { EditDiagnosisButton } from './inputs/edit-diagnosis-button'

export const ConditionDiagnosis = ({ conditionDiagnosis, editable }: any) => {
  const { currentCase } = useWaitingRoomCase()
  const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
  const { getDoctorProfile } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [
    removeConditionDiagnosis,
    {
      loading: removeConditionDiagnosisLoading,
      error: removeConditionDiagnosisError,
    },
  ] = useMutation(RemoveConditionDiagnosisMutation, {
    refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
    onCompleted: () => {
      enqueueSnackbar('Diagnosis removed successfully', { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar('Something went wrong', { variant: 'success' })
    },
  })

  return (
    <VerticalList>
      <VerticalListRow sx={{ display: 'flex', alignItems: 'baseline' }}>
        <VerticalListRowItem sx={{ display: 'flex', alignItems: 'center' }}>
          {editable && diagnosisRecord.actions.canDiagnose.enabled && (
            <IconButton
              disableRipple
              sx={{ p: 0 }}
              onClick={async () =>
                await removeConditionDiagnosis({
                  variables: {
                    customerId: diagnosisRecord.customerId,
                    caseId: diagnosisRecord.caseId,
                    doctor: getDoctorProfile(),
                    diagnosisRecordId: diagnosisRecord.id,
                    conditionDiagnosisId: conditionDiagnosis.id,
                  },
                })
              }
            >
              <Delete sx={{ fontSize: 16 }} />
            </IconButton>
          )}

          <TagGroupItem
            sx={{
              minWidth: 75,
              justifyContent: 'center',
              border: 'unset',
              py: 0,
              textTransform: 'uppercase',
              borderRadius: 1,
              backgroundColor: '#e2e9f9',
              mr: 0,
            }}
          >
            {conditionDiagnosis.icd10Diagnosis}
          </TagGroupItem>

          {editable && diagnosisRecord.actions.canDiagnose.enabled && (
            <EditDiagnosisButton conditionDiagnosis={conditionDiagnosis} />
          )}
        </VerticalListRowItem>

        <VerticalListRowItem fullWidth={true}>
          <Box component="span" sx={{ fontWeight: 500 }}>
            {conditionDiagnosis.name}
          </Box>

          <Box>
            Offline consultation:&nbsp;
            {conditionDiagnosis.recommendOfflineConsultation
              ? 'recommended'
              : 'not recommended'}
          </Box>

          <Box>
            Additional directions:&nbsp;
            {conditionDiagnosis.additionalDirections || 'none'}
          </Box>

          {conditionDiagnosis.prefillId && (
            <Typography variant="caption">
              Prefill:&nbsp;
              {conditionDiagnosis.prefillId}
            </Typography>
          )}
        </VerticalListRowItem>
      </VerticalListRow>
    </VerticalList>
  )
}
