import { Box } from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import { Trans } from 'react-i18next'
import { NavLinkButton } from './nav-link.button'

export const TofuChannelMedicalMenuButton = () => {
  const { isCurrentEmployeeMedicalAssistant } = useAuth()
  return (
    <>
      <Box sx={{ cursor: 'default' }}>
        <NavLinkButton to="/tofu/waiting-room">
          <Trans i18nKey="App.Menu.waiting-room" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default' }}>
        <NavLinkButton to="/tofu/patients">
          <Trans i18nKey="App.Menu.patients" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default' }}>
        <NavLinkButton
          to={`/tofu/cases?${
            isCurrentEmployeeMedicalAssistant()
              ? 'caseDiagnosisStatus=REQUIRES_CONTENT'
              : 'extendedStatus=AWAITING_DIAGNOSIS&assignee=UNASSIGNED'
          }
          `}
        >
          <Trans i18nKey="App.Menu.cases" />
        </NavLinkButton>
      </Box>
    </>
  )
}
