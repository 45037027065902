import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import { forwardRef, useState } from 'react'
import { useWaitingRoomCaseDiagnosis } from '../../../waiting-room-case-diagnosis.context'
import {
  AddConditionDiagnosisMutation,
  getWaitingRoomCaseDiagnosisQuery,
  updateConditionDiagnosisMutation,
} from '../../../waiting-room.gql.bff'

export const MedicalDiagnosisInput = forwardRef(
  ({ onClose, type, conditionDiagnosis }: any, ref) => {
    const { diagnosisRecord } = useWaitingRoomCaseDiagnosis()
    const { getDoctorProfile } = useAuth()

    const [medicalDiagnosis, setMedicalDiagnosis] = useState(
      conditionDiagnosis?.name || '',
    )
    const [icd10Diagnosis, setIcd10Diagnosis] = useState(
      conditionDiagnosis?.icd10Diagnosis || '',
    )
    const [recommendOfflineConsultation, setRecommendOfflineConsultation] =
      useState(conditionDiagnosis?.recommendOfflineConsultation || false)
    const [additionalDirections, setAdditionalDirections] = useState(
      conditionDiagnosis?.additionalDirections || '',
    )

    const [
      addConditionDiagnosis,
      {
        loading: addConditionDiagnosisLoading,
        error: addConditionDiagnosisError,
      },
    ] = useMutation(AddConditionDiagnosisMutation, {
      refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
      onCompleted: () => {
        onClose()
      },
    })

    const [
      updateConditionDiagnosis,
      {
        loading: updateConditionDiagnosisLoading,
        error: updateConditionDiagnosisError,
      },
    ] = useMutation(updateConditionDiagnosisMutation, {
      refetchQueries: [getWaitingRoomCaseDiagnosisQuery],
      onCompleted: () => {
        onClose()
      },
    })

    return (
      <Box ref={ref}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {conditionDiagnosis?.prefillId && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="caption">
                Prefill: {conditionDiagnosis?.prefillId}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex' }}>
            <CustomTextField
              fullWidth
              autoFocus
              size="small"
              id="medicalDiagnosis"
              name="medicalDiagnosis"
              value={medicalDiagnosis}
              label="Type diagnosis here"
              disabled={addConditionDiagnosisLoading}
              onChange={(e) => setMedicalDiagnosis(e.target.value)}
            />

            <CustomTextField
              fullWidth
              size="small"
              id="medicalCondition"
              name="medicalCondition"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ backgroundColor: 'red' }}
                  >
                    Icd10
                  </InputAdornment>
                ),
              }}
              sx={{ ml: 2, maxWidth: 110 }}
              value={icd10Diagnosis}
              disabled={addConditionDiagnosisLoading}
              onChange={(e) => setIcd10Diagnosis(e.target.value)}
            />
          </Box>
        </Box>

        <CustomTextField
          fullWidth
          size="small"
          id="additionalDirections"
          name="additionalDirections"
          label="Additional directions for diagnosis"
          sx={{ mt: 3 }}
          value={additionalDirections}
          disabled={addConditionDiagnosisLoading}
          onChange={(e) => setAdditionalDirections(e.target.value)}
        />

        <Box>
          <FormControlLabel
            id="recommendOfflineConsultation"
            control={
              <Checkbox
                // value={recommendOfflineConsultation}
                checked={recommendOfflineConsultation}
                onChange={(e) =>
                  setRecommendOfflineConsultation(e.target.checked)
                }
              />
            }
            label="Recommend offline practice visit"
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            onClick={onClose}
            color="error"
            disabled={addConditionDiagnosisLoading}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            disabled={
              addConditionDiagnosisLoading ||
              !medicalDiagnosis ||
              !icd10Diagnosis
            }
            onClick={async () => {
              if (conditionDiagnosis?.id) {
                await updateConditionDiagnosis({
                  variables: {
                    customerId: diagnosisRecord.customerId,
                    caseId: diagnosisRecord.caseId,
                    doctor: getDoctorProfile(),
                    diagnosisRecordId: diagnosisRecord.id,
                    diagnosisType: type,
                    conditionDiagnosisId: conditionDiagnosis.id,
                    medicalDiagnosis,
                    icd10Diagnosis,
                    recommendOfflineConsultation,
                    additionalDirections,
                  },
                })
              } else {
                await addConditionDiagnosis({
                  variables: {
                    customerId: diagnosisRecord.customerId,
                    caseId: diagnosisRecord.caseId,
                    doctor: getDoctorProfile(),
                    diagnosisRecordId: diagnosisRecord.id,
                    diagnosisType: type,
                    medicalDiagnosis,
                    icd10Diagnosis,
                    recommendOfflineConsultation,
                    additionalDirections,
                    prefillId: conditionDiagnosis?.prefillId,
                    candidateForCore: conditionDiagnosis?.candidateForCore,
                  },
                })
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    )
  },
)

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: theme.palette.divider,

    height: '100%',
    maxHeight: 'unset',
    textTransform: 'unset',
    // padding: '28px 14px',
    padding: '0px 4px',
    marginRight: 4,
    borderTopLeftRadius: theme.shape.borderRadius + 'px',
    borderBottomLeftRadius: theme.shape.borderRadius + 'px',

    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
  '& .MuiInputBase-root': {
    fontSize: 12,
  },
}))
