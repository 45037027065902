import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import {
  DateTime,
  HorizontalList,
  HorizontalListItem,
  UserGeneratedHtml,
} from '@trueskin-backoffice/components'
import { useWaitingRoomCase } from '../waiting-room-case.context'

export const DiagnosisRecordRejectionView = () => {
  const { currentCase } = useWaitingRoomCase()

  if (currentCase.status.toLowerCase() !== 'rejected') {
    return null
  }

  return (
    <>
      <HorizontalList>
        <HorizontalListItem>
          <Typography variant="caption" sx={{ mr: 1 }}>
            Rejected on
          </Typography>
          <DateTime
            dateTime={currentCase.metadata.rejectedAt}
            tooltipPlacement="top"
          />
        </HorizontalListItem>

        <HorizontalListItem>
          <Typography variant="caption" sx={{ mr: 1 }}>
            Rejected by
          </Typography>
          <Box sx={{ fontStyle: 'italic' }}>
            {currentCase.rejectedBy.title} {currentCase.rejectedBy.firstName}{' '}
            {currentCase.rejectedBy.lastName}
          </Box>
        </HorizontalListItem>
      </HorizontalList>

      <Accordion
        sx={{
          boxShadow: 'none',
          border: 0,
          borderRadius: 3,
          width: '100%',
          background: '#f4f7fc',
          mt: 2,
          '&:before': { opacity: 0 },
        }}
        disableGutters
        defaultExpanded={true}
        disabled={true}
      >
        <AccordionSummary
          aria-controls={`panel-rejection-note`}
          id={`panel-rejection-note`}
        >
          <Typography variant="h2" color="error">
            Rejection note
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <UserGeneratedHtml
            html={currentCase.rejectionMessage.content.richText}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
