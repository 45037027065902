import { ApolloError, useMutation, useQuery } from '@apollo/client'
import React from 'react'
import {
  deleteAccountMutation,
  updateAccountEmailMutation,
  updateAccountPasswordMutation,
  verifyAccountDeletionStateQuery,
} from './account.gql.bff'

export interface AccountMutationsContextProps {
  verifyAccountDeletionState: any
  verifyAccountDeletionStateLoading: boolean
  verifyAccountDeletionStateError: ApolloError | undefined

  deleteAccountLoading: boolean
  deleteAccountError: ApolloError | undefined
  deleteAccount: () => void

  updateAccountEmail: (params: { email: string }) => Promise<any>
  updateAccountEmailLoading: boolean
  updateAccountEmailError: ApolloError | undefined

  updateAccountPassword: (params: {
    newPassword: string
    confirmPassword: string
  }) => Promise<any>
  updateAccountPasswordLoading: boolean
  updateAccountPasswordError: ApolloError | undefined
}

const AccountMutationsContext = React.createContext<
  AccountMutationsContextProps | undefined
>(undefined)

const AccountMutationsProvider = ({
  accountId,
  children,
}: {
  accountId: string
  children: any
}) => {
  const {
    data: verifyAccountDeletionState,
    loading: verifyAccountDeletionStateLoading,
    error: verifyAccountDeletionStateError,
  } = useQuery(verifyAccountDeletionStateQuery, {
    skip: !accountId,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })

  const [
    deleteAccount,
    { loading: deleteAccountLoading, error: deleteAccountError },
  ] = useMutation(deleteAccountMutation, {})

  const [
    updateAccountEmail,
    { loading: updateAccountEmailLoading, error: updateAccountEmailError },
  ] = useMutation(updateAccountEmailMutation, {
    notifyOnNetworkStatusChange: true,
  })

  const [
    updateAccountPassword,
    {
      loading: updateAccountPasswordLoading,
      error: updateAccountPasswordError,
    },
  ] = useMutation(updateAccountPasswordMutation, {
    notifyOnNetworkStatusChange: true,
  })

  const value = React.useMemo(
    () => ({
      // TODO: improve these crappy names
      verifyAccountDeletionState,
      verifyAccountDeletionStateLoading,
      verifyAccountDeletionStateError,

      deleteAccountLoading,
      deleteAccountError,
      deleteAccount: () =>
        deleteAccount({
          variables: {
            accountId,
          },
        }),

      updateAccountEmail: ({ email }: { email: string }) =>
        updateAccountEmail({
          variables: {
            accountId,
            email,
          },
        }),
      updateAccountEmailLoading,
      updateAccountEmailError,

      updateAccountPassword: ({
        newPassword,
        confirmPassword,
      }: {
        newPassword: string
        confirmPassword: string
      }) =>
        updateAccountPassword({
          variables: {
            accountId,
            newPassword,
            confirmPassword,
          },
        }),
      updateAccountPasswordLoading,
      updateAccountPasswordError,
    }),
    [
      accountId,

      verifyAccountDeletionState,
      verifyAccountDeletionStateLoading,
      verifyAccountDeletionStateError,

      deleteAccountLoading,
      deleteAccountError,
      deleteAccount,

      updateAccountEmail,
      updateAccountEmailLoading,
      updateAccountEmailError,

      updateAccountPassword,
      updateAccountPasswordLoading,
      updateAccountPasswordError,
    ],
  )

  return (
    <AccountMutationsContext.Provider value={value}>
      {children}
    </AccountMutationsContext.Provider>
  )
}

// TODO: rename file to account.context maybe?
function useAccountMutations(): AccountMutationsContextProps {
  const context = React.useContext(AccountMutationsContext)
  if (context === undefined) {
    throw new Error(
      `useAccountMutations must be used within a AccountMutationsProvider`,
    )
  }
  return context
}

export { AccountMutationsProvider, useAccountMutations }
