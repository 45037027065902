import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  MenuItem,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import { useV2Mutation, useV2Query } from '@trueskin-backoffice/api-client'
import {
  H2,
  HeroCard,
  Id,
  JsonButton,
  MoreActionsButton,
  Section,
} from '@trueskin-backoffice/components'
import { formatCurrency } from '@trueskin-backoffice/functions'
import React, { useState } from 'react'
import { voidInvoice } from '../../invoices/invoices.gql'
import { InvoiceStatus } from '../components/invoice-status'
import { InvoiceTransactions } from '../components/invoice-transaction'
import {
  collectInvoiceImmediatelyMutation,
  getInvoicesByOrderId,
  getOrderById,
} from '../gql'
import { InvoiceRefundDialog } from './invoice-refund-dialog'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} disableRipple sx={{ p: 0 }} color="primary" />
  // return <ExpandMoreIcon />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const InvoiceSummary = ({ invoice, refetch }: any) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const [voidConfirmationDialogOpened, setVoidConfirmationDialogOpened] =
    useState(false)

  return (
    <HeroCard>
      <VoidInvoiceDialog
        open={voidConfirmationDialogOpened}
        onClose={() => setVoidConfirmationDialogOpened(false)}
        orderId={invoice.orderId}
        invoiceId={invoice.id}
      />
      <Box
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <H2>
            Invoice for{' '}
            <b>
              {formatCurrency(
                invoice.total / 100,
                invoice.locale,
                invoice.currency,
              )}
            </b>
            <Id fontSize="11px">{invoice.id}</Id>
          </H2>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CollectFutureInstalment invoice={invoice} />
            <InvoiceStatus invoice={invoice} />
            <JsonButton id={invoice.id} jsonData={invoice} />
            {invoice?.status === 'PAYMENT_DUE' && (
              <MoreActionsButton
                horizontalIcon
                size={'small'}
                buttonProps={{ p: 0 }}
              >
                <MenuItem onClick={() => setVoidConfirmationDialogOpened(true)}>
                  Void
                </MenuItem>
              </MoreActionsButton>
            )}
          </Box>
        </Box>

        <Divider sx={{ mt: 1 }} />

        <Box
          sx={{
            px: 4,
            py: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography>
              Paid:{' '}
              {formatCurrency(
                invoice.amountPaid / 100,
                invoice.locale,
                invoice.currency,
              )}
            </Typography>
          </Box>

          <Box>
            <div>
              Due:{' '}
              {formatCurrency(
                invoice.amountDue / 100,
                invoice.locale,
                invoice.currency,
              )}
            </div>
          </Box>

          <Box>
            <div>
              Refunded:{' '}
              {formatCurrency(
                invoice.amountRefunded / 100,
                invoice.locale,
                invoice.currency,
              )}
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InvoiceRefundDialog invoice={invoice} />
          </Box>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="text"
            size="small"
            onClick={handleExpandClick}
            endIcon={
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          >
            {expanded ? 'Hide' : 'Show'} transactions
          </Button>
        </Box>

        <Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <InvoiceTransactions invoice={invoice} />
          </Collapse>
        </Box>
      </Box>
    </HeroCard>
  )
}

export const OrderInvoiceInformation = ({ order }: any) => {
  const orderId = order?._id
  const [expanded, setExpanded] = React.useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const {
    data: { getInvoicesByOrderId: invoices } = {},
    loading,
    refetch,
  } = useV2Query(getInvoicesByOrderId, {
    skip: orderId === undefined && order.payment.status !== 'upcoming',
    variables: {
      orderId,
    },
  })

  const orderInstalmentInvoices = (invoices || []).filter((invoice: any) =>
    (order?.instalments?.invoices || [])
      .map((invoice: any) => invoice.id)
      .includes(invoice.id),
  )
  const otherInvoices = (invoices || []).filter(
    (invoice: any) =>
      !order.instalments?.invoices
        ?.map(({ id }: any) => id)
        .includes(invoice.id),
  )

  if (loading) {
    return <Skeleton />
  }

  if (!invoices?.length) {
    return null
  }

  return (
    <>
      <Section headerLabel={`Instalments (${orderInstalmentInvoices?.length})`}>
        {orderInstalmentInvoices.map((invoice: any) => (
          <Box sx={{ mb: 2 }}>
            <InvoiceSummary invoice={invoice} refetch={refetch} />
          </Box>
        ))}
      </Section>

      {otherInvoices?.length > 0 && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="text"
              size="small"
              onClick={handleExpandClick}
              endIcon={
                <ExpandMore
                  expand={expanded}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              }
            >
              {expanded ? 'Hide' : 'Show'} other invoices
            </Button>
          </Box>

          <Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {otherInvoices.map((invoice: any) => (
                <InvoiceSummary invoice={invoice} />
              ))}
            </Collapse>
          </Box>
        </>
      )}
    </>
  )
}

const VoidInvoiceDialog = ({
  orderId,
  invoiceId,
  open,
  onClose,
}: {
  orderId: string
  invoiceId: string
  open: boolean
  onClose: () => void
}) => {
  const [handleVoidInvoice, { loading, error }] = useV2Mutation(voidInvoice, {
    variables: { invoiceId, orderId },
    refetchQueries: [
      {
        query: getOrderById,
        variables: {
          orderId,
        },
      },
      {
        query: getInvoicesByOrderId,
        variables: {
          orderId,
        },
      },
    ],
    onCompleted: () => {
      onClose()
    },
  })

  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>Void invoice</DialogTitle>
      <Container>
        <Box sx={{ pl: 4 }}>
          <Typography variant="h2">
            Are you sure you want invoice to be voided?
          </Typography>
          <Alert variant="outlined" color="warning" sx={{ p: 0, m: 0 }}>
            <Typography>
              This action might only needed if subscription was canceled.
            </Typography>
            <Typography>
              By confirming this action, there is no way back to restore
              invoice.
            </Typography>
            <Typography> Hope you know what you are doing. </Typography>
          </Alert>
          {error && <Typography color="red">Error: {error.message}</Typography>}
        </Box>
        <Grid
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 4 }}
        >
          <LoadingButton
            loading={loading}
            color="error"
            onClick={() => handleVoidInvoice()}
          >
            Void invoice
          </LoadingButton>
          <Button disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
        </Grid>
      </Container>
    </Dialog>
  )
}

const CollectFutureInstalment = ({ invoice }: any) => {
  const canCollectFutureInstalment =
    invoice.collectAt &&
    invoice.autoCollect &&
    !invoice.paymentTransactions?.length &&
    new Date() < new Date(invoice.collectAt) &&
    invoice?.status !== 'VOIDED'

  const [collectInvoiceImmediately, { error, loading }] = useV2Mutation(
    collectInvoiceImmediatelyMutation,
    {
      refetchQueries: [getOrderById, getInvoicesByOrderId],
      variables: {
        invoiceId: invoice.id,
        customerId: invoice.customerId,
      },
    },
  )

  if (
    !canCollectFutureInstalment ||
    !process.env['NX_FEATURE_FLAG_COLLECT_INSTALMENT_MANUALLY']
  ) {
    return null
  }

  return (
    <Button size="small" onClick={() => collectInvoiceImmediately(invoice.id)}>
      Collect
    </Button>
  )
}
