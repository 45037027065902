import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/system'

export const VerticalList = ({ children, ...other }: VerticalListProps) => (
  <Box
    component="table"
    sx={{
      borderCollapse: 'collapse',
      position: 'relative',
      textAlign: 'left',
      width: '100%',
    }}
    {...other}
  >
    <Box component="tbody">{children}</Box>
  </Box>
)

export type VerticalListProps = BoxProps

export const VerticalListRow = styled(
  ({ children, ...other }: VerticalListRowProps) => (
    <Box component="tr" {...other}>
      {children}
    </Box>
  ),
)()

type VerticalListRowProps = BoxProps

export const VerticalListRowItem = styled(
  ({ fullWidth, children, ...other }: VerticalListRowItemProps) => (
    <Box
      component="td"
      sx={{ p: 2, ...(fullWidth ? { width: '100%' } : {}) }}
      {...other}
    >
      {children}
    </Box>
  ),
)()

interface VerticalListRowItemProps extends BoxProps {
  fullWidth?: boolean
}
