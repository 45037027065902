import { Box, Divider } from '@mui/material'
import { Restricted } from '@trueskin-backoffice/components'
import { useAuth, useUIController } from '@trueskin-backoffice/contexts'
import { Trans } from 'react-i18next'
import { NavLinkButton } from './menu/components/nav-link.button'
import { MainMenuFooter } from './menu/main-menu-footer'
import { MainMenuHeader } from './menu/main-menu-header'
// import { MedicalMenu } from './medical-menu'

const MainMenuWebChannel = () => (
  <>
    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/customers">
        <Trans i18nKey="App.Menu.customers" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/subscriptions">
        <Trans i18nKey="App.Menu.subscriptions" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/orders">
        <Trans i18nKey="App.Menu.orders" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/invoices">
        <Trans i18nKey="App.Menu.invoices" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/prescriptions">
        <Trans i18nKey="App.Menu.prescriptions" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/consultations">
        <Trans i18nKey="App.Menu.consultations" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <Divider />
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/products">
        <Trans i18nKey="App.Menu.products" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/vouchers?active=true">
        <Trans i18nKey="App.Menu.vouchers" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/discounts">
        <Trans i18nKey="App.Menu.discounts" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/discount-groups">
        <Trans i18nKey="App.Menu.discount-groups" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/pricing">
        <Trans i18nKey="App.Menu.pricing" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/presets">
        <Trans i18nKey="App.Menu.presets" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/preset-lists">
        <Trans i18nKey="App.Menu.preset-lists" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <Divider />
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/audit">
        <Trans i18nKey="App.Menu.audit" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/external-webhooks">
        <Trans i18nKey="App.Menu.external-webhooks" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/webhooks">
        <Trans i18nKey="App.Menu.webhooks" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/cron-jobs">
        <Trans i18nKey="App.Menu.cron-jobs" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/employees">
        <Trans i18nKey="App.Menu.employees" />
      </NavLinkButton>
    </Box>
  </>
)

const MainMenuTofuChannel = () => (
  <>
    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/tofu/patients">
        <Trans i18nKey="App.Menu.patients" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/tofu/cases?sortDesc=true">
        <Trans i18nKey="App.Menu.cases" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/tofu/prescriptions">
        <Trans i18nKey="App.Menu.prescriptions" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <Divider />
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/tofu/questionnaires-definitions">
        <Trans i18nKey="App.Menu.questionnaires-definitions" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/tofu/questionnaires-sessions">
        <Trans i18nKey="App.Menu.questionnaires-sessions" />
      </NavLinkButton>
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <Divider />
    </Box>

    <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
      <NavLinkButton to="/tofu/registrations">
        <Trans i18nKey="App.Menu.registrations" />
      </NavLinkButton>
    </Box>
  </>
)

export const MainMenu = () => {
  const { controller } = useUIController()

  if (controller?.hideLeftHandSideMenu) {
    return null
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          position: 'relative',
          zIndex: 100,
          flex: 1,
          borderRadius: '0 0 8px 0',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            boxShadow:
              '1px 0 2px 0 rgb(0 0 40 / 8%), 6px 0 10px 0 rgb(0 0 40 / 8%)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            width: 200,
            borderRadius: '0 8px 8px 0',
          }}
        >
          <MainMenuHeader />

          <Box sx={{ overflowY: 'auto', flex: '1 1 auto' }}>
            <Box sx={{ pt: 2, mt: 2 }}>
              <CurrentMenu />
            </Box>
          </Box>

          <MainMenuFooter />
        </Box>
      </Box>
    </Box>
  )
}

const CurrentMenu = () => {
  const { employee, channel } = useAuth()

  if (
    employee?.roles?.includes('doctor') ||
    employee?.roles?.includes('medicalassistant')
  ) {
    if (channel === 'web') {
      return <MedicalMenuWebChannel />
    } else {
      return <MedicalMenuTofuChannel />
    }
  }

  if (channel === 'web') {
    return <MainMenuWebChannel />
  } else {
    return <MainMenuTofuChannel />
  }
}

const MedicalMenuWebChannel = () => {
  return (
    <>
      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/customers">
          <Trans i18nKey="App.Menu.customers" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/initials">
          <Trans i18nKey="App.Menu.initials" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/renewals">
          <Trans i18nKey="App.Menu.renewals" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/repurchases">
          <Trans i18nKey="App.Menu.repurchases" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/questions">
          <Trans i18nKey="App.Menu.questions" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/checkins">
          <Trans i18nKey="App.Menu.checkins" />
        </NavLinkButton>
      </Box>
    </>
  )
}

const MedicalMenuTofuChannel = () => {
  const { isCurrentEmployeeMedicalAssistant } = useAuth()
  return (
    <>
      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/tofu/waiting-room">
          <Trans i18nKey="App.Menu.waiting-room" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <Divider />
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton to="/tofu/patients">
          <Trans i18nKey="App.Menu.patients" />
        </NavLinkButton>
      </Box>

      <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
        <NavLinkButton
          to={`/tofu/cases?${
            isCurrentEmployeeMedicalAssistant()
              ? 'caseDiagnosisStatus=REQUIRES_CONTENT'
              : 'extendedStatus=AWAITING_DIAGNOSIS&assignee=UNASSIGNED'
          }
          `}
        >
          <Trans i18nKey="App.Menu.cases" />
        </NavLinkButton>
      </Box>

      <Restricted to={['doctor']}>
        <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
          <Divider />
        </Box>

        <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
          <NavLinkButton to="/tofu/prescriptions">
            <Trans i18nKey="App.Menu.prescriptions" />
          </NavLinkButton>
        </Box>
      </Restricted>

      <Restricted to={['doctor']}>
        <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
          <Divider />
        </Box>

        <Box sx={{ cursor: 'default', my: 1, px: 2 }}>
          <NavLinkButton to="/tofu/notion-cms?category=diagnoses">
            <Trans i18nKey="App.Menu.notion-cms" />
          </NavLinkButton>
        </Box>
      </Restricted>
    </>
  )
}
