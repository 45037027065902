import { gql } from '@apollo/client'
import { orderFields } from '../../orders/orders.gql'

export const getInvoicesByOrderId = gql`
  query GetInvoiceByOrderId($orderId: String!) {
    getInvoicesByOrderId(orderId: $orderId) {
      id
      amountDue
      amountPaid
      status
      currency
      generatedAt
      paymentTransactions {
        transactionId
        status
        amount
        appliedAt
        createdAt
        updatedAt
      }
      orderId
      customerId
      total
      amountBeingProcessed
      paidAt
      voidedAt
      voidReason
      voidReasonText
      dunningStatus
      amountRefunded
      nextRetryAt
      updatedAt

      amountRefundBeingProcessed
      autoCollect
      collectAt
      dunningDuration {
        period
        value
      }
      dunningTransactions {
        transactionId
        status
        amount
        appliedAt
        createdAt
        updatedAt
      }
      refundTransactions {
        transactionId
        status
        amount
        appliedAt
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrderById = gql`
  ${orderFields}
  query GetOrderById($orderId: String!) {
    getOrderById(orderId: $orderId) {
      ...OrderFields
    }
  }
`

export const addVoucherToUpcomingOrder = gql`
  mutation AddVoucherToUpcomingOrder(
    $voucherCode: String!
    $v1UserId: String
    $upcomingOrderPaymentStatus: OrderPaymentStatus
  ) {
    addVoucherToUpcomingOrder(
      voucherCode: $voucherCode
      v1UserId: $v1UserId
      upcomingOrderPaymentStatus: $upcomingOrderPaymentStatus
    ) {
      vouchers
    }
  }
`
export const removeVoucherFromUpcomingOrder = gql`
  mutation RemoveVoucherFromUpcomingOrder(
    $voucherCode: String!
    $v1UserId: String
    $upcomingOrderPaymentStatus: OrderPaymentStatus
  ) {
    removeVoucherFromUpcomingOrder(
      voucherCode: $voucherCode
      v1UserId: $v1UserId
      upcomingOrderPaymentStatus: $upcomingOrderPaymentStatus
    ) {
      vouchers
    }
  }
`
