import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material'
import { useV2Mutation, useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColumnDef,
  DataTable,
  DateTime,
  IdTag,
  PageHeader,
} from '@trueskin-backoffice/components'
import { useMemo, useState } from 'react'
import {
  getAllEmployees,
  getAvailabilitiesSpreadsheetLinkQuery,
  getLatestAvailabilitiesQuery,
  importAvailabilitiesMutation,
} from './employees.gql'

import SyncIcon from '@mui/icons-material/Sync'

const today = new Date()

const currentMonthDays = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0,
).getDate()

export const AvailabilitiesPage = () => {
  const [saving, setSaving] = useState(false)

  const {
    data: availabilitiesSpreadsheetLinkQueryData,
    loading: availabilitiesSpreadsheetLinkQueryLoading,
    error: availabilitiesSpreadsheetLinkQueryError,
  } = useV2Query(getAvailabilitiesSpreadsheetLinkQuery)

  const spreadsheetLink =
    availabilitiesSpreadsheetLinkQueryData?.getAvailabilitiesSpreadsheetLink
      ?.url

  const {
    data: employeesQueryData,
    loading: getAllEmployeesLoading,
    error: getAllEmployeesError,
  } = useV2Query(getAllEmployees, {
    variables: {
      offset: 0,
      limit: 9999,
    },
  })

  const employees = employeesQueryData?.getAllEmployees?.data ?? []

  const [
    importAvailabilities,
    {
      loading: importAvailabilitiesLoading,
      error: importAvailabilitiesError,
      data: importedAvailabilitiesMutationData,
    },
  ] = useV2Mutation(importAvailabilitiesMutation)

  const importedAvailabilities =
    importedAvailabilitiesMutationData?.importAvailabilitiesMutation

  const {
    data: latestAvailabilitiesQueryData,
    loading: getLatestAvailabilitiesLoading,
    error: getLatestAvailabilitiesError,
    refetch,
  } = useV2Query(getLatestAvailabilitiesQuery)

  const latestAvailabilities =
    latestAvailabilitiesQueryData?.getLatestAvailabilities ?? []

  const availabilities = importedAvailabilities ?? latestAvailabilities

  const employeesWithAvailabilities: any[] =
    availabilities && employees
      ? employees
          .map((e: any) => {
            const employeeAvailabilities = availabilities.filter(
              (av: any) => e._id === av.employeeId,
            )
            if (employeeAvailabilities.length === 0) {
              return null
            }

            return { ...e, availabilities: employeeAvailabilities }
          })
          .filter((v: any) => !!v)
      : []

  const monthDaysColumns: ColumnDef<any, any>[] = useMemo(
    () =>
      Array.from(Array(currentMonthDays).keys()).map((day) => ({
        header: (day + 1).toString(),
        accessorKey: 'availabilities',
        cell: ({ getValue }) => {
          const value = getValue().find((av: any) =>
            av.date ? new Date(av?.date).getDate() === day + 1 : false,
          )
          return <Typography>{value?.value}</Typography>
        },
      })),
    [],
  )

  const columns: ColumnDef<any, any>[] = useMemo(
    () => [
      {
        header: 'Employee ID',
        accessorKey: '_id',
        cell: ({ getValue }) => {
          return (
            <IdTag
              id={getValue()}
              linkTo={`/employees/${getValue()}`}
              allowCopyToClipboard
            />
          )
        },
      },
      {
        header: 'Name',
        accessorKey: 'firstName',
        cell: ({ row }) => {
          return (
            <Typography>
              {row.original.firstName} {row.original.lastName}
            </Typography>
          )
        },
      },
      ...monthDaysColumns,
    ],
    [monthDaysColumns],
  )

  const loading =
    importAvailabilitiesLoading ||
    getLatestAvailabilitiesLoading ||
    getAllEmployeesLoading

  const error =
    importAvailabilitiesError ||
    getLatestAvailabilitiesError ||
    getAllEmployeesError ||
    availabilitiesSpreadsheetLinkQueryError

  // TODO
  return (
    <Box>
      <PageHeader
        sx={{
          mb: 2,
          mr: -2,
          ml: -2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography variant="caption" sx={{ mr: 1 }}>
              Last sync on
            </Typography>
            <DateTime
              dateTime={latestAvailabilities?.[0]?.importDate}
              showTime
            />
          </Box>
          <Box sx={{ ml: 2 }}>
            {availabilitiesSpreadsheetLinkQueryLoading ? (
              <CircularProgress />
            ) : (
              <Link href={spreadsheetLink} variant="body2" target="_blank">
                <OpenInNewIcon />
              </Link>
            )}
          </Box>
          <Box sx={{ ml: 'auto' }}>
            {!saving ? (
              <Button
                onClick={async () => {
                  await importAvailabilities({ variables: { save: false } })
                  setSaving(true)
                }}
              >
                <SyncIcon />
              </Button>
            ) : (
              <Box>
                <Button
                  onClick={async () => {
                    await importAvailabilities({ variables: { save: true } })
                    await refetch()
                    setSaving(false)
                  }}
                >
                  Save
                </Button>
                <Button
                  color="warning"
                  onClick={async () => {
                    await refetch()
                    setSaving(false)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </PageHeader>

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        {loading && <LinearProgress />}
        {error && <Typography color={'red'}>{error.message}</Typography>}
        {!loading && !error && employeesWithAvailabilities.length > 0 && (
          <DataTable
            columns={columns}
            data={employeesWithAvailabilities}
            isLoading={loading}
            totalPages={employeesWithAvailabilities.length}
            rowsPerPage={employeesWithAvailabilities.length}
          />
        )}
      </Card>
    </Box>
  )
}
