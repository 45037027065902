import { Box, Card } from '@mui/material'
import {
  DataFilter,
  FilterComponent,
  InputFilterProps,
} from '@trueskin-backoffice/components'
import { ExternalWebhookStatus } from '@trueskin-backoffice/contexts/types'
import React from 'react'
import { DecodedValueMap, StringParam, useQueryParams } from 'use-query-params'
import { ExternalWebhooksTable } from './external-webhooks.table'

const EXTERNAL_WEBHOOKS_FILTER_INIT_VALUES = {
  status: StringParam,
  eventId: StringParam,
}

type ExternalWebhooksInputFilterProps = Record<
  keyof Pick<ExternalWebhooksFilterProps, 'status'>,
  InputFilterProps
>

export type ExternalWebhooksFilterProps =
  typeof EXTERNAL_WEBHOOKS_FILTER_INIT_VALUES

export const ExternalWebhooksPage = () => {
  const [query, setQuery] = useQueryParams<ExternalWebhooksFilterProps>(
    EXTERNAL_WEBHOOKS_FILTER_INIT_VALUES,
  )

  return (
    <Box>
      <DataFilter<
        DecodedValueMap<ExternalWebhooksFilterProps>,
        ExternalWebhooksInputFilterProps
      >
        filter={query}
        onChange={setQuery}
        names={Object.keys(EXTERNAL_WEBHOOKS_FILTER_INIT_VALUES)}
        inputs={{
          status: {
            component: FilterComponent.SELECT,
            options: Object.keys(ExternalWebhookStatus).map((status) => ({
              label: status,
              value: status,
            })),
          },
        }}
      />

      <Card
        sx={{
          boxShadow:
            '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          overflow: 'unset',
          p: 2,
          maxWidth: '1500px',
          mx: 'auto',
        }}
      >
        <ExternalWebhooksTable filter={query} />
      </Card>
    </Box>
  )
}
