import { gql } from '@apollo/client'

export const getProducts = gql`
  query GetProducts($filter: ProductsFilter, $offset: Int, $limit: Int) {
    products(filter: $filter, offset: $offset, limit: $limit) {
      rows {
        id
        tags
        locales
        rules {
          name
          number
          prods
        }
      }
      totalRows
    }
  }
`
