import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import { H2 } from '@trueskin-backoffice/components'
import { useFormik } from 'formik'
import { isEqual, pick } from 'lodash'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { FormSection } from '../employees/employee.components'
import {
  getConsultationsSettingsQuery,
  updateConsultationsSettingsMutation,
} from './consultations.gql.bff'

const INIT_VALUES = { smartAssigning: false }

export const ConsultationsSettingsPage = () => {
  const {
    data: getConsultationsSettingsQueryData,
    loading: getConsultationsSettingsQueryLoading,
    error: getConsultationsSettingsQueryError,
    refetch,
  } = useQuery<{ getConsultationsSettings: typeof INIT_VALUES }>(
    getConsultationsSettingsQuery,
  )

  const settings = getConsultationsSettingsQueryData?.getConsultationsSettings

  const [
    updateConsultationsSettings,
    {
      loading: updateConsultationsSettingsMutationLoading,
      error: updateConsultationsSettingsMutationError,
    },
  ] = useMutation(updateConsultationsSettingsMutation)

  const formik = useFormik<{
    smartAssigning: boolean
  }>({
    initialValues: INIT_VALUES,
    onSubmit: async (values) => {
      await updateConsultationsSettings({
        variables: {
          data: values,
        },
      })
      refetch()
    },
    validationSchema: Yup.object({
      smartAssigning: Yup.boolean(),
    }),
  })

  useEffect(() => {
    const settingsValue = pick(
      settings,
      Object.keys(INIT_VALUES),
    ) as typeof INIT_VALUES
    if (
      !getConsultationsSettingsQueryLoading &&
      !isEqual(formik.values, settingsValue) &&
      !Object.values(formik.touched).some((v) => v) &&
      settings
    ) {
      formik.setValues(settingsValue)
    }
  }, [formik, settings, getConsultationsSettingsQueryLoading])

  if (getConsultationsSettingsQueryLoading) {
    return <LinearProgress />
  }

  const error =
    updateConsultationsSettingsMutationError ||
    getConsultationsSettingsQueryError

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', m: 20 }}>
        <Box sx={{ m: 2 }}>
          <H2>Consultations Settings</H2>
        </Box>
        <Card sx={{ p: 3 }}>
          {error && <Typography color={'red'}>{error.message}</Typography>}
          <Grid container spacing={2} alignItems="center">
            <FormSection
              label="Smart Assigning"
              labelXs={2}
              value={
                <Box sx={{ m: 2 }}>
                  {getConsultationsSettingsQueryLoading ||
                  updateConsultationsSettingsMutationLoading ? (
                    <CircularProgress />
                  ) : (
                    <Checkbox
                      checked={formik.values.smartAssigning}
                      onChange={(e) => {
                        formik.setFieldValue('smartAssigning', e.target.checked)
                        formik.setFieldTouched('smartAssigning', true)
                      }}
                    />
                  )}
                </Box>
              }
            />
          </Grid>
        </Card>
        {Object.values(formik.touched).some((v) => v) && (
          <Box sx={{ m: 10, display: 'flex', justifyContent: 'center' }}>
            <Button
              color="secondary"
              onClick={async () => {
                await refetch()
                formik.resetForm()
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                await formik.submitForm()
                formik.resetForm()
              }}
              disabled={Object.keys(formik.errors).length > 0}
            >
              Update
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
