import { Star } from '@mui/icons-material'
import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { yellow } from '@mui/material/colors'
import { useV2Mutation, useV2Query } from '@trueskin-backoffice/api-client'
import {
  HeroCard,
  HeroCardDivider,
  HeroCardEmpty,
  HeroCardSection,
  HorizontalList,
  HorizontalListItem,
  MoreActionsButton,
  Section,
  VerticalList,
  VerticalListRow,
  VerticalListRowItem,
} from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { formatDateTime } from '@trueskin-backoffice/functions'
import {
  addTnxFailureCardMutation,
  getPaymentSourceByCustomerId,
  setPaymentMethodAsPrimaryMutation,
} from '../customer.gql'

const CustomerPaymentMethodsSummary = ({ paymentSource, customerId }: any) => {
  if (!paymentSource) {
    return (
      <HeroCardEmpty>
        <Typography fontSize={12}>No payment methods found</Typography>
      </HeroCardEmpty>
    )
  }

  return (
    <HeroCard>
      {paymentSource?.paymentMethods?.map((pm: any, index: number) => (
        <>
          <HeroCardSection sx={{ m: 0 }}>
            <PaymentMethod
              paymentMethod={pm}
              customerId={customerId}
              primaryPaymentMethodId={paymentSource.primaryPaymentMethodId}
            />
          </HeroCardSection>

          {index < paymentSource.paymentMethods.length - 1 ? (
            <HeroCardDivider />
          ) : null}
        </>
      ))}
    </HeroCard>
  )
}

const PaymentMethod = ({
  primaryPaymentMethodId,
  paymentMethod,
  customerId,
}: {
  primaryPaymentMethodId: string
  paymentMethod: any
  customerId: string | undefined
}) => {
  const [
    setPaymentMethodAsPrimary,
    {
      loading: setPaymentMethodAsPrimaryLoading,
      error: setPaymentMethodAsPrimaryError,
    },
  ] = useV2Mutation(setPaymentMethodAsPrimaryMutation, {
    variables: { customerId, paymentMethodId: paymentMethod.id },
    refetchQueries: [getPaymentSourceByCustomerId],
  })

  return (
    <VerticalList>
      <VerticalListRow>
        <VerticalListRowItem fullWidth={true}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: paymentMethod.deleted ? 'line-through' : 'unset',
            }}
          >
            {paymentMethod.gateway.toLowerCase() !== 'klarna' && (
              <Typography
                sx={{
                  display: 'flex',
                  fontWeight: 700,
                  mr: 1,
                }}
              >
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {paymentMethod.card
                    ? `${paymentMethod.card.paymentMethod}`
                    : 'Paypal Express Checkout'}
                </Typography>

                {paymentMethod.type.toLowerCase() === 'card' && (
                  <Typography sx={{ ml: 1 }}>
                    ending {paymentMethod.card.lastFourDigits}
                  </Typography>
                )}
              </Typography>
            )}

            <Typography variant="caption" sx={{ mr: 1 }}>
              via
            </Typography>

            <Typography sx={{ textTransform: 'capitalize', mr: 1 }}>
              {paymentMethod.gateway.toLowerCase()}
            </Typography>

            {paymentMethod.type.toLowerCase() === 'apple_pay' && (
              <Typography variant="caption">(via ApplePay)</Typography>
            )}

            {paymentMethod.id === primaryPaymentMethodId && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Star sx={{ color: yellow[600] }} />
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Primary
                </Typography>
              </Box>
            )}
          </Typography>

          <HorizontalList>
            <HorizontalListItem>
              <Typography variant="caption">{paymentMethod.id}</Typography>
            </HorizontalListItem>

            {paymentMethod?.card && (
              <>
                <HorizontalListItem>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    Last 4 digits
                  </Typography>

                  <Box sx={{ mr: 1 }}>{paymentMethod.card.lastFourDigits}</Box>
                </HorizontalListItem>

                <HorizontalListItem>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    Expires on
                  </Typography>

                  <Box sx={{ mr: 1 }}>
                    {cardExpirationDate(paymentMethod.card)}
                  </Box>
                </HorizontalListItem>
              </>
            )}

            {paymentMethod?.type === 'PAYPAL' && (
              <>
                <HorizontalListItem>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {paymentMethod.paypal.email}
                  </Typography>
                </HorizontalListItem>
                <HorizontalListItem>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {paymentMethod.paypal.billingAgreementId}
                  </Typography>
                </HorizontalListItem>
              </>
            )}
          </HorizontalList>
        </VerticalListRowItem>

        <VerticalListRowItem>
          <MoreActionsButton
            horizontalIcon
            size={'small'}
            buttonProps={{ p: 0 }}
          >
            {paymentMethod.id !== primaryPaymentMethodId && (
              <MenuItem onClick={() => setPaymentMethodAsPrimary()}>
                Set as primary
              </MenuItem>
            )}
            <MenuItem disabled>Delete</MenuItem>
          </MoreActionsButton>
        </VerticalListRowItem>
      </VerticalListRow>
    </VerticalList>
  )
}

const cardExpirationDate = (card: any) => {
  const date = new Date(card.expiryYear, card.expiryMonth - 1, 1)
  return formatDateTime(date.toISOString(), {
    day: undefined,

    hour: undefined,
    minute: undefined,
    second: undefined,
  })
}

export const CustomerPaymentMethodsSection = () => {
  const {
    customer: { customerId },
  } = useCustomer()

  const {
    data: { getPaymentSource: paymentSource } = {},
    loading,
    error,
  } = useV2Query(getPaymentSourceByCustomerId, {
    skip: customerId === undefined,
    variables: {
      customerId,
    },
  })

  const [
    addTnxFailureCard,
    { loading: addTnxFailureCardLoading, error: addTnxFailureCardError },
  ] = useV2Mutation(addTnxFailureCardMutation, {
    variables: { customerId },
    refetchQueries: [getPaymentSourceByCustomerId],
  })

  if (loading) {
    return <LinearProgress />
  }

  const hasTxnFailureCard = Boolean(
    (paymentSource?.paymentMethods || []).find(
      (pm: any) => pm.id === 'psrc_pm_txn_failure_card',
    ),
  )

  return (
    <Section
      headerLabel={`Payment Methods (${
        paymentSource?.paymentMethods?.length || 0
      })`}
      headerActions={
        <Button
          size="small"
          onClick={() => addTnxFailureCard()}
          disabled={addTnxFailureCardLoading}
          sx={{ visibility: hasTxnFailureCard ? 'hidden' : 'visible' }}
        >
          Add txn failure card
        </Button>
      }
    >
      <CustomerPaymentMethodsSummary
        customerId={customerId}
        paymentSource={paymentSource}
      />
    </Section>
  )
}
