import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import { JsonButton, Table } from '@trueskin-backoffice/components'
import { formatDateTime } from '@trueskin-backoffice/functions'
import React, { useEffect, useState } from 'react'
import { DecodedValueMap } from 'use-query-params'
import { getAllWebhooks } from './webhooks.gql'
import { WebhooksFilterProps } from './webhooks.page'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
}

const LIMIT = 20

interface Props {
  filter: DecodedValueMap<WebhooksFilterProps>
}

export const WebhooksTable = ({ filter }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [json, setJson] = useState('')

  const {
    data: allWebhooksData,
    loading: allWebhooksLoading,
    error: allWebhooksError,
    fetchMore: fetchMoreWebhooks,
    refetch: refetchWebhooks,
  } = useV2Query(getAllWebhooks, {
    variables: { limit: LIMIT, offset: 0, filter },
  })

  useEffect(() => {
    if (!allWebhooksLoading) {
      refetchWebhooks({ offset: 0, limit: LIMIT, filter })
    }
  }, [allWebhooksLoading, refetchWebhooks, filter])

  const handleOpenModal = (jsonData: any) => (event: any) => {
    event.stopPropagation()
    setJson(jsonData)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setJson('')
    setOpenModal(false)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
        width: 250,
      },
      {
        Header: 'Source',
        accessor: 'source',
        width: 100,
      },
      {
        Header: 'Customer ID',
        accessor: 'customerId',
        width: 325,
      },
      {
        Header: 'External event id',
        accessor: 'externalEventId',
        width: 200,
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Created at',
        Cell: ({ row }: any) => (
          <div>
            {row?.original?.createdAt
              ? formatDateTime(row?.original.createdAt)
              : '-'}
          </div>
        ),
      },
      {
        Header: 'Updated at',
        Cell: ({ row }: any) => (
          <div>
            {row?.original?.updatedAt
              ? formatDateTime(row?.original.updatedAt)
              : '-'}
          </div>
        ),
      },
      {
        Header: 'Actions',
        Cell: ({ row }: any) => {
          return <JsonButton jsonData={row.original} />
        },
      },
    ],
    [],
  )

  if (allWebhooksLoading) {
    return <LinearProgress />
  }

  if (allWebhooksError) {
    return <Typography color={'red'}>{allWebhooksError.message}</Typography>
  }

  const fetchMoreData = () => {
    fetchMoreWebhooks({
      variables: {
        offset: allWebhooksData.getAllWebhooks.length,
      },
      updateQuery(
        { getAllWebhooks: prevWebhooks },
        { fetchMoreResult: { getAllWebhooks: nextWebhooks } },
      ) {
        return { getAllWebhooks: [...prevWebhooks, ...nextWebhooks] }
      },
    })
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </Box>
      </Modal>

      <Table
        columns={columns}
        data={allWebhooksData.getAllWebhooks}
        update={fetchMoreData}
      />
    </>
  )
}
