import { Add, Search } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import { HeroCardDivider } from '@trueskin-backoffice/components'
import { useNotionCms } from '@trueskin-backoffice/contexts'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import { MedicationInput } from './medication.input'

export const AddMedicationButton2 = ({
  showTopDivider,
  required,
  disabled,
}: any) => {
  const { notionCmsTreatments } = useNotionCms()

  const addMedicationBtnRef = useRef(null)
  const selectMedicationPrefillBtnRef = useRef(null)
  const [prefill, setPrefill] = useState<object | null>(null)

  const [showAddMedicationPopup, setShowAddMedicationPopup] =
    useState<boolean>(false)
  const [
    showSelectMedicationPrefillPopup,
    setShowSelectMedicationPrefillPopup,
  ] = useState<boolean>(false)

  const onAddMedication = (event: React.MouseEvent<HTMLElement>) => {
    if (showAddMedicationPopup) {
      handleClose()
    } else {
      setShowAddMedicationPopup(true)
    }
  }

  const handleClose = () => {
    setShowAddMedicationPopup(false)
    setShowSelectMedicationPrefillPopup(false)
    setPrefill(null)
  }

  const handleSearchMedicationPrefillsClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    if (showSelectMedicationPrefillPopup) {
      setShowSelectMedicationPrefillPopup(false)
      setPrefill(null)
    } else {
      setShowSelectMedicationPrefillPopup(true)
    }
  }

  const handlePrefillSelected = (event: any, newValue: any) => {
    console.log('prefill = ', newValue)
    setPrefill(newValue)
    setShowSelectMedicationPrefillPopup(false)
    setShowAddMedicationPopup(true)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {showTopDivider && <HeroCardDivider />}

      <Box
        sx={{
          color: '#3367d6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: 12,
          cursor: 'pointer',
          borderRadius: 1,
          width: '100%',
        }}
      >
        <Box>
          <Button
            size="small"
            onClick={onAddMedication}
            disabled={disabled}
            ref={addMedicationBtnRef}
          >
            <Add /> <Typography variant="caption">Medication</Typography>
            {required && (
              <Typography variant="caption" color="error">
                *
              </Typography>
            )}
          </Button>

          <Popper
            open={showAddMedicationPopup}
            anchorEl={addMedicationBtnRef.current}
            placement="bottom"
            role={undefined}
            sx={{ zIndex: 2 }}
          >
            <Paper sx={{ p: 2 }}>
              {prefill && Object.keys(prefill).length ? (
                <MedicationInputList onClose={handleClose} prefill={prefill} />
              ) : (
                <MedicationInput onClose={handleClose} />
              )}
            </Paper>
          </Popper>
        </Box>

        <Box>
          <Button
            onClick={handleSearchMedicationPrefillsClick}
            ref={selectMedicationPrefillBtnRef}
            startIcon={<Search />}
            sx={{ p: 0, m: 0, minWidth: 'unset' }}
          ></Button>

          <Popper
            open={showSelectMedicationPrefillPopup}
            anchorEl={selectMedicationPrefillBtnRef.current}
            placement="bottom"
            role={undefined}
          >
            <Paper sx={{ minWidth: 500 }}>
              <Autocomplete
                fullWidth
                id="medication-prefills"
                size="small"
                options={
                  notionCmsTreatments?.filter((t) => t.medication?.length) || []
                }
                getOptionLabel={(option: any) => option._id}
                onChange={handlePrefillSelected}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    {...params}
                    label="Search medication prefills"
                  />
                )}
              />
            </Paper>
          </Popper>
        </Box>
      </Box>
    </Box>
  )
}

const MedicationInputList = ({ onClose, prefill }: any) => {
  console.log('MedicationInputList = ', prefill)
  const [inputsToHide, setInputsToHide] = useState<number[]>([])
  const hideMedicalInput = useCallback(
    (inputToHide: number) => {
      if (inputsToHide.length + 1 === prefill.medication.length) {
        onClose()
      } else {
        setInputsToHide([...inputsToHide, inputToHide])
      }
    },
    [inputsToHide, onClose, prefill],
  )

  return (
    <Fragment>
      {prefill?.medication?.map((medication: any, index: number) =>
        inputsToHide.includes(index) ? null : (
          <Box key={index}>
            <MedicationInput
              onClose={() => hideMedicalInput(index)}
              medication={{ ...medication, prefillId: prefill._id }}
            />
            <Divider sx={{ my: 3 }} />
          </Box>
        ),
      )}

      <Box>
        <Button fullWidth onClick={onClose} color="error">
          Cancel all
        </Button>
      </Box>
    </Fragment>
  )
}
