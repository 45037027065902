import { Box, Typography } from '@mui/material'
import { TagGroup, TagGroupItem } from '@trueskin-backoffice/components'
import { blockTypes, namespaceTags, systemLabels } from './db'

export const MedicalLibraryPage = () => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ mr: 1 }}>Namespaces:</Typography>

        <TagGroup
          items={namespaceTags.map((ns: any) => (
            <TagGroupItem>{ns.id}</TagGroupItem>
          ))}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ mr: 1 }}>Block types:</Typography>

        <TagGroup
          items={blockTypes.map((bt: string) => (
            <TagGroupItem>{bt}</TagGroupItem>
          ))}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ mr: 1 }}>Labels:</Typography>

        <TagGroup
          items={systemLabels.map((label: string) => (
            <TagGroupItem>{label}</TagGroupItem>
          ))}
        />
      </Box>
    </Box>
  )
}
