import { gql } from '@apollo/client'

export const voucherFields = gql`
  fragment VoucherFields on Voucher {
    id
    code
    active
    stackable
    obsolete
    discountGroupId
    confirmationMessage
    type
  }
`

export const getVouchers = gql`
  ${voucherFields}
  query GetVouchers($filter: VoucherFilter, $offset: Int, $limit: Int) {
    vouchers(filter: $filter, offset: $offset, limit: $limit) {
      rows {
        ...VoucherFields
      }
      totalRows
    }
  }
`
