import { Box, Divider, Icon } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  DateTime,
  HorizontalList,
  HorizontalListItem,
} from '@trueskin-backoffice/components'
import { FaLink } from 'react-icons/fa'
import { useWaitingRoomCase } from '../waiting-room-case.context'
import { CaseGoals } from './case-goals'

export const TreatmentHistory = () => {
  const { currentCase } = useWaitingRoomCase()
  const diagnosedCases = currentCase?.patient?.cases?.filter(
    (someCase: any) => !!someCase.diagnosis,
  )

  if (!diagnosedCases?.length) {
    return (
      <Typography
        sx={{
          display: 'flex',
          fontSize: 15,
        }}
        component="span"
        variant="caption"
      >
        None
      </Typography>
    )
  }

  return (
    <Box sx={{ mx: 4 }}>
      {diagnosedCases.map((someCase: any, index: number) => (
        <Box key={someCase.id}>
          {index !== 0 && <Divider sx={{ my: 1 }} />}
          <HorizontalList>
            <HorizontalListItem>
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                component={currentCase?.id === someCase.id ? 'div' : 'a'}
                href={`/tofu/patients/${someCase.customerId}/cases/${someCase.id}/medical-view`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  component={FaLink}
                  sx={{
                    fontSize: 10,
                    mr: 2,
                  }}
                />
                <Typography
                // color={
                //   currentCase?.id === someCase.id ? 'primary' : 'default'
                // }
                >
                  {someCase.documentNumber}
                </Typography>
              </Box>
            </HorizontalListItem>

            <HorizontalListItem sx={{ alignItems: 'center' }}>
              {someCase.extendedStatus}
            </HorizontalListItem>
          </HorizontalList>

          <CaseGoals currentCase={someCase} />

          {someCase.diagnosis.generatedAt && (
            <Box>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Generated at
              </Typography>
              <DateTime dateTime={someCase.diagnosis.generatedAt} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}
