import { Box, BoxProps, styled } from '@mui/material'
import { TooltipProps } from '@mui/material/Tooltip'
import { formatDate, formatDateTime } from '@trueskin-backoffice/functions'
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { TextTooltip } from './tooltips'

export const DateTime = styled(
  (
    props: BoxProps & {
      dateTime: string
      showTime?: boolean
      tooltipPlacement?: TooltipProps['placement']
      disableHoverListener?: boolean
    },
  ) => {
    const {
      dateTime,
      showTime,
      tooltipPlacement,
      disableHoverListener,
      ...other
    } = props

    if (showTime) {
      return <Box>{formatDateTime(dateTime)}</Box>
    }

    return (
      <DateTimeWithTooltip
        dateTime={dateTime}
        tooltipPlacement={tooltipPlacement}
        disableHoverListener={disableHoverListener}
        {...other}
      >
        {formatDate(dateTime)}
      </DateTimeWithTooltip>
    )
  },
)()

export const DateTimeDistanceToNow = styled(
  (
    props: BoxProps & {
      dateTime: string
      tooltipPlacement?: TooltipProps['placement']
      disableHoverListener?: boolean
      strict?: boolean
      disableSuffix?: boolean
    },
  ) => {
    const {
      strict,
      disableSuffix,
      dateTime,
      tooltipPlacement,
      disableHoverListener,
      ...other
    } = props

    return (
      <DateTimeWithTooltip
        dateTime={dateTime}
        tooltipPlacement={tooltipPlacement}
        disableHoverListener={disableHoverListener}
        {...other}
      >
        {strict
          ? formatDistanceToNowStrict(new Date(dateTime), {
              addSuffix: disableSuffix,
            })
          : formatDistanceToNow(new Date(dateTime), {
              addSuffix: disableSuffix,
            })}
      </DateTimeWithTooltip>
    )
  },
)()

const DateTimeWithTooltip = styled(
  (
    props: BoxProps & {
      dateTime: string
      tooltipPlacement?: TooltipProps['placement']
      disableHoverListener?: boolean
    },
  ) => {
    const {
      dateTime,
      tooltipPlacement,
      disableHoverListener,
      children,
      ...other
    } = props
    const formattedDateTime = formatDateTime(dateTime)

    return (
      <TextTooltip
        title={formattedDateTime}
        placement={tooltipPlacement}
        disableHoverListener={disableHoverListener}
      >
        <Box component="span" {...other}>
          {children}
        </Box>
      </TextTooltip>
    )
  },
)()
