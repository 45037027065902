import { Box, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import {
  ColoredStatus,
  ColumnDef,
  DataTable,
  Pagination,
} from '@trueskin-backoffice/components'
import React, { useEffect, useState } from 'react'
import { DecodedValueMap } from 'use-query-params'
import { getAllCommandsQuery } from './audit.gql'
import { CommandsFilterProps } from './audit.page'

const DEFAULT_PAGE_SIZE = 25

const COMMAND_STATUS_COLORS = {
  created: '#F99E00',
  processed: '#54A813',
  failed: '#D74637',
}

interface Props {
  filter: DecodedValueMap<CommandsFilterProps>
}

export const AuditTable = ({ filter }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      getAllCommands: { rows: commands = [], totalRows: totalCommand = 0 } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useV2Query(getAllCommandsQuery, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalCommand / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: '_id',
      header: 'Id',
      cell: ({ getValue }) => {
        return <Typography fontSize={11}>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'customerId',
      header: 'Customer Id',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ getValue }) => {
        return <Typography>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue }) => {
        return (
          <ColoredStatus status={getValue()} colorMap={COMMAND_STATUS_COLORS} />
        )
      },
    },
    {
      accessorKey: 'correlationId',
      header: 'Correlation',
    },
  ]

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={commands}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}
