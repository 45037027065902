import { useQuery } from '@apollo/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getAllCaseOrders } from './case-orders.gql.bff'

const DEFAULT_PAGE_SIZE = 25

// TODO: Aggregate customers data with subscriptions
export const CaseOrdersTable = () => {
  const { query: filter } = useDataFilterV2()
  const { getCustomerLink } = useAuth()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const columns = useMemo(
    () => caseOrdersTableColumns(getCustomerLink),
    [getCustomerLink],
  )

  const {
    data: {
      caseOrders: {
        rows: caseOrders = [],
        totalRows: totalCaseOrders = 0,
      } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getAllCaseOrders, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalCaseOrders / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={caseOrders}
        isLoading={loading}
        page={currentPage}
        totalPages={100}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const caseOrdersTableColumns = (
  getCustomerLink: any,
): ColumnDef<any, any>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    cell: ({ getValue }) => {
      return (
        <div>
          <NavLink to={getCustomerLink(getValue())}>
            <Typography fontSize={11}>{getValue()}</Typography>
          </NavLink>
        </div>
      )
    },
  },
  {
    header: 'Account Id',
    accessorKey: 'accountId',
    cell: ({ getValue }) => {
      return (
        <div>
          <Typography fontSize={11}>{getValue()}</Typography>
        </div>
      )
    },
  },
  {
    accessorKey: 'profile.firstName',
    header: 'First Name',
  },
  {
    accessorKey: 'profile.lastName',
    header: 'Last Name',
  },
  // {
  //   accessorKey: 'medicalData.birthdate',
  //   header: 'Bi Date',
  //   cell: ({ getValue }) => {
  //     return (
  //       <Box>
  //         <DateTime dateTime={getValue()} tooltipPlacement="right" />
  //       </Box>
  //     )
  //   },
  // },
]
