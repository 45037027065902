import { Box, BoxProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'

export type TagVariant =
  | 'success'
  | 'info'
  | 'warning'
  | 'default'
  | 'error'
  | 'light-error'

// TODO: maybe rename to TagItem
// TODO: clean up implementation
export const TagGroupItem = styled((props: TagGroupItemProps) => {
  const { variant, ...other } = props

  return (
    <Box
      {...other}
      sx={{
        marginRight: 2,
        display: 'inline-flex',
        borderRadius: 1,
        whiteSpace: 'nowrap',
        px: 2,
        py: 1,
        fontSize: 12,
      }}
    />
  )
})(({ theme, variant }) => ({
  color:
    variant === 'info'
      ? '#0052cc'
      : variant === 'success'
      ? '#306e24'
      : variant === 'warning'
      ? '#763b24'
      : variant === 'error'
      ? '#fff'
      : variant === 'light-error'
      ? '#aa2c39'
      : 'inherit',
  backgroundColor:
    // variant === 'info'
    //   ? '#0052cc'
    //   :
    variant === 'success'
      ? '#e6f5e4'
      : variant === 'warning'
      ? '#fff7e6'
      : variant === 'error'
      ? '#de3618'
      : variant === 'light-error'
      ? '#fce8e5'
      : '#f4f7fc',
  border: '1px solid',
  borderColor:
    variant === 'info'
      ? '#a8cbff'
      : variant === 'success'
      ? '#96d48a'
      : variant === 'warning'
      ? '#ffb400'
      : variant === 'error'
      ? '#de3618'
      : variant === 'light-error'
      ? '#f4a190'
      : '#d4d4e8',
}))

export interface TagGroupItemProps extends BoxProps {
  variant?: TagVariant
}

export const KeyTag = styled(
  (
    props: TagGroupItemProps & {
      id: string
      linkTo?: string
      hideKeyIcon?: boolean
    },
  ) => {
    const { id, linkTo, hideKeyIcon, ...other } = props

    return (
      <TagGroupItem sx={{ mt: 1, mr: 0 }} {...other}>
        {!hideKeyIcon && <Box sx={{ mr: 1 }}>🔑</Box>}

        {props.linkTo ? (
          <Box component={NavLink} to={props.linkTo}>
            {props.id}
          </Box>
        ) : (
          <Box>{props.id}</Box>
        )}
      </TagGroupItem>
    )
  },
)()

export const LabeledTag = styled(
  (props: TagGroupItemProps & { label: string }) => (
    <TagGroupItem sx={{ mt: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="caption" sx={{ mr: 1 }}>
          {props.label}
        </Typography>
        <Box>{props.children}</Box>
      </Box>
    </TagGroupItem>
  ),
)()
