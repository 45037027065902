import { Box, Skeleton } from '@mui/material'
import { PageHeader } from '@trueskin-backoffice/components'
import { useCustomer } from '@trueskin-backoffice/contexts'
import { NavLink, useLocation } from 'react-router-dom'

export const CustomerHeader = ({ children }: any) => {
  const location = useLocation()
  const { customer, loading } = useCustomer()

  if (loading) {
    return <Skeleton variant="rectangular" height={60} />
  }

  if (!customer) {
    return null
  }

  const isMedicalView = location.pathname.startsWith('/m/')

  return (
    <PageHeader sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ mr: 8 }}>
        <NavLink to={''}>{customer.email}</NavLink>

        <Box>
          {customer.firstName} {customer.lastName}
        </Box>
      </Box>

      {isMedicalView && (
        <NavLink to={`/customers/${customer.id}`}>Switch to CS view</NavLink>
      )}
    </PageHeader>
  )
}
