import { useMutation } from '@apollo/client'
import { Edit } from '@mui/icons-material'
import { Box, Button, Dialog, TextField } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DockAction, H1 } from '@trueskin-backoffice/components'
import { usePatient } from '@trueskin-backoffice/contexts'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import 'react-day-picker/dist/style.css'
import { updatePatientBirthDateMutation } from '../../patient.gql.bff'

export const EditBirthDateAction = () => {
  const { customer } = usePatient()
  const { enqueueSnackbar } = useSnackbar()
  const [showEditDialog, setShowEditDialog] = React.useState(false)

  const [
    updatePatientBirthDate,
    {
      loading: updatePatientBirthDateLoading,
      error: updatePatientBirthDateError,
    },
  ] = useMutation(updatePatientBirthDateMutation, {})

  return (
    <>
      <DockAction startIcon={<Edit />} onClick={() => setShowEditDialog(true)}>
        Birth date
      </DockAction>

      {showEditDialog ? (
        <Dialog
          open={showEditDialog}
          keepMounted
          onClose={() => setShowEditDialog(false)}
        >
          <UpdateBirthNameModal
            currentBirthDate={customer?.medicalData?.birthdate}
            onClose={() => setShowEditDialog(false)}
            onSubmit={async (birthDate: string) => {
              console.log('birthDate = ', birthDate)
              try {
                await updatePatientBirthDate({
                  variables: {
                    customerId: customer.id,
                    birthDate,
                  },
                })
                enqueueSnackbar('Birth date updated successfully.', {
                  variant: 'success',
                })
                setShowEditDialog(false)
              } catch (err) {
                enqueueSnackbar('Birth date update failed.', {
                  variant: 'error',
                })
              }
            }}
          />

          {updatePatientBirthDateError && (
            <Box sx={{ maxWidth: 500 }}>
              <pre>{JSON.stringify(updatePatientBirthDateError)}</pre>
            </Box>
          )}
        </Dialog>
      ) : null}
    </>
  )
}

export const UpdateBirthNameModal = ({
  currentBirthDate,
  onClose,
  onSubmit,
}: {
  currentBirthDate: string
  onClose: any
  onSubmit: any
}) => {
  return (
    <>
      <Formik
        initialValues={{
          birthDate: currentBirthDate,
        }}
        validateOnChange={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          const dt = values.birthDate as unknown as Date
          const dtDateOnly = new Date(
            dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
          )

          onSubmit(format(dtDateOnly, 'yyyy-MM-dd'))
        }}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                minWidth: 500,
                minHeight: 200,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <H1>Update customer birthdate</H1>

              <Box sx={{ my: 4 }}>
                Current birth date:{' '}
                {new Date(currentBirthDate).toLocaleDateString(
                  process.env['NX_I18N_LOCALE'],
                  {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  },
                )}
              </Box>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  inputFormat="yyyy-MM-dd"
                  value={values.birthDate}
                  onChange={(value) => {
                    setFieldValue('birthDate', value)
                  }}
                  renderInput={(params) => (
                    <TextField name="birthDate" {...params} />
                  )}
                  openTo={'day'}
                  displayStaticWrapperAs="desktop"
                  disableFuture
                  reduceAnimations
                />
              </LocalizationProvider>

              <Box>
                <Button color="secondary" onClick={() => onClose()}>
                  Cancel
                </Button>

                <Button type="submit">Save</Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}
