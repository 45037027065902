import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { SubscriptionsTable } from './subscription.table'
import { SubscriptionsFilters } from './subscriptions.filters'

const SUBSCRIPTION_FILTER_INIT_VALUES = {
  id: StringParam,
  customerId: StringParam,
  v1UserId: StringParam,
  status: StringParam,
}

export const SubscriptionsPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={SUBSCRIPTION_FILTER_INIT_VALUES}>
        <SubscriptionsFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <SubscriptionsTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
