import { DockItem, ViewJsonDockAction } from '@trueskin-backoffice/components'
import { PageContentDockDivider } from '../../layouts/page-content.layout'
import { useOrder } from '../contexts'
import { AddDiscountDockAction } from './actions/add-discount.dock-action'
import { CancelOrderDockAction } from './actions/cancel-order.dock-action'

export const OrderDock = () => {
  const { order } = useOrder()

  return (
    <>
      <DockItem>
        <AddDiscountDockAction />
      </DockItem>

      <DockItem>
        <CancelOrderDockAction />
      </DockItem>

      <PageContentDockDivider />

      <DockItem>
        <ViewJsonDockAction jsonData={order || {}} />
      </DockItem>
    </>
  )
}
