import { Box, LinearProgress, Typography } from '@mui/material'
import { Section } from '@trueskin-backoffice/components'
import { usePatient } from '@trueskin-backoffice/contexts'
import { PageContentLayout } from '../../layouts/page-content.layout'
import { PatientDock } from './dock/patient.dock'
import { PatientAddressesSummary } from './sub-sections/patient-addresses-summary'
import { PatientCasesSummary } from './sub-sections/patient-cases-summary'
import { PatientSummarySection } from './sub-sections/patient-summary'

export const PatientPage = () => {
  const { loading, error } = usePatient()

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    // @ts-ignore
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <PageContentLayout sidebar={<PatientDock />}>
      <PatientInfo />
    </PageContentLayout>
  )
}

const PatientInfo = () => {
  return (
    <Box>
      <PatientSummarySection />

      <Section headerLabel={'Addresses'}>
        <PatientAddressesSummary />
      </Section>

      <Section headerLabel={'Cases history'}>
        <PatientCasesSummary />
      </Section>
    </Box>
  )
}
