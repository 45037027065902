import { useMutation } from '@apollo/client'
import CancelIcon from '@mui/icons-material/Cancel'
import { Box, CircularProgress, Typography } from '@mui/material'
import {
  ConfirmActionDialog,
  DockAction,
} from '@trueskin-backoffice/components'
import { useState } from 'react'
import { useOrder } from '../../contexts'
import { cancelOrderMutation } from '../../gql'

export const CancelOrderDockAction = () => {
  const { order, reFetchOrder } = useOrder()

  const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState(false)

  const [
    cancelOrder,
    { loading: cancelOrderLoading, error: cancelOrderError },
  ] = useMutation(cancelOrderMutation)

  if (cancelOrderLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={32} />
      </Box>
    )
  }

  if (cancelOrderError) {
    return <Typography color={'red'}>{cancelOrderError.message}</Typography>
  }

  return (
    <>
      <DockAction
        startIcon={<CancelIcon />}
        disabled={order.payment.status !== 'open'}
        onClick={() => setConfirmationDialogOpened(true)}
      >
        Cancel
      </DockAction>

      {confirmationDialogOpened && (
        <ConfirmActionDialog
          title="Submit order cancellation"
          onClose={() => setConfirmationDialogOpened(false)}
          opened={confirmationDialogOpened}
          confirmText={['Are you sure you want to continue?']}
          options={[
            {
              action: 'Cancel order',
              onSubmit: async () => {
                await cancelOrder({ variables: { orderId: order._id } })
                reFetchOrder()
              },
            },
          ]}
        />
      )}
    </>
  )
}
