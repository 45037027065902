import { ApolloError, useQuery } from '@apollo/client'
import React, { ReactNode, useEffect } from 'react'
import { getWaitingRoomCaseDiagnosisQuery } from './waiting-room.gql.bff'

interface StandardProps {
  children?: ReactNode
  caseId: string
}

export interface WaitingRoomCaseDiagnosisProps {
  loading: boolean
  error: string | undefined | ApolloError | Error
  diagnosisRecord: any
}

const WaitingRoomCaseDiagnosisContext = React.createContext<
  WaitingRoomCaseDiagnosisProps | undefined
>(undefined)

function WaitingRoomCaseDiagnosisProvider({ caseId, children }: StandardProps) {
  const {
    data: { waitingRoomCase } = {},
    loading,
    error,
    refetch,
  } = useQuery(getWaitingRoomCaseDiagnosisQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !caseId,
    variables: {
      caseId,
    },
  })

  useEffect(() => {
    refetch({
      caseId,
    })
  }, [caseId])

  const value = React.useMemo(
    () => ({
      loading,
      error,
      diagnosisRecord: waitingRoomCase?.diagnosisRecord,
    }),
    [loading, waitingRoomCase, error],
  )

  return (
    <WaitingRoomCaseDiagnosisContext.Provider value={value}>
      {children}
    </WaitingRoomCaseDiagnosisContext.Provider>
  )
}

function useWaitingRoomCaseDiagnosis(): WaitingRoomCaseDiagnosisProps {
  const context = React.useContext(WaitingRoomCaseDiagnosisContext)
  if (context === undefined) {
    throw new Error(
      `useWaitingRoomCaseDiagnosis must be used within a WaitingRoomCaseDiagnosisProvider`,
    )
  }
  return context
}

export { WaitingRoomCaseDiagnosisProvider, useWaitingRoomCaseDiagnosis }
