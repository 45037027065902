import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { QueryParams } from 'use-query-params'

interface StandardProps {
  children: ReactNode
  config: any
}

export interface DataFilterV2ContextProps {
  query: any
  setQuery: any
}

const PatientContext = React.createContext<
  DataFilterV2ContextProps | undefined
>(undefined)

function DataFilterV2Provider({ config, children }: StandardProps) {
  const { customerId } = useParams<{ customerId: string }>()

  return (
    <QueryParams config={config}>
      {({ query, setQuery }) => (
        <PatientContext.Provider
          value={{
            query,
            setQuery,
          }}
        >
          {children}
        </PatientContext.Provider>
      )}
    </QueryParams>
  )
}

function useDataFilterV2(): DataFilterV2ContextProps {
  const context = React.useContext(PatientContext)
  if (context === undefined) {
    throw new Error(
      `useDataFilterV2 must be used within a DataFilterV2Provider`,
    )
  }
  return context
}

export { DataFilterV2Provider, useDataFilterV2 }
