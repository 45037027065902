import { useQuery } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  Pagination,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getPricing } from './pricing.gql.bff'

const DEFAULT_PAGE_SIZE = 10

export const PricingTable = () => {
  const columns = useMemo(() => pricingTableColumns(), [])
  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: pricingData,
    loading,
    error,
    refetch,
  } = useQuery(getPricing, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
    fetchPolicy: 'no-cache',
  })

  const pricing = pricingData?.pricing?.rows ?? []
  const total = pricingData?.pricing?.totalRows ?? 0

  const totalPages = Math.ceil(total / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={pricing}
        isLoading={loading}
        page={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const pricingTableColumns = (): ColumnDef<any, any>[] => [
  {
    header: 'Id',
    accessorKey: '_id',
    cell: ({ getValue, row }) => {
      return (
        <div>
          <NavLink to={`/pricing/${row?.original?._id}`}>
            <Typography fontSize={13}>{getValue()}</Typography>
          </NavLink>
        </div>
      )
    },
  },
  {
    header: 'Occurrences',
    accessorKey: 'occurrences',
    cell: ({ getValue }) => {
      return <Typography fontSize={13}>{getValue()?.join(', ')}</Typography>
    },
  },
  {
    header: 'Products',
    accessorKey: 'products',
    cell: ({ getValue, row }) => {
      return (
        <div>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 600 }}>Name:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>Quantity: </Typography>
            </Grid>
          </Grid>
          {getValue().map((product: { product: string; quantity: number }) => (
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={8}>
                <NavLink to={`/products/${product?.product}`}>
                  <Typography fontSize={13}>{product?.product}</Typography>
                </NavLink>
              </Grid>
              <Grid item xs={4}>
                {product?.quantity}
              </Grid>
            </Grid>
          ))}
        </div>
      )
    },
  },
  {
    header: 'Locale',
    accessorKey: 'locale',
    cell: ({ getValue }) => {
      return <Typography fontSize={13}>{getValue()}</Typography>
    },
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    cell: ({ getValue, row }) => {
      return (
        <Typography fontSize={13}>{`${getValue()} ${
          row?.original?.currency
        }`}</Typography>
      )
    },
  },
]
