import { useMutation, useQuery } from '@apollo/client'
import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Card, Typography } from '@mui/material'
import {
  ColumnDef,
  DataFilterTextInput,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
  DataTable,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  getNotionCmsTreatments,
  syncNotionCmsTreatmentsMutation,
} from '../notion-cms.gql.bff'

export const NotionTreatmentsTable = () => {
  const { query: filter } = useDataFilterV2()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: { notionCmsTreatments } = { notionCmsTreatments: [] },
    loading,
    error,
  } = useQuery(getNotionCmsTreatments)

  const [data, setData] = useState(notionCmsTreatments)

  const [syncNotionCmsTreatments, { loading: syncLoading, error: syncError }] =
    useMutation(syncNotionCmsTreatmentsMutation, {
      refetchQueries: [getNotionCmsTreatments],
      onCompleted: (_) => {
        enqueueSnackbar('Sync completed successfully.', { variant: 'success' })
      },
    })

  useEffect(() => {
    if (!filter?.search && data) {
      setData(notionCmsTreatments)
      return
    }

    const textsToSearch = filter?.search?.split(' ').filter((r: string) => !!r)

    setData(
      notionCmsTreatments.filter((r: any) =>
        textsToSearch.some(
          (el: string) => r._id.toLowerCase().indexOf(el) > -1,
        ),
      ),
    )
  }, [filter, setData, data, notionCmsTreatments])

  if (loading) {
    return <Box>Loading data</Box>
  }

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: '_id',
      header: 'Name',
      cell: ({ getValue, row }) => {
        return <Typography sx={{ minWidth: 150 }}>{getValue()}</Typography>
      },
    },
    {
      accessorKey: 'mdContent',
      header: 'Content',
      cell: ({ getValue, row }) => {
        return <ReactMarkdown>{getValue()}</ReactMarkdown>
      },
    },
    {
      accessorKey: 'medication',
      header: 'Medication',
      cell: ({ getValue, row }) => {
        const medications = getValue()

        if (!medications?.length) {
          return null
        }
        return (
          <Box>
            {medications.map((m: any, index: number) => (
              <Card key={index} sx={{ mb: 2, p: 1 }}>
                <Box>Name: {m.name}</Box>
                <Box>Usage: {m.usageDirections}</Box>
                <Box>
                  Prescription:{' '}
                  {m.requiresPrescription ? 'required' : 'not required'}
                </Box>
                <Box>Additional directions: {m.additionalDirections}</Box>
              </Card>
            ))}
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DataFilterTextInput
          input={{
            label: 'Search records',
            key: 'search',
            type: DataFilterV2FilterComponent.TEXT,
            op: DataFilterV2InputOperation.EQUALS,
          }}
        />
        <LoadingButton
          loading={syncLoading}
          loadingPosition="start"
          startIcon={syncLoading ? <Save /> : null}
          sx={{ textTransform: 'unset' }}
          onClick={() => syncNotionCmsTreatments()}
        >
          Sync treatments from notion
        </LoadingButton>
      </Box>

      <Box>
        <DataTable columns={columns} data={data} isLoading={loading} />
      </Box>
    </Box>
  )
}
