import { Box, Grid, Typography } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params'
import { WaitingRoomCase } from './waiting-room-case'
import { WaitingRoomCaseProvider } from './waiting-room-case.context'
import {
  useWaitingRoomLayoutController,
  WaitingRoomLayoutControllerProvider,
} from './waiting-room-layout-controller.context'
import { WaitingRoomFilter } from './waiting-room.filter'
import { WaitingRoomList } from './waiting-room.list'

const waitingRoomFilterConfig = {
  // id: StringParam,
  // v1UserId: StringParam,
  // email: StringParam,
  // paypalEmail: StringParam,
  // name: StringParam,
  accountId: StringParam,
  // caseId: StringParam,
  patientId: StringParam,
  assignee: ArrayParam,
  status: StringParam,
  extendedStatus: StringParam,
  caseDiagnosisStatus: StringParam,
  documentNumber: StringParam,
  email: StringParam,
  followupMessageFromCustomerRequiresFeedback: StringParam,
  showWatchedCases: StringParam,
}

export const WaitingRoomPage = () => {
  return (
    <DataFilterV2Provider config={waitingRoomFilterConfig}>
      <WaitingRoomLayoutControllerProvider>
        <WaitingRoomPageContent />
      </WaitingRoomLayoutControllerProvider>
    </DataFilterV2Provider>
  )
}

const WaitingRoomPageContent = () => {
  const { controller } = useWaitingRoomLayoutController()
  const { employee } = useAuth()
  const [caseId] = useQueryParam('caseId', StringParam)

  if (!employee) {
    return null
  }

  return (
    <Box>
      <WaitingRoomFilter />

      <Grid
        container
        spacing={2}
        sx={{
          px: 4,
        }}
      >
        {!controller.hideWaitingRoomList && (
          <Grid item xs={2}>
            <Typography
              component="p"
              variant="caption"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Cases
            </Typography>
            <WaitingRoomList />
          </Grid>
        )}

        <Grid item xs={controller.hideWaitingRoomList ? 12 : 10}>
          <WaitingRoomCaseProvider caseId={caseId}>
            <WaitingRoomCase />
          </WaitingRoomCaseProvider>
        </Grid>
      </Grid>
    </Box>
  )
}
