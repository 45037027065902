import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { lightTheme } from '@trueskin-backoffice/themes'
import * as React from 'react'
import { ReactNode } from 'react'

export const ThemeContext = (props: ThemeContextProps) => {
  const prefersDarkMode = null // useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createTheme({
        ...props.theme,
        // palette: {
        //   mode: prefersDarkMode ? 'dark' : 'light',
        // },
      }),
    [prefersDarkMode],
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

export interface ThemeContextProps {
  children?: ReactNode
  theme?: object
}

ThemeContext.defaultProps = {
  theme: lightTheme,
}
