import { Alert, Box, Typography } from '@mui/material'
import { DateTime } from '@trueskin-backoffice/components'
import { useMemo } from 'react'
import { DunningDetails } from './dunning-details'

export enum InvoiceStatuses {
  PAID = 'paid',
  PAYMENT_DUE = 'payment_due',
  NOT_PAID = 'not_paid',
  VOIDED = 'voided',
}

export const InvoiceStatus = ({ invoice, showTime, hideCollectNow }: any) => {
  const statusElementMap: Record<string, JSX.Element> = useMemo(
    () => ({
      PAID: (
        <Alert
          severity="success"
          sx={{
            p: 0,
            width: 'fit-content',
            px: 2,
            display: 'flex',
            // width: '100%'
            '& .MuiAlert-message': {
              display: 'flex',
            },
          }}
        >
          <span>Paid on&nbsp;</span>
          <DateTime dateTime={invoice.paidAt} showTime={showTime} />
        </Alert>
      ),
      VOIDED: (
        <Alert
          severity="warning"
          sx={{
            width: 'fit-content',
            px: 1,
            py: 0,
            backgroundColor: 'rgb(170, 170, 170)',
            color: '#000',
            '& .MuiAlert-icon': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <Box>
            <Typography>
              Voided on{' '}
              <DateTime
                dateTime={
                  invoice.voidedAt || invoice.paidAt || invoice.generatedAt
                }
                showTime={showTime}
              />
            </Typography>
            <Typography>Void reason: {invoice.voidReason}</Typography>
          </Box>
        </Alert>
      ),
      NOT_PAID: (
        <Alert
          severity="error"
          sx={{
            p: 0,
            width: 'fit-content',
            px: 2,
          }}
        >
          Not paid
        </Alert>
      ),
      PAYMENT_DUE:
        invoice.dunningStatus ||
        (invoice.collectAt &&
          invoice.autoCollect &&
          new Date() < new Date(invoice.collectAt)) ? (
          <DunningDetails invoice={invoice} hideCollectNow={hideCollectNow} />
        ) : (
          <Alert
            severity="info"
            sx={{
              p: 0,
              width: 'fit-content',
              px: 2,
            }}
          >
            {invoice.collectAt &&
            invoice.autoCollect &&
            new Date() < new Date(invoice.collectAt) ? (
              <Box>
                Scheduled <DateTime dateTime={invoice.collectAt} />
              </Box>
            ) : (
              'Payment due'
            )}
          </Alert>
        ),
    }),
    [hideCollectNow, invoice, showTime],
  )

  return statusElementMap[invoice.status]
}
