import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { AvailabilitiesPage } from './availabilities.page'
import { CreateEmployeePage } from './create-employee.page'
import { EmployeePage } from './employee.page'
import { EmployeesPage } from './employees.page'
import { RolesPage } from './roles.page'
import { TeamsPage } from './teams.page'

import BadgeIcon from '@mui/icons-material/Badge'
import GroupsIcon from '@mui/icons-material/Groups'
import ListIcon from '@mui/icons-material/List'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'

export const EmployeesRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box sx={{ ml: 2, mr: 2 }}>
      <Box sx={{ mb: '60px' }}>
        <Routes>
          <Route path={'/'} element={<EmployeesPage />} />
          <Route path={'/create'} element={<CreateEmployeePage />} />
          <Route path={'/teams'} element={<TeamsPage />} />
          <Route path={'/roles'} element={<RolesPage />} />
          <Route path={'/availabilities'} element={<AvailabilitiesPage />} />
          <Route path={'/:employeeId'} element={<EmployeePage />} />
        </Routes>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '43%',
        }}
      >
        <BottomNavigation
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
          }}
          showLabels
          value={location.pathname}
          onChange={(event, newValue) => {
            navigate(newValue)
          }}
        >
          <BottomNavigationAction
            label="Employees"
            value={'/employees'}
            icon={<ListIcon />}
          />
          <BottomNavigationAction
            label="Teams"
            value={'/employees/teams'}
            icon={<GroupsIcon />}
          />
          <BottomNavigationAction
            label="Roles"
            value={'/employees/roles'}
            icon={<BadgeIcon />}
          />
          <BottomNavigationAction
            value={'/employees/availabilities'}
            label="Availabilities"
            icon={<PermContactCalendarIcon />}
          />
        </BottomNavigation>
      </Box>
    </Box>
  )
}
