import { Box, Card } from '@mui/material'
import { DataFilterV2Provider } from '@trueskin-backoffice/components'
import { StringParam } from 'use-query-params'
import { PricingFilters } from './pricing.filters'
import { PricingTable } from './pricing.table'

const PRICING_FILTER_INIT_VALUES = {
  productIds: StringParam,
  currency: StringParam,
  locale: StringParam,
  occurrence: StringParam,
  tags: StringParam,
}

export const PricingPage = () => {
  return (
    <Box>
      <DataFilterV2Provider config={PRICING_FILTER_INIT_VALUES}>
        <PricingFilters />

        <Card
          sx={{
            boxShadow:
              '0 2px 4px 0 rgb(0 0 30 / 12%), 0 4px 8px 0 rgb(0 0 30 / 12%)',
            overflow: 'unset',
            p: 2,
            maxWidth: '1500px',
            mx: 'auto',
          }}
        >
          <PricingTable />
        </Card>
      </DataFilterV2Provider>
    </Box>
  )
}
