import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { DockAction } from '@trueskin-backoffice/components'
import { useState } from 'react'
import { OrderVoucherAddDialog } from '../../components/order-voucher-add-dialog'
import { useOrder } from '../../contexts'

export const AddDiscountDockAction = () => {
  const { order } = useOrder()
  const [openVoucherAddDialog, setOpenVoucherAddDialog] =
    useState<boolean>(false)

  return (
    <>
      <DockAction
        startIcon={<LocalOfferIcon sx={{ transform: 'rotate(90deg)' }} />}
        disabled={
          order.payment.status !== 'upcoming' && order.payment.status !== 'open'
        }
        onClick={() => setOpenVoucherAddDialog(true)}
      >
        Add discount
      </DockAction>

      {openVoucherAddDialog && (
        <OrderVoucherAddDialog
          orderId={order._id}
          customerId={order.customer}
          open={openVoucherAddDialog}
          paymentStatus={order.payment.status}
          onDismiss={() => setOpenVoucherAddDialog(false)}
        />
      )}
    </>
  )
}
