import { gql } from '@apollo/client'

// TODO: improve query to not load all fields by default
/**
 * Do not resolve attachments, messages in this query as those fields trigger extra resolvers
 */
export const getAllCases = gql`
  query AllCasesQuery(
    $offset: Int!
    $limit: Int!
    $filter: WaitingRoomsFilter
  ) {
    waitingRoomCases(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        accountId
        customerId
        documentNumber
        status
        extendedStatus
        uploadedPhotosCount
        #        questionnaireSummary
        #        attachments
        #        messages
        #        paymentObject
        #        orderObject
        diagnosis
        assignedTo
        customer
        metadata

        questionnaireFields

        actions {
          canAssignCase {
            enabled
          }
          canAssignCaseDiagnosisContentRequest {
            enabled
          }
          canWriteMessage {
            enabled
          }
          canCreateDiagnosisDraft {
            enabled
          }
          canPublishDiagnosis {
            enabled
          }
          canRejectDiagnosis {
            enabled
          }
        }
      }
      totalRows
    }
  }
`

// export const waitingRoomCaseQuery = gql`
//   query WaitingRoomCaseQuery($caseId: String!) {
//     waitingRoomCase(caseId: $caseId) {
//       case {
//         id
//         accountId
//         customerId
//         documentNumber
//         status
//         extendedStatus
//         assignedTo
//         customer
//         metadata
//       }
//     }
//   }
// `

export const assignCaseToMeMutation = gql`
  mutation AssignCaseToMe(
    $customerId: String!
    $caseId: String!
    $doctor: DoctorProfileInput!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      assignDoctorToCase(doctor: $doctor, caseId: $caseId) {
        case {
          id
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
        }
      }
    }
  }
`

export const assignCaseDiagnosisContentToMeMutation = gql`
  mutation AssignCaseDiagnosisContentToMe(
    $customerId: String!
    $caseId: String!
    $diagnosisRecordId: String!
    $medicalAssistant: DoctorProfileInput!
  ) {
    patientCase(customerId: $customerId, caseId: $caseId) {
      assignMedicalAssistantToCaseDiagnosisContent(
        caseId: $caseId
        diagnosisRecordId: $diagnosisRecordId
        medicalAssistant: $medicalAssistant
      ) {
        case {
          id
          actions {
            canAssignCase {
              enabled
            }
            canAssignCaseDiagnosisContentRequest {
              enabled
            }
            canWriteMessage {
              enabled
            }
            canCreateDiagnosisDraft {
              enabled
            }
            canPublishDiagnosis {
              enabled
            }
            canRejectDiagnosis {
              enabled
            }
          }
          diagnosis
          accountId
          customerId
          documentNumber
          status
          extendedStatus
          assignedTo
          customer
          metadata
        }
      }
    }
  }
`
