import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Dialog, LinearProgress, Typography } from '@mui/material'
import {
  ColumnDef,
  DataTable,
  DateTimeDistanceToNow,
  HeroCardSection,
  IdTag,
  Pagination,
  Restricted,
  TagGroupItem,
  useDataFilterV2,
} from '@trueskin-backoffice/components'
import { useAuth } from '@trueskin-backoffice/contexts'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CaseGoals } from '../waiting-room/components/case-goals'
import { CasePhotosCountPreview } from '../waiting-room/components/case-photos-count-preview'
import { getPatientFullName } from '../waiting-room/utils'
import {
  assignCaseDiagnosisContentToMeMutation,
  assignCaseToMeMutation,
  getAllCases,
} from './cases.gql.bff'

const DEFAULT_PAGE_SIZE = 25

// TODO: ideally move this to components and
//  make it more configurable, like (showKeyIcon + copyToClipboard + copyToClipboardPosition)
const CaseIdLink = ({ customerId, caseId }: any) => {
  const {
    getCustomerLink,
    isCurrentEmployeeMedicalAssistant,
    isCurrentEmployeeDoctor,
  } = useAuth()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IdTag
            id={caseId}
            linkTo={getCustomerLink(`${customerId}/cases/${caseId}`)}
            allowCopyToClipboard
          />
        </Box>

        {(isCurrentEmployeeMedicalAssistant() || isCurrentEmployeeDoctor()) && (
          <Box>
            <NavLink
              to={getCustomerLink(`${customerId}/cases/${caseId}/medical-view`)}
            >
              <Typography fontSize={11}>Go to medical view</Typography>
            </NavLink>
          </Box>
        )}
      </Box>
    </Box>
  )
}

// TODO: Aggregate customers data with subscriptions
export const CasesTable = () => {
  const { isCurrentEmployeeDoctor, isCurrentEmployeeMedicalAssistant } =
    useAuth()

  const { query: filter } = useDataFilterV2()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const {
    data: {
      waitingRoomCases: { rows: cases = [], totalRows: totalCases = 0 } = {},
    } = {},
    loading,
    error,
    refetch,
  } = useQuery(getAllCases, {
    variables: {
      offset: 0,
      limit: pageSize,
      filter,
    },
  })

  const totalPages = Math.ceil(totalCases / pageSize)

  useEffect(() => {
    if (!loading) {
      refetch({ offset: (currentPage - 1) * pageSize, limit: pageSize, filter })
    }
  }, [pageSize, currentPage, filter])

  useEffect(() => {
    if (!loading) {
      setCurrentPage(1)
    }
  }, [filter])

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'id',
      header: 'Id',
      cell: ({ getValue, row }) => {
        return (
          <CaseIdLink
            caseId={getValue()}
            customerId={row.original.customerId}
            // currentCase={row.original}
          />
        )
      },
    },
    {
      accessorKey: 'questionnaireFields.treatmentGoals',
      header: 'Goals',
      cell: ({ getValue, row }) => {
        return (
          <CaseGoals currentCase={row.original} hideLabel />
          // <Box>
          //   {getValue() && (
          //     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          //       {getValue().map((x: string) => (
          //         <Typography key={x} variant="caption" fontWeight={600}>
          //           {x}
          //         </Typography>
          //       ))}
          //     </Box>
          //   )}
          // </Box>
        )
      },
    },
    {
      accessorKey: 'metadata.activatedAt',
      header: 'Since',
      cell: ({ getValue }) => {
        const activatedAt = getValue()
        if (!activatedAt) {
          return null
        }
        return (
          <Typography
            sx={{
              display: 'flex',
              fontSize: 11,
              fontWeight: 500,
              // mb: 1,
              // fontStyle: 'italic',
            }}
            variant="caption"
          >
            <DateTimeDistanceToNow
              dateTime={activatedAt}
              tooltipPlacement="right"
              strict={true}
            />
          </Typography>
        )
      },
    },
    {
      accessorKey: 'status',
      header: 'Status / Extended',
      cell: ({ row }) => {
        const { status, extendedStatus } = row.original
        return (
          <Box>
            <TagGroupItem sx={{ m: 1, py: 0, borderRadius: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    color: '#8181ad',
                  }}
                >
                  {status.replaceAll('_', ' ').toLowerCase()}
                </Box>
              </Box>
            </TagGroupItem>

            <TagGroupItem sx={{ m: 0, py: 0, borderRadius: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    color: '#8181ad',
                  }}
                >
                  {extendedStatus.replaceAll('_', ' ').toLowerCase()}
                </Box>
              </Box>
            </TagGroupItem>
          </Box>
        )
      },
    },
    // {
    //   accessorKey: 'extendedStatus',
    //   header: 'Extended Status',
    //   cell: ({ getValue }) => {
    //     return (
    //       <TagGroupItem sx={{ m: 0, py: 0, borderRadius: 1 }}>
    //         <Box sx={{ display: 'flex' }}>
    //           <Box
    //             sx={{
    //               color: '#8181ad',
    //             }}
    //           >
    //             {getValue().replaceAll('_', ' ').toLowerCase()}
    //           </Box>
    //         </Box>
    //       </TagGroupItem>
    //     )
    //   },
    // },
    {
      header: 'Doc. Number',
      accessorKey: 'documentNumber',
      cell: ({ getValue }) => {
        return <Typography fontSize={11}>{getValue()}</Typography>
      },
    },
    {
      header: 'Photos #',
      accessorKey: 'uploadedPhotosCount',
      cell: ({ getValue, row }) => {
        return (
          <CasePhotosCountPreview
            currentCase={row.original}
            uploadedPhotosCount={getValue()}
          />
        )
      },
    },
    // {
    //   accessorKey: 'customer.profile.email',
    //   header: 'Email',
    // },
    {
      accessorKey: 'customer.profile',
      header: 'Customer',
      cell: ({ getValue, row }) => {
        const fullName = getPatientFullName(row?.original?.customer)
        const email = row?.original?.customer?.profile?.email

        return (
          <Box sx={{ maxWidth: 250 }}>
            <Typography sx={{ lineBreak: 'anywhere' }}>{fullName}</Typography>
            <Typography
              sx={{
                display: 'flex',
                // fontSize: 16,
                fontWeight: 500,
                mb: 1,
                fontStyle: 'italic',
              }}
              variant="caption"
            >
              {email}
            </Typography>
          </Box>
        )
      },
    },
    {
      accessorKey: 'assignedTo',
      header: 'Assignee',
      cell: ({ getValue, row }) => {
        const assignee = getValue()

        // if (!assignee) {
        //   return <Typography>-</Typography>
        // }
        const canAssignToMeDoctor =
          isCurrentEmployeeDoctor() &&
          row?.original?.status?.toLowerCase() === 'active'
        // &&
        // !assignee
        const canAssignToMeAssistant =
          isCurrentEmployeeMedicalAssistant() &&
          row?.original?.status?.toLowerCase() === 'active' &&
          !row?.original?.diagnosis?.contentRequest?.assignedTo

        // if (isCurrentEmployeeDoctor()) {
        //   if (row?.original?.status?.toLowerCase() !== 'active') {
        //     return null
        //   }
        //
        //   // const assignee = row?.original?.assignee
        //
        //   if (!assignee) {
        //     return <AssignCaseToMeButton currentCase={row.original} />
        //   }
        // }
        //
        // if (isCurrentEmployeeMedicalAssistant()) {
        //   if (
        //     row?.original?.diagnosis?.diagnosisStatus?.toLowerCase() !==
        //     'requires_content'
        //   ) {
        //     return null
        //   }
        //
        //   const assignee = row?.original?.diagnosis?.contentRequest?.assignedTo
        //
        //   if (!assignee) {
        //     return (
        //       <AssignCaseDiagnosisContentToMeButton
        //         currentCase={row.original}
        //       />
        //     )
        //   }
        // }

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ mr: 1 }}>
              {!assignee
                ? 'None'
                : `${assignee.firstName} ${assignee.lastName}`}
            </Typography>

            {canAssignToMeDoctor ? (
              <AssignCaseToMeButton currentCase={row.original} />
            ) : null}

            {canAssignToMeAssistant ? (
              <AssignCaseDiagnosisContentToMeButton
                currentCase={row.original}
              />
            ) : null}
          </Box>
        )
      },
    },
    // {
    //   accessorKey: 'actions',
    //   header: 'Actions',
    //   cell: ({ getValue, row }) => {
    //     if (isCurrentEmployeeDoctor()) {
    //       if (row?.original?.status?.toLowerCase() !== 'active') {
    //         return null
    //       }
    //
    //       const assignee = row?.original?.assignee
    //
    //       if (!assignee) {
    //         return <AssignCaseToMeButton currentCase={row.original} />
    //       }
    //     }
    //
    //     if (isCurrentEmployeeMedicalAssistant()) {
    //       if (
    //         row?.original?.diagnosis?.diagnosisStatus?.toLowerCase() !==
    //         'requires_content'
    //       ) {
    //         return null
    //       }
    //
    //       const assignee = row?.original?.diagnosis?.contentRequest?.assignedTo
    //
    //       if (!assignee) {
    //         return (
    //           <AssignCaseDiagnosisContentToMeButton
    //             currentCase={row.original}
    //           />
    //         )
    //       }
    //     }
    //
    //     return null
    //   },
    // },
    // {
    //   accessorKey: 'medicalData.birthdate',
    //   header: 'Bi Date',
    //   cell: ({ getValue }) => {
    //     return (
    //       <Box>
    //         <DateTime dateTime={getValue()} tooltipPlacement="right" />
    //       </Box>
    //     )
    //   },
    // },
  ]

  if (error) {
    return <Typography color={'red'}>{error.message}</Typography>
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        data={cases}
        isLoading={loading}
        page={currentPage}
        totalPages={100}
        rowsPerPage={pageSize}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setPageSize}
      />

      {totalPages > 1 && (
        <Box>
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={setPageSize}
          />
        </Box>
      )}
    </Box>
  )
}

const AssignCaseToMeButton = ({ currentCase }: any) => {
  const { getDoctorProfile, employee } = useAuth()
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false)

  const [
    assignCaseToMe,
    { loading: assignCaseToMeLoading, error: assignCaseToMeError },
  ] = useMutation(assignCaseToMeMutation, {
    variables: {
      customerId: currentCase.customerId,
      caseId: currentCase.id,
      doctor: getDoctorProfile(),
    },
  })

  if (currentCase?.assignedTo?.id === employee?.id) {
    return null
  }

  return (
    <Restricted to={['doctor']}>
      <Button
        sx={{ textTransform: 'unset', p: 0, px: 1, fontSize: 12 }}
        onClick={async () => {
          if (
            !currentCase?.assignedTo?.id ||
            currentCase?.assignedTo?.id === employee?.id
          ) {
            await assignCaseToMe()
          } else {
            setShowConfirmationDialog(true)
          }
        }}
        disabled={assignCaseToMeLoading}
      >
        Assign to me
      </Button>

      {showConfirmationDialog ? (
        <Dialog
          open={showConfirmationDialog}
          keepMounted
          onClose={() => setShowConfirmationDialog(false)}
        >
          <Box sx={{ overflow: 'visible', p: 0 }}>
            <Box sx={{ mb: 8 }}>
              <Typography variant="h1" fontWeight={500}>
                Reassign case #{currentCase.documentNumber} for{' '}
                {currentCase.customer.profile.firstName}{' '}
                {currentCase.customer.profile.lastName}
              </Typography>
            </Box>

            <HeroCardSection sx={{ mb: 1 }}>
              <Typography>
                This case is currently assigned to{' '}
                <Box
                  component="span"
                  sx={{ fontStyle: 'italic', fontWeight: 500 }}
                >
                  {currentCase?.assignedTo?.title}{' '}
                  {currentCase?.assignedTo?.firstName}{' '}
                  {currentCase?.assignedTo?.lastName}
                </Box>
                .
              </Typography>
              <Typography>
                Are you sure you want to reassign it to yourself?
              </Typography>

              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}
              >
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  sx={{ borderRadius: '0 4px 4px 0', boxShadow: 'none', mt: 2 }}
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ borderRadius: '0 4px 4px 0', boxShadow: 'none', mt: 2 }}
                  onClick={async () => await assignCaseToMe()}
                >
                  Reassign
                </Button>
              </Box>
            </HeroCardSection>
          </Box>
        </Dialog>
      ) : null}
    </Restricted>
  )
}

const AssignCaseDiagnosisContentToMeButton = ({ currentCase }: any) => {
  const { employee, getMedicalAssistantProfile } = useAuth()
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false)

  const [
    assignCaseDiagnosisContentToMe,
    {
      loading: assignCaseDiagnosisContentToMeLoading,
      error: assignCaseDiagnosisContentToMeError,
    },
  ] = useMutation(assignCaseDiagnosisContentToMeMutation, {
    variables: {
      customerId: currentCase.customerId,
      caseId: currentCase.id,
      diagnosisRecordId: currentCase.diagnosis.diagnosisRecordId,
      medicalAssistant: getMedicalAssistantProfile(),
    },
  })

  // if (currentCase?.assignedTo?.id === employee?.id) {
  //   return null
  // }

  if (!currentCase?.actions?.canAssignCaseDiagnosisContentRequest?.enabled) {
    return null
  }

  // TODO: look to remove Restrict completely and rely on X.actions everywhere
  return (
    <Restricted to={['medicalassistant']}>
      <Button
        sx={{ textTransform: 'unset', p: 0, px: 1 }}
        onClick={async () => {
          if (
            !currentCase?.diagnosis?.contentRequest?.assignedTo?.id ||
            currentCase?.diagnosis?.contentRequest?.assignedTo?.id ===
              employee?.id
          ) {
            await assignCaseDiagnosisContentToMe()
          } else {
            setShowConfirmationDialog(true)
          }
        }}
        disabled={assignCaseDiagnosisContentToMeLoading}
      >
        Assign content creation to me
      </Button>

      {showConfirmationDialog ? (
        <Dialog
          open={showConfirmationDialog}
          keepMounted
          onClose={() => setShowConfirmationDialog(false)}
        >
          <Box sx={{ overflow: 'visible', p: 0 }}>
            <Box sx={{ mb: 8 }}>
              <Typography variant="h1" fontWeight={500}>
                Reassign case #{currentCase.documentNumber} for{' '}
                {currentCase.customer.profile.firstName}{' '}
                {currentCase.customer.profile.lastName}
              </Typography>
            </Box>

            <HeroCardSection sx={{ mb: 1 }}>
              <Typography>
                The content creation for this case's diagnosis is currently
                assigned to{' '}
                <Box
                  component="span"
                  sx={{ fontStyle: 'italic', fontWeight: 500 }}
                >
                  {currentCase?.assignedTo?.title}{' '}
                  {currentCase?.assignedTo?.firstName}{' '}
                  {currentCase?.assignedTo?.lastName}
                </Box>
                .
              </Typography>
              <Typography>
                Are you sure you want to reassign it to yourself?
              </Typography>

              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}
              >
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  sx={{ borderRadius: '0 4px 4px 0', boxShadow: 'none', mt: 2 }}
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ borderRadius: '0 4px 4px 0', boxShadow: 'none', mt: 2 }}
                  onClick={async () => await assignCaseDiagnosisContentToMe()}
                >
                  Reassign
                </Button>
              </Box>
            </HeroCardSection>
          </Box>
        </Dialog>
      ) : null}
    </Restricted>
  )
}
