import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import {
  DataFilterV2,
  DataFilterV2FilterComponent,
  DataFilterV2InputOperation,
} from '@trueskin-backoffice/components'
import { useNavigate } from 'react-router-dom'

const VOUCHER_TYPES = ['marketing', 'cs', 'product', 'referral', 'system']

export const VouchersFilters = () => {
  const navigate = useNavigate()

  return (
    <DataFilterV2
      inputRows={[
        [
          {
            label: 'Search for code to contain',
            key: 'codeContains',
            type: DataFilterV2FilterComponent.TEXT,
            op: DataFilterV2InputOperation.CONTAINS,
          },
          {
            type: DataFilterV2FilterComponent.DIVIDER,
            borderVisible: false,
          },
        ],
      ]}
      additionalFilters={[
        {
          label: 'Active',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'active',
          op: DataFilterV2InputOperation.IS,
          options: [true, false].map((item: boolean) => ({
            label: item.toString(),
            value: item.toString(),
          })),
        },
        {
          label: 'Code',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'code',
          op: DataFilterV2InputOperation.EQUALS,
        },
        {
          label: 'Discount group id',
          component: DataFilterV2FilterComponent.TEXT,
          key: 'discountGroupId',
          op: DataFilterV2InputOperation.EQUALS,
        },
        {
          label: 'Type',
          component: DataFilterV2FilterComponent.SELECT,
          key: 'type',
          op: DataFilterV2InputOperation.IS,
          options: VOUCHER_TYPES.map((item: string) => ({
            label: item,
            value: item,
          })),
        },
      ]}
    >
      <Button onClick={() => navigate('/vouchers/create')} startIcon={<Add />}>
        Voucher
      </Button>
    </DataFilterV2>
  )
}
