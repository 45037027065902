import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { DataFilterV2InputConfigToggle } from '../data-filter-input-config'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  // '& .Mui-selected': {
  //   backgroundColor: '#e2e9f9',
  // },

  '& .MuiToggleButtonGroup-grouped': {
    fontSize: 14,
    textTransform: 'unset',
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    color: '#3367d6',

    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      // marginLeft: theme.spacing(1),
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

export const DataFilterToggleInput = ({
  input,
}: // addToFilter,
// filter,
{
  input: DataFilterV2InputConfigToggle
  // addToFilter: any
  // filter: any
}) => {
  const [inputValue, setInputValue] = useQueryParam(input.key, StringParam)

  const [filterValue, setFilterValue] = useState<string | null | undefined>(
    inputValue,
  )

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!filterValue && inputValue) {
        setInputValue(undefined)
      } else {
        setInputValue(filterValue)
      }
    }, 250)

    return () => clearTimeout(getData)
  }, [filterValue])

  useEffect(() => {
    setFilterValue(inputValue)
  }, [inputValue])

  return (
    <StyledToggleButtonGroup
      size="small"
      value={filterValue}
      exclusive
      onChange={(_, newValue) => setFilterValue(newValue)}
    >
      {input.options.map(({ value, component }, index) =>
        index === 0 ? (
          <ToggleButton key={value} value={value}>
            {component}
          </ToggleButton>
        ) : (
          [
            <Box
              key={`${value}_sep`}
              sx={{
                fontSize: 16,
                userSelect: 'none',
                lineHeight: 1.4,
                color: 'rgba(0,0,0,0.6)',
              }}
            >
              /
            </Box>,
            <ToggleButton key={value} value={value}>
              {component}
            </ToggleButton>,
          ]
        ),
      )}
    </StyledToggleButtonGroup>
  )
}
