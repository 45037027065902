import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from '@apollo/client'
import { useAuth } from '@trueskin-backoffice/contexts'
import React, { ReactNode, useMemo } from 'react'
import {
  createCommentMutation,
  getCommentsQuery,
} from '../gql/comments.gql.bff'

interface StandardProps {
  children: ReactNode
  namespace: string
}

export interface Comment {
  id: string
  important: boolean
  updatedAt: string
  createdAt: string
  content: {
    text?: string
    richText?: string
  }
  author: {
    type: string
    profile: {
      id: string
      email: string
      firstName: string
      lastName: string
      title?: string
      avatarUrl?: string
    }
  }
}

export interface CommentsContextProps {
  comments: Comment[]
  createComment: (
    options?:
      | MutationFunctionOptions<Comment, any, DefaultContext, ApolloCache<any>>
      | undefined,
  ) => Promise<any>
  loading: boolean
  error: any
}

const CommentsContext = React.createContext<CommentsContextProps | undefined>(
  undefined,
)

export const CommentsProvider = ({ namespace, children }: StandardProps) => {
  const { employee } = useAuth()

  const { data, loading, error, refetch } = useQuery(getCommentsQuery, {
    variables: {
      namespace,
    },
  })

  const [createComment] = useMutation(createCommentMutation, {
    variables: {
      namespace,
      author: {
        id: employee?.id,
        firstName: employee?.firstName,
        lastName: employee?.lastName,
        email: employee?.email,
        title: employee?.profile?.doctor?.title,
        avatarUrl: employee?.profile?.doctor?.profileImage?.locationUrl,
      },
    },
    onCompleted: () => refetch(),
  })

  const sortedComments = useMemo(() => {
    const comments = [...(data?.comments || [])] as Comment[]

    comments?.sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    )

    return comments
  }, [data])

  return (
    <CommentsContext.Provider
      value={{
        comments: sortedComments,
        createComment,
        loading,
        error,
      }}
    >
      {children}
    </CommentsContext.Provider>
  )
}

export const useComments = (): CommentsContextProps => {
  const context = React.useContext(CommentsContext)

  if (context === undefined) {
    throw new Error(`useComments must be used within a CommentsProvider`)
  }

  return context
}
