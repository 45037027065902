import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import { useV2Query } from '@trueskin-backoffice/api-client'
import { JsonButton, Table } from '@trueskin-backoffice/components'
import { formatDateTime } from '@trueskin-backoffice/functions'
import React, { useState } from 'react'
import { DecodedValueMap } from 'use-query-params'
import { getAllCronJobs } from './cron-jobs.gql'
import { CronJobFilterProps } from './cron-jobs.page'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
}

const LIMIT = 20

interface Props {
  filter: DecodedValueMap<CronJobFilterProps>
}

export const CronJobsTable = ({ filter }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [json, setJson] = useState('')

  const handleOpenModal = (jsonData: any) => (event: any) => {
    event.stopPropagation()
    setJson(jsonData)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setJson('')
    setOpenModal(false)
  }

  const {
    data: allCronJobsData,
    loading: allCronJobsLoading,
    error: allCronJobsError,
    fetchMore: fetchMoreCronJobs,
  } = useV2Query(getAllCronJobs, {
    variables: { limit: LIMIT, offset: 0, filter: { isArchived: false } },
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 325,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 150,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
      },
      {
        Header: 'Scheduler name',
        accessor: 'schedulerName',
        width: 300,
      },
      {
        Header: 'Start date',
        accessor: 'startDate',
        Cell: ({ row }: any) => {
          return (
            <div>
              {row?.original?.startDate
                ? formatDateTime(row?.original.startDate)
                : '-'}
            </div>
          )
        },
      },
      {
        Header: 'End date',
        accessor: 'endDate',
        Cell: ({ row }: any) => {
          return (
            <div>
              {row?.original?.endDate
                ? formatDateTime(row?.original.endDate)
                : '-'}
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }: any) => {
          return <JsonButton jsonData={row.original} />
        },
      },
    ],
    [],
  )

  if (allCronJobsLoading) {
    return <LinearProgress />
  }

  if (allCronJobsError) {
    return <Typography color={'red'}>{allCronJobsError.message}</Typography>
  }

  const fetchMoreData = () => {
    fetchMoreCronJobs({
      variables: {
        offset: allCronJobsData.getAllCronJobs.length,
      },
      updateQuery(
        { getAllCronJobs: prevCronJobs },
        { fetchMoreResult: { getAllCronJobs: nextCronJobs } },
      ) {
        return { getAllCronJobs: [...prevCronJobs, ...nextCronJobs] }
      },
    })
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </Box>
      </Modal>

      <Table
        columns={columns}
        data={allCronJobsData.getAllCronJobs}
        update={fetchMoreData}
      />
    </>
  )
}
