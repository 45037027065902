import { gql } from '@apollo/client'

export const customerQuery = gql`
  query Customer($customerId: String, $v1UserId: String) {
    customer(customerId: $customerId, v1UserId: $v1UserId) {
      id
      accountId
      v2AccountId
      type
      firstName
      lastName
      email
      v1UserId
      customerId
      birthDate
      phoneNumber

      settings {
        emailConfirmed
        newsletterSubscription
        smsCheckInReminder
        isTest
      }

      referral {
        refereeDiscountGroupId
        referrerDiscountBonus {
          amount
          currency
        }
        code
        referredBy
        referredAt
        count
        balance {
          used
          available
          currency
        }
      }

      preferences {
        locale
        shippingPharmacy
        primaryDoctor {
          id
          name
        }
      }

      addressBook {
        billingAddress {
          id
          country
          city
          firstName
          lastName
          street
          CPF
          phoneNumber
          postalCode
          neighborhood
          state
          streetNumber
          additionalAddress
        }
        shippingAddress {
          id
          country
          city
          firstName
          lastName
          street
          CPF
          phoneNumber
          postalCode
          neighborhood
          state
          streetNumber
          additionalAddress
        }
        isShippingSameAsBilling
        actions {
          canEditBillingAddress {
            enabled
          }
          canEditShippingAddress {
            enabled
          }
        }
      }

      actions {
        canDelete {
          enabled
        }
      }
    }
  }
`
