import { Button, ButtonProps, styled } from '@mui/material'
import React from 'react'

export const DangerButton = styled((props: ButtonProps) => (
  <Button
    sx={{
      textTransform: 'unset',
    }}
    variant="contained"
    color="error"
    disableElevation
    disableRipple
    disableFocusRipple
    disableTouchRipple
    {...props}
  />
))(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 24%)',
  },
}))
