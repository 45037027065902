import { Box } from '@mui/material'
import { useAuth } from '@trueskin-backoffice/contexts'
import React, { useEffect } from 'react'

// TODO @fox: looking at this url now, I am figuring out that the prod backoffice BR will connect to this iframe located in EU
//  we should revisit a specific deployment of connect.formelskin.com.br
//  but this would suck because it means the google client of this app will always have to be updated when we release a new country
const AUTH_CONNECT_URL = 'https://connect.formelskin.de/iframe'

export const Login = () => {
  const { loginWithGoogle } = useAuth()

  function decodeJWT(token: string) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== new URL(AUTH_CONNECT_URL).origin) {
          return
        }

        const jwtCredential = event.data.response.credential
        const credential = decodeJWT(jwtCredential)

        return loginWithGoogle({
          variables: {
            identifier: credential.email,
            accessToken: jwtCredential,
          },
        })
      },
      false,
    )
  }, [])

  return (
    <Box
      component="iframe"
      src={AUTH_CONNECT_URL}
      border="none"
      frameBorder={0}
      width="100%"
      height="100%"
      style={{ minHeight: '40vh' }}
      scrolling="no"
    ></Box>
  )
}
