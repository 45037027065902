import {
  Box,
  Skeleton,
  styled,
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  TableMeta,
  useReactTable,
} from '@tanstack/react-table'
import { FC, Fragment } from 'react'

const StyledRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.grey[200],
  // },
  // ':hover': {
  //   backgroundColor: theme.palette.grey[300],
  // },

  '&:last-child td': {
    border: 0,
  },
}))

interface TableProps {
  columns: ColumnDef<any>[]
  data: any[]
  page?: number
  totalPages?: number
  rowsPerPage?: number
  isLoading?: boolean
  onPageChange?: (page: number) => void
  onRowsPerPageChange?: (rowsCount: number) => void
  renderSubComponent?: (props: { row: Row<any> }) => React.ReactElement
  getRowCanExpand?: (row: Row<any>) => boolean
  subRowsKey?: string
  meta?: TableMeta<any>
}
//todo: rename this to Table, after dropping legacy table
const DataTable: FC<TableProps> = ({
  columns,
  data,
  page,
  totalPages,
  rowsPerPage = 10,
  isLoading,
  onPageChange,
  onRowsPerPageChange,
  getRowCanExpand,
  renderSubComponent,
  subRowsKey = 'subRows',
  meta,
  ...props
}: TableProps) => {
  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data,
    columns,
    getRowCanExpand,
    getSubRows: (row) => row[subRowsKey],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    pageCount: totalPages,
    meta,
  })

  const skeletons = Array.from({ length: rowsPerPage }, (x, i) => i)
  const columnCount = getAllColumns().length

  return (
    <Fragment>
      <Box style={{ overflow: 'auto' }}>
        <MUITable {...props}>
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow key={`header_${headerGroup.id}`}>
                {headerGroup.headers.map((header, index) => (
                  <TableCell key={`cell_${header.id}_${index}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {!isLoading ? (
              getRowModel().rows.map((row) => (
                <>
                  <StyledRow key={`row_${row?.original?.id || row.id}`}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={`cell_${cell.id}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </StyledRow>
                  {getRowCanExpand
                    ? row.getIsExpanded() && (
                        <StyledRow key={`sub_${row.id}`}>
                          <TableCell
                            key={`sub_cell_${row.id}`}
                            colSpan={row.getVisibleCells().length}
                          >
                            {renderSubComponent
                              ? renderSubComponent({ row })
                              : null}
                          </TableCell>
                        </StyledRow>
                      )
                    : null}
                </>
              ))
            ) : (
              <>
                {skeletons.map((skeleton) => (
                  <TableRow key={`skeleton_${skeleton}`}>
                    {Array.from({ length: columnCount }, (x, i) => i).map(
                      (elm) => (
                        <TableCell key={elm}>
                          <Skeleton height={12} />
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </MUITable>
      </Box>
      {/* {totalPages && page && (
          <Box>
            <Pagination
              page={page}
              totalPages={totalPages}
              pageSize={rowsPerPage}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </Box>
        )} */}
    </Fragment>
  )
}

export { DataTable, ColumnDef }
